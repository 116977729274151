/* eslint-disable no-dupe-keys */
<template>
  <v-app-bar
    height="auto"
    elevation="0"
    fixed
    :color="
      bg === 'rgba(12, 17, 20, 0.72)' ? 'rgba(12, 17, 20, 0.72)' : bg
    "
    style="background: transparent !important"
    :class="[icon !== 'menu' ? 'appBar-none' : 'appBar']"
    :style="{ background: bg }"
    class="ipad "
  >
    <page-layout>
      <template #Content>
        <div class="tw-flex tw-w-full">
          <div
            class="
              tw-hidden
              lg:tw-flex
              tw-w-full tw-items-center tw-py-5 tw-justify-between
            "
          >
            <!-- web starts here -->
            <!-- first -->
            <img
              src="../assets/logos/white.png"
              width="146px"
              height="40px"
              alt="logo"
              class="tw-cursor-pointer"
              @click="routeToHome"
            />
            <!-- second -->
            <div
              class="tw-flex tw-flex-row tw-items-center"
              style="height: 40px"
            >
              <v-tabs
                v-model="currentTab"
                slider-color="#004aad"
                :hide-slider="
                  $route.name === 'HomePage' ||
                  $route.name === 'TransportCompanyRegister' ||
                  $route.name === 'Login' ||
                  $route.name === 'RegisterAs' ||
                  $route.name === 'Career' ||
                  $route.name === 'JobOpenings'||
                  $route.name === 'TermsAndPolicies'||
                  $route.name === 'OneWaySelectSeats'||
                  $route.name === 'OneWayInputDetails'||
                  $route.name === 'OneWayCheckout' ||
                  $route.name === 'HireSelectVehicle' ||
                  $route.name === 'HireLocation' ||
                  $route.name === 'HireInputDetails'||
                  $route.name === 'HirePayment'
                "
              >
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        v-on="on"
                        class="v-tab"

                    >
                      Products <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list class="product-container tw-py-5" >
                    <v-list-item
                        v-for="(item, index) in products"
                        :key="index" class="product-item tw-cursor-pointer">
                      <div class="tw-flex tw-flex-row tw-items-center
                      tw-w-full tw-p-5 tw-cursor-pointer" reveal.reset="{delay:300*index}">
                        <icons :name="item.iconName"/>
                        <div class="tw-flex tw-flex-col tw-w-auto tw-items-start tw-pl-5">
                          <h6 class="product-container-header">{{item.title}}</h6>
                          <label class="product-container-desc">{{item.description}}</label>
                        </div>
                      </div>
                    </v-list-item>

                  </v-list>
                </v-menu>
                <v-tab  to="/about">About</v-tab>
                <v-tab to="/blog">Blog</v-tab>
<!--                <v-tab @click="routeToPages('Developer')">Developers</v-tab>-->
                <v-tab to="/faqs">F.A.Qs</v-tab>
                <v-tab to="/contact">Contact</v-tab>
                <v-tab to="/booking">Book Trip</v-tab>
              </v-tabs>
            </div>

            <!-- third -->
            <div class="tw-w-auto">
              <router-link
                to="/login"
                class="appBar-title tw-w-auto tw-mr-5"
                style="color: #fff"
                >Sign In</router-link
              >
              <router-link to="/register-as" class="appBar-title signup tw-py-2"
                >Get Started
              </router-link>
            </div>
          </div>

          <!-- web ends here -->
          <div
            class="lg:tw-hidden tw-w-full tw-flex tw-flex-col tw-h-auto py-2 "

          >
            <div
              class="
                tw-flex tw-w-full tw-justify-between tw-items-center tw-px-4 small-screen
              "
            >
              <img
                src="../assets/logos/white.png"
                width="160
"
                height="40"
                @click="$router.push({ name: 'HomePage' })"
                class="tw-cursor-pointer"
              />
              <v-icon color="white" v-if="icon === 'menu'" @click="toggelIcon" class="tw-cursor-pointer">mdi-menu</v-icon>
              <v-icon color="white" v-else @click="toggelIcon" class="tw-cursor-pointer">mdi-window-close</v-icon>

            </div>
            <div
              class="tw-flex tw-flex-col tw-px-5"
              v-if="icon === 'x'"
              :class="[icon === 'x' ? 'anim' : '']"
            >
              <v-tabs
                vertical
                v-model="currentTab"
                slider-color="#004aad"
                :hide-slider="
                  $route.name === 'HomePage' ||
                  $route.name === 'TransportCompanyRegister' ||
                  $route.name === 'Login' ||
                  $route.name === 'RegisterAs' ||
                  $route.name === 'Career' ||
                  $route.name === 'JobOpenings'||
                  $route.name === 'TermsAndConditions'||
                  $route.name === 'OneWaySelectSeats'||
                  $route.name === 'OneWayInputDetails'||
                  $route.name === 'OneWayCheckout' ||
                  $route.name === 'HireSelectVehicle' ||
                  $route.name === 'HireLocation' ||
                  $route.name === 'HireInputDetails'||
                  $route.name === 'HirePayment'
                ">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        v-on="on"
                        class="v-tab"
                    >
                      Products <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list >
                    <v-list-item
                        v-for="(item, index) in products"
                        :key="index">
                      <div class="tw-flex tw-w-full tw-items-center tw-py-5 product-item">
                        <icons :name="item.iconMobileName" />
                        <h6 class="product-container-header tw-pl-5">{{item.title}}</h6>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-tab @click="closeToggle" to="/about">About</v-tab>
                <v-tab @click="closeToggle" to="/blog">Blog</v-tab>
<!--                <v-tab @click="routeToPages('Developer')">Developers</v-tab>-->
                <v-tab @click="closeToggle" to="/faqs">F.A.Qs</v-tab>
                <v-tab @click="closeToggle" to="/contact">Contact</v-tab>
                <v-tab to="/booking">Book Trip</v-tab>
              </v-tabs>
              <div class="item-css tw-flex">
                <label class="sign-in tw-mr-5" @click="$router.push({ name: 'Login' })">Sign In</label>
                <v-btn
                  class="get-started tw-p-2"
                  text
                  color="white"
                  @click="$router.push({ name: 'RegisterAs' })"
                  >Get Started</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </template>
    </page-layout>
  </v-app-bar>
</template>

<script>
import PageLayout from "@/components/reuseables/PageLayout";
import Icons from "@/components/reuseables/Icons.vue";
export default {
  name: "NavbarWhite",
  components: {
    Icons,
    PageLayout,
  },
  data() {
    return {
      icon: "menu",
      menus: ["About", "Blog", "Developer", "F.A.Qs", "Contact"],
      bg: "",
      currentTab: "Blog",
      products:[
        {
          title: 'RIBS',
          iconName: 'ribs-icon-web',
          iconMobileName : 'ribs-icon-mobile',
          description:"Nomadicpod’s Inventory & Booking System for Transport Companies."
        },
        {
          title: 'FLAIR',
          iconName : 'flair-icon-web',
          iconMobileName : 'flair-icon-mobile',
          description: "Fleet AI by Nomadicpod for Corporate Organizations with Large Fleet"
        },
        {
          title: 'TAR',
          iconName: 'tar-icon-web',
          iconMobileName : 'tar-icon-mobile',
          description: "Travel Agents by Nomadicpod"
        },
      ]
    };
  },
  watch: {
    "$route.name": {
      handler: function (val) {
        if (val === "About") {
          this.currentTab = "About";
        } else if (val === "Blog") {
          this.currentTab = "Blog";
        } else if (val === "Contact") {
          this.currentTab = "Contact";
        }
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    toggelIcon() {
      console.log(this.icon)
      if (this.icon === "menu") {
        this.icon = "x";
        this.bg= "rgba(12, 17, 20, 0.9)"
      } else {
        this.icon = "menu";
        this.bg= "transparent"
      }
    },
    closeToggle(){
      this.icon = "menu"
    },
    routeToHome() {
      if (this.$route.name !== "HomePage")
        this.$router.push({ name: "HomePage" });
    },
    routeToPages(name) {
      if (name === "Developer") {
        window.open("https://developer-roadpadi.netlify.app", "_self");
      }
    },

    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.bg = "rgba(12, 17, 20, 0.72)";
      } else {
        this.bg = "transparent";
      }
    },
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
};
</script>

<style scoped lang="scss">
.v-tab {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 24px;
  color: #fff !important;
  text-transform: none;
  @media (max-width: 1024px) {
    max-width: 100% !important;
    justify-content: left;
    padding: 20px;
  }
}

::v-deep .theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}
.ipad {
  z-index: 2;
  padding-right: 10rem !important;
  padding-left: 10rem !important;

  @media screen and (min-width: 768px) and (max-width: 1264px) {
    padding: 0.5rem 1rem !important;
  }

  @media screen and (max-width: 768px) {
    padding-top: 1rem;
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

}

.small-screen{
  @media screen and (max-width: 370px) {
    padding:  0 0.5rem;
  }
}

.appBar-title {
  font-family: "Inter", serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-transform: none !important;
  text-align: left;
  color: #263238;
}

.item-css {
  width: 100%;
  height: 76px;
  cursor: pointer;
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #154b94;
  @media (max-width: 1024px) {
    padding: 10px 20px;
  }
}

.anim {
  transition: 5s;
  transition-timing-function: ease-in-out;
  transform: translateY(0);
  opacity: 1;
  animation: infinite;
}

.item-css:hover {
  height: 76px;
  background: rgba(124, 176, 246, 0.05);
  width: 100%;
  cursor: pointer;
}

.sign-in {
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #fff;
}

.get-started {
  border: 1px solid #004aad;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #004aad;
}

.appBar-title.signup {
  background: #004aac;
  border-radius: 6px;
  color: #ffffff;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 3rem !important;
}

.v-toolbar__content {
  padding: 4px 0px !important;
}

::v-deep .v-toolbar__content,
.v-toolbar__extension {
  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: rgba(12, 17, 20, 0.001)
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 46px!important;
}
::v-deep .v-btn__content {
  font-family: Raleway, sans-serif !important;
}

.product-container{
  width: 424px;
  min-height: 331px;
  background: #FDFFFC;
  box-shadow: 0 4px 60px rgba(38, 50, 56, 0.1);
  border-radius: 10px;
}

.product-item:hover{
  background-color: #FDFFDD;

}
.product-container-header{
  font-family: 'Raleway',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #263238;
}
.product-container-desc{
  font-family: 'Raleway',sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #263238;
  cursor: pointer;
}
</style>