<template>
   <section class="
          four
          tw-py-8
          md:tw-pl-32
          tw-pl-6
          md:tw-pr-24
          tw-pr-3
          md:tw-mt-10
          tw-mt-5
          hundred-vh
        ">
        <v-row class="
            tw-flex
            md:tw-flex-row
            tw-flex-col
            md:tw-items-center md:tw-justify-center md:tw-h-full">
          <v-col sm="12" md="5">
            <div class="tw-flex tw-flex-col lg:tw-pl-12 tw-px-0">
              <p class="first-header">Special Feature</p>
              <p class="text-header">Introducing Traveller's Codes</p>
              <p class="sub-text tw-pb-8">
                With your personalized traveller's code, you can fill all your
                details in before getting to the terminal.
              </p>
              <p @click="$router.push({ name: 'PassengerBooking' })" class="btn tw-cursor-pointer">
                Get Your Traveller's Code
              </p>
            </div>
          </v-col>
          <v-col sm="12" md="7" class="second-col">
            <img src="../assets/homepage/sf-sbg.svg" alt="" class="web-img" />
            <img src="../assets/qr-code.svg" alt="" class="mobile-img" />
            <div class="first-box tw-flex-col tw-flex tw-justify-center tw-px-5">
              <p class="header tw-m-0">Fill Once and Never Fill Again</p>
              <p class="sub-text tw-m-0">
                Once you fill in the details for your traveller's code, you can
                Reuse your personal traveller detail at any transporter.
              </p>
            </div>
            <div class="second-box tw-flex-col tw-flex tw-justify-center tw-px-5">
              <p class="header tw-m-0">Avoid Long Queues</p>
              <p class="sub-text tw-m-0">
                With your traveller's code you can book a trip in the comfort of
                your home and avoid the long queues
              </p>
            </div>
            <div class="third-box tw-flex-col tw-flex tw-justify-center tw-px-5">
              <p class="header tw-m-0">Save Time</p>
              <p class="sub-text tw-m-0">
                Let's get you going quickly. Spend time doing more productive things and not standing in queues.
              </p>
            </div>
          </v-col>
        </v-row>
      </section>
</template>

<script>
export default {

}
</script>

<style lang='scss' scoped>

  .four {
    background-image: url("../assets/fifth-back.svg");
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 768px) {
      padding-left: 1.5rem;
    }

    @media screen and (max-width: 360px) {
      padding-left: 1rem;
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px;
      letter-spacing: 0;
      text-align: left;

    }

  }
.first-header{
    font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 21px;
line-height: 56px;
color: #004AAD;

}
  .text-header {
    font-family: Raleway, serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 56px;
    color: #263238;
    width: 70%;

    @media screen and (max-width: 768px) {
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: 0;
      text-align: left;
      width: 100%;
    }
  }

  .sub-text {
    font-family: Inter, serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: #263238;
    width: 50%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .btn {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    /* height: 24px; */
    border-radius: 6px;
    width: max-content;
    border: 2px solid rgba(0, 74, 173, 1);
    color: rgba(0, 74, 173, 1);
    padding: 14px 26px 14px 37px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.01em;
      text-align: left;
    }
  }

  .second-col {
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    position: relative;

    .web-img {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .mobile-img {
      @media screen and (min-width: 768px) {
        display: none;
      }

      width: 436px;
      margin: 0 auto;
      z-index: 1;
    }

    .first-box,
    .second-box,
    .third-box {
      @media screen and (max-width: 768px) {
        margin-top: 10px;
      }

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    .first-box {
      height: 211px;
      width: 221px;
      border-radius: 10px;
      background: rgba(253, 255, 252, 1);
      border: 2px solid rgba(198, 70, 122, 0.1);
      box-sizing: border-box;
      box-shadow: 0 4px 60px rgba(0, 74, 173, 0.1);

      @media screen and (max-width: 768px) {
        margin-top: 3rem;
        height: max-content;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      .header {
        font-family: "Inter", serif !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: rgba(124, 176, 246, 1);
      }

      .sub-text {
        font-family: "Inter", serif !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
        color: rgba(111, 127, 148, 1);
      }
    }

    .second-box {
      height: 219px;
      width: 221px;
      border-radius: 10px;
      background: rgba(253, 255, 252, 1);
      border: 2px solid rgba(198, 70, 122, 0.1);
      box-sizing: border-box;
      box-shadow: 0 4px 60px rgba(0, 74, 173, 0.1);

      @media screen and (max-width: 768px) {
        margin-top: 3rem;
        height: max-content;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      .header {
        height: 24px;
        width: 150px;
        font-family: "Inter", serif !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(200, 72, 103, 1);

        @media screen and (max-width: 768px) {
          height: auto;
        }
      }

      .sub-text {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(111, 127, 148, 1);
      }
    }

    .third-box {
      height: 187px;
      width: 200px;
      background: #ba68c8;
      border: 2px solid rgba(198, 70, 122, 0.1);
      box-sizing: border-box;
      box-shadow: 0 4px 60px rgba(0, 74, 173, 0.12);
      border-radius: 10px;

      @media screen and (max-width: 768px) {
        margin-top: 3rem;
        height: max-content;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      .header {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
      }

      .sub-text {
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
      }
    }
  }

</style>