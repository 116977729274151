<template>
  <div id="terms">
      <NavbarWhite/>
      <div class="nonlandingpage">
<h1 class="lp-text">Terms & Policies</h1>
      </div>
    <div class="terms-section">
      <terms/>
        <TermsMobile/>
<PrivacyMobile/>
    </div>

           
     <Footer/>
  </div>

</template>
<script>
import NavbarWhite from '../../home/NavbarWhite.vue'
import Footer from '../../home/Footer.vue'
import TermsMobile from './TermsMobile.vue';
import Terms from "@/components/reuseables/Terms";
import PrivacyMobile from './PrivacyMobile.vue';
export default {
    name:"TermsPage",
    components:{
      Terms,
      PrivacyMobile,
      NavbarWhite,
      Footer,
      TermsMobile
},

};
</script>
<style lang="scss" scoped >
#terms {
  min-height: 100vh;
}
.hide{
  display: flex;
  @media (max-width:1264px) {
    display: none;
    
  }
}
.mobile{
  display: none;
  @media (max-width:1264px) {
    display: block;
    
  }
}
.nonlandingpage{
    background-image: url(../../assets/termsimg.png);
    background-position: center right;
    background-size: cover;
    min-height: 100vh;
    padding-left: 11rem;
    padding-top: 23rem;
    @media (max-width:768px) {
          background-image: url(../../assets/termsMobile.png);
padding-left: 4rem;
padding-top:50vh;
    }
    @media (max-width:1264px){
      padding-left: 3rem;
    }
}
.terms-section {
  background-color: #e5e5e5;
  padding:  10rem ;
  @media (max-width:1264px) {
 padding: 6rem 3rem;
  }
}
.tree,
.open-tree {
  background-color: #ffff;
}
.lp-text{
    
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 54px;
color: #FFFFFF;

}
.open-tree {
  margin-left: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 3rem 4rem;
    overflow-y: auto;
  max-height: 100vh !important;
}
.open-tree p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  color: #4e5b64;

}
.link {
  color: #004aad;
  text-decoration: underline;
}
.header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #4e5b64;
}
.tree{
    background: #FFFFFF;
box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
border-radius: 10px;
    max-height: 100vh;
     overflow-x: hidden;
    overflow-y: auto;

}
.v-application a{
    
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 20px;
color: rgba(29, 38, 45, 0.71);

}
.v-application a:hover {
    color: #004AAD;
}
span{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
/* identical to box height */


color: #004AAD;

}
</style>