<template>
  <div class="tw-flex tw-w-full tw-h-auto" style="background: white;">
    <v-col sm="12" lg="4" class="tw-hidden left-side lg:tw-flex">
      <OnboardingLeft />
    </v-col>
    <v-col sm="12" lg="8" class="right-side scroll">
      <v-col sm="12" class="right-side-body">
        <img src="@/assets/logos/blue.png" alt="logo" style="height: 34px; width: 136px;" class="mb-3 mt-6 img-logo">

        <p class="welcome tw-mt-0">Contact</p>
        <p class="admin tw-mt-6">
          We'd love to know how to contact you.
        </p>
<!--        <v-row class="form-field form pt-15">-->
<!--          <v-col sm="12" lg="6">-->
<!--            <ValidationProvider name="Company Rep. name" rules="required" v-slot=" {classes, errors} ">-->
<!--              <p class="label">Company Rep. First Name</p>-->
<!--              <div :class="classes">-->
<!--                <v-text-field solo placeholder="John" type="text" color="#004aad" hide-details-->
<!--                  v-model="companyRepFirstName"></v-text-field>-->
<!--                <span> {{errors[0]}} </span>-->
<!--              </div>-->
<!--            </ValidationProvider>-->

<!--          </v-col>-->
<!--          <v-col sm="12" lg="6">-->
<!--            <p class="form-header">Last Name</p>-->
<!--            <ValidationProvider name="last name" rules="required" v-slot=" {classes, errors} ">-->
<!--              <div :class="classes">-->
<!--                <v-text-field solo placeholder="Doe" type="text" color="#004aad" hide-details-->
<!--                  v-model="companyRepLastName"></v-text-field>-->
<!--                <span> {{errors[0]}} </span>-->
<!--              </div>-->
<!--            </ValidationProvider>-->

<!--          </v-col>-->
<!--        </v-row>-->
        <v-row class="form">

          <v-col lg="6" sm="12" cols="12" class="mb-md-5">
            <label class="label">Alternative Phone number</label>
            <div class="tw-pt-1 mb-1 mt-2">
              <phone-number @getNumber="getNumber" :phone-number-exist="secondPhoneNumber" />
            </div>

          </v-col>

          <v-col lg="6" sm="12" cols="12" class="mb-md-5">
            <ValidationProvider name="Secondary Email" rules="required" v-slot="{ classes, errors }">
              <label class="label">Alternative Email</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-text-field placeholder="admin@example.com" solo hide-details type="email" v-model="secondaryEmail"
                  required></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </v-col>

        </v-row>

        <div class="btn-div tw-flex lg:tw-justify-end tw-justify-center">
          <router-link :to="{name: 'CompanyOnboardingAddress'}" class="btn-div-text">
            <img src="../../assets/arrow-back.svg" alt="" class="btn-div-img" />
            <span>
              Previous
            </span>
          </router-link>
          <button class="btn-div-text" :disabled="!formIsValid" @click.prevent="saveData">
            <span>verification</span>
            <img src="../../assets/arrow_forward.svg" alt="" class="btn-div-img">
          </button>
        </div>
      </v-col>
    </v-col>
  </div>
</template>

<script>
  import OnboardingLeft from "@/components/reuseables/CompanyOnboardingLeft";
  import PhoneNumber from "@/components/reuseables/PhoneNumber";
  export default {
    name: "CompanyOnboardingContact",
    components: {
      PhoneNumber,
      OnboardingLeft
    },
    data() {
      return {
        secondPhoneNumber: '',
        secondaryEmail: '',
        companyRepLastName:'',
        companyRepFirstName:''
      };
    },
    computed: {
      storedData() {
        return JSON.parse(sessionStorage.getItem('data'))
      },
      formIsValid() {
        return this.secondPhoneNumber && this.secondaryEmail
      },
      data() {
        return JSON.parse(sessionStorage.getItem('data'))
      }
    },
    methods: {
      getNumber(number) {
        this.secondPhoneNumber = number
      },
      fillFields() {
        if (this.data) {
          this.secondaryEmail = this.data.secondaryEmail
          this.secondPhoneNumber = this.data.secondContactPhoneNumber
          // this.companyRepLastName = this.data.companyRepLastName
          // this.companyRepFirstName = this.data.companyRepFirstName
        }
      },
      saveData() {
        if (this.validPhoneNumber(this.secondPhoneNumber) && this.validEmail(this.secondaryEmail)) {
          let data = {
            secondContactPhoneNumber: this.secondPhoneNumber,
            secondaryEmail: this.secondaryEmail,
            // companyRepLastName: this.companyRepLastName,
            // companyRepFirstName: this.companyRepFirstName,
            ...this.storedData
          }
          sessionStorage.setItem('data', JSON.stringify(data))
          this.$router.replace({
            name: 'CompanyOnboardingVerification'
          })
        }
      },
      validPhoneNumber(tel) {
        if (tel.length > 6) {
          return true;
        } else {
          this.$displaySnackbar({
            message: "Phone number not valid e.g 2348000000000",
            success: false,
          });
          return false;
        }
      },
      validEmail(email) {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      }
    },
    mounted() {
      this.fillFields()
    }
  };
</script>

<style lang="scss" scoped>
  button:disabled,
  button[disabled] {
    opacity: 0.7;
  }

  .scroll {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 99vh;
    height: 98vh;
  }

  .scroll::-webkit-scrollbar {
    display: none;
  }

  .left-side {
    width: 100%;
    min-height: 100vh;
    // height: 100%;
    background-image: url("../../assets/onboarding-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 0 !important;
    color: #ffffff;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .right-side {
    width: 100%;
    min-height: 100vh;
    height: auto;
    padding: 0 !important;
    background: white;
  }

  .right-side-body {
    width: 100%;
    padding: 7rem;

    @media screen and (min-width: 1440px) {
      padding-top: 4.25rem;
    }

    @media screen and (max-width: 1440px) {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    @media screen and (max-width: 1366px) and (min-width: 1025px) {
      padding-top: 4rem;
      padding-bottom: 2rem;
    }

    @media screen and (max-width: 1024px) {
      padding: 1.5rem;
    }
  }

  .right-side-body>.img-logo {
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  .right-side-body .welcome {
    font-family: "Inter", serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #004aad;

    @media screen and (max-width: 1024px) {
      margin-top: 50px;
    }
  }

  .right-side-body .admin {
    width: 255px;
    height: 24px;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #828282;
  }

  .label {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .btn-div {
    margin-top: 52px;

    @media screen and (max-width: 1023px) {
      margin-top: 25px;
    }
  }

  .btn-div .btn-div-text:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 30px;
    border-radius: 10px;
    width: 161px;
    background: white;
    color: #004aad;
    border: 1px solid #004aad;

    .btn-div-img {
      margin-right: 5px;
    }
  }

  .btn-div .btn-div-text:last-child {
    margin-left: 45px;
    display: flex;
    width: 161px;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    border-radius: 10px;
    background: #004aad;
    color: white;

    .btn-div-img {
      margin-left: 5px;
    }
  }

  .form {
    margin-top: 40px;

    @media screen and (max-width: 1024px) {
      margin-top: 60px;
    }
  }
</style>