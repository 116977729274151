import { render, staticRenderFns } from "./OnboardingIdentity.vue?vue&type=template&id=5b61a65a&scoped=true&"
import script from "./OnboardingIdentity.vue?vue&type=script&lang=js&"
export * from "./OnboardingIdentity.vue?vue&type=script&lang=js&"
import style0 from "./OnboardingIdentity.vue?vue&type=style&index=0&id=5b61a65a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b61a65a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VDatePicker,VIcon,VMenu,VProgressCircular,VProgressLinear,VTextField})
