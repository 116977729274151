<template>
    <div>
        <v-menu offset-y :close-on-content-click="false" min-width="290px" class="px-4 py-4">
            <template v-slot:activator="{ on, attrs }">
              <ValidationProvider
                  name="Passenger(s)"
                  rules="required"
                  v-slot="{ classes, errors }">
                <div class="" :class="classes">
                <v-text-field hide-details solo :flat="$vuetify.breakpoint.mdAndUp" placeholder="0 Adults, 0 Children " readonly
                    v-model="selectedNumberOfPassengers" prepend-inner-icon="mdi-car-seat" v-on="on" v-bind="attrs" />
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </template>
            <v-list>
                <v-list-item>
                    <div class="grouping1 tw-flex">
                        <div class="left">
                            <span class="type look">Adults</span> <br>
                            <span class="hint">( &gt; 12 years)</span>
                        </div>
                        <div class="right">
                            <button class="decrease">
                                <v-icon slot="append" color="#004aad" @click="minusAdult">
                                    mdi-minus
                                </v-icon>
                            </button>

                            <span v-text="adultPassengers" class="mx-2"></span>
                            <button class="increase">
                                <v-icon slot="prepend" color="#004aad" @click="addAdult">
                                    mdi-plus
                                </v-icon>
                            </button>

                        </div>
                    </div>


                </v-list-item>
                <v-list-item class="mt-5">
                    <div class="grouping1 tw-flex">
                        <div class="left">
                            <span class="type look">Children</span> <br>
                            <span class="hint">( &lt; 12 years)</span>
                        </div>
                        <div class="right">
                            <button class="decrease">
                                <v-icon slot="append" color="#004aad" @click="minusChild">
                                    mdi-minus
                                </v-icon>
                            </button>
                            <span v-text="childPassengers" class="mx-2"></span>
                            <button class="increase">
                                <v-icon slot="prepend" color="#004aad" @click="addChild">
                                    mdi-plus
                                </v-icon>
                            </button>

                        </div>
                    </div>


                </v-list-item>

            </v-list>



        </v-menu>
    </div>
</template>

<script>
    export default {
      
     name :"SelectPassengers",
    data(){
        return{
              adultPassengers: 0,
                childPassengers: 0,
                selectedNumberOfPassengers: ""
            }
        },
        computed: {
            toggleChildtoChildren() {
                if (this.childPassengers > 1) {
                    return " Children"
                } else {
                    return " Child"
                }
            },
            toggleAdulttoAdults() {
                if (this.adultPassengers > 1) {
                    return " Adults  "
                } else {
                    return " Adult  "
                }
            }
        },

        methods: {
            minusAdult() {
                if (this.adultPassengers > 0) {

                    this.adultPassengers--
                    this.selectedNumberOfPassengers = this.adultPassengers+  this.toggleAdulttoAdults + this.childPassengers + this.toggleChildtoChildren
                    this.$emit('getTotalPassengers',this.adultPassengers+this.childPassengers)
                }
            },
            addAdult() {
                this.adultPassengers++
                this.selectedNumberOfPassengers = this.adultPassengers +  this.toggleAdulttoAdults+ this.childPassengers + this
                    .toggleChildtoChildren
                    this.$emit('getTotalPassengers',this.adultPassengers+this.childPassengers)
            },
            minusChild() {
                if (this.childPassengers > 0) {
                    this.childPassengers--
                    this.selectedNumberOfPassengers = this.adultPassengers +  this.toggleAdulttoAdults + this.childPassengers + this
                        .toggleChildtoChildren
                        this.$emit('getTotalPassengers',this.adultPassengers+this.childPassengers)
                }
            },
            addChild() {
                this.childPassengers++
                this.selectedNumberOfPassengers = this.adultPassengers + this.toggleAdulttoAdults + this.childPassengers + this
                    .toggleChildtoChildren
                    this.$emit('getTotalPassengers',this.adultPassengers+this.childPassengers)
            },

        }
    }
</script>

<style lang='scss' scoped>
    .hint {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 24px;
    }

    .left {
        width: 60%;
    }

    .right {
        width: 40%;
        margin-top: 10px;
    }

    .grouping1 {
        width: 100%;
    }

    .increase,
    .decrease {
        border: 2px solid #004aad;
        border-radius: 4px;
    }

    .submit-div {}

    .submit {
        justify-content: flex-end;
        align-items: flex-end;
        display: flex;
        padding: 5px 10px;
        color: #fff;
        background-color: #004aad;
        border-radius: 10px;
        margin: 30px auto 15px;
    }
</style>