<template>
  <div class="step-three">
    <loader :loading="loading" v-if="loading" />
    <div class="tw-w-full" v-else>
      <NavBar />
      <booking-steps />
      <div class=" tw-flex tw-w-full ">
        <div class="tw-flex tw-w-full tw-px-8 lg:tw-px-80 tw-justify-center">
          <customer-details />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BookingSteps from '../../components/reuseables/hire/BookingSteps.vue'
  import CustomerDetails from '../hire/CustomerDetails.vue'
  import NavBar from "@/home/Navbar.vue";
  import Loader from "@/components/reuseables/Loader.vue";
  export default {
    name: "InputDetails",
    components: {
      Loader,
      NavBar,
      BookingSteps,
      CustomerDetails
    },
    data() {
      return {
        loading: false
      }
    },

  }
</script>

<style lang='scss' scoped>
  .step-three {
    background-color: #fafafa;
  }

  .look {
    width: 1000px;

    @media screen and (max-width: 1024px) {
      width: 700px;
    }

    @media screen and (max-width: 768px) {
      width: 500px;
    }

    @media screen and (max-width: 576px) {
      width: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }


  .sub-heading {

    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #4F4F4F;
  }

  .group1 {
    display: flex;
    justify-content: space-between;
  }

  .sub-title {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #004AAD;

    @media (max-width:576px) {
      font-size: 14px;
    }
  }

  .btn-design {
    border-radius: 4px;
    border: 1px solid #004AAD;
    background: #004AAD;
    color: #FFF;
    font-family: Inter;
    width: 150px;
    height: 50px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 15px;

    @media screen and (max-width:576px) {
      width: 100%;
    }
  }

  .previous-btn {
    border-radius: 4px;
    border: 2px solid #004AAD;
    color: #004aad !important;
    font-family: Inter;
    width: 150px;
    height: 50px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 15px;

    @media screen and (max-width:576px) {
      width: 100%;
    }
  }

  .btn-divs {
    margin-top: 50px;
    margin-bottom: 100px;
    margin-right: -20px;
    @media screen and (max-width: 960px){
      margin-right: 0px;
      margin-top: 10px;
    }
    @media screen and (max-width: 768px){
      margin-top: 0;
    }
  }
</style>