<template>
  <div class="section-sculptor">
    <v-col lg="5" sm="12" mmd="12" cols="12" >
      <p class="blue-small-bold-text mb-5"  >Intercity Bookings</p>
      <p class="header"  >
        Effortless Travel between Cities
      </p>
      <p class="text-subheader my-9" >Are you ready to embark on a journey that seamlessly connects you to the heart of every city? Look no further – our Intercity Travel Booking service is your passport to stress-free travel. </p>
      <div class="fs-title blue-small-bold-text text-center tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-2 tw-cursor-pointer
                  " click="routeToNpod">Book a trip today
      </div>
    </v-col>
    <v-col lg="7" sm="12" md="12" cols="12" class="tw-flex-wrap tw-flex p-0 second-sec-p2" >
      <div class="tw-flex tw-flex-wrap">
        <v-col lg="6" cols="12" md="6" sm="6" class="">
          <img src="@/assets/newlandingpage/date-with-bg.svg"/>
          <p class="medium-header-raleway my-5">
            Seamless Booking
          </p>
          <span class="black-small-text">
                Say goodbye to long queues and complicated booking processes. With a few clicks, you can reserve your seat and be on your way to the next destination.
            </span>
        </v-col>
        <v-col lg="6" cols="12" md="6" sm="6" class="">
          <img src="@/assets/newlandingpage/wallet-with-bg.svg"/>
          <p class="medium-header-raleway my-5">
            Affordable Fares
          </p>
          <span class="black-small-text">
                Moving between cities shouldn't strain your budget or leave a dent in your wallet. Experience competitive rates without sacrificing quality.
            </span>
        </v-col>
      </div>
      <div class="sm:mt-12 mt-2 tw-flex tw-flex-wrap">
        <v-col lg="6" cols="12" md="6" sm="6" class="">
          <img src="@/assets/newlandingpage/clock-with-bg.svg"/>
          <p class="medium-header-raleway my-5">
            Real time updates
          </p>
          <span class="black-small-text">
                Stay informed with real time notifications regarding schedules, delays, and more. Your journey is priority.
            </span>
        </v-col>
        <v-col lg="6" cols="12" md="6" sm="6" class="">
          <img src="@/assets/newlandingpage/umbrella-with-bg.svg"/>
          <p class="medium-header-raleway my-5">
            Comfort
          </p>
          <span class="black-small-text">
                Discover a variety of vehicle options, including upscale buses, high-speed mini-vans, and premium SUV, guaranteeing your journey in both style and comfort.
            </span>
        </v-col>
      </div>

    </v-col>
  </div>
</template>

<script>
export default {
    name: "SectionTwo",
  methods:{
    routeToNpod(){
      window.open(process.env.VUE_APP_NPOD_URL,"_blank")

    },
  }
}
</script>

<style lang="scss" scoped>
.section-sculptor {
  display: flex;
  //align-items: center;
  margin-bottom: 100px;
  min-height: 60vh;
  margin-top: 100px;
  @media screen and (min-width: 1920px) {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  @media (width: 1366px) {
    flex-direction: row;
    min-height: 60vh;
    padding-bottom: 30px;
    padding-top: 50px;
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }
  @media (max-width: 576px) {
    min-height: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  @media screen and (min-width: 768px) and (max-width: 1264px) {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  @media (max-width: 1264px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media screen and (max-width: 1040px) {
    flex-direction: column;
    background-size: cover;
    background-position: right center;
  }
  @media screen and (width: 768px) {
    padding-left: 1.0rem;
    padding-right: 1.0rem;
    padding-top: 30px;
  }
  @media screen and (max-width: 430px) {
    //padding-left: 1.0rem;
    padding-right: 1.0rem;
    padding-top: 30px;
  }
  @media screen and (width: 428px) {
    padding-left: 1.0rem;
    padding-right: 1.0rem;
    padding-top: 30px;
  }
  @media screen and (max-width: 414px) {
    padding-left: 1.0rem;
    padding-right: 1.0rem;
    padding-top: 30px;
    //height: 90vh;
  }
  @media screen and (width: 390px) {
    padding-left: 1.0rem;
    padding-right: 1.0rem;
    padding-top: 30px;
  }
  @media screen and (width: 375px) {
    padding-left: 1.0rem;
    padding-right: 1.0rem;
    padding-top: 30px;
  }
  @media screen and (max-width: 360px) {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    padding-top: 30px;
  }
}
.second-sec-p2{
  padding: 0 !important;
}

.header {
        color: #263238;
    font-family: Raleway;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    //line-height: 70px;
    width: 90%;

    @media screen and (max-width:768px) {
      width: 100%;
      font-size: 43px;
      line-height: 60px;
    }
    @media screen and (max-width:576px) {
      font-size: 35px;
      line-height: 50px;
    }
    @media screen and (max-width: 430px) {
      //height: 15vh;
      font-size: 35px;
      width: 90%;
      line-height: 40px;
      padding-bottom: 0.2rem;
    }
    @media screen and (width: 414px) {
      //height: 15vh;
      font-size: 35px;
      width: 90%;
      line-height: 40px;
      padding-bottom: 0.2rem;
    }
    @media screen and (width: 390px) {
      //height: 15vh;
      font-size: 35px;
      width: 90%;
      line-height: 40px;
      padding-bottom: 0.2rem;
    }
    @media screen and (width: 375px) {
      //height: 15vh;
      font-size: 35px;
      width: 90%;
      line-height: 40px;
      padding-bottom: 0.2rem;
    }
    @media screen and (width: 360px) {
      //height: 15vh;
      font-size: 35px;
      width: 90%;
      line-height: 40px;
      padding-bottom: 0.2rem;
    }
    @media screen and (min-width: 1920px) {
      height: 15vh;
      font-size: 65px;
      width: 90%;
      line-height: 70px;
      padding-top: 1.5rem;
      padding-bottom: 2rem;
    }
    @media screen and (max-width: 1366px) {
      height: 15vh;
      font-size: 40px;
      width: 90%;
      line-height: 60px;
      padding-top: 1rem;
      padding-bottom: 8rem;
    }
}

.text-subheader {
        font-family: "Raleway", sans-serif !important;

        font-style: normal;
        font-weight: 300;
        line-height: 38px;
        letter-spacing: 0;
        text-align: left;
        color: #263238;
        width: 90%;


        @media screen and (width: 1920px) {
            font-size: 24px;
            line-height: 38px;
            height: 30px;
            padding-bottom: 12rem;
            padding-top: 2rem;
        }

        @media screen and (max-width: 1366px) {
          font-size: 16px;
          line-height: 28px;
          height: 30px;
          //padding-top: 2rem;
          padding-bottom: 10rem;
        }

        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 28px;
            width: 100%;
        }
    }
.blue-small-bold-text{
    color: #004AAD;
font-family: Raleway;
font-size: 18px;
font-style: normal;
letter-spacing: 0;
text-align: left;
font-weight: 600;
line-height: 30px; 
@media screen and (max-width:768px) {
        font-size: 16px;
    }
    @media screen and (max-width:400px) {
        font-size: 13px !important;
    }
}

.fs-title {
    width: fit-content;
    height: 50px;
    text-align: center;

    border-radius: 10px;
border: 2px solid var(--RoadPadi-06, #004AAD);
box-shadow: 0px 4px 8px 0px rgba(57, 130, 240, 0.10);
    background-color: transparent;
}
.medium-header-raleway{
    color: #0C0910;
    font-family: Raleway;
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  @media screen and (max-width: 1920px) {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    font-family: Raleway;
  }
  @media screen and (max-width: 1366px) {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    font-family: Raleway;
  }
  @media screen and (max-width: 768px) {
    line-height: 32px;
    font-weight: 700;
    font-family: Raleway;
    font-size: 23px;
  }

  @media screen and (max-width: 576px) {
      font-size: 17px;
      line-height: 25px;

  }
}
.black-small-text{
    color: #344053;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 24px */
  @media screen and (width: 1920px) {
    font-size: 16px;
    line-height: 25px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 12px;
    line-height: 25px;
  }
  @media screen and (max-width: 920px) {
    font-size: 16px;
    line-height: 25px;
  }
  @media screen and (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
}
</style>
