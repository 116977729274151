<script>
export default {
  name: 'BusinessForProducts',

}
</script>

<template>
  <div class="parent-div">
  <div class="tw-flex flex-column tw--mr-10" >
    <div class="tw-flex" >
      <div>
      <div class="avatar-container">
        <div class="avatar-inner">
        <img src="@/assets/icons/business-mdi-bus.svg" alt="bus icon"/>
      </div>
      </div>
      </div>

      <router-link to="/nibs">
      <div class="tw-pl-3">
        <p class="product-txt">Trip-Jotter</p>
        <p class="paragraph-txt">Manage your trip inventory, sales and booking operations, all<br/> in one place.</p>
        <p class="paragraph-txt-mobile">Manage your trip inventory, sales and<br/>booking operations, all in one place.</p>
      </div>
      </router-link>
    </div>

    <div class="tw-flex tw-mt-10">
      <div class="avatar-container tw-mr-4">
        <div class="avatar-inner">
          <img src="@/assets/icons/fintracka-icon.svg" alt="icon"/>
        </div>
      </div>

      <router-link to="/nibs">
      <div>
        <p class="product-txt">Fintracka</p>
        <p class="paragraph-txt">Automate the tracking, and management of your revenue<br/>
          inflow and expenditure from all booking and sale operations.</p>
        <p class="paragraph-txt-mobile">Automate the tracking, and management<br>of your revenue
          inflow and expenditure <br>from all booking and sale operations.</p>
      </div>
      </router-link>
    </div>
  </div>
  </div>
</template>

<style scoped lang="scss">
.parent-div{
  width: 587px;
  height: 248px;
  background: #FFFFFF;
  @media (max-width: 576px) {
    padding: 20px 100px 10px 10px;
  }
}
.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background: #F5F9FF;
  @media (max-width: 576px) {
    width: 50px;
    height: 50px;

  }
}

.avatar-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background: #C1F1E5;
  @media (max-width: 576px) {
    width: 45px;
    height: 45px;
  }
}

.avatar-inner img {
  border-radius: 50%;
  object-fit: contain;
  display: flex;
  align-self: center;
}
.product-txt{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #000F23;
}
.paragraph-txt{
  font-family: Raleway,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #000000;
  white-space: nowrap;
  padding-top: 10px;
  @media (max-width: 576px) {
   display: none;
  }
}
.paragraph-txt-mobile{
  display: none;
  @media (max-width: 576px) {
    display: flex;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #00193A;
  }
}
</style>