<script>
export default {
  name: 'GetStartedBtn',

  props: {
    buttonText: {
      type: String,
      default: 'Get Started Now'
    }
  }
}

</script>

<template>
<div class="get-started-btn">
  <v-btn style="background:#004aad;  color: #FBFCFE; padding: 20px; border: 1px solid #3982F0;
  border-radius: 10px; text-transform: none;">{{ buttonText }}</v-btn>
</div>
</template>

<style scoped lang="scss">
.get-started-btn{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 130px;
  height: fit-content;
}
</style>