<script>


export default {
  name: 'TanSectionTwo',
  components: {}
}
</script>

<template>
<div class="section-parent tw-flex tw-px-5 lg:tw-px-40">
  <div class="left-side">
<p class="head-txt">Features</p>
  <p class="tw-my-8 header-bold">Being An Affiliate Has<br>Never Been Easier</p>
  </div>

  <div class="right-side tw-flex">
    <div class="tw-mr-10 icon-wrapper"><img src="@/assets/group-icons.png" alt="icon" class="icon-groups"></div>
    <div>
    <div>
      <p class="sub-head-txt">Sign Up</p>
      <p class="tw-my-3">Create an account for free with your email.</p>
    </div>


    <div class="text-blocks">
        <p class="sub-head-txt">Onboard</p>
        <p class="tw-my-3">Complete your profile by inputting your contact details, address and CAC number.</p>
    </div>

      <div class="text-blocks">
        <p class="sub-head-txt">Book Trips for Passengers</p>
        <p class="tw-my-3">Find clients and passengers and book travel tickets for them.</p>
      </div>


      <div class="text-blocks">
        <p class="sub-head-txt">Manage your Ticket Sales</p>
        <p class="tw-my-3">Oversee bookings, Trips and track ticket sales from your <span style="color: #A73C56;cursor: pointer">dashboard</span>.</p>
      </div>

    </div>

  </div>



</div>
</template>

<style scoped lang="scss">
.section-parent{
  margin-bottom: 100px;
  min-height: 60vh;
  margin-top: 100px;
@media screen and (max-width: 576px){
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
}
.left-side{
  width: 50%;
}
.right-side{
  width: 50%;
  @media (max-width: 576px) {
    padding-top: 70px;
  }
}
.head-txt{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #A73C56;
  @media (max-width: 576px) {
    white-space: nowrap;
  }
}
.header-bold{
  font-family: Raleway,sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  color: #263238;
  @media (max-width: 576px) {
    font-size: 32px;
    line-height: 44.8px;
    white-space: nowrap;
  }
}
.sub-head-txt{
  font-family: Raleway,sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #263238;

  @media (max-width: 576px) {
    white-space: nowrap;
  }
}
.paragraph{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #263238;
  @media (max-width: 576px) {
   white-space: nowrap;
  }
}
.text-blocks{
  margin-top: 73px;
}
.icon-wrapper{
  @media (max-width: 576px) {
   display: none;
  }
}
.icon-groups{

}
</style>