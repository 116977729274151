<template>
  <v-app-bar height="auto" elevation="0" fixed :color="bg === 'white' ? 'white' : 'transparent'"
    style="background: inherit !important;" :class="[icon !== 'menu' ? 'appBar-none' : 'appBar']"
    :style="{ background: bg }" class=" tw-z-40">
    <page-layout>
      <template #Content>
        <div class="tw-flex tw-w-full tw-flex-col">
          <NoticeNavBar   v-if="discount"  :notice="discount.name" />
          <div class="
              tw-hidden
              lg:tw-flex
              tw-w-full tw-items-center tw-py-5 tw-justify-between lg:tw-px-40
            ">
            <!-- web starts here -->
            <!-- first -->
            <div class="tw-flex tw-items-center tw-justify-start">
            <img :src="logo" alt="logo" class="nomadicpod-logo tw-cursor-pointer" @click="routeToHome" />
            </div>
            <!-- second -->
            <div class="tw-flex tw-flex-row tw-items-center" style="height: 40px">
              <v-tabs v-model="currentTab" slider-color="#004aad" :hide-slider="
                  $route.name === 'HomePage' ||
                  $route.name === 'TransportCompanyRegister' ||
                  $route.name === 'Login' ||
                  $route.name === 'RegisterAs' ||
                  $route.name === 'Career' ||
                  $route.name === 'JobOpenings' ||
                  $route.name === 'TermsAndConditions'||
                  $route.name === 'OneWaySelectSeats'||
                  $route.name === 'OneWayInputDetails'||
                  $route.name === 'OneWayCheckout'||
                  $route.name === 'HireSelectVehicle' ||
                  $route.name === 'HireLocation' ||
                  $route.name === 'HireInputDetails'||
                  $route.name === 'HirePayment'||
                  $route.name === 'Tan'||
                  $route.name === 'WaveJotter'||
                  $route.name === 'Nibs'
                ">

                <v-menu offset-y open-on-hover  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        v-on="on"
                        class="v-tab"
                    >
                      Commuters <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>


<!--                  <v-list class="product-container tw-py-5">-->
<!--                    <v-list-item-->
<!--                        v-for="(item, index) in products"-->
<!--                        :key="index" class="product-item tw-cursor-pointer" @click="navigateToPage(item.routeName)">-->
<!--                     <div class="tw-flex tw-flex-row tw-items-center-->
<!--                      tw-w-full tw-p-5 tw-cursor-pointer" reveal.reset="{delay:300*index}">-->
<!--                       <icons :name="item.iconName"/>-->
<!--                       <div class="tw-flex tw-flex-col tw-w-auto tw-items-start tw-pl-5">-->
<!--                         <h6 class="product-container-header">{{item.title}}</h6>-->
<!--                         <label class="product-container-desc">{{item.description}}</label>-->
<!--                       </div>-->
<!--                     </div>-->
<!--                    </v-list-item>-->

<!--                  </v-list>-->


                  <template>
                    <v-card style="width: 800px; height: 373px; padding-top: 40px; " class="products-card" >

                      <v-tabs
                          v-model="tab"
                          color="primary"
                          direction="vertical"
                          vertical
                          stacked
                          class="mr-16"

                      >
                        <v-tab value="tab-1" class="head-txt" v-for="(tab,index) in commutersTabs" :key="index"
                               @click.stop="switchTab(tab.value)" @mouseover.stop="switchTab(tab.value)">
                          {{tab.label}}
                        </v-tab>

                        <v-tabs-items v-model="tab" class="">
                          <v-tab-item key="tab-1" direction="vertical">
                            <CommutersForProducts />
                          </v-tab-item>
                        </v-tabs-items>
                      </v-tabs>

                    </v-card>
                  </template>
               </v-menu>




                <v-menu offset-y open-on-hover  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        v-on="on"
                        class="v-tab"
                    >
                      Businesses <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>


                  <!--                  <v-list class="product-container tw-py-5">-->
                  <!--                    <v-list-item-->
                  <!--                        v-for="(item, index) in products"-->
                  <!--                        :key="index" class="product-item tw-cursor-pointer" @click="navigateToPage(item.routeName)">-->
                  <!--                     <div class="tw-flex tw-flex-row tw-items-center-->
                  <!--                      tw-w-full tw-p-5 tw-cursor-pointer" reveal.reset="{delay:300*index}">-->
                  <!--                       <icons :name="item.iconName"/>-->
                  <!--                       <div class="tw-flex tw-flex-col tw-w-auto tw-items-start tw-pl-5">-->
                  <!--                         <h6 class="product-container-header">{{item.title}}</h6>-->
                  <!--                         <label class="product-container-desc">{{item.description}}</label>-->
                  <!--                       </div>-->
                  <!--                     </div>-->
                  <!--                    </v-list-item>-->

                  <!--                  </v-list>-->


                  <template>
                    <v-card style="width: 800px; height: 373px; padding-top: 40px; " class="products-card" >

                      <v-tabs
                          v-model="tab2"
                          color="primary"
                          direction="vertical"
                          vertical
                          stacked
                          class="mr-16"

                      >
                        <v-tab value="tab-1" class="head-txt" v-for="(tab,index) in businessTabs" :key="index"
                               @click.stop="switchTab(tab.value)" @mouseover.stop="switchTab(tab.value)">
                          {{tab.label}}
                        </v-tab>

                        <v-tabs-items v-model="tab2" class="">
<!--                          <v-tab-item key="tab-1" direction="vertical">-->
<!--                            <CommutersForProducts />-->
<!--                          </v-tab-item>-->
                          <v-tab-item key="tab-1" direction="vertical" >
                            <BusinessForProducts   />
                          </v-tab-item>
                          <v-tab-item key="tab-2" direction="vertical" >
                            <TravelAgents  direction="vertical" />
                          </v-tab-item>
                          <v-tab-item key="tab-3" direction="vertical">
                            <WaterWay/>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-tabs>

                    </v-card>
                  </template>
                </v-menu>





<!--                  <v-list class="product-container tw-py-5">-->
<!--                    <v-list-item-->
<!--                        v-for="(item, index) in products"-->
<!--                        :key="index" class="product-item tw-cursor-pointer" @click="navigateToPage(item.routeName)">-->
<!--                     <div class="tw-flex tw-flex-row tw-items-center-->
<!--                      tw-w-full tw-p-5 tw-cursor-pointer" >-->
<!--                       <icons :name="item.iconName"/>-->
<!--                       <div class="tw-flex tw-flex-col tw-w-auto tw-items-start tw-pl-5">-->
<!--                         <h6 class="product-container-header">{{item.title}}</h6>-->
<!--                         <label class="product-container-desc">{{item.description}}</label>-->
<!--                       </div>-->
<!--                     </div>-->
<!--                    </v-list-item>-->
<!--                  </v-list>-->

<!--                <v-tab to="/about">About</v-tab>-->
                <!--                <v-tab @click="routeToPages('Developer')">Developers</v-tab>-->
                <v-tab to="/faqs">F.A.Qs</v-tab>
                <v-tab to="/blog">Blog</v-tab>
<!--                <v-tab to="/contact">Contact</v-tab>-->

              </v-tabs>

            </div>

            <!-- third -->
            <div class="tw-w-auto tw-flex tw-flex-row tw-items-center">
              <router-link to="/register-as" class="appBar-title tw-w-auto tw-mr-5" style="color: #004aac">Get Started
              </router-link>
              <router-link to="/login" class="appBar-title tw-w-auto tw-mr-5" style="color: #004aac">Login
              </router-link>
              <div @click="routeToNpod" class="appBar-title signup-mobile tw-cursor-pointer">Book Trip
              </div>

            </div>
          </div>

          <!-- web ends here -->



          <!--Mobile starts here -->
          <div class="
              lg:tw-hidden tw-w-full tw-flex tw-flex-col tw-h-auto tw-bg-white md:tw-bg-transparent tw-py-3">
            <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-px-5 small-screen" >
              <img class="tw-cursor-pointer " :src="logo" width="160
               " height="40" alt="" @click="routeToHome" />
              <v-icon v-if="icon === 'menu'" @click="toggelIcon" class="tw-cursor-pointer">mdi-menu</v-icon>
              <v-icon v-else  @click="toggelIcon" class="tw-cursor-pointer">mdi-window-close</v-icon>
            </div>


   <v-expand-transition>
            <div class=" tw-flex tw-flex-col tw-w-full tw-px-5 lg:tw-px-40 "
                 style="background: #F7FAFF; max-height: 90vh ; overflow: scroll; margin-top: 20px;
                  " v-if="icon === 'x'" :class="[icon === 'x' ? 'anim' : '']">
                <v-tabs class="tw-w-full " vertical v-model="currentTab" slider-color="#004aad" :hide-slider="
                  $route.name === 'HomePage' ||
                  $route.name === 'TransportCompanyRegister' ||
                  $route.name === 'Login' ||
                  $route.name === 'RegisterAs' ||
                  $route.name === 'Career' ||
                  $route.name === 'JobOpenings'||
                  $route.name === 'TermsAndPolicies'||
                  $route.name === 'OneWaySelectSeats'||
                  $route.name === 'OneWayInputDetails'||
                  $route.name === 'OneWayCheckout' ||
                  $route.name === 'HireSelectVehicle' ||
                  $route.name === 'HireLocation' ||
                  $route.name === 'HireInputDetails'||
                  $route.name === 'HirePayment' ||
                  $route.name === 'Tan'||
                  $route.name === 'WaveJotter'||
                  $route.name === 'Nibs'
                ">
                  <p class="tw-mt-10" style="font-family: Raleway,sans-serif; font-weight: 600; font-size: 16px">PRODUCTS</p>
                  <CommutersMobileView />
                  <v-divider color="#80A4D6" class="tw-w-56"></v-divider>
                  <BusinessMobileView/>
                  <v-divider color="#80A4D6" class="tw-w-56"></v-divider>
                  <v-tab @click="closeToggle" to="/blog">Blog</v-tab>
                  <v-divider color="#80A4D6" class="tw-w-56"></v-divider>

                  <v-tab @click="closeToggle" to="/faqs">F.A.Qs</v-tab>
                  <v-divider color="#80A4D6" class="tw-w-56"></v-divider>

                    <router-link to="/register-as" class="appBar-title tw-ml-5 tw-my-5" style="color: #004aac">Get Started
                    </router-link>
                  <router-link to="/login" class="appBar-title tw-w-auto tw-mr-5" style="color: #004aac">Login
                  </router-link>
                  <v-divider color="#80A4D6" class="tw-w-56"></v-divider>
                  <div @click="routeToNpod" class="appBar-title signup-mobile tw-cursor-pointer tw-my-5">Book Trip
                  </div>



                </v-tabs>
            </div>
               </v-expand-transition>
          </div>
        </div>
      </template>
    </page-layout>
  </v-app-bar>
</template>

<script>
  import PageLayout from "@/components/reuseables/PageLayout";
  import CommutersForProducts from "@/home/landingpage/CommutersForProducts.vue";
  import BusinessForProducts from "@/home/landingpage/BusinessForProducts.vue";
  import TravelAgents from "@/home/landingpage/TravelAgents.vue";
  import {getActiveDiscount} from "@/services/api/APIService";
  import NoticeNavBar from "@/components/reuseables/NoticeNavBar.vue";
  import WaterWay from "@/home/landingpage/WaterWay.vue";
  import CommutersMobileView from "@/home/landingpage/CommutersMobileView.vue";
  import BusinessMobileView from "@/home/landingpage/BusinessMobileView.vue";
  export default {
    name: "HomeNavbar",
    components: {
      BusinessMobileView,
      CommutersMobileView,
      WaterWay,
      TravelAgents,
      BusinessForProducts,
      // Icons,
      PageLayout,
      CommutersForProducts,
      NoticeNavBar
    },

      data() {
        return {
          shouldHideSlider: false,
          showBusinessList: false,
          tab: 'tab-1',
          tab2: 'tab-1',
          businessTab: null,
          commutersTabs: [
            {label: "Commuters", value: "customers"},
          ],
          businessTabs: [
            {label: "Intercity Road Transport Company(s)", value: "companies"},
            {label: "Travel Agents", value: "agents"},
            {label: "Waterway Boat Operator", value: "waterWay"}
          ],

          discount: null,
          logo: "https://res.cloudinary.com/myroadpadi/image/upload/v1702465764/assets/frontend/blue_hici8x.png",
          icon: "menu",
          menus: ["About", "Blog", "Developer", "F.A.Qs", "Contact", "Book Trip"],
          bg: "",
          currentTab: "Blog",
          products: [
            {
              title: 'For Commuters',
              subItems:[
                CommutersForProducts,
              ]
            },
            {
              title: 'For Business',
              subItems:[
                BusinessForProducts,
              ]
            },
            {
              title: 'For Travel Agents',
              subItems:[
                TravelAgents
              ]
            },
            {
              title: 'For Water Way',
              subItems: [
                 WaterWay
              ]
            }
            // {
            //   title: 'NIBS',
            //   iconName: 'nibs-icon-web',
            //   iconMobileName : 'ribs-icon-mobile',
            //   description:"Nomadicpod’s Inventory & Booking System for Transport Companies.",
            //   routeName: 'ribs'
            // },
            //
            // {
            //   title: 'SKIP',
            //   iconName : 'skip-icon-web',
            //   iconMobileName : 'skip-icon-mobile',
            //   description: "Skip AI by Nomadicpod for Corporate Organizations with Large Fleet",
            //   routeName: 'skip'
            // },
            //
            // {
            //   title: 'RAIL',
            //   iconName : 'rail-icon-web',
            //   iconMobileName : 'skip-icon-mobile',
            //   description: "Nomadicpod's Railway Booking system for transport companies",
            //   routeName: 'skip'
            // },
            //
            //
            // {
            //   title: 'FLAIN',
            //   iconName : 'flain-icon-web',
            //   iconMobileName : 'flair-icon-mobile',
            //   description: "Fleet AI by Nomadicpod",
            //   routeName: 'flair'
            // },
            // {
            //   title: 'TAN',
            //   iconName: 'tan-icon-web',
            //   iconMobileName : 'tar-icon-mobile',
            //   description: "Travel Agents by Nomadicpod",
            //   routeName: 'tar'
            // },
          ]
        };
      },
      watch: {
        "$route.name": {
          handler: function (val) {
            if (val === "About") {
              this.currentTab = "About";
            } else if (val === "Blog") {
              this.currentTab = "Blog";
            } else if (val === "Contact") {
              this.currentTab = "Contact"
              ;
            }
          },
          immediate: true,
        },
      },
      computed: {},
      methods: {

        switchTab(tabName) {
          if (tabName === 'customers') {
            this.tab = 0;
            this.tab2 = 0;
          } else if (tabName === 'companies') {
            this.tab = 0;
            this.tab2 = 0;
          }
          else if(tabName === 'agents'){
            this.tab = 1;
            this.tab2 = 1;
          }else if (tabName === 'waterWay'){
            this.tab = 2;
            this.tab2 = 2
          }
          this.currentTab = tabName;
          console.log("Current tab",this.tab)
        },
        //
        // switchBusinessTab(tabValue) {
        //   if (tabValue === 'travel') {
        //     this.businessTab = 'travel';
        //   }
        // },

        // closeSubMenu() {
        //   this.showSubMenu = false;
        // },

        toggelIcon() {
          if (this.icon === "menu") {
            this.icon = "x";
          } else {
            this.icon = "menu";
          }
        },
        closeToggle() {
          this.icon = "menu";
        },
        routeToHome() {
          console.log("joo")
          if (this.$route.name !== "HomePage")
            this.$router.push({
              name: "HomePage"
            });
        },
        routeToNpod(){
          window.open(process.env.VUE_APP_NPOD_URL,"_blank")

        },
        async getDiscount(){
          await getActiveDiscount().then(res =>{
            if (res.data){
              this.discount = res.data
            }
          }).catch((err) => {
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
          });
        },
        navigateToPage(routeName) {
          switch (routeName) {
            case 'ribs':
              this.$router.push({name: 'Ribs'});
              break;
            case 'skip':
              this.$router.push({name: 'Skip'});
              break;
            case 'flair':
              this.$router.push({name: 'Flair'});
              break;
            default:
              break;
          }
        },
        routeToPages(name) {
          if (name === "Developer") {
            window.open("https://developer-roadpadi.netlify.app", "_self");
          }
        },

        changeColor() {
          if (
              document.body.scrollTop > 100 ||
              document.documentElement.scrollTop > 100
          ) {
            this.bg = "white";
          } else {
            this.bg = "transparent";
          }
        },
      },
      mounted() {
        window.onscroll = () => {
          this.changeColor();
        };
      },
     async created() {
      await this.getDiscount()
     }
  }
</script>

<style scoped lang="scss">
  ::v-deep .v-tab {
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 24px;
    color: #000F23 !important;
    //color: var(--padiGrey);
    text-transform: none;
    letter-spacing: 0 !important;
    @media (max-width: 1024px) {
      max-width: 100% !important;
      justify-content: left;
      padding: 20px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
    }
  }

  ::v-deep .theme--light.v-tabs>.v-tabs-bar {
    background-color: transparent !important;
  }



  .mobile-nav-line{
    @media (max-width: 1024px) {
      width: 200px;
      height: 2px;
      opacity: 50%;
      margin-left: 20px;
      background-color: #80A4D6;
    }

  }

  .ipad {
    z-index: 10;
    padding-right: 10rem !important;
    padding-left: 10rem !important;

    @media screen and (min-width: 768px) and (max-width: 1264px) {
      padding: 0.5rem 1rem !important;
    }

    @media screen and (max-width: 768px) {
      padding: 10px !important;
    }

  }

  .v-toolbar__content {
    padding: 4px 0 !important;
  }
  ::v-deep .v-toolbar__content, .v-toolbar__extension {
    padding: 0!important;
  }

  .small-screen {
    @media screen and (max-width: 370px) {
      padding: 0 0.5rem;
    }
  }

  .appBar-title {
    font-family: "Raleway", serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: none !important;
    text-align: left;
    color: #263238;
  }

  .item-css {
    width: 100%;
    height: 76px;
    cursor: pointer;
    font-family: Raleway, serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #154b94;

    @media (max-width: 1024px) {
      padding: 10px 20px;
    }
  }

  .anim {
    transition: 5s;
    transition-timing-function: ease-in-out;
    transform: translateY(0);
    opacity: 1;
    animation: infinite;
  }

  .item-css:hover {
    height: 76px;
    background: rgba(124, 176, 246, 0.05);
    width: 100%;
  }

  .sign-in {
    font-family: Raleway, serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #004aad;
  }

  .get-started {
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Raleway, serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #004aad;
  }

  .appBar-title.signup {
    background: #004aac;
    border-radius: 6px;
    color: #ffffff;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 3rem !important;
  }
  .appBar-title.signup-mobile{
    background: #004aac;
    border-radius: 6px;
    color: #ffffff;
    padding: 5px 5px 5px 1.5rem;
    width: 7rem !important;
    margin-left: 20px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    height: 46px!important;
  }
  ::v-deep .v-btn__content {
    font-family: Raleway, sans-serif !important;
  }

  .product-container{
    width: 424px;
    min-height: 331px;
    background: #FDFFFC;
    box-shadow: 0 4px 60px rgba(38, 50, 56, 0.1);
    border-radius: 10px;

  }
  .new-product-container{
    width: 800px;
    height: 206px;
    background: #FCFDFF;
    display: flex;
    flex-direction: column;
  }
  .head-txt{
    font-family: Raleway,sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 55px;
    text-align: left;
    color: #000F23;
    height: 63px !important;
    width: 265px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .head-txt:hover{
    background: #E3EEFF;
  }

  .product-txt{
    font-family: Raleway,sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #000F23;
  }
  .paragraph-txt{
    font-family: Raleway,sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #000000;
    white-space: nowrap;
    padding-top: 10px;
  }
  .product-item:hover{
    background-color: #FDFFDD;
  }

  .avatar-container {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    background: #F5F9FF;
    padding-right: 50px;

  }

  .avatar-inner {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    background-color: #E3F0FF;
    margin-top: 10px;
    margin-left: 10px;
  }

  .avatar-inner img {
    padding-top: 20px;
    margin-left: 20px;
    border-radius: 50%;
    object-fit: contain;
    display: flex;
    align-self: center;
  }
  .custom-menu .v-menu{
    max-width: 30px !important;
  }
  .product-container-header{
    font-family: 'Raleway',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #263238;
    @media (max-width: 1024px) {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #000F23;
    }
  }
  .product-container-desc{
    font-family: 'Raleway',sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #263238;
    cursor: pointer;
  }

  .products-card{
    @media screen and (max-width: 375px){
      display: flex;
      flex-wrap: wrap;
      width: 293px;
      height: 814px;
    }
  }

  .item-title{
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #000F23;
    display: flex;
    align-content: center;
  }

  .fade-enter-from {
    opacity: 0;
  }
  .fade-enter-to {
    opacity: 1;
  }
  .fade-enter-active {
    transition: all 2s ease;
  }
  .fade-leave-from {
    opacity: 1;
  }
  .fade-leave-to {
    opacity: 0;
  }
  .fade-leave-active {
    transition: all 2s ease;
  }

  .nomadicpod-logo{
    height: 45px;
    width: 200px;
    object-fit: contain;
    display: flex;
    align-self: center;
    justify-self: flex-start;

  //  @media (width:360px){
  //  padding-left: 1rem;
  //}
  }

</style>