<script>
export default {
  name: 'TanSectionThree'
}
</script>

<template>
<div class="section-parent tw-px-5 lg:tw-px-40">

  <p class="head-txt">Features</p>
  <p class="bold-txt tw-my-4">Revolutionize Your Intercity Travel Business</p>
  <p class="sub-txt tw-my-4">Enjoy the extra income by providing our services on your terminal and Pos machines</p>



  <div class=" tw-flex tw-justify-between tw-w-full tw-mt-20 detailed-info-block">

      <div class="tw-my-8" >
      <div class="tw-flex justify-center"><img src="@/assets/icons/thunder-bolt-icon.svg" alt="icon"></div>
        <p class="bold-header-txt tw-my-5">Effortless Booking Management</p>
        <p class="list-txt tw-my-5">Spend less time managing bookings and more time<br> growing your business. Our platform provides a<br>
          centralized view of all your intercity travel bookings,<br> allowing you to easily track, modify, and confirm<br>
          reservations for your passengers.</p>
      </div>
      <div class="tw-my-8">
        <div class="tw-flex justify-center"><img src="@/assets/icons/record-tracker-icon.svg" alt="" class="tw-mr-3"></div>
        <p class="bold-header-txt tw-my-5">Track Performance & Earn More</p>
        <p class="list-txt tw-my-5">Uncover valuable insights into your booking history<br> and sales performance.
          Our detailed analytics<br> dashboard helps you identify trends, optimize your<br> offerings, and maximize revenue generation.</p>
      </div>
      <div class="tw-my-8">
        <div class="tw-flex justify-center"><img src="@/assets/icons/united-people-icon.svg" alt="" class="tw-mr-3"></div>
        <p class="bold-header-txt tw-my-5">Stay Organized & Impress Clients</p>
        <p class="list-txt tw-my-5">Access a comprehensive record of all your clients'<br> past and upcoming trips. Provide exceptional<br>
          customer service with easy access to booking<br> details and itinerary management tools, ensuring a<br> smooth travel experience for your clients</p>
      </div>

    </div>


</div>
</template>

<style scoped lang="scss">
.section-parent{
  margin-bottom: 100px;
  min-height: 60vh;
  margin-top: 100px;
}
.detailed-info-block{
  @media (max-width: 576px) {
    display: flex;
    flex-wrap: wrap ;
    margin-top: 10%;
  }
}
.left{
  width: 50%;
}
.right{
  width: 50%;
}
.head-txt{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #A73C56;
}
.bold-txt{
  font-family: Raleway,sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  text-align: center;
  color: #263238;
  @media (max-width: 576px) {
    font-size: 36px;
    line-height: 43.2px;
    text-align: center;
  }
}
.sub-txt{
  font-family: Raleway,sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #263238;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 30px;
  }
}
.bold-header-txt{
  font-family: Raleway,sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
  color: #263238;
  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 28px;
  }
}

.list-txt{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #263238;
}
</style>