<script>
export default {
  name: 'TanSectionFive'
}
</script>

<template>
  <div class="section-parent tw-flex tw-px-5 lg:tw-px-40">
    <div class="left-side">
      <img src="@/assets/Tan-sec5-img.png" alt="">
    </div>
    <div class="right-side">
      <p class="bold-header-txt tw-pt-32">Reporting and Analytics</p>
      <p class="paragraph-sub-head tw-my-8">With Wave-Jotter Analytics, stay ahead with forecasting tools and<br>
        detailed reports. Proactively manage resources and strategically position<br>
        your transport business for success.</p>
      <div class="tw-flex  tw-my-8">
        <img src="@/assets/icons/tan-icon-sec5(1).svg" alt="icon" class="tw-mr-3">
        <p class="list-txt">Gain valuable insights into sales and operational performance through<br>
          intuitive analytics.</p>
      </div>
      <div class="tw-flex tw-my-8">
        <img src="@/assets/icons/tan-icon-sec5(2).svg" alt="icon" class="tw-mr-3">
        <p class="list-txt">Make informed decisions based on comprehensive reports, enhancing<br>overall efficiency.</p>
      </div>
      <div class="tw-flex tw-my-8">
        <img src="@/assets/icons/tan-icon-sec5(3).svg" alt="icon" class="tw-mr-3">
        <p class="list-txt">Monitor the performance of routes, drivers, and overall operations to<br> enhance efficiency and service delivery.</p>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
.section-parent{
  margin-bottom: 100px;
  min-height: 60vh;
  margin-top: 100px;
  @media (max-width: 576px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}
.left-side{
  width: 50%;
  @media (max-width: 576px) {
    width: 100%;
  }
}
.right-side{
  width: 50%;
  @media (max-width: 576px) {
    width: 100%;
  }
}
.head-txt{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #A73C56;
}
.bold-txt{
  font-family: Raleway,sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  text-align: center;
  color: #263238;
}
.sub-txt{
  font-family: Raleway,sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #263238;
}
.bold-header-txt{
  font-family: Raleway,sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  color: #263238;
  @media (max-width: 576px) {
    padding-top: 0;
    font-size: 32px;
    line-height: 38.4px;
    text-align: left;
  }
}
.paragraph-sub-head{
  font-family: Raleway,sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #263238;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
  }
}
.list-txt{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #263238;
}
</style>