<template>
    <div class="section-sculptor">
        <v-col lg="6" sm="12">
            <div class="display-center">
                <div class="image-div">
                    <div class="display-center" >
                        <img src="@/assets/newlandingpage/waterways-img1.svg" alt="waterways"
                            class="waterways-img top-img">
                    </div>
                    <div >
                        <img src="@/assets/newlandingpage/waterways-img2.svg" alt="waterways" class="waterways-img">
                    </div>
                    <div class="shift-end" >
                        <img src="@/assets/newlandingpage/waterways-img3.svg" alt="waterways"
                            class="waterways-img bottom-img">
                    </div>
                </div>
            </div>

        </v-col>
        <v-col lg="6" sm="12" class="lg:tw-mt-0 tw-mt-8">
            <p class="blue-small-bold-text mb-5" >Waterway Trips</p>
            <p class="header" >
                Discover Waterway Adventures
            </p>
            <p class="text-subheader py-8" >Do you have an appetite for waterborne adventure, or simply seek a convenient
                way to get around? <span style="color: #004aad;"> Our on-the-go cruise and boat booking service</span>
                is your gateway to discovering waterway journeys like never before.. </p>
            <div   class="
                        coming-soon
                        red-small-bold-text
                        text-center
                        tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-2
                      ">Coming Soon</div>
        </v-col>
    </div>
</template>

<script>
    export default {
        name: "SectionThree"
    }
</script>

<style lang="scss" scoped>
    .section-sculptor {
        display: flex;
        align-items: center;
        padding-bottom: 100px;
        min-height: 60vh;
        padding-top: 100px;
       background: var(--npodLightBlue01);

      @media screen and (min-width: 1920px){
        padding-right: 9rem !important;
        padding-left: 9rem !important;
      }
      @media screen and (width: 1366px){
        padding-right: 9rem !important;
        padding-left: 9rem !important;
      }
      @media (max-width:1264px) {
        flex-direction: column;
        min-height: 60vh;
        padding-bottom: 50px;
        padding-top: 50px;
      }
      @media (max-width:576px) {
        min-height: auto;
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 30px;
        padding-top: 30px;
      }
      @media screen and (min-width: 768px) and (max-width: 1264px) {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
      }
      @media (max-width:1264px) {
        padding-left: 3rem;
        padding-right: 3rem;
      }
      @media screen and (max-width: 1040px) {
        flex-direction: column;
        background-size: cover;
        background-position: right center;
      }
      @media screen and (width:768px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (max-width:430px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (width:428px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (max-width:414px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
        //height: 90vh;
      }
      @media screen and (width:390px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (width:375px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (max-width:360px) {
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        padding-top: 30px;
      }
    }

    .header {
    color: #263238;
    font-family: Raleway;
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    width: 90%;

        @media screen and (max-width:1366px) {
          width: 100%;
          font-size: 40px;
          line-height: 55px;
        }
        @media screen and (max-width:768px) {
        width: 100%;
            font-size: 43px;
            line-height: 60px;
        }
        @media screen and (max-width:576px) {
            font-size: 35px;
            line-height: 50px;
        }
        @media screen and (min-width: 1920px) {
            font-size: 70px;
            width: 90%;
            line-height: 100px;
           }
}

    .text-subheader {
        font-family: "Raleway", sans-serif !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 38px;
        letter-spacing: 0;
        text-align: left;
        color: #263238;

        @media screen and (min-width: 1920px) {
            width: 90%;
            font-size: 22px;
            line-height: 38px;
        }
        @media screen and (max-width: 1366px) {
          width: 90%;
          font-size: 16px;
          line-height: 38px;
        }

        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 28px;
            width: 100%;
        }
    }

    .blue-small-bold-text {
        color: #004AAD;
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        letter-spacing: 0;
        text-align: left;
        font-weight: 600;
        line-height: 30px;

        @media screen and (max-width:768px) {
            font-size: 16px;
        }

        @media screen and (max-width:576px) {
            font-size: 13px !important;
        }
    }

    .red-small-bold-text {
      color: red;
      font-family: Raleway;
      font-size: 18px;
      font-style: normal;
      letter-spacing: 0;
      text-align: left;
      font-weight: 600;
      line-height: 30px;


      @media screen and (max-width:768px) {
        font-size: 16px;
      }

      @media screen and (max-width:576px) {
        font-size: 13px !important;
      }
    }

    .fs-title {
        width: fit-content;
        height: 50px;
        text-align: center;

        border-radius: 10px;
        border: 2px solid var(--RoadPadi-06, #004AAD);
        box-shadow: 0px 4px 8px 0px rgba(57, 130, 240, 0.10);
        background-color: transparent;

    }

    .coming-soon {
      width: fit-content;
      height: 50px;
      text-align: center;
      border-radius: 10px;
      border: 2px solid var(--RoadPadi-06, red);
      box-shadow: 0px 4px 8px 0px rgba(57, 130, 240, 0.10);
      background-color: transparent;

    }

    .medium-header-raleway {
        color: #0C0910;
        font-family: Raleway;
        font-size: 27px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;

        @media screen and (max-width: 768px) {
            font-size: 23px;
        }

        @media screen and (max-width: 576px) {
            font-size: 17px;
            line-height: 25px;

        }
    }

    .black-small-text {
        color: #344053;
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @media screen and (max-width: 920px) {
            font-size: 16px;
        }

        @media screen and (max-width: 576px) {
            font-size: 12px;
            line-height: 15px;
        }
    }

    .image-div {
        max-width: 100%;
        width: 600px;

        @media (max-width:576px) {
            width: 100%;
        }
    }

    .waterways-img {
        width: 380px;
        object-fit: cover;

        @media screen and (max-width:768px) {
            width: 330px;
        }

        @media screen and (max-width:576px) {
            width: 250px;
        }
    }

    .bottom-img {
        margin-top: -150px;

        @media (max-width:700px) {
            margin-top: -100px;
        }

        @media (max-width:430px) {
            margin-right: -70px;
        }
    }

    .top-img {
        margin-bottom: -50px;
        margin-left: 80px;
    }
</style>