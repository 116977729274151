var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-full look"},[_c('div',{staticClass:"tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-justify-between tw-items-start lg:tw-items-center tw-py-5"},[_c('p',{staticClass:"passenger",style:({color: '#004AAD'})},[_vm._v("Passenger "),(_vm.index > 0)?_c('span',{staticClass:"or"},[_vm._v(" "+_vm._s(_vm.index+1)+" ")]):_vm._e(),_vm._v(" Details")]),_c('p',{staticClass:"tw-pt-8 md:tw-pt-0"},[_c('span',{staticClass:"use-code tw-cursor-pointer",style:({color: '#004AAD'}),on:{"click":_vm.closeModal}},[_vm._v("Use Traveller’s Code ")]),(_vm.index > 0)?_c('span',{staticClass:"or"},[_vm._v(" OR ")]):_vm._e(),(_vm.index > 0)?_c('span',{staticClass:"use-code tw-cursor-pointer",style:({color:'#004AAD'}),on:{"click":_vm.getPrimaryTraveller}},[_vm._v("Use Primary Details")]):_vm._e()])]),_c('div',{staticClass:"tw-flex flipping"},[_c('v-col',{staticClass:"data left",attrs:{"sm":"12","lg":"6","md":"6"}},[_c('p',{staticClass:"form-header"},[_vm._v("First Name")]),_c('ValidationProvider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"solo":"","placeholder":"John","type":"text","color":"#004aad","hide-details":""},model:{value:(_vm.traveller.firstName),callback:function ($$v) {_vm.$set(_vm.traveller, "firstName", $$v)},expression:"traveller.firstName"}}),_c('span',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('v-col',{staticClass:"data right",attrs:{"sm":"12","lg":"6","md":"6"}},[_c('p',{staticClass:"form-header"},[_vm._v("Last Name")]),_c('ValidationProvider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"solo":"","placeholder":"Doe","type":"text","color":"#004aad","hide-details":""},model:{value:(_vm.traveller.lastName),callback:function ($$v) {_vm.$set(_vm.traveller, "lastName", $$v)},expression:"traveller.lastName"}}),_c('span',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('div',{staticClass:"tw-flex flipping"},[_c('v-col',{staticClass:"data left",attrs:{"sm":"12","lg":"6","md":"6"}},[_c('p',{staticClass:"form-header"},[_vm._v(" Phone Number")]),_c('phone-number',{attrs:{"phone-number-exist":_vm.traveller.phoneNumber,"color":"#004aad"},on:{"getNumber":_vm.getTravellerNumber}})],1),_c('v-col',{staticClass:"data right",attrs:{"sm":"12","lg":"6","md":"6"}},[_c('p',{staticClass:"form-header"},[_vm._v("Email")]),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"solo":"","placeholder":"example@gmail.com","hide-details":"","type":"email","color":"#004aad"},model:{value:(_vm.traveller.email),callback:function ($$v) {_vm.$set(_vm.traveller, "email", $$v)},expression:"traveller.email"}}),_c('span',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('div',{staticClass:"flipping tw-flex"},[_c('v-col',{staticClass:"data left",attrs:{"sm":"12","lg":"6","md":"6"}},[_c('p',{staticClass:"form-header"},[_vm._v(" Date of Birth")]),_c('ValidationProvider',{attrs:{"name":"Date of Birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('div',{class:classes},[_c('v-menu',{ref:"fromDateMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"placeholder":"2022-09-9","solo":"","hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{},[_vm._v(" mdi-calendar-month-outline ")])]},proxy:true}],null,true),model:{value:(_vm.traveller.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.traveller, "dateOfBirth", $$v)},expression:"traveller.dateOfBirth"}},on))]}}],null,true),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{attrs:{"locale":"en-in","no-title":"","max":_vm.maxDate,"scrollable":""},model:{value:(_vm.traveller.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.traveller, "dateOfBirth", $$v)},expression:"traveller.dateOfBirth"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.fromDateMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$refs.fromDateMenu.save(_vm.traveller.dateOfBirth)}}},[_vm._v("OK ")])],1)],1),_c('span',[_vm._v(_vm._s(errors[0]))])],1)])]}}])})],1),_c('v-col',{staticClass:"data right",attrs:{"sm":"12","lg":"6","md":"6"}},[_c('p',{staticClass:"form-header"},[_vm._v("Gender")]),_c('ValidationProvider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-select',{staticClass:"text-capitalize",attrs:{"items":_vm.genders,"solo":"","placeholder":"MALE","hide-details":"","type":"text","color":"#004aad","append-icon":"mdi-chevron-down"},model:{value:(_vm.traveller.gender),callback:function ($$v) {_vm.$set(_vm.traveller, "gender", $$v)},expression:"traveller.gender"}}),_c('span',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('div',{staticClass:"tw-flex flipping"},[_c('v-col',{staticClass:"data left",attrs:{"sm":"12","md":"6"}},[_c('p',{staticClass:"form-header"},[_vm._v("Address")]),_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-cursor-pointer",class:classes,on:{"click":_vm.handleShowAddressModal}},[_c('v-combobox',{staticClass:"text-capitalize",attrs:{"solo":"","placeholder":"317, Borno way, Alagomeji Yaba, Lagos","hide-details":"","type":"text","color":"#004aad","prepend-inner-icon":"","append-icon":""},model:{value:(_vm.customerAddress),callback:function ($$v) {_vm.customerAddress=$$v},expression:"customerAddress"}}),_c('span',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('div',{staticClass:"next-of-kin-details"},[_c('p',{staticClass:"nof",style:({color: '#004AAD'})},[_vm._v("Next Of Kin Details")]),_c('div',{staticClass:"tw-flex flipping"},[_c('v-col',{staticClass:"data left",attrs:{"sm":"12","lg":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Next of kin first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('p',{staticClass:"form-header"},[_vm._v("First Name")]),_c('div',{class:classes},[_c('v-text-field',{attrs:{"solo":"","placeholder":"John","type":"text","color":"#004aad","hide-details":""},model:{value:(_vm.traveller.nextOfKinFirstName),callback:function ($$v) {_vm.$set(_vm.traveller, "nextOfKinFirstName", $$v)},expression:"traveller.nextOfKinFirstName"}}),_c('span',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('v-col',{staticClass:"data right",attrs:{"sm":"12","lg":"6","md":"6"}},[_c('p',{staticClass:"form-header"},[_vm._v("Last Name")]),_c('ValidationProvider',{attrs:{"name":"Next of kin last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"solo":"","placeholder":"Doe","type":"text","color":"#004aad","hide-details":""},model:{value:(_vm.traveller.nextOfKinLastName),callback:function ($$v) {_vm.$set(_vm.traveller, "nextOfKinLastName", $$v)},expression:"traveller.nextOfKinLastName"}}),_c('span',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('div',{staticClass:"tw-flex flipping"},[_c('v-col',{staticClass:"data left",attrs:{"sm":"12","lg":"6","md":"6"}},[_c('p',{staticClass:"form-header"},[_vm._v(" Phone Number")]),_c('phone-number',{attrs:{"phone-number-exist":_vm.traveller.nextOfKinPhoneNumber,"color":"#004aad"},on:{"getNumber":_vm.getNextOfKinNumber}})],1),_c('v-col',{staticClass:"data right",attrs:{"sm":"12","lg":"6","md":"6"}},[_c('p',{staticClass:"form-header"},[_vm._v("Email (Optional)")]),_c('ValidationProvider',{attrs:{"name":"Next of kin email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"solo":"","placeholder":"example@gmail.com","hide-details":"","type":"email","color":"#004aad"},model:{value:(_vm.traveller.nextOfKinEmail),callback:function ($$v) {_vm.$set(_vm.traveller, "nextOfKinEmail", $$v)},expression:"traveller.nextOfKinEmail"}}),_c('span',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)]),_c('booking-code-modal',{attrs:{"dialog":_vm.dialog,"doneLoading":_vm.getCodeLoading},on:{"close":_vm.closeModal,"bookingAction":_vm.enterBookingCode}}),_c('modal',{attrs:{"section":"custom-address","address":_vm.traveller.address,"dialog":_vm.showAddressModal},on:{"handleAddress":_vm.handleAddress,"close":_vm.closeHandleModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }