<script>
export default {
  name: 'BusinessMobileView'
}

</script>

<template>
  <div>
    <p class="mobile-header-txt tw-pb-5 tw-pt-10 tw-pl-0">Businesses</p>
    <router-link to="/nibs">
    <div class="tw-flex tw-pb-5">
      <img src="@/assets/icons/business-mdi-bus.svg" alt="icon" class="tw-pb-10">
      <div class="tw-pl-5 tw-mt-1">
        <p class="sub-head tw-pb-1">Trip-Jotter</p>
        <p class="mobile-paragraph-txt">
          Manage your trip inventory, sales and booking<br>operations, all in one place.
        </p>
      </div>
    </div>
    </router-link>
    <div class="tw-flex tw-pb-5">
      <img src="@/assets/icons/fintracka-icon.svg" alt="icon" class="tw-pb-14">
      <div class="tw-pl-5 tw-mt-1">
        <p class="sub-head tw-pb-1">Fintracka</p>
        <p class="mobile-paragraph-txt">
          Automate the tracking, and management of your<br>
          revenue inflow and expenditure from all booking<br>
          and sales operations.</p>
      </div>
    </div>
    <router-link to="/wave-jotter">
      <div class="tw-flex tw-pb-5">
        <img src="@/assets/icons/waterway-icon.svg" alt="icon" class="tw-pb-5 tw-pl-1">
        <div class="tw-flex tw-pl-0 tw-mt-1 tw-flex-col">
          <p class="sub-head tw-pb-1 tw-pl-2">Wave-Jotter</p>
          <label class="mobile-paragraph-txt tw-pl-2 tw-flex tw-flex-wrap">
            For waterways transport providers to manage your waterway<br/>
            trip-inventory, sales, and booking operations seamlessly.
          </label>
        </div>
      </div>
    </router-link>
    <router-link to="/tan">
    <div class="tw-flex tw-pb-5">
      <img src="@/assets/icons/travel-agent-icon.svg" alt="icon" class="tw-pb-5 tw-pl-1">
      <div class="tw-pl-5 tw-mt-1">
        <p class="sub-head tw-pb-1 tw-pl-2">Assistant</p>
        <p class="mobile-paragraph-txt tw-pl-2">
          Make extra income as a travel agent.
        </p>
      </div>
    </div>
    </router-link>
  </div>
</template>

<style scoped lang="scss">
.sub-head{
  font-family: Raleway,sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #000F23 ;
}
.mobile-header-txt{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #000F23;
}
.mobile-paragraph-txt{
  font-family: Raleway,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #00193A;
  flex-wrap: wrap;
  display: flex;
}
</style>