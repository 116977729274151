<template>
  <div id="career">
    <NavbarWhite />
    <div class="section-one hundred-vh">
      <div class="one-width">
        <h1>Careers</h1>
        <p class="mt-4 mb-7">
          We have a healthy work culture and we are keen on your personal and
          professional growth
        </p>
        <v-btn text class="tw-cursor-button" @click="scrollTo">
          See Current Openings</v-btn
        >
      </div>
    </div>
    <div class="section-two hundred-vh">
      <div class="wordings">
        <h6>OUR CULTURE</h6>
        <h1>Life At Nomadicpod</h1>
        <p>
          We have the best environment to work, grow and have fun while doing
          it. This is what to expect daily while working with us.
        </p>
      </div>
      <div class="images">
        <div class="picture2">
          <img src="../assets/careerpictures/pic2.png" alt="" class="pic2" />
          <p class="btn-like mt-5 one">Fun Loving Environment</p>
        </div>
        <div class="picture3">
          <img src="../assets/careerpictures/pic5.png" alt="" class="pic3" />
        </div>
        <div class="picture4">
          <p class="btn-like two">A Great Community</p>
          <img src="../assets/careerpictures/pic4.png" alt="" class="pic4" />
        </div>
      </div>
    </div>
    <hr />
    <div class="section-three hundred-vh">
      <div class="textpart">
        <h6>PERKS & BENEFITS</h6>
        <h2>Build The Future With Us</h2>
        <p class="mt-3">
          We have the best environment to work, grow and have fun while doing
          it. This is what to expect daily while working with us.
        </p>
      </div>
      <div class="benefits row">
        <div class="col-lg-4 col-md-4 col-sm-6 col-6 my-10">
          <img src="../assets/careericons/heart.png" width="70" alt="" />
          <p>Health Insurance</p>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-6 my-10">
          <img src="../assets/careericons/umbrella.png" width="60" alt="" />
          <p>Paid Annual Leave</p>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-6 my-10">
          <img src="../assets/careericons/company.png" width="70" alt="" />
          <p>A hybrid workplace</p>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-6 my-10">
          <img src="../assets/careericons/gym.png" width="90" alt="" />
          <p>Gym Membership</p>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-6 my-10">
          <img
            src="../assets/careericons/party.png"
            width="70"
            style="padding-bottom: 20px"
            alt=""
          />
          <p>Monthly Fun Activities</p>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-6 my-10">
          <img
            src="../assets/careericons/laptop.png"
            style="padding-bottom: 20px"
            width="70"
            alt=""
          />
          <p>High End Laptop</p>
        </div>
      </div>
    </div>
    <div class="section-four hundred-vh" id="jobopp">
      <div class="textdiv">
        <h6>JOB OPPORTUNITIES</h6>
        <h1>Do It the Nomadicpod Way</h1>
        <p>Are you ready to Lead, Excel and Innovate?</p>
      </div>
      <div class="cardarea">
        <v-card elevation="1" v-for="(jobOpp, index) in jobOpps" :key="index" min-height="350">
          <v-card-text>
            <h6>{{ jobOpp.category }}</h6>
            <h1 class="my-3">{{ jobOpp.role }}</h1>
            <p>
              {{ jobOpp.desc }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              style="color: #77caf1"
              @click="
                $router.push({
                  name: 'JobOpenings',
                  params: { jobname: jobOpp.role },
                })
              "
              >See More &nbsp; <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      j
    </div>
    <Footer />
  </div>
</template>

<script>
import NavbarWhite from "@/home/NavbarWhite.vue";
import Footer from "@/home/Footer.vue";

export default {
  components: {
    NavbarWhite,
    Footer,
  },
  data() {
    return {
      jobOpps: [
        {
          category: "DESIGN",
          role: "Product Designer",
          desc: "They build designs for new Products, add improvements to existing ones.",
        },
        {
          category: "ENGINEERING",
          role: "Frontend Developer",
          desc: " A Front End Developer is responsible for optimizing websites for different formats like desktops and mobile phones. ",
        },
      ],
    };
  },
  methods: {
    scrollTo() {
      const el = document.getElementById("jobopp");
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.section-one {
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.85)),
    url("https://res.cloudinary.com/myroadpadi/image/upload/v1651305648/pic1_haso27.png");
  background-size: cover;
  background-color: rgba(16, 16, 13, 0.175);
  @media (max-width: 500px) {
    background-position: center;
  }
}
.btn-like {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  background: #ffffff;
  border: 1px solid rgba(38, 50, 56, 0.21);
  padding: 10px;
}

.one-width {
  width: 300px;
  margin: 0 auto;
  padding: 350px 0 200px;
  text-align: center;
  h1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 54px;
    /* identical to box height, or 150% */

    text-align: center;

    color: #ffffff;
  }
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 175% */

    text-align: center;

    color: #ffffff;
  }
  .v-btn {
    text-transform: initial;
    color: #fff;
    border: 1px solid #fff;
  }
}
.wordings {
  width: 300px;
  margin: 0 auto;
  padding-top: 100px;
  text-align: center;
  h6 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 54px;
    letter-spacing: 3px;
    color: #004aad;
  }
  h1 {
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;

    color: #4f4f4f;
    padding: 5px 0 15px;
  }
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #4f4f4f;
  }
}
.images {
  display: flex;
  padding: 3rem 10rem 10rem;
  justify-content: center;
  align-items: center;
  @media (max-width: 1264px) {
    padding: 3rem 3rem 10rem;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  @media (max-width: 600px) {
    padding: 3rem 1.5rem 10rem;
  }
  .picture2 {
    width: 300px;
    height: 380px;
    margin-right: 30px;
    @media (max-width: 1024px) {
      width: fit-content;
      height: fit-content;
      margin: 0 auto 30px;
    }
    .one {
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
  .picture3 {
    margin-right: 30px;
    @media (max-width: 1024px) {
      width: fit-content;
      margin: 0 auto;
      height: fit-content;
    }
    img {
      width: 400px;
      height: 380px;
      @media (max-width: 1024px) {
        width: 100%;
        height: auto;
      }
    }
  }
  .picture4 {
    width: 280px;
    height: 380px;
    @media (max-width: 1024px) {
      width: 100%;
      height: auto;
      margin: 0 auto;
    }
    .two {
      @media (max-width: 1024px) {
        margin-top: 20px;
      }
    }
  }
}

hr {
  border: none;
  border-bottom: 2px solid rgba(79, 79, 79, 0.23);
  width: 40%;
  margin: 0 auto;
}
.section-three {
  .textpart {
    width: 400px;
    margin: 0 auto;
    padding-top: 150px;
    text-align: center;
    @media (max-width: 600px) {
      width: fit-content;
      margin: 20px;
      padding-top: 90px;
    }
    h6 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 54px;
      text-align: center;
      letter-spacing: 3px;
      color: #004aad;
    }
    h2 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 54px;
      text-align: center;
      color: #4f4f4f;
    }
    p {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      color: #4f4f4f;
    }
  }
  .benefits {
    padding: 7% 10%;
    text-align: center;
    @media (max-width: 1264px) {
      padding: 3rem 3rem 10rem;
    }
    @media (max-width: 600px) {
      padding: 3rem 1rem 10rem;
    }
    img {
      margin: 0 auto;
      padding-bottom: 40px;
      height: 100px;
    }
    p {
      font-family: "Raleway", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;

      text-align: center;

      color: #4f4f4f;
    }
  }
}
.section-four {
  display: none;
  background-color: #263238;
  padding: 10rem;
  @media (max-width: 1264px) {
    padding: 7rem 3rem 10rem;
  }
  @media (max-width: 500px) {
    padding: 7rem 1.8rem 10rem;
  }
  .textdiv {
    width: 350px;
    @media (max-width: 786px) {
      width: 100%;
    }
    h6 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 54px;
      color: #77caf1;
    }
    h1 {
      font-family: "Raleway", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 23px;
      line-height: 28px;
      /* identical to box height, or 140% */
      margin: 10px 0;
      color: #fafafa;
    }
    p {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      /* or 200% */

      color: #fafafa;
    }
  }
  .cardarea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .v-card {
      background: #263238;
      box-shadow: 0 4px 50px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      width: 380px;
      height: fit-content;
      padding: 40px 20px;
      margin: 20px 40px 20px 0;
      border: none;
      @media (max-width: 1024px) {
        width: 320px;
        height: fit-content;
        margin: 20px 25px 20px 0;
      }
      @media screen and (max-width: 768px) {
        margin: 20px 0;
      }
      @media (max-width: 500px) {
        width: 350px;
      }
    }
    h6 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #77caf1;
    }
    h1 {
      font-family: "Raleway", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #fafafa;
    }
    p {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      color: #fafafa;
    }
  }
}
</style>