<template>
  <div class="step-one">
    <loader :loading="loading" v-if="loading" />
    <div class="tw-w-full" v-else>
      <nav-bar />
      <booking-steps />
      <one-way-search @handleRemount="handleRemount" />
      <available-vehicles v-if="remount" />
    </div>
    <!-- <checkout-details/> -->
  </div>
</template>

<script>
  import BookingSteps from '../../components/reuseables/hire/BookingSteps.vue'
  import AvailableVehicles from '../hire/AvailableVehicles.vue'
  import OneWaySearch from '../hire/HireSearch.vue'
  import NavBar from "@/home/Navbar.vue";
  import {
    mapGetters
  } from "vuex";
  import dayjs from "dayjs";
  import Loader from "@/components/reuseables/Loader.vue";
  export default {
    data() {
      return {
        tab: null,
        loading: false,
        remount: true
      }
    },
    components: {
      Loader,
      NavBar,
      OneWaySearch,
      AvailableVehicles,
      BookingSteps
    },
    methods: {
      handleRemount() {
        this.remount = false
        this.$nextTick(() => {
          this.remount = true
        })
      }
    },
    computed: {
      ...mapGetters("booking", ["pendingTrips"])
    },
    async created() {
      let queryData = JSON.parse(sessionStorage.getItem("queryData"))
      if (!queryData) {
        this.loading = true
        let data = {}
        data.page = 0
        data.pageSize = 100
        await this.$store.dispatch("booking/setPendingTripsByPage", data).then(() => {
          this.pendingTrips.sort(function (a, b) {
            return (dayjs(a.takeOffDate + a.takeOffTime).toDate() -
              dayjs(b.takeOffDate + b.takeOffTime).toDate()
            )
          });
          this.pendingTrips = this.pendingTrips.filter(trip =>
            dayjs(trip.takeOffDate, trip.takeOffTime).isSame(dayjs().toDate()) ||
            dayjs(trip.takeOffDate, trip.takeOffTime).isAfter(dayjs(dayjs().toDate())))
          this.$store.dispatch("booking/setOneWayTrips", this.pendingTrips.slice(0, 10))
          this.loading = false
        }).finally(() => this.loading = false)
      }
    }
  }
</script>

<style lang='scss' scoped>

</style>