import { render, staticRenderFns } from "./Blogpages.vue?vue&type=template&id=bc8eca82&scoped=true&"
import script from "./Blogpages.vue?vue&type=script&lang=js&"
export * from "./Blogpages.vue?vue&type=script&lang=js&"
import style0 from "./Blogpages.vue?vue&type=style&index=0&id=bc8eca82&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc8eca82",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VCardSubtitle,VCardText,VCardTitle,VDivider,VImg})
