<template>
    <div class="section-sculptor">
        <v-col lg="5" sm="12" class="lg:tw-mt-0 tw-mt-8">
            <p class="blue-small-bold-text mb-5">Travel Insurance</p>
            <p class="header">
                Travel Insurance for Confident Travel
            </p>
            <p class="text-subheader my-8">With 24/7 assistance and customizable plans, we've got you covered, so you
                can focus on making unforgettable memories. </p>
            <div class="tw-hidden tw-items-center">
                <router-link to="/register-as" class="
                        fs-title
                        blue-small-bold-text
                        text-center
                        tw-flex tw-items-center tw-justify-center sm:tw-px-5 tw-px-2 tw-py-2
                      ">View Insurance Plans</router-link>
                <router-link to="/register-as" class="blue-small-bold-text sm:tw-ml-8 tw-ml-4">Learn more <v-icon
                        color="#004aad">mdi-arrow-right</v-icon>
                </router-link>
            </div>
        </v-col>
        <v-col lg="7" sm="12" class="">
            <div class="display-center">
                <div class="image-div">
                <div class="shift-end">
                    <img src="@/assets/newlandingpage/insurance-img1.svg" alt="insurance-services"
                        class="insurance-img top-img">
                </div>
                <div>
                    <img src="@/assets/newlandingpage/insurance-img2.svg" alt="insurance-services"
                        class="insurance-img mdl-img">
                </div>
                <div class="shift-end">
                    <img src="@/assets/newlandingpage/insurance-img3.svg" alt="insurance-services"
                        class="insurance-img bottom-img">
                </div>
            </div>
            </div>
        </v-col>
    </div>
</template>

<script>
    export default {
        name: "SectionEight"
    }
</script>

<style lang="scss" scoped>
    .section-sculptor {
        display: flex;
        align-items: center;
        min-height: 60vh;
        padding-top: 100px;
        padding-bottom: 100px;

      @media screen and (min-width: 1920px){
        padding-right: 9rem !important;
        padding-left: 9rem !important;
      }
      @media screen and (width: 1366px){
        padding-right: 9rem !important;
        padding-left: 9rem !important;
      }
      @media (max-width:1264px) {
        flex-direction: column;
        min-height: 60vh;
        padding-bottom: 50px;
        padding-top: 50px;
      }
      @media (max-width:576px) {
        min-height: auto;
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 30px;
        padding-top: 30px;
      }
      @media screen and (min-width: 768px) and (max-width: 1264px) {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
      }
      @media (max-width:1264px) {
        padding-left: 3rem;
        padding-right: 3rem;
      }
      @media screen and (max-width: 1040px) {
        flex-direction: column;
        background-size: cover;
        background-position: right center;
      }
      @media screen and (width:768px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (max-width:430px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (width:428px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (max-width:414px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
        //height: 90vh;
      }
      @media screen and (width:390px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (width:375px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (max-width:360px) {
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        padding-top: 30px;
      }
    }
    .image-div {
        max-width: 100%;
        width: 700px;

        @media (max-width:760px) {
            width: 100%;
        }
    }
    .header {
    color: #263238;
    font-family: Raleway;
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;

    @media screen and (max-width:768px) {
        width: 100%;
            font-size: 43px;
            line-height: 60px;
        }
        @media screen and (max-width:576px) {
            font-size: 35px;
            line-height: 50px;
        }
        @media screen and (max-width: 1366px) {
          font-size: 50px;
          line-height: 60px;
        }
        @media screen and (min-width: 1920px) {
            font-size: 70px;
            line-height: 100px;
        }
}
   

    .text-subheader {
        font-family: "Raleway", sans-serif !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 38px;
        letter-spacing: 0;
        text-align: left;
        color: #263238;

        @media screen and (min-width: 1920px) {
            width: 90%;
            font-size: 22px;
            line-height: 38px;
        }

        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 28px;
            width: 100%;
        }
    }

    .blue-small-bold-text {
        color: #004AAD;
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        letter-spacing: 0;
        text-align: left;
        font-weight: 600;
        line-height: 30px;

        @media screen and (max-width:768px) {
            font-size: 16px;
        }

        @media screen and (max-width:576px) {
            font-size: 13px !important;
        }
    }

    .fs-title {
        width: fit-content;
        height: 50px;
        text-align: center;

        border-radius: 10px;
        border: 2px solid var(--RoadPadi-06, #004AAD);
        box-shadow: 0px 4px 8px 0px rgba(57, 130, 240, 0.10);
        background-color: transparent;
    }

    .insurance-img {
        width: 350px;
        object-fit: cover;

        @media screen and (max-width:768px) {
            width: 300px;
        }
        @media screen and (max-width:600px) {
            width: 250px;
        }
    }

    .bottom-img {
        margin-top: 10px;
        @media (max-width:700px) {
            margin-top: 20px;
        }
        @media (max-width:600px) {
            margin-right: -50px;
        } 
    }

    .top-img {
        margin-bottom: - 110px;
        @media (max-width:700px) {
            margin-bottom: -80px;
        }
        @media (max-width:500px) {
            margin-right: -50px;
        } 
    }
    .mdl-img{
        @media (max-width:600px) {
            margin-left: -50px;
        } 
        @media (max-width:500px) {
            margin-left: -70px;
        } 
    }
</style>