<template>
  <div
    class="
      tw-flex tw-justify-start tw-items-center
      mt-10
      tw-cursor-pointer
      pl-4
      social-media
    "
  >
    <img
      src="@/assets/homepage/facebook.svg"
      class="mr-5 social-icon"
      alt="facebook"
      @click="socialPlatform('facebook')"
    />
    <img
      src="@/assets/homepage/twitter.svg"
      class="mr-5 social-icon"
      alt="twitter"
      @click="socialPlatform('twitter')"
    />
    <img
      src="@/assets/homepage/instagram.svg"
      class="mr-5 social-icon"
      alt="instagram"
      @click="socialPlatform('instagram')"
    />
    <img
      src="@/assets/whatsapp.svg"
      class="social-icon mr-5"
      alt="whatsapp "
      @click="socialPlatform('whatsapp')"
    />
    <img
      src="@/assets/homepage/linkedin.svg"
      alt="linkedin"
      @click="socialPlatform('linkedin')"
    />
  </div>
</template>

<script>
export default {
  methods: {
    socialPlatform(type) {
      if (type === "instagram") {
        window.open("https://www.instagram.com/nomadicpodhq", "_blank");
      } else if (type === "whatsapp") {
        window.open("https://wa.me/2349131540676", "_blank");
      } else if (type === "facebook") {
        window.open(
          "https://www.facebook.com/nomadicpodhq-111121164737744/",
          "_blank"
        );
      } else if (type === "twitter") {
        window.open("https://www.twitter.com/nomadicpodhq", "_blank");
      } else if (type === "linkedin") {
        window.open("https://www.linkedin.com/company/nomadicpodhq");
      }
    },
  },
};
</script>

<style>
</style>