var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"contact"}},[_c('Navbar'),_c('div',{staticClass:"together"},[_c('div',{staticClass:"alone1"},[_c('h1',[_vm._v("Get in Touch")]),_c('p',{staticClass:"my-3"},[_vm._v("If you need to get in touch with us, Drop a message.")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitMessage)}}},[_c('div',{staticClass:"input-space tw-flex tw-justify-between"},[_c('div',{staticClass:"name-field mr-5 mb-7"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Full name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Full Name","hide-details":"","required":""},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mail-field mb-7"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"E-mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","hide-details":"","label":"E-mail","type":"email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('v-spacer'),_c('div',{staticClass:"mb-6"},[_c('ValidationProvider',{staticClass:"mb-7",attrs:{"rules":"required","name":"Subject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","hide-details":"","label":"Subject","required":""},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-6"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"counter":"","label":"Message","outlined":"","required":"","hide-details":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"btndiv"},[_c('v-btn',{staticClass:"sendbtn",attrs:{"loading":_vm.loading,"type":"submit"}},[_vm._v(" send ")])],1)],1)]}}])})],1),_c('div',{staticClass:"contactinfo alone2"},[_c('h2',{staticClass:"grey-inter"},[_vm._v("Contact Us")]),_c('div',{staticClass:"space"},[_c('div',{staticClass:"tw-flex"},[_c('v-icon',{staticClass:"pr-4"},[_vm._v("mdi-map-marker")]),_c('p',{staticClass:"pt-1"},[_vm._v("371 Borno way by Spencer, Alagomeji, Yaba, Lagos")])],1),_c('p',[_c('v-icon',{staticClass:"pr-4"},[_vm._v("mdi-email")]),_vm._v(" hello@nomadicpod.com")],1),_c('p',[_c('v-icon',{staticClass:"pr-4"},[_vm._v("mdi-phone")]),_vm._v("+234-912 312 1378")],1)]),_c('hr'),_c('SocialMedia')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }