<template>
  <div id="onboarding" class="tw-flex">
    <v-col lg="4" class="left-side">
      <OnboardingLeft />
    </v-col>

    <v-col class="right-side">
      <img src="../../assets/roadpadi_logo.svg" alt="logo" width="140" class="mobile-view-logo">

      <h1 class="identity">Personal Identity</h1>

      <p class="story mt-2">Let's get you all set up</p>
      <div class="progressbar">
        <v-progress-linear value="15" background-color=#D9D9D9 color="#004aad" rounded height="10"></v-progress-linear>
        <p class="progress mt-2">Profile progress</p>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(saveDetails)">
          <div class="tw-flex flipping">
            <v-col sm="12" lg="6">
              <p class="form-header">Profile</p>
              <button class="button-logo-input" type="button" @click="onLogoFile()" :class="image ? 'border-0' : ''">
                <v-progress-circular :size="100" :width="3" :color="companyColor" indeterminate style="position: absolute;" v-if="logoImageLoading"></v-progress-circular>
                <img :src="recoveredImg" alt="" class="button-logo-img" v-if="recoveredImg">
                <img :src="image" alt="" class="button-logo-img" v-else-if="logoImage">
                <img src="@/assets/camera.png" alt="" class="button-logo-input-img" v-else>
              </button>
              <input type="file" ref="logoImage" accept="image/*" style="display: none" @change="onLogoImagePicked">
            </v-col>
            <v-col sm="12" lg="6">
              <p class="form-header">Banner Image</p>
              <button class="banner-input" type="button" @click="onLogoFile2()" :class="image2 ? 'border-0' : ''">
         <v-progress-circular
                  :size="100"
                  :width="3"
                  :color="companyColor"
                  indeterminate
                  style="position: absolute"
                  v-if="bannerImageLoading"
                ></v-progress-circular>
                <img :src="recoveredImg2" alt="" class="button-logo-img" v-if="recoveredImg2">

                <img :src="image2" alt="" class="button-logo-img" v-if="bannerImage" />
                <img src="../../assets/camera.png" alt="" class="banner-image" v-else />
              </button>

              <input type="file" ref="bannerImage" accept="image/*" style="display: none" @change="onBannerImagePickedImagePicked" />
            </v-col>
          </div>
          <div class="form-field tw-flex tw-full flipping">
            <v-col sm="12" lg="6">
              <validation-provider name="first name" rules="required" v-slot=" {classes, errors} ">
                <p class="form-header">First Name</p>
                <div :class="classes">
                <v-text-field solo placeholder="John"  type="text" color="#004aad" hide-details v-model="customer.firstName"></v-text-field>
               <span> {{errors[0]}} </span>
                </div>
              </validation-provider>

            </v-col>
            <v-col sm="12" lg="6">
              <p class="form-header">Last Name</p>
              <validation-provider name="last name" rules="required" v-slot=" {classes, errors} ">
                <div :class="classes">
                  <v-text-field solo placeholder="Doe"  type="text" color="#004aad" hide-details v-model="customer.lastName"></v-text-field>
                  <span> {{errors[0]}} </span>
                </div>
              </validation-provider>

            </v-col>
          </div>
          <div class="tw-flex flipping">
            <v-col sm="12" lg="6">
              <p class="form-header">Email</p>
              <validation-provider name="Email" rules="required" v-slot="{classes, errors}">
            <div :class="classes">
              <v-text-field solo placeholder="example@gmail.com" hide-details type="email" color="#004aad" v-model="customer.email"></v-text-field>
              <span> {{errors[0]}} </span>
            </div>

              </validation-provider>
            </v-col>
            <v-col sm="12" lg="6">
              <p class="form-header"> Secondary Email</p>
                 <v-text-field solo type="email" hide-details placeholder="example2@gmail.com" color="#004aad" v-model="customer.secondaryEmail"></v-text-field>
         </v-col>
          </div>
          <div class="tw-flex flipping">
            <v-col sm="12" lg="6">
              <p class="form-header"> Phone Number</p>
              <phone-number @getNumber="getNumber" color="#004aad" />
            </v-col>
            <v-col sm="12" lg="6">
              <p class="form-header"> Date of Birth</p>

                  <v-menu
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="date"
                          v-on="on"
                          placeholder="2022-09-9"
                          solo
                          hide-details
                         readonly
                      >
                        <template #prepend-inner>
                          <v-icon :color="companyColor" class="">
                            mdi-calendar-month-outline
                          </v-icon>
                        </template></v-text-field
                      >
                    </template>

                    <v-date-picker
                        locale="en-in"
                        v-model="date"
                        no-title
                        @input="dateMenu = false"
                        :max="maxDate"
                        :color="companyColor"
                    ></v-date-picker>
                  </v-menu>
            </v-col>

          </div>
          <div class="btn-div">
           <base-button  button-text="Next" type="submit" />
          </div>
        </form>
    </validation-observer>


      </div>

    </v-col>

  </div>
</template>

<script>
import {uploadToCloudinary} from "@/services/api/APIService";
import OnboardingLeft from "@/components/reuseables/OnboardingLeft";
import PhoneNumber from "@/components/reuseables/PhoneNumber";
import BaseButton from "@/components/reuseables/BaseButton";
import {customerOnBoarding} from "@/services/api/APIService"
export default {
    name: "OnboardingIdentity",
    components: {
      BaseButton,
      PhoneNumber,
      OnboardingLeft
    },
    data() {
      return {
        logoImage: '',
        logoImageUrl: '',
        logoImageLoading: false,
        recoveredImg: '',
        image: '',
        bannerImage: '',
        bannerImageUrl: '',
        bannerImageLoading: false,
        recoveredImg2: '',
        image2: '',

        companyColor: "#004AAD",
        customer: {
          phoneNumber: "",
          email:"",
          secondaryEmail:"",
          firstName:"",
          lastName:"",
          image: "",
        },
        activePicker: null,
        date: null,
        dateMenu:false,
        maxDate: new Date(new Date().getTime() + 60 * 60 * 1000)
            .toISOString()
            .substr(0, 10),
      }
    },

    computed: {
      currentPage() {
        return this.$route.name
      },
      preset() {
        return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
      },
    },

    methods: {
      goHome() {
        window.open(process.env.VUE_APP_MAIN_FRONTEND_URL, '_self')
      },
      getNumber(number) {
        this.customer.phoneNumber = number
      },

      onLogoFile() {
        this.$refs.logoImage.click();
      },
      onLogoFile2() {
        this.$refs.bannerImage.click();
      },
      saveDetails() {
      if(this.customer.firstName && this.customer.lastName && this.customer.email && this.customer.phoneNumber){
        customerOnBoarding(this.customer)
            .then((res) => {
              console.log(res);
              this.$router.replace({ name: 'OnboardingAddress' })


            })
            .catch((err) => {
              console.log(err.response);
              this.$displaySnackbar({
                message: err.response.data.details[0],
                success: false,
              });

            })
     }
      },

      async onLogoImagePicked(event) {
        const files = event.target.files[0]
        try {
          this.logoImageLoading = true
          const fileReader = new FileReader()
          fileReader.addEventListener('load', async () => {
            this.recoveredImg = ""
            this.image = fileReader.result
            this.logoImage = files.name

            let newData = {
              file: this.image,
              upload_preset: this.preset
            }
            console.log(newData)
            const response = await uploadToCloudinary(newData)
            console.log(response)
            this.logoImageUrl = response.data.url
            this.logoImageLoading = false
          })
          fileReader.readAsDataURL(files)
        } catch (err) {
          console.log(err.response)
        }

      },
      async onBannerImagePicked(event) {
        const files = event.target.files[0]
        try {
          this.bannerImageLoading = true
          const fileReader = new FileReader()
          fileReader.addEventListener('load', async () => {
            this.recoveredImg2 = ""
            this.image2 = fileReader.result
            this.bannerImage = files.name

            let newData = {
              file: this.image2,
              upload_preset: this.preset
            }
            console.log(newData)
            const response = await uploadToCloudinary(newData)
            console.log(response)
            this.bannerImageUrl = response.data.url
            this.bannerImageLoading = false
          })
          fileReader.readAsDataURL(files)
        } catch (err) {
          console.log(err.response)
        }

      },
    }
  }
</script>

<style lang="scss" scoped>
  .left-side {
    padding: 0 !important;
    background-size: cover !important;
    height: 100vh;
    background: linear-gradient(rgba(2, 58, 134, 0.51), rgba(2, 58, 134, 0.52)), url(../../assets/OBpic1.png);

    @media (max-width:1264px) {
      display: none;
    }
  }

  .btn-div {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @media (max-width:1264px) {
      margin-top: 20px;
    }
  }

  .v-application a {
    color: #ffff;
    width: 161px;
    padding: 10px 0;
    text-align: center;
    border-radius: 10px;
    background-color: #004AAD;
  }

  .identity {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 24px;
    color: #004AAD;
  }

  .right-side {
    background-color: #FDFFFC;
    height: 100vh;
    padding: 3rem 8rem;

    @media (max-width:1264px) {
      min-height: 100vh !important;
    }

    @media (max-width:1440px) {
      padding: 5rem 4rem;
    }

    @media (max-width:576px) {
      padding: 5rem 2rem;
    }
  }

  .v-progress-linear {
    width: 340px;

    @media (max-width:400px) {
      width: 280px;
    }
  }

  .form-header {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #4F4F4F;

  }

  .v-text-field {
    background-color: #fff;
  }

  .button-logo-input {
    display: flex;
    position: relative;
    align-items: center;
    height: 80px;
    border-radius: 50%;
    width: 80px;
    justify-content: center;
    background-color: rgba(100, 100, 100, 0.06);

    border: 1px solid rgba(189, 189, 189, 0.3);
  }

  .button-logo-input .button-logo-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;

  }

  .button-logo-input .button-logo-input-img {
    width: 24.19px;
    height: 18.72px;
  }

  .banner-input {
    display: flex;
    position: relative;
    align-items: center;
    height: 80px;
    width: 100%;
    background-color: rgba(100, 100, 100, 0.06);
    border-radius: 20px;
    justify-content: center;
    border: 1px solid rgba(189, 189, 189, 0.3);
  }

  .story {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #828282;

  }
.progress{
  
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 22px;
color: #828282;

}
  .flipping {
    @media (max-width:1264px) {
      flex-direction: column;
    }
  }

  .mobile-view-logo {
    display: none;

    @media (max-width:1264px) {
      display: block;
      padding-bottom: 70px;
    }
  }
</style>