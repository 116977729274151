<script>
import NavBar from "@/home/Navbar.vue";
import DemoButton from "@/components/reuseables/DemoButton.vue";
import GetStartedBtn from "@/components/reuseables/GetStartedBtn.vue";
import Icons from "@/components/reuseables/Icons.vue";
import Footer from "@/home/landingpage/Footer.vue";

export default {
  name: 'Flair',
  components: {Footer, Icons, GetStartedBtn, DemoButton, NavBar}
}
</script>

<template>

  <div class="tw-w-full">

    <NavBar/>

    <div class="first-sec tw-flex tw-w-full tw-justify-between">

      <div class="first-sec-left ">
          <p class="bold-txt" id="sec-1-bold-txt">Optimize Fleet Operations<br>with FLAIN</p>
        <div class="medium-txt tw-mt-8" id="sec-1-small-txt"><p>Using Fleet AI by Nomadicpod (FLAIN), unlock unparalleled <br>efficiency, heightened performance,
          and complete visibility into<br> your fleet's operations like never before. </p></div>
        <div class="tw-flex tw-mt-10">
          <DemoButton/>
          <GetStartedBtn button-text="Get Started"/>
        </div>
      </div>

      <div class="first-sec-right ">
        <div><img src="https://res.cloudinary.com/myroadpadi/image/upload/v1711238637/assets/frontend/flain-laptop_iz12ws.png" class="img-sec1"></div>
      </div>

    </div>


    <div class="second-sec">
      <div class="bold-txt" style="font-size: 40px; color: #131920; text-align: center">
        <p>One stop for all your fleet management<br> operation needs</p></div>

  <div class="operations-block tw-flex justify-center align-center justify-space-around tw-mt-20 ">
      <div class="tw-mr-5">
        <icons name="thunder-bolt" style="margin-left: 9rem"/>
        <div class="tw-mb-5"><p class="bold-txt" style="color: #263238; font-size: 24px; text-align: center">Fleet Management</p></div>
        <p class="small-txt" style="color: #263238">Ditch spreadsheets and paperwork in favor of a<br>
          centralized mobile fleet management system<br> crafted to simplify your tasks.</p>
      </div>

      <div>
        <icons name="vehicle" style="margin-left: 9rem; margin-top: 25px"/>
        <div class="tw-mb-5"><p class="bold-txt" style="color: #263238; font-size: 24px;text-align: center">Inventory Management</p></div>
        <p class="small-txt" style="color: #263238">Keep a real-time record of all spare parts and
          <br>components within your fleet. Track usage,<br>
          reorder levels, and restocking requirements for<br> optimal maintenance.
        </p>
      </div>

      <div>
        <icons name="personnel" style="margin-left: 9rem"/>
       <div class="tw-mb-5"> <p class="bold-txt" style="color: #263238; font-size: 24px;text-align: center; ">Personnel Management</p></div>
        <p class="small-txt" style="color: #263238">Oversee all personnel involved in your fleet<br>
          operation, from the fleet manager to the drivers<br> and customers.
        </p>
      </div>

  </div>

    </div>

    <div class="third-sec tw-ml-36 tw-mt-20 tw-w-full">
      <p class="small-txt" style="font-weight: 600; text-align: left; color: #263238">Getting Started</p>
      <div class="third-sec-bold-txt"><p>Your Intelligent Fleet AI Solution</p></div>
      <p class="third-sec-small-txt">Empowering Corporate Excellence through Advanced Fleet Intelligence and<br> Seamless Automation</p>
      
      <div class="tw-flex tw-mt-32 tw-ml-12 step-by-step-block">

        <div class=" left tw-mr-20">
          <div class="vertical-stroke"></div>

       <div>

         <div class="number">1</div>
         <div class="bold-txt tw-mt-5 tw-mb-5" id="sign-up-bold-txt" style="color:#131920; line-height: 34px; font-size: 24px;"><p>Sign Up</p></div>
         <p class="medium-txt" style="font-size: 16px; line-height: 24px; color: #263238">Create an account for free with your email.</p>
       </div>

        <div class="tw-mt-10">

          <div class="number">2</div>
          <div class="bold-txt tw-mt-5 tw-mb-5" style="color:#131920; line-height: 34px; font-size: 24px;"><p>Onboard Assests</p></div>
          <p class="medium-txt" style="font-size: 16px; line-height: 24px; color: #263238">Complete your profile
            by inputting your contact details, address and CAC<br> number.</p>
        </div>

        <div class="tw-mt-10">

          <div class="number">3</div>
          <div class="bold-txt tw-mt-5 tw-mb-5" style="color:#131920; line-height: 34px; font-size: 24px;"><p>Start Managing</p></div>
          <p class="medium-txt" style="font-size: 16px; line-height: 24px; color: #263238">Oversee Fleets, Drivers, Customers, Inventory,
            Logistics and financial<br> transactions  from your dashboard </p>
        </div>
        </div>
        <img src="@/assets/Frame 5958.png" class="sec-3-img">
      </div>
    </div>

    <div class="fourth-sec">
      <p class="small-txt" style="color: #263238; font-weight: 600">Explore more features</p>
      <div class="bold-txt" style="text-align: center;color: #263238; margin-top: 10px; margin-bottom: 20px">
        <p>See Better. Operate Smarter</p></div>
      <p class="medium-txt" style="color: #263238; text-align: center;line-height: 27px; font-size: 18px">Unleash the Power of Fleet AI by Nomadicpod</p>

      <div class="img-block tw-flex justify-center align-center tw-mt-20">

        <div class="sec-4-left tw-mr-10">
          <img src="@/assets/FleetImgOne.png" class="tw-mb-20">
          <img src="@/assets/FleetImgThree.png">
        </div>

        <div class="sec-4-right">
          <img src="@/assets/FleetImgTwo.png" class="tw-mb-20">
          <img src="@/assets/FleetImgFour.png">
        </div>


      </div>

    </div>
<div class="padding">
    <div class="fifth-sec tw-mt-20 tw-flex tw-justify-center align-center">
      <div class="sec-5-left tw-mr-20">
      <div><p class="bold-txt" id="sec-5-bold-txt" style="font-size: 40px; color: #263238">Ready to Transform Your<br> Transportation Operations ?</p></div>
      <div class="small-txt tw-mt-5 tw-mb-5" id="sec-5-small-txt" style="color: #2D3348; text-align: left;
       font-size: 20px; line-height: 32px">
        <p>Experience the Future of Fleet Management with FLAIN -<br>Your Intelligent Fleet AI Solution</p></div>

      <div class="tw-flex">
        <DemoButton/>
        <GetStartedBtn/>
      </div>
      </div>
      
      <div class="tw-pt-7 sec-5-img">
        <img src="@/assets/View Fleet.png">
      </div>
        
        
        
        
    </div>
</div>

    <div class="last-sec">
      <Footer/>
    </div>


  </div>

</template>

<style scoped lang="scss">
.first-sec{
  background-image:
      url("../../../assets/vertical-line-bg.svg"),
      url("../../../assets/horizontal-line-bg.svg");
  background-repeat: no-repeat;
  min-height: 100vh;
  background-size: cover;
  object-fit: cover;
  align-items: center;
  padding-top: 200px;
  background-color: #e8e8e8;
  @media screen and (max-width: 768px){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  @media screen and (max-width: 375px){
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 20px;
  }
}
#sec-1-bold-txt{
  @media screen and (max-width: 768px){
    margin: 0;

  }
  @media screen and (max-width: 375px){
    text-align: left;
    font-size: 36px;
    line-height: 48px;
  }
}

.whatfirst-sec-right{

  @media screen and (max-width: 768px){
    margin-right: 0;
  }
  @media screen and (max-width: 375px){
    margin-top: 60px;
    padding: 20px;
    margin-left: -20px;
    margin-right: 0;
  }
}

.img-sec1{
  height: 90vh;
  width: auto;
  @media screen and (max-width: 375px){
    height: 60vh;
  }
}
.first-sec-left{
  margin-left: 9%;
  margin-bottom: 15%;

  @media screen and (max-width: 375px){
    padding-top: 20%;
  }

    @media screen and (max-width: 768px){
      margin: 0 0 50px;
    }
}

.bold-txt{
  font-family: Raleway;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  @media screen and (max-width: 375px){
    font-size: 36px;
    text-align: center;
    line-height: 43px;

  }
}
#sign-up-bold-txt{
  @media screen and (max-width: 375px){
    padding-right: 52%;
  }
}

#sec-1-small-txt{
  @media screen and (max-width: 375px){
    font-size: 16px;
    line-height: 32px;
    text-align: left;
    margin-left: 10px;
  }
}

.medium-txt{
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.small-txt{
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.second-sec{
  margin-top: 15%;
  margin-bottom: 15%;
  background-image: url("../../../assets/vertical-line-bg.svg");
  //min-height: 100vh;
}

.third-sec-bold-txt{
  font-family: Raleway;
  font-size: 40px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #263238;
  margin-top: 10px;
  margin-bottom: 20px;
}
third-sec-small-txt{
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #263238;
}

.third-sec{
  background-image: url("../../../assets/vertical-line-bg.svg");
  @media screen and (max-width: 375px){
    margin-left: 20px;
  }
}

.sec-3-img{
  height: 90vh;
  margin-left: 150px;
  margin-top: -10%;
  width: auto;
  @media screen and (max-width: 768px){
    margin-left: -90px;
    margin-top: 50px;
  }
  @media screen and (max-width: 375px){
    margin-left: -60px;
    margin-top: 50px;
  }
}

.vertical-stroke {
  background-color: #263238;
  position: absolute;
  height: 560px;
  width: 4px;
  margin-left: -30px;
  margin-top: -40px;
  @media screen and (max-width: 375px){
    height: 700px;
  }
}
.number{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #A7C6D3;
  border-radius: 50%;
  padding: 8px;
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  color: #131920;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.fourth-sec{
  margin-top: 10%;
  background-image: url("../../../assets/vertical-line-bg.svg");
}

.sec-4-left{
  @media screen and (max-width: 375px){
    margin: 0 0 50px;
  }
}

.fifth-sec{
  height: fit-content;
  padding: 50px;
  background-color: #f6f7f9;
  background-image: url("../../../assets/flair-sec-5-bg-img.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 24px;
  margin-bottom: 70px;
  @media screen and (max-width: 375px){
    display: flex;
    flex-direction: column;
    margin-left: 200px;
    width: 0;
    padding: 0;
    text-align: left;
    margin-bottom: 0;
  }
}
.sec-5-left{
  @media screen and (max-width: 375px){
  margin: 0;
  }
}
#sec-5-bold-txt{
  @media screen and (max-width: 375px){
  text-align: left;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    margin-right: 30px;
  }
}
#sec-5-small-txt{
  @media screen and (max-width: 375px){
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 15px;
  }
}
.sec-5-img{
  margin-right: -50px;
  width: auto;
  height: 55vh;
  @media screen and (max-width: 375px){
    padding: 10px;
    margin-right: 30px;
    width: 375px;
    margin-top: 30px;
    margin-bottom: 70px;
  }
}
.operations-block{
  @media screen and (max-width: 768px){
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 70px 0 0;
  }
  @media screen and (max-width: 375px){
    display: flex;
    flex-direction: column;
  }
}

.step-by-step-block{
  @media screen and (max-width: 768px){
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 375px){
    display: flex;
    flex-direction: column;
  }
}


.img-block{
  background-image: url("../../../assets/vertical-line-bg.svg");
  @media screen and (max-width: 768px){
    padding: 30px;
  }
  @media screen and (max-width: 375px){
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
}

.padding{
  @media screen and (max-width: 768px){
    padding: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 5%;
  background-image: url("../../../assets/vertical-line-bg.svg");
}
</style>