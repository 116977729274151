<template>
    <div class="niches">
        <div class="group1 tw-cursor-pointer" @click="$router.push({name: 'HomePage'})"><img src="https://res.cloudinary.com/myroadpadi/image/upload/v1702465764/assets/frontend/blue_hici8x.png" alt="" width="130"></div>
        <div class="niche-content tw-w-full display-center flex-column">
            <div class="group2  tw-flex  tw-justify-center tw-items-center flex-column">
                <img src="../assets/niche-check.svg" alt="">
<!--                <p class="niche-content-header pt-3 pb-7">You’ve Successfully Registered</p>-->
            </div>
            <div class="group3 tw-pt-20">
                <p class="niche-subheading">Please Choose your Niche</p>
                <p class="niche-body">You can choose multiple niches. Your first selection would be recorded as your primary niche.</p>
            </div>
            
            <div class="group4">
                <div class="display-center flex-column py-3" >
                    <v-col sm="12" class="checkbox-group">
                        <v-checkbox color='#004aad' hide-details="" v-model="tcp" >
                            <template #label>
                                <span class="niche-title">Transport Company</span>
                            </template>
                        </v-checkbox>
                    </v-col>

                    <v-col sm="12" class="">
                        <p class="niche-details pl-9 pt-4">
                          For Transport Companies who are in the business of transporting people
                            from place to
                            place.
                        </p>
                    </v-col>
                </div>
<!--                 <div class="display-center flex-column py-3" >-->
<!--                    <v-col sm="12" class="checkbox-group">-->
<!--                        <v-checkbox color='#004aad' hide-details="" v-model="companyType" value="FLEET_MANAGEMENT_COMPANY">-->
<!--                            <template #label>-->
<!--                                <span class="niche-title">Fleet Management Company</span>-->
<!--                            </template>-->
<!--                        </v-checkbox>-->
<!--                    </v-col>-->

<!--                    <v-col sm="12" class="">-->
<!--                        <p class="niche-details pl-9 pt-4">-->
<!--                          For Business who would like to manage their assets like cars, staff, etc.-->
<!--                        </p>-->
<!--                    </v-col>-->
<!--                </div>-->
                 <div class="display-center flex-column py-3" >
                    <v-col sm="12" class="checkbox-group">
                        <v-checkbox color='#004aad' hide-details="" v-model="lcp" >
                            <template #label>
                                <span class="niche-title">Leasing Company</span>
                            </template>
                        </v-checkbox>
                    </v-col>

                    <v-col sm="12" class="">
                        <p class="niche-details pl-9 pt-4">
                         For Companies in the business of leasing out vehicles to people.
                        </p>
                    </v-col>
                </div>

            </div>
            
        </div>
        <div class="group5">
    <v-btn text class="progress-button" @click="handleNext()">Next &nbsp; <v-icon>mdi-trending-neutral</v-icon></v-btn>
</div>
    </div>
</template>

<script>
    export default {
        name: "ChooseYourNiche",
        data(){
            return{
                selectedServicesToProduce:[],
              onboardingData:{},
              tcp: false,
              lcp: false
            }
        },
  methods:{
    handleNext(){
     // this.onboardingData.companyType = this.selectedServicesToProduce
      const services = new Set();
      if(services.length > 0){
        services.forEach(service =>{
          if(service === 'Transport Provider Services' && !this.tcp){
            services.filter(service => service !== 'Transport Provider Services')
          }
          else if(this.tcp){
            services.add('Transport Provider Services')
          }
          if(service === 'Leasing Services' && !this.lcp){
            services.filter(service => service !== 'Leasing Services')
          }
          else if(this.lcp){
            services.add('Leasing Services')
          }
        })
      }
      else {
        if(this.tcp){
          services.add('Transport Provider Services')
        }
        if(this.lcp){
          services.add('Leasing Services')
        }
      }
      this.selectedServicesToProduce = [...services]
      sessionStorage.setItem('serviceToOffer', JSON.stringify(this.selectedServicesToProduce));
      if(this.selectedServicesToProduce.length){
        this.$router.push({name: 'TransportCompanyRegister'})
      }
      else {
        this.$displaySnackbar({
          message: "Please select a niche",
          success: false,
        });
      }

    }
  },
      created() {
          let data = JSON.parse(sessionStorage.getItem('serviceToOffer'))
         if (data && data.length){
           data.forEach(service =>{
             if(service === 'Transport Provider Services'){
               this.tcp = true
             }
             if(service === 'Leasing Services'){
               this.lcp = true
             }
           })
           console.log(data)
           this.selectedServicesToProduce = data
         }
      }
    }
</script>

<style lang="scss" scoped>
    .niches {
        padding: 2rem 7rem;
        min-height: 100vh;
        @media (max-width:1024px) {
            padding: 2rem 4rem;
        }
         @media (max-width:400px) {
            padding: 2rem;
        }
        
    }

    .niche-content {
      display: flex;

        padding: 30px 0 0;
          @media (max-width:1024px) {
            padding: 70px 0 30px;
        }
    }

    .niche-content-header {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 64px;
        color: #4F4F4F;
        @media (max-width:768px) {
            font-size: 23px;
            text-align: center;
            line-height: 29px;
        }

    }
    .niche-subheading{
        font-family: 'Inter';
font-weight: 500;
font-size: 20px;
line-height: 24px;
color: #4F4F4F;

    }
    .niche-body{
        font-family: 'Inter';
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #4F4F4F;
    }
.col{
    padding: 0 !important;
}
    .niche-title {
        font-family: 'Inter';
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
        color: #4F4F4F;

    }

    .niche-details {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #4F4F4F;

    }

    .group3,
    .group4 {
        width: 400px;
        @media (max-width:576px) {
            width: 100%;
        }
    }
    .group5{
        display: flex;
        justify-content: right;
        align-items: flex-end;
    }
    .progress-button{
        text-transform: none !important;
        color: #004aad;
    }
</style>