import {reserveSeats, reserveVesselSeats, unReserveSeats, unReserveVesselSeats} from "@/services/api/APIService";

export default {
    UPDATE_TRIP_AND_TRAVELLER_DETAILS: (state, value) => {
        sessionStorage.setItem("passengerDetail",JSON.stringify(value))
        state.tripAndTravellerDetails = value
    },

    SET_PENDING_TRIPS: (state,value) =>{
        state.pendingTrips = value
    },
    SET_ONE_WAY_TRIPS: (state,value)=>{
        state.oneWayTrips = value
    },
    SET_USER_SELECTED_TRIP: (state, value)=>{
        sessionStorage.setItem("userSelectedTrip", JSON.stringify(value))
        state.userSelectedTrip = value
    },
    ADD_SEAT: (state, value) => {
        let minusFromTimer = JSON.parse(sessionStorage.getItem('minusFromTimer'))
        let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))
        let currentTripId = sessionStorage.getItem("currentTripId")
        let seats = []
        let data = {}
        state.seats = [...state.seats,value]
        state.seats.forEach(seat => seats.push(seat))
        data.tripId = userSelectedTrip && userSelectedTrip.id ? userSelectedTrip.id : currentTripId
        data.selectedSeats = seats
        sessionStorage.setItem("currentSelectedSeats",JSON.stringify(state.seats))
        if (userSelectedTrip.transportCompanyId) {
            if (userSelectedTrip.id) {
                reserveSeats(data).then(() => {
                    if (!minusFromTimer || state.seats.length <= 0) {
                        // window.location.reload()
                        console.log("")
                    }
                }).catch(err => {
                    console.log(err.response)
                    state.seats = state.seats.filter(seat => seat.seatNumber !== value.seatNumber)
                    sessionStorage.setItem("currentSelectedSeats", JSON.stringify(state.seats))
                    // window.location.reload()
                    //
                })
            }
        }
        else if (userSelectedTrip.waterTransportOperatorId){
            data.waterTransportOperatorId = userSelectedTrip.waterTransportOperatorId
            reserveVesselSeats(data).then(() => {
                if (!minusFromTimer || state.seats.length <= 0) {
                    // window.location.reload()
                    console.log("")
                }
            }).catch(err => {
                console.log(err.response)
                state.seats = state.seats.filter(seat => seat.seatNumber !== value.seatNumber)
                sessionStorage.setItem("currentSelectedSeats", JSON.stringify(state.seats))
                // window.location.reload()
                //
            })
        }
    },
    REMOVE_SEAT: (state, value) =>{
        let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))
        let currentTripId = sessionStorage.getItem("currentTripId")
        let seats = []
        let data = {}
        state.seats = state.seats.filter(seat => seat.seatNumber !== value.seatNumber)
        seats.push(value)
        data.tripId = userSelectedTrip && userSelectedTrip.id ? userSelectedTrip.id : currentTripId
        data.selectedSeats = seats
        sessionStorage.setItem("currentSelectedSeats",JSON.stringify(state.seats))
        if (userSelectedTrip.transportCompanyId) {
            unReserveSeats(data).then(() => {

            }).catch(err => {
                console.log(err)
            })
        }
        else if (userSelectedTrip.waterTransportOperatorId){
            data.waterTransportOperatorId = userSelectedTrip.waterTransportOperatorId
            unReserveVesselSeats(data).then(() => {

            }).catch(err => {
                console.log(err)
            })
        }
    },
    SET_ONE_WAY_BOOKING_MINUTES_TIMER: (state, minutesTimer) =>{
        state.oneWayBookingMinutesTimer = minutesTimer
    },

    SET_ONE_WAY_BOOKING_SHOW_TIMER:(state,showTimer) =>{
        state.oneWayBookingShowTimer = showTimer
    },
    CLEAR_SEATS:(state) =>{
        state.seats = []
        sessionStorage.removeItem("currentSelectedSeats")
    },
    UPDATE_CURRENT_SEATS:(state,value)=>{
        state.seats = value
    },

    GET_TOTAL_AMOUNT: (state, value) =>{
        let uniqueSeats = new Set()
        state.seats.forEach(seat => uniqueSeats.add(JSON.stringify(seat)))
        if (state.userSelectedTrip.lifeGuard){
            state.oneWayTotalAmount =  (uniqueSeats.size * value) + (uniqueSeats.size * state.userSelectedTrip.lifeGuardCharge)
        }
        else {
            state.oneWayTotalAmount =  uniqueSeats.size *value
        }
    },

    SET_SELECT_INSURANCE_PROVIDER: (state, value) =>{
        sessionStorage.setItem("selectedInsuranceProvider",JSON.stringify(value))
        state.selectedInsuranceProvider = value
    },

    SET_INSURANCE_PROVIDER: (state, value) =>{
        sessionStorage.setItem("insuranceProvider",JSON.stringify(value))
        state.insuranceProvider = value
    },
    SET_SELECTED_INSURANCE_PLAN: (state, value) =>{
        sessionStorage.setItem("SelectedInsurancePlan",JSON.stringify(value))
        state.selectedInsurancePlan = value
    },

    SET_SELECTED_INSURANCE_AMOUNT: (state,value) =>{
        let uniqueSeats = new Set()
        let uniqueTravellers = new Set()
        state.seats.forEach(seat => uniqueSeats.add(JSON.stringify(seat)))
        uniqueSeats.forEach(seat => {
            uniqueTravellers.add(seat.travellerDetail)
        })
        state.selectedInsuranceAmount = value
        state.oneWayTotalAmount = state.oneWayTotalAmount + (uniqueTravellers.size * value)
    },
    REMOVE_SELECTED_INSURANCE_AMOUNT:(state,value)=>{
        let uniqueSeats = new Set()
        let uniqueTravellers = new Set()
        state.seats.forEach(seat => uniqueSeats.add(JSON.stringify(seat)))
        uniqueSeats.forEach(seat => {
            uniqueTravellers.add(seat.travellerDetail)
        })
        state.oneWayTotalAmount = state.oneWayTotalAmount - (uniqueTravellers.size * value)
    }

};
