<template>
  <div class="faq-div">
    <Navbar />
    <div class="section-one">
      <v-col lg="6" sm="12" class="intro-text">
        <h1>Get Answers to Some Questions About how to use Nomadicpod</h1>
      </v-col>
      <v-col lg="6" sm="12">
        <div class="imagediv">
          <v-img class="image" src="../assets/faq-img.png">
            <div class="text-div">
              <h1 class="title">How do I get Started?</h1>
              <p class="title-text">
                Click any of the
                <span
                  style="color: #1565c0"
                  class="tw-cursor-pointer"
                  @click="$router.push({ name: 'RegisterAs' })"
                  >Get Started</span
                >
                links on this page
              </p>
            </div>
          </v-img>
        </div>
      </v-col>
    </div>

    <v-card elevation="0" class="tab-card">
      <v-tabs v-model="tab">
        <v-tabs-slider> </v-tabs-slider>
        <v-tab href="#all"> All </v-tab>
        <v-tab href="#getting-started"> Getting Started </v-tab>

        <v-tab href="#using-rp"> Using Nomadicpod </v-tab>
        <v-tab href="#issues"> Issues </v-tab>
      </v-tabs>
    </v-card>
    <div class="tw-flex tw-full-width tw-flex-wrap divs-div">
      <div class="tw-flex first-div">
        <v-expansion-panels flat class="condensed">
          <v-expansion-panel v-for="(faq, index) in posts" :key="index">
            <p>
              <v-expansion-panel-header>
                {{ faq.question }}
              </v-expansion-panel-header>
            </p>
            <v-expansion-panel-content>
              <p>
                {{ faq.answerTitle }} <br v-if="faq.answer1" /><br
                  v-if="faq.answer1"
                />
                <span
                  style="color: #1565c0; font-weight: 550"
                  v-if="faq.answer1"
                >
                  TravelPadi</span
                >
                {{ faq.answer1 }} <br v-if="faq.answer2" />
                <br v-if="faq.answer2" />
                <span
                  v-if="faq.answer2"
                  style="color: #1565c0; font-weight: 550"
                >
                  FleetPadi</span
                >{{ faq.answer2 }}
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="second-div">
        <v-expansion-panels flat>
          <v-expansion-panel v-for="(morefaq, index) in nextposts" :key="index">
            <v-expansion-panel-header
              >{{ morefaq.question }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="morefaq.question === 'How do I get Started?'">
                Click on the Get
                <span
                  class="tw-cursor-pointer"
                  style="color: #004aad"
                  @click="$router.push({ name: 'RegisterAs' })"
                >
                  Started button</span
                >
                to Signup
              </div>
              <p v-else>{{ morefaq.answer }} <br /></p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../home/Navbar.vue";
import Footer from "../home/Footer.vue";

export default {
  name: "Faq",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      faqs: [
        {
          question: "What does Onboarding entail",
          answerTitle:
            "Onboarding entails providing information about your company such" +
            " as Name, Address, Phone number, CAC document e.t.c",
          category: "getting-started",
        },
        {
          question:
            "Must I upload my CAC documentation before I can use  Nomadicpod??",
          answerTitle:
            "Yes, As a Transport Company, CAC Documentation is one of the important documents we use for verification.",
          category: "using-rp",
        },
        {
          question: "Do I need to sign in to get a traveller code?",
          answerTitle: "No, you can get a traveller code without signing in",
          category: "issues",
        },
      ],
      morefaqs: [
        {
          question: "How do I get Started?",
          answer: " Click any of the Get Started links on this page",
          category: "getting-started",
        },
        {
          question: "How long does it take to get verified as a service provider",
          answer:
            " Verification takes a few minutes, you will receive an email once your account is verified.",
          category: "issues",
        },
        // {
        //   question: "What service does Nomadicpod Provide",
        //   answer:
        //     "We help to facilitate bookings, Manage fleets and also Provide APIs  for new " +
        //     "and existing businesses to build on our travel Infrastructure",
        //   category: "getting-started",
        // },
      ],
      tab: null,
      posts: [],
      nextposts: [],
    };
  },
  watch: {
    tab: {
      handler: function (val) {
        if (val === "all") {
          this.posts = this.faqs;
          this.nextposts = this.morefaqs;
        } else {
          this.posts = this.faqs.filter(
            (post) => post.category.toLowerCase() === val
          );
          this.nextposts = this.morefaqs.filter(
            (nextpost) => nextpost.category.toLowerCase() === val
          );
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.faq-div {
  background-color: white;
}

.section-one {
  min-height: 80vh;
  display: flex;
  flex-direction: row;
  padding: 10% 6% 0 0;

  @media screen and (max-width: 1440px) {
    padding: 10% 3% 10% 15% !important;
  }

  @media screen and (max-width: 768px) {
    padding: 10% 3% 10% 10% !important;
  }

  @media screen and (max-width: 1264px) {
    padding-top: 20%;
    flex-direction: column;
  }

  @media screen and (max-width: 1024px) {
    padding: 20% 5% 10%;
  }

  @media screen and (max-width: 600px) {
    padding: 150px 5% 10% !important;
  }
}

.image {
  height: 280px;
  width: 500px;
  border-radius: 15px;
  justify-self: center;
  margin: 0 auto;
  box-shadow: 3px -36px 80px -46px rgba(160, 157, 157, 0.97);

  @media screen AND (max-width: 500px) {
    width: 350px;
  }
}

.intro-text {
  padding: 7% 5% 5% 15rem;

  @media screen and (max-width: 1440px) {
    padding: 7% 7% 5% 0;
  }

  @media screen and (max-width: 1264px) {
    width: 70%;
    padding-left: 1rem;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
    padding-top: 30%;
    padding-bottom: 15%;
  }

  @media screen and (max-width: 591px) {
    padding: 30% 15px 20%;
    width: 100%;
  }
}

.intro-text h1 {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 65px;
  color: var(--padiGrey);
}

.imagediv {
  width: 100%;
  position: relative;
  padding-top: 7%;
  height: 500px;
}

.text-div {
  width: 30%;
  position: absolute;
  right: 50px;
  bottom: 35px;

  @media screen AND (max-width: 500px) {
    width: 40%;
    right: 10px;
    bottom: 30px;
  }
}

.title-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  color: var(--padiGrey);
  text-align: start;
}

.title {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
  color: var(--padiGrey);
}

.tab-card {
  padding-left: 15rem;
  background-color: #fdfffc;

  @media screen and (max-width: 1440px) {
    padding: 0 3% 3% 15% !important;
  }

  @media screen and (max-width: 768px) {
    padding: 0 3% 3% 10% !important;
  }

  @media screen and (max-width: 500px) {
    padding: 0 !important;
  }
}

.v-tab,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.v-tabs {
  text-transform: none;
  min-width: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(79, 79, 79, 0.28);
  background-color: #fdfffc;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.v-slide-group__next,
.v-slide-group__prev {
  display: flex;
  flex: 0 1 0 !important;
  min-width: 0 !important;
}

.v-expansion-panel {
  margin: 10px 0;
  box-shadow: 3px 4px 8px 0px rgba(203, 200, 200, 0.99);
}

.v-expansion-panel-header {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  color: var(--padiGrey);
  padding: 25px;
}

.v-expansion-panel-content__wrap p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--padiGrey);
  padding: 10px;
}

.divs-div {
  flex-direction: row;
  padding: 5% 7% 5% 14rem;

  @media screen and (max-width: 1440px) {
    padding: 5% 3% 5% 15% !important;
  }

  @media screen and (max-width: 768px) {
    padding: 5% 2rem !important;
    flex-direction: column;
  }
}

.first-div {
  width: 50%;
  margin: 15px;

  @media screen and (max-width: 768px) {
    width: 85%;
  }
}

.second-div {
  width: 37%;
  margin: 15px;
  @media screen and (max-width: 768px) {
    width: 85%;
  }
}
//::v-deep .theme--light.v-sheet {
//  background-color: transparent !important;
//}

</style>