<template>
  <div class="step-one">
    <loader :loading="loading" v-if="loading" />
    <div class="tw-w-full" v-else>
    <nav-bar/>
      <div class="tw-flex tw-w-full tw-items-center tw-justify-center tw-px-5 lg:tw-px-40 tw-mt-16 lg:tw-mt-32" v-if="discount">
        <div class="discount-banner tw-p-3 tw-flex tw-flex-row tw-items-center">
          <div class="tw-px-5 ">
          </div>
          <label class="discount-banner-text">{{discount.name}}</label>
        </div>
      </div>
    <booking-steps/>
    <one-way-search @handleRemount="handleRemount" />
    <available-trips v-if="remount"/>

    <Footer/>
    </div>
    <!-- <checkout-details/> -->
  </div>
</template>

<script>
import BookingSteps from '../../components/reuseables/bookingAndHiring/OneWayBookingSteps.vue'
  import AvailableTrips from '../bookings/AvailableTrips.vue'
  // import CheckoutDetails from '../bookings/CheckoutDetails.vue'
  import OneWaySearch from '../bookings/OneWaySearch.vue'
import NavBar from "@/home/Navbar.vue";
import Footer from "@/home/Footer.vue";
import {mapGetters} from "vuex";
import dayjs from "dayjs";
import Loader from "@/components/reuseables/Loader.vue";
import {getActiveDiscount} from "../../services/api/APIService";
  export default {
    data() {
      return {
        tab: null,
        loading : false,
        remount: true,
        discount:null
      }
    },
    components: {
      Loader,
      Footer,
      NavBar,
      OneWaySearch,
      AvailableTrips,
        BookingSteps
    },
    methods:{
      handleRemount(){
        this.remount = false
        this.$nextTick(() => {
          this.remount = true
        })
      },
      async getDiscount(){
        await getActiveDiscount().then(res =>{
          if (res.data){
            this.discount = res.data
          }
        }).catch((err) => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
      }
    },
    computed:{
      ...mapGetters("booking",["pendingTrips"])
    },
   async created() {
      await this.getDiscount()
     let queryData = JSON.parse(sessionStorage.getItem("queryData"))
     if (!queryData) {
       this.loading = true
       let data = {}
       data.page = 0
       data.pageSize = 100
       await this.$store.dispatch("booking/setPendingTripsByPage", data).then(() => {
         this.pendingTrips.sort(function (a, b) {
           return (dayjs(a.takeOffDate + a.takeOffTime).toDate() -
               dayjs(b.takeOffDate + b.takeOffTime).toDate()
           )
         });
         this.pendingTrips = this.pendingTrips.filter(trip =>
             dayjs(trip.takeOffDate, trip.takeOffTime).isSame(dayjs().toDate())
             || dayjs(trip.takeOffDate, trip.takeOffTime).isAfter(dayjs(dayjs().toDate())))
         this.$store.dispatch("booking/setOneWayTrips", this.pendingTrips.slice(0, 10))
         this.loading = false
       }).finally(() => this.loading = false)
     }
   }
  }
</script>

<style lang='scss' scoped>
.discount-banner{
  background: #f54c35;
  width: 100%;
  min-height: 60px;
  border-radius: 10px;
}
.discount-banner-text{
  color: white;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 18px;

}
</style>