import { render, staticRenderFns } from "./CorporateAgentRegisterView.vue?vue&type=template&id=b4889964&scoped=true&"
import script from "./CorporateAgentRegisterView.vue?vue&type=script&lang=js&"
export * from "./CorporateAgentRegisterView.vue?vue&type=script&lang=js&"
import style0 from "./CorporateAgentRegisterView.vue?vue&type=style&index=0&id=b4889964&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4889964",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCheckbox,VCol,VTextField})
