<template>
  <div>
    <navbar-white/>
    <div class="section-one">
      <v-col lg="6" sm="12"  class="intro tw-p-0 md:tw-p-3">
        <h3 class="intro-h">We are powering a new generation of travelers</h3>
        <p class="intro-p py-9">Your travel padi</p>
      </v-col>
    </div>
    <div class="section-two">
      <v-col
        lg="6"
        sm="12"
        class="tw-flex tw-w-full tw-flex-wrap tw-relative mnv"
      >
        <v-card class="mission" elevation="0">
          <v-card-title>Our Mission</v-card-title>
          <v-card-subtitle>
            To empower individuals, institutions and businesses with efficient and reliable mobility
            and transportation solutions that accelerate growth and promote sustainable development.
          </v-card-subtitle>
        </v-card>
        <v-card class="vision" elevation="0">
          <v-card-title>Our Vision </v-card-title>
          <v-card-subtitle
            >Promoting economic growth and environmental preservation through seamless cross border transportation of people and freight.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col lg="6" sm="12" class="right-side">
        <h1>We are on a journey to transform travel</h1>
        <p>
          Nomadicpod is a platform that provides decentralized access to travel services and
          resources from transporters, fleet managers, third party service providers across Africa, helping you travel efficiently.
        </p>
      </v-col>
    </div>

    <div class="section-three">
      <v-col class="road-div" lg="6" sm="12">
        <img src="../assets/road.jpg" alt="road" class="road-image" />
      </v-col>
      <v-col
        lg="6"
        sm="12"
        class="
          tw-flex
          lg:tw-px-20
          tw-flex-col tw-items-start tw-justify-center
          section-three-content
        "
      >
        <h1>The Great journey</h1>
        <p>
          We’re inspired by the vision of millions of road commuters and the
          thousands of businesses across the country who use the road to carry
          out their daily activities and businesses. We help users access
          accurate information, stay safer enroute destinations, efficiently and
          effectively build, manage, and grow businesses using our
          infrastructure; from fleet owners (corporates), transporters,
          travelers, market leaders, students, tourists and businesses launching
          new business models along commute lines.
        </p>
      </v-col>
    </div>

    <div class="section-four">
      <v-col lg="5" sm="12" class="ourvalues">
        <h1>Our Values</h1>
        <p>
          Our values define the Nomadicpod culture, who we are, and what we do
          every day. They are the foundation of our identity and the compass of
          our interaction with all stakeholders.
        </p>
      </v-col>
      <div class="tw-flex tw-w-full tw-flex-wrap card-div">
        <div v-for="(value, index) in values" :key="index" >
          <v-card class="mx-auto card-box" elevation="0" >
            <v-img height="70px" width="70px" :src="value.image"></v-img>
            <v-card-title> {{ value.title }} </v-card-title>
            <v-card-subtitle> {{ value.subtitle }}</v-card-subtitle>
          </v-card>
        </div>
      </div>
    </div>
     <Teamcard />

    <Nexttofooter />
    <Footer />
  </div>
</template>

<script>
import Footer from "../home/Footer.vue";
import Nexttofooter from "../components/reuseables/Nexttofooter.vue";
import Teamcard from "../components/reuseables/Teamcard.vue";
import icon1 from "../assets/valueicons/icon1.png";
import icon2 from "../assets/valueicons/icon2.png";
import icon3 from "../assets/valueicons/icon3.png";
import icon4 from "../assets/valueicons/icon4.png";
import NavbarWhite from "../home/NavbarWhite";

export default {
  name: "About",
  components: {
    NavbarWhite,
    Footer,
    Nexttofooter,
    Teamcard,
  },
  data() {
    return {
      values: [
        {
          image: icon1,
          title: "Empathy &  Graciousness",
          subtitle:
            "We live a life of positivity and kindness, we imagine ourselves in the shoes of others as we do our work with colleagues and customers.",
        },
        {
          image: icon2,
          title: "Collaboration & Teamwork ",
          subtitle:
            "Our success is dependent on how well we can collaborate as teams and with partners to build and deliver travel solutions for our customers. ",
        },
        {
          image: icon3,
          title: "Clarity & Communication",
          subtitle:
            "We emphasize clarity in our dealings with customers, partners and our colleagues. Communicating clearly through our solution is a constant. ",
        },
        {
          image: icon4,
          title: "Growth & Development ",
          subtitle:
            "Our success is greatly impacted by our continuous effort in empowering our people for career growth and self development. Equipping them with the right skill and knowledge that helps us build solutions that cater to the growing needs of our customers at all times.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.section-one {
  background-image: linear-gradient(rgba(12, 17, 20, 0.8),rgba(12, 17, 20, 0.8)),
  url("https://res.cloudinary.com/myroadpadi/image/upload/v1651305892/pexels-p_eqhqzk.png");
  background-size: cover;
  background-color: rgba(16, 16, 13, 0.175);
  @media (max-width: 500px) {
    background-position: center;
  }
  padding: 10rem;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1264px) {
    justify-content: center;
    padding: 1.5rem;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
}

.intro-h {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 54px;
  color: #FFFFFF;
  padding-top: 27%;

  @media screen and (max-width: 1440px) {
    line-height: 60px;
    font-size: 45px;
  }

  @media screen and (max-width: 1264px) {
    padding-top: 10%;
  }
  @media screen and (max-width: 500px) {
    font-size: 37px;
    line-height: 40px;
  }
}

.intro-p {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;

  @media screen and (max-width: 1264px) {
    font-size: 20px;
  }
}

img {
  max-width: 200%;
}

.col-img {
  width: 115%;

  @media screen and (max-width: 1264px) and (min-width: 1024px) {
    width: 100%;
    padding-top: 10%;
    padding-left: 5%;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 576px) {
    padding-top: 29%;
  }
}

.section-two {
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 10% 5% 10% 0;

  @media screen and (max-width: 1264px) {
    flex-direction: column-reverse !important;
    justify-content: center;
    padding: 1.5rem;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
}

.mission {
  background: #ffffff;
  border: 1.5px solid #004aad;
  box-sizing: border-box;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 350px;
  height: 380px;
  padding: 30px;
  margin-left: 22%;


  @media screen and (min-width: 1650px) {
    margin-left: 27%;
  }
  @media screen and (max-width: 1264px) {
    margin: 20px 20px 20px 0;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 1em 0 !important;
  }
}

.mission .v-card__title,
.vision .v-card__title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 48px;
  color: #004aad;
  width: 60%;
  padding-bottom: 40px;
  word-break: normal;
}

.mission .v-card__subtitle,
.vision .v-card__subtitle {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
}

.vision {
  width: 350px;
  height: 390px;
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  position: absolute;
  left: 65%;
  top: 200px;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.05) !important;

  @media (max-width: 1440px) {
    top: 300px;
    left: 68%;
  }

  @media (max-width: 1264px) {
    position: static;
    margin: 20px;
  }
  @media screen and (max-width: 1024px) {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
}

.vision .v-card__title {
  color: #ba68c8;
}

.right-side {
  padding: 10% 12%;

  @media screen and (max-width: 1440px) and (min-width: 600px) {
    width: 70%;
    padding: 0 0 7% !important;
  }

  @media screen and (max-width: 1440px) and (min-width: 1024px) {
    width: 70%;
    padding: 5%;
  }

  @media screen and (max-width: 500px) {
    padding: 40px 0;
  }
}

.right-side h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 50px;
  color: var(--padiGrey);
  padding-bottom: 40px;

  @media screen and (max-width: 576px) {
    font-size: 35px;
    line-height: 40px;
  }
}

.right-side p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.6);
}

.section-three {
  display: flex;
  flex-direction: row;
  min-height: 90vh;

  @media screen and (max-width: 1264px) {
    flex-direction: column;
  }
}

.section-three h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: #263238;
  padding-bottom: 30px;
}

.section-three p {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  color: var(--padiGrey);
  padding-right: 5rem;
  @media screen and (max-width: 500px) {
    padding: 0;
  }
}

.road-div {
  padding: 0;
}

.road-image {
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1264px) {
    display: none;
  }
}

.section-three-content {
  @media screen and (max-width: 1264px) {
    padding: 15%;
    width: 100% !important;
  }
  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
}

.section-four {
  background-color: #f9f9f9;
  min-height: 100vh;
  padding: 10rem ;

  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
}

.ourvalues {
  @media screen and (max-width: 1024px) {
    width: 70%;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.ourvalues h1 {
  font-family: "Raleway";
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  color: var(--padiGrey);
  padding-bottom: 5%;
}

.ourvalues p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  color: rgba(0, 0, 0, 0.6);
}

.card-div {
  padding: 5% 0;
  justify-content: center;

}

.card-box{
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.05) !important;
}

.section-four .v-card__title {
  padding-bottom: 30px;
  font-family: "DM Sans",sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  word-break: normal;
  width: 65%;
}

.section-four .v-card__subtitle {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.6);
}

.section-four .v-card {
  padding: 40px;
  margin: 20px !important;
  height: 500px;
  width: 500px;
  border-radius: 20px;
  box-shadow: 3px 4px 18px 0px rgba(203, 200, 200, 0.99);

  @media screen and (max-width: 576px) {
    margin: 20px 0 !important;
    width: 100%;
    padding: 1.5rem;
    height: auto;
  }
  @media (max-width: 400px) {
    height: fit-content;
  }
}

.v-image {
  margin-left: 10px;
  margin-bottom: 30px;
}
</style>