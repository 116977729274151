<template>
    <div class="final-step">
      <loader :loading="loading" v-if="loading" />
      <div class="tw-w-full" v-else>
       <NavBar/>
        <div class="tw-mt-20"></div>
        <booking-steps />
      <div class="wrapper">
        <div class="back tw-cursor-pointer" @click="$router.push({name:'OneWayInputDetails'})"><v-icon>mdi-chevron-left</v-icon>Back</div>
      </div>
        <div class="rower">
          <div class="left">
            <div class="tw-flex tw-w-full tw-items-start lg:tw-items-center tw-flex-col lg:tw-flex-row tw-justify-end tw-pb-5">
              <seat-timer/>
            </div>
            <checkout-left-side @handlePayment="handleSubmit" :payment-loading="submitLoading" />
          </div>
            <div class="right display-center ">
                <select-insurance-plan />

                <checkout-passenger-summary />
                <button  class="mt-9 cta-button tw-hidden"  @click="handleSubmit" >Submit Booking </button>
            </div>
        </div>
      <Footer/>
      <modal section="print-ticket" :dialog="showBookingCompletedModal" color="#004AAD"
             @close="closePrintTicketModal" @printTicket="printBooking" title="Booking Successful" description="A booking confirmation have been sent to your email"/>

      <section slot="pdf-content" v-if="isPrinting">
        <booking-receipt  :booking="currentBooking" ref="customerReceipt" />
      </section>

      <paystack
          :amount="getTotalAmount*100"
          :email="tripAndTravellerDetails.seats[0].travellerDetail.email"
          :paystackkey="getPayStackKey"
          :reference="reference"
          :callback="handlePaystackPayment"
          :close="handleClosePaytackModal"
          :embed="false"
          :channels= "['card']"
      >
        <div class="tw-hidden" ref="paystack">Pay</div>
      </paystack>
    </div>
    </div>
</template>

<script>
    import BookingSteps from '../../components/reuseables/bookingAndHiring/OneWayBookingSteps.vue'
    import CheckoutLeftSide from '../bookings/CheckoutLeftSide.vue'
    import CheckoutPassengerSummary from '../bookings/CheckoutPassengerSummary.vue'
    import paystack from "vue-paystack";
    import SelectInsurancePlan from '../bookings/SelectInsurancePlan.vue'
    import NavBar from "@/home/Navbar.vue";
    import Footer from "@/home/Footer.vue";
    import SeatTimer from "@/components/reuseables/bookingAndHiring/SeatTimer.vue";
    import BookingReceipt from "@/components/reuseables/bookingAndHiring/BookingReceipt.vue";
    import Modal from "@/components/reuseables/Modal.vue";
    import {mapGetters} from "vuex";
    import {
      addBooking,addScheduleBooking, addWaveBooking,
      getPaystackAccountDetail, getTripByIdAndWaterTransportOperatorId,
      getTripByTripId,
      verifyPaystackPayment
    } from "@/services/api/APIService";
    import Loader from "@/components/reuseables/Loader.vue";
    import {getRandomInsuranceProvider} from "../../services/api/APIService";
    export default {
      name: "CheckOut",
        components: {
          Loader,
          SeatTimer,
          Footer,
          NavBar,
            BookingSteps,
            CheckoutPassengerSummary,
            CheckoutLeftSide,
            Modal,
          paystack,
          BookingReceipt,
          SelectInsurancePlan
        },
        data(){
         return{
           loading : false,
           submitLoading : false,
           paymentUrl : "",
           showPaymentModal : false,
           showBookingCompletedModal: false,
           currentBooking:{},
           isPrinting: false,
           paymentReference : "",
           paystackDetail:{}
         }
        },
      computed:{
        ...mapGetters("booking", ["oneWayTotalAmount"]),
        ...mapGetters("booking", ["tripAndTravellerDetails","userSelectedTrip", "insuranceProvider","selectedInsurancePlan", "selectedInsuranceAmount"]),

        getIsBookedByCustomer(){
          let isBookedByCustomer = false
          let userData = sessionStorage.getItem("userData")
          if (userData){
            let data = JSON.parse(userData)
            if(data.userRole === 'customer'){
              isBookedByCustomer = true
            }
          }
          else {
            isBookedByCustomer = true
          }
          return isBookedByCustomer
        },
        reference(){
          let text = "";
          let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

          for( let i=0; i < 10; i++ )
            text += possible.charAt(Math.floor(Math.random() * possible.length));
          return text;
        },
        getPayStackKey(){
          return process.env.VUE_APP_PAYSTACK_LIVE_PUBLIC_KEY
        },
        getTotalPrice(){
          return this.tripAndTravellerDetails.seats.length * this.userSelectedTrip.tripAmount
        },
        getTotalDiscount(){
          if (this.userSelectedTrip.discount && this.userSelectedTrip.discount.type === 'PERCENTAGE'){
            return (this.getTotalPrice * this.userSelectedTrip.discount.unitAmount) / 100
          }
          else if(this.userSelectedTrip.discount && this.userSelectedTrip.discount.type === 'FIXED'){
            return this.getTotalPrice - this.userSelectedTrip.discount.unitAmount
          }
          else {
            return 0
          }
        },
        getTotalAmount(){
          if (this.userSelectedTrip.discount){
            if (this.userSelectedTrip.discount && this.userSelectedTrip.discount.additionalIncentive && this.userSelectedTrip.discount.additionalIncentive.toLowerCase() === 'insurance'){
              if (this.selectedInsuranceAmount === this.userSelectedTrip.discount.additionalIncentiveUnitAmount){
                return  this.getTotalPrice - this.getTotalDiscount
              }
              else {
                return  (this.getTotalPrice + this.selectedInsuranceAmount) - this.getTotalDiscount
              }
            }
            else {
              return (this.getTotalPrice + this.selectedInsuranceAmount) - this.getTotalDiscount
            }
          }
          else {
            return this.getTotalPrice + this.selectedInsuranceAmount
          }
        }
      },
      methods:{
        async handleSubmitBooking(){
          let selectedInsurancePlan = this.selectedInsurancePlan
          let insuranceProvider = this.insuranceProvider
          let bookingData = {}
          let travellerDetails = []
          bookingData.bookedBy = this.tripAndTravellerDetails.seats[0].travellerDetail.firstName
          if (this.userSelectedTrip.tripRegion === 'crossCountry'){
            let userSelectedCategory = JSON.parse(sessionStorage.getItem("userSelectedCategory"))
            bookingData.bookingType = userSelectedCategory.type
          }
          bookingData.tripFare = this.getTotalAmount * 100
          bookingData.domain = window.location.hostname
          if (this.userSelectedTrip.discount){
            bookingData.discount = {
              id : this.userSelectedTrip.discount.id,
            }
            bookingData.discountedAmount = this.getTotalDiscount * 100
          }
          bookingData.tripId = this.tripAndTravellerDetails.tripId
          bookingData.scheduleId = this.userSelectedTrip.scheduleId
          bookingData.takeOffDate = this.userSelectedTrip.takeOffDate
          bookingData.departure = this.userSelectedTrip.routeDepartureCity
          bookingData.arrival = this.userSelectedTrip.routeDestinationCity
          this.tripAndTravellerDetails.seats.forEach(seat => travellerDetails.push(seat.travellerDetail))
          bookingData.travellerDetails = travellerDetails
          bookingData.selectedSeats = this.tripAndTravellerDetails.seats
          bookingData.paymentReference = this.reference
          if (selectedInsurancePlan && insuranceProvider){
            let insuranceData = {}
            insuranceData.companyEmail = insuranceProvider.companyEmail
            insuranceData.plan = selectedInsurancePlan
            bookingData.selectedInsurance = insuranceData
          }
          if (this.userSelectedTrip.transportCompanyId) {
            if (bookingData.tripId) {
              await addBooking(bookingData).then(res => {
                res.data.tripFare = res.data.tripFare * 100
                this.currentBooking = res.data
                this.showBookingCompletedModal = true
                this.submitLoading = false
              }).catch((err) => {
                this.submitLoading = false
                this.$displaySnackbar({
                  message: err.response.data.details[0],
                  success: false,
                });
              });
            }
            else if (bookingData.scheduleId){
              await addScheduleBooking(bookingData).then(async res =>{
                bookingData.bookingRefId = res.data.bookingRefId
                await addBooking(bookingData).then(res => {
                  res.data.tripFare = res.data.tripFare * 100
                  this.currentBooking = res.data
                  this.showBookingCompletedModal = true
                  this.submitLoading = false
                }).catch((err) => {
                  this.submitLoading = false
                  this.$displaySnackbar({
                    message: err.response.data.details[0],
                    success: false,
                  });
                });
              })
            }
          }
          else if (this.userSelectedTrip.waterTransportOperatorId){
            bookingData.waterTransportOperatorId = this.userSelectedTrip.waterTransportOperatorId
            await addWaveBooking(bookingData).then(res => {
              res.data.tripFare = res.data.tripFare * 100
              this.currentBooking = res.data
              this.showBookingCompletedModal = true
              this.submitLoading = false
            }).catch((err) => {
              this.submitLoading = false
              this.$displaySnackbar({
                message: err.response.data.details[0],
                success: false,
              });

            });
          }

        },
        closePrintTicketModal(){
          this.showBookingCompletedModal = false
          sessionStorage.removeItem("tripDetails")
          sessionStorage.removeItem("userSelectedTrip")
          sessionStorage.removeItem("oneWaySeats")
          sessionStorage.removeItem("primaryTraveller")
          sessionStorage.removeItem("oneWayBookingShowTimer")
          sessionStorage.removeItem("minusFromTimer")
          sessionStorage.removeItem("selectedInsuranceProvider")
          sessionStorage.removeItem("selectedPlan")
          this.$store.dispatch("booking/clearSeats")
          this.$store.dispatch("booking/setUserSelectedTrip",{})
          this.$store.dispatch("booking/updateTripAndTravellerDetails",{})
          this.$store.dispatch("booking/setOneWayBookingMinutesTimer",0)
          this.$store.dispatch("booking/setOneWayBookingShowTimer",false)
          this.$store.dispatch("booking/setSelectedInsuranceProvider",{})
          this.$store.dispatch("booking/setSelectedInsuranceAmount",0)
          if (this.getIsBookedByCustomer){
            this.$router.push({name : 'OneWaySelectTrip'})
          }
          else {
            sessionStorage.setItem("dashboardLocationTracker", "booking")
            this.$router.push({name : 'BookingDashboard'})
          }
        },
        handleClosePaytackModal(){},
        printBooking() {
          this.isPrinting = !this.isPrinting
          this.$refs.customerReceipt.print()
        },
        async handleSubmit(){
          if (this.getIsBookedByCustomer){
            this.$refs.paystack.click()
          }
          else {
            await this.handleSubmitBooking()
          }
        },
        async handlePaystackPayment(){
          let seats = ""
          this.tripAndTravellerDetails.seats.forEach(seat => {
            seats += seat.seatNumber
            if (this.tripAndTravellerDetails.seats.length > 1){ seats+= " ," }})

          this.submitLoading = true
          let data = {}
          let travellerDetails = []
          if (this.userSelectedTrip.transportCompanyId) {
            data.departure = this.userSelectedTrip.routeDepartureTerminalName + " " + this.userSelectedTrip.routeDepartureCity + " " + this.userSelectedTrip.routeDepartureState
            data.destination = this.userSelectedTrip.routeDestinationTerminalName + " " + this.userSelectedTrip.routeDestinationCity + " " + this.userSelectedTrip.routeDestinationState
          }
          else if (this.userSelectedTrip.waterTransportOperatorId){
            data.departure = this.userSelectedTrip.routeDepartureJettyName + " " + this.userSelectedTrip.routeDepartureCity + " " + this.userSelectedTrip.routeDepartureState
            data.destination = this.userSelectedTrip.routeDestinationJettyName + " " + this.userSelectedTrip.routeDestinationCity + " " + this.userSelectedTrip.routeDestinationState
          }
          data.selectedSeatNumbers = seats
          data.tripId = this.tripAndTravellerDetails.tripId
          data.amount = this.getTotalAmount
          if (this.userSelectedTrip.discount){
            data.discount = {
              id : this.userSelectedTrip.discount.id,
            }
          }
          data.discountedAmount = this.getTotalDiscount
          data.paymentReference = this.reference
          this.tripAndTravellerDetails.seats.forEach(seat => travellerDetails.push(seat.travellerDetail))
          data.receivingAccountName = this.paystackDetail.businessName
          data.receivingAccountNumber = this.paystackDetail.accountNumber
          data.paymentProcessor = "Paystack"
          if (this.selectedInsurancePlan ){
            data.paymentPurpose = "Booking with Insurance"
          }
          else {
            data.paymentPurpose = "Booking"
          }
          data.customerEmail = this.tripAndTravellerDetails.seats[0].travellerDetail.email
          data.travellers = travellerDetails
          await verifyPaystackPayment(this.paystackDetail.userId, this.reference, data).then(async res => {
            if (res.data.detail === 'Transaction was successfully!!') {
              await this.handleSubmitBooking()
            }
            else {
              this.$displaySnackbar({
                message: "Payment was unsuccessful, give it a minute and try again.",
                success: false,
              });
            }
          }).catch(err => {
            console.log(err)
            this.submitLoading = false})
          //
        },
        async getPaystackAccount(){
          if (this.userSelectedTrip.transportCompanyId) {
            await getPaystackAccountDetail(this.userSelectedTrip.transportCompanyEmail).then(res => {
              this.paystackDetail = res.data
            })
          }
          else if (this.userSelectedTrip.waterTransportOperatorId){
            await getPaystackAccountDetail(this.userSelectedTrip.waterTransportOperatorEmail).then(res => {
              this.paystackDetail = res.data
            })
          }
        },
        async getInsurancePlans(){
          await  getRandomInsuranceProvider().then(res =>{
            if (res.data.plans){
              this.$store.dispatch("booking/setInsuranceProvider", res.data)
            }
          })
        },
      },
     async created() {
        this.loading = true
       await this.getInsurancePlans()
        let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))
        let passengerDetail = JSON.parse(sessionStorage.getItem("passengerDetail"))
        let userSelectedCategory = JSON.parse(sessionStorage.getItem("userSelectedCategory"))
         if (passengerDetail.seats){
           passengerDetail.seats.forEach(seat =>{
             if (seat.travellerDetail.address && !Object.keys(seat.travellerDetail.address).length){
               seat.travellerDetail.address = null
             }
           })
         }
        if (Object.keys(userSelectedTrip).length){
          if (userSelectedTrip.transportCompanyId) {
            let data = {}
            data.transportCompanyId = userSelectedTrip.transportCompanyId
            data.tripId = userSelectedTrip.id
            data.id = userSelectedTrip.id
            data.scheduleId = userSelectedTrip.scheduleId
            data.departureQuery = userSelectedTrip.routeDepartureCity
            data.destinationQuery = userSelectedTrip.routeDestinationCity
            getTripByTripId(data).then(async res => {
              if (res.data.tripRegion === 'crossCountry' && userSelectedCategory && Object.keys(userSelectedCategory).length) {
                res.data.tripAmount = userSelectedCategory.tripAmount
              }
              await this.$store.dispatch("booking/setUserSelectedTrip", res.data)
              await this.$store.dispatch("booking/updateTripAndTravellerDetails", passengerDetail)
              await this.$store.dispatch("booking/updateCurrentSeats", passengerDetail.seats)
              await this.$store.dispatch("booking/getTotalAmount", res.data.tripAmount)
              await this.getPaystackAccount()
              this.loading = false
            }).catch(() => this.loading = false)
          }
          else if (userSelectedTrip.waterTransportOperatorId){
            let data = {}
            data.waterTransportOperatorId = userSelectedTrip.waterTransportOperatorId
            data.tripId = userSelectedTrip.id
            getTripByIdAndWaterTransportOperatorId(data).then(async res => {
              if (res.data.tripRegion === 'crossCountry' && userSelectedCategory && Object.keys(userSelectedCategory).length) {
                res.data.tripAmount = userSelectedCategory.tripAmount
              }
              await this.$store.dispatch("booking/setUserSelectedTrip", res.data)
              await this.$store.dispatch("booking/getTotalAmount", res.data.tripAmount)
              await this.$store.dispatch("booking/updateTripAndTravellerDetails", passengerDetail)
              await this.$store.dispatch("booking/updateCurrentSeats", passengerDetail.seats)
              await this.getPaystackAccount()
              this.loading = false
            }).catch(() => this.loading = false)
          }
          if (userSelectedTrip.discount && userSelectedTrip.discount.additionalIncentive && userSelectedTrip.discount.additionalIncentive.toLowerCase() === 'insurance'){
            if (this.insuranceProvider && this.insuranceProvider.plans){
              let plan = this.insuranceProvider.plans.find(plan => plan.planAmount === userSelectedTrip.discount.additionalIncentiveUnitAmount)
              if (plan) {
                await this.$store.dispatch("booking/setSelectedInsurancePlan", plan)
                await this.$store.dispatch("booking/setSelectedInsuranceAmount", plan.planAmount)
              }
            }
          }
        }
      }

    }
</script>

<style lang='scss' scoped>
    .rower {
        display: flex;
        flex-direction: row;
        padding: 50px 100px;
        width: 100%;
         @media (max-width:1440px) {
            padding: 50px;
        }
             @media (max-width:1024px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        @media (max-width:576px) {
            padding: 4rem 1rem;
        }

    }

    .left {
      width: 100%;
      padding: 0 100px 100px;
      @media (max-width:1440px) {
        padding: 50px;
      }
      @media (max-width:1264px) {
        padding: 0 50px 0 0;
      }
      @media (max-width:1024px) {
        margin-bottom: 100px;
        padding: 0;
      }
      @media (max-width:768px) {
        margin-bottom:40px;

      }
    }

    .seats {
        padding: 0 !important;
    }

    .right {
        width: 574px;
        height: fit-content;
        flex-direction: column;
        padding: 50px   20px;
        background: #ffff;
        box-shadow: 4px 4px 35px rgba(79, 79, 79, 0.15);
        @media (max-width:576px) {
            width: 360px;
        }
    }
    .cta-button{
        width: 330px;
         font-family: Inter, serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  background: #004aac;
  border-radius: 8px;
  width: 100%;
height: 53px;
          @media (max-width:576px) {
            width: 300px;
        }
    }

    .back{
      display: flex;
      align-items: center;
      font-family: 'Inter',sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #4F4F4F;
      width: max-content;
      text-transform: none;
      cursor: pointer;
    }

    .wrapper{
      width: 100%;
      padding: 0 10rem;

      @media screen and (max-width: 1024px) {
        padding: 1rem;
      }
    }
</style>