
export default {
    'Abia': [
        "Aba",
        "Arochukwu",
        "Bende",
        "Ikwuano",
        "Isiala Ngwa",
        "Isuikwuato",
        "Obi Ngwa",
        "Ohafia",
        "Osisioma Ngwa",
        "Ugwunagbo",
        "Ukwa",
        "Umu Nneochi",
        "Umuahia",
    ],
    'Adamawa': [
        "Demsa",
        "Fufore",
        "Ganye",
        "Girei",
        "Gombi",
        "Guyuk",
        "Hong",
        "Jada",
        "Lamurde",
        "Madagali",
        "Maiha",
        "Mayo-Belwa",
        "Michika",
        "Mubi",
        "Numan",
        "Shelleng",
        "Song",
        "Toungo",
        "Yola",
    ],
    'Akwa Ibom': [
        "Abak",
        "Eastern Obolo",
        "Esit-Eket",
        "Essien Udim",
        "Etim-Ekpo",
        "Etinan",
        "Ibeno",
        "Ibesikpo Asutan",
        "Ibiono Ibom",
        "Ika",
        "Ikono",
        "Ikot Abasi",
        "Ikot Ekpene",
        "Ini",
        "Itu",
        "Mbo",
        "Mkpat Enin",
        "Nsit Atai",
        "Nsit Ibom",
        "Nsit Ubium",
        "Obot Akara",
        "Okobo",
        "Onna",
        "Oron",
        "Oruk Anam",
        "Udung Uko",
        "Ukanafun",
        "Uquo-Ibeno",
        "Uruan",
        "Urue-Offong/Oruko",
        "Uyo"

    ],
    'Anambra': [
        "Aghamelu",
        "Aguata",
        "Anambra",
        "Anaocha",
        "Awka",
        "Ayamelum",
        "Dunukofia",
        "Ekwusigo",
        "Idemili",
        "Ihiala",
        "Njikoka",
        "Nnewi",
        "Ogbaru",
        "Onitsha",
        "Orumba",

    ],
    'Bauchi': [
        "Alkaleri",
        "Bauchi",
        "Bogoro",
        "Damban",
        "Darazo",
        "Dass",
        "Gamawa",
        "Ganjuwa",
        "Giade",
        "Itas/Gadau",
        "Jama are",
        "Katagum",
        "Kirfi",
        "Misau",
        "Ningi",
        "Shira",
        "Toro",
        "Warji",
        "Zaki"
    ],
    'Bayelsa': [
        "Brass",
        "Ekeremor",
        "Kolokuma Opokuma",
        "Nembe",
        "Ogbia",
        "Sagbama",
        "Southern Ijaw",
        "Yenagoa"
    ],
    'Benue': [
        "Ado",
        "Agatu",
        "Apa",
        "Buruku",
        "Gboko",
        "Guma",
        "Gwer",
        "Katsina-Ala",
        "Konshisha",
        "Kwande",
        "Logo",
        "Makurdi",
        "Obi",
        "Ogbadibo",
        "Ohimini",
        "Oju",
        "Okpokwu",
        "Otukpo",
        "Tarka",
        "Ukum",
        "Ushongo",
        "Vandeikya",
    ],
    'Borno': [
        "Abadam",
        "Askira/Uba",
        "Bama",
        "Bayo",
        "Biu",
        "Chibok",
        "Damboa",
        "Dikwa",
        "Gubio",
        "Guzamala",
        "Gwoza",
        "Hawul",
        "Jere",
        "Kaga",
        "Kala/Balge",
        "Konduga",
        "Kukawa",
        "Kwaya Kusar",
        "Mafa",
        "Magumeri",
        "Maiduguri",
        "Marte",
        "Mobbar",
        "Monguno",
        "Ngala",
        "Nganzai",
        "Shani"
    ],
    "Cross River": [
        "Abi",
        "Akamkpa",
        "Akpabuyo",
        "Bakassi",
        "Bekwara",
        "Biase",
        "Boki",
        "Calabar",
        "Etung",
        "Ikom",
        "Obanliku",
        "Obubra",
        "Obudu",
        "Odukpani",
        "Ogoja",
        "Yakuur",
        "Yala"
    ],

    'Delta': [
        "Asaba",
        "Aniocha",
        "Bomadi",
        "Burutu",
        "Ethiope",
        "Ika",
        "Isoko",
        "Ndokwa",
        "Kwale",
        "Okpe",
        "Oshimili",
        "Patani",
        "Sapele",
        "Udu",
        "Effurun",
        "Ughelli",
        "Uvwie",
        "Warri"
    ],

    'Ebonyi': [
        "Abakaliki",
        "Afikpo",
        "Ebonyi",
        "Ezza",
        "Ikwo",
        "Ishielu",
        "Ivo",
        "Izzi",
        "Ohaozara",
        "Ohaukwu",
        "Onicha"
    ],
    'Edo': [
        "Akoko-Edo",
        "Auchi",
        "Benin City",
        "Egor",
        "Ekpoma",
        "Esan",
        "Fugar",
        "Igueben",
        "Ikpoba-Okha",
        "Irrua",
        "Uromi",
        "Okada",
        "Orhionmwon",
        "Ovia South",
        "Owan",
        "Ubiaja",
        "Uhunmwonde",
    ],

    'Ekiti': [
        "Ado Ekiti",
        "Aiyekire (Gbonyin)",
        "Aramoko",
        "Efon",
        "Emure",
        "Ido-Osi",
        "Ijero",
        "Ikere",
        "Ikole",
        "Ilawe",
        "Ilejemeje",
        "Irepodun/Ifelodun",
        "Ise/Orun",
        "Moba",
        "Omuo",
        "Oye",
    ],
    'Rivers': [
        "Abua/Odual",
        "Ahoada",
        "Akuku Toru",
        "Andoni",
        "Asari-Toru",
        "Bonny",
        "Degema",
        "Eleme",
        "Emohua",
        "Etche",
        "Gokana",
        "Ikwerre",
        "Khana",
        "Obio-Akpor",
        "Ogba/Egbema/Ndoni",
        "Ogu/Bolo",
        "Okrika",
        "Omuma",
        "Oyigbo",
        "Port-Harcourt",
        "Tai"
    ],
    'Enugu': [
        "Aninri",
        "Awgu",
        "Enugu",
        "Ezeagu",
        "Igbo Eze",
        "Igbo-Etiti",
        "Isi-Uzo",
        "Nkanu",
        "Nsukka",
        "Oji-River",
        "Udenu",
        "Udi",
        "Uzo-Uwani"
    ],
    'Abuja': [
        "Abuja",
        "Abaji",
        "Apo District",
        "Asokoro",
        "Bwari",
        "Central Business District",
        "Dakibiyu",
        "Dakwo District",
        "Dei-Dei",
        "Duboyi",
        "Durumi",
        "Dutse-Alhaji",
        "Gaduwa",
        "Galadimawa",
        "Garki 1",
        "Garki 2",
        "Gudu",
        "Guzape District",
        "Gwagwa",
        "Gwagwalada",
        "Gwarinpa",
        "Idu Industrial",
        "Jabi",
        "Jahi",
        "Jikwoyi",
        "Jiwa",
        "Kabusa",
        "Kado",
        "Karmo",
        "Karshi",
        "Karu",
        "Katampe",
        "Kaura",
        "Kpeyegyi",
        "Kubwa",
        "Kuchigoro",
        "Kuje",
        "Kurudu",
        "Kwali",
        "Lokogoma",
        "Lugbe District",
        "Mabushi",
        "Maitama",
        "Mararaba",
        "Masaka",
        "Mbora",
        "Mpape",
        "Nyanya",
        "Okanje",
        "Orozo",
        "Pyakasa",
        "Sabo Gida",
        "Utako",
        "Wumba",
        "Wuse",
        "Wuse 2",
        "Wuye",
        "Zuba",

    ],
    'Gombe': [
        "Akko",
        "Balanga",
        "Billiri",
        "Dukku",
        "Funakaye",
        "Gombe",
        "Kaltungo",
        "Kwami",
        "Nafada",
        "Shomgom",
        "Yamaltu/Deba"
    ],
    'Imo': [
        "Aboh-Mbaise",
        "Ahiazu-Mbaise",
        "Ehime-Mbano",
        "Ezinihitte",
        "Ezinihitte Mbaise",
        "Ideato",
        "Ihitte/Uboma",
        "Ikeduru",
        "Isiala Mbano",
        "Isu",
        "Mbaitoli",
        "Ngor-Okpala",
        "Njaba",
        "Nkwerre",
        "Nwangele",
        "Obowo",
        "Oguta",
        "Ohaji/Egbema",
        "Okigwe",
        "Onuimo",
        "Orlu",
        "Orsu",
        "Oru",
        "Owerri"
    ],
    'Jigawa': [
        "Auyo",
        "Babura",
        "Biriniwa",
        "Buji",
        "Dutse-Jigawa",
        "Gagarawa",
        "Garki",
        "Gumel",
        "Guri",
        "Gwaram",
        "Gwiwa",
        "Hadejia",
        "Jahun",
        "Kafin Hausa",
        "Kaugama",
        "Kazaure",
        "Kiri Kasamma",
        "Kiyawa",
        "Maigatari",
        "Malam Madori",
        "Miga",
        "Ringim",
        "Roni",
        "Sule-Tankarkar",
        "Taura",
        "Yankwashi",

    ],
    'Kaduna': [
        "Birnin-Gwari",
        "Chikun",
        "Giwa",
        "Igabi",
        "Ikara",
        "Jaba",
        "Jema a",
        "Kachia",
        "Kaduna",
        "Kagarko",
        "Kajuru",
        "Kaura-Kaduna",
        "Kauru",
        "Kubau",
        "Kudan",
        "Lere",
        "Makarfi",
        "Sanga",
        "Soba",
        "Zango-Kataf",
        "Zaria",
    ],
    'Kano': [
        "Ajingi",
        "Albasu",
        "Bagwai",
        "Bebeji",
        "Bichi",
        "Bunkure",
        "Dala",
        "Dambatta",
        "Dawakin Kudu",
        "Dawakin Tofa",
        "Doguwa",
        "Fagge",
        "Gabasawa",
        "Garko",
        "Garum Mallam",
        "Garun Mallam",
        "Gaya",
        "Gezawa",
        "Gwale",
        "Gwarzo",
        "Kabo",
        "Kano Municipal",
        "Karaye",
        "Kibiya",
        "Kiru",
        "Kumbotso",
        "Kunchi",
        "Kura",
        "Madobi",
        "Makoda",
        "Minjibir",
        "Nasarawa-Kano",
        "Rano",
        "Rimin Gado",
        "Rogo",
        "Shanono",
        "Sumaila",
        "Takai",
        "Tarauni",
        "Tofa",
        "Tsanyawa",
        "Tudun Wada",
        "Ungogo",
        "Warawa",
        "Wudil"

    ],
    'Katsina': [
        "Bakori",
        "Batagarawa",
        "Batsari",
        "Baure",
        "Bindawa",
        "Charanchi",
        "Dan Musa",
        "Dandume",
        "Danja",
        "Daura",
        "Dutsi",
        "Dutsin-Ma",
        "Faskari",
        "Funtua",
        "Ingawa",
        "Jibia",
        "Kafur",
        "Kaita",
        "Kankara",
        "Kankia",
        "Katsina",
        "Kurfi",
        "Kusada",
        "Mai adua",
        "Malumfashi",
        "Mani",
        "Mashi",
        "Matazu",
        "Musawa",
        "Rimi",
        "Sabuwa",
        "Safana",
        "Sandamu",
        "Zango"
    ],
    'Kebbi': [
        "Aleiro",
        "Arewa-Dandi",
        "Argungu",
        "Augie",
        "Bagudo",
        "Birnin Kebbi",
        "Bunza",
        "Dandi",
        "Fakai",
        "Gwandu",
        "Jega",
        "Kalgo",
        "Koko/Besse",
        "Maiyama",
        "Ngaski",
        "Sakaba",
        "Shanga",
        "Suru",
        "Wasagu/Danko",
        "Yauri",
        "Zuru"

    ],
    'Kogi': [
        "Adavi",
        "Ajaokuta",
        "Ankpa",
        "Bassa",
        "Dekina",
        "Ibaji",
        "Idah",
        "Igala Mela",
        "Igalamela-Odolu",
        "Ijumu",
        "Kabba/Bunu",
        "Kogi",
        "Koton Karfe",
        "Lokoja",
        "Mopa-Muro",
        "Ofu",
        "Ogori/Magongo",
        "Okehi",
        "Okene",
        "Olamaboro",
        "Omala",
        "Yagba",

    ],
    'Kwara': [
        "Asa",
        "Baruten",
        "Edu",
        "Ekiti-Kwara",
        "Ifelodun-Kwara",
        "Ilorin",
        "Irepodun-Kwara",
        "Isin",
        "Kaiama",
        "Moro",
        "Oke-Ero",
        "Oyun",
        "Pategi"
    ],
    "Lagos": [
        "Abule Egba",
        "Agbara-Igbesan",
        "Agboyi/Ketu",
        "Agege",
        "Ajah",
        "Alimosho",
        "Amuwo-Odofin",
        "Badagry",
        "Egbe Idimu",
        "Ejigbo",
        "Eko Atlantic",
        "Epe",
        "Gbagada",
        "Ibeju",
        "Ifako-Ijaiye",
        "Ikeja",
        "Ikorodu",
        "Ikotun/Igando",
        "Ikoyi",
        "Ilashe",
        "Ilupeju",
        "Ipaja",
        "Isolo",
        "Kosofe",
        "Lagos Island (Eko)",
        "Lekki",
        "Magodo",
        "Maryland",
        "Mushin",
        "Ogba",
        "Ogudu",
        "Ojo",
        "Ojodu",
        "Ojota",
        "Orile",
        "Oshodi",
        "Shomolu",
        "Surulere",
        "Tarkwa Bay Island",
        "Victoria Island",
        "Yaba"

    ],
    'Nasarawa': [
        "Akwanga",
        "Awe",
        "Doma",
        "Karu-Nasarawa",
        "Keana",
        "Keffi",
        "Kokona",
        "Lafia",
        "Nasarawa",
        "Nasarawa-Eggon",
        "Obi-Nasarawa",
        "Toto"
    ],
    'Niger': [
        "Agaie",
        "Agwara",
        "Bida",
        "Borgu",
        "Bosso",
        "Chanchaga",
        "Edati",
        "Gbako",
        "Gurara",
        "Kontagora",
        "Lapai",
        "Lavun",
        "Magama",
        "Mariga",
        "Mashegu",
        "Minna",
        "Mokwa",
        "Muya",
        "Paikoro",
        "Rafi",
        "Rijau",
        "Shiroro",
        "Suleja",
        "Tafa",
        "Wushishi"
    ],
    'Ogun': [
        "Abeokuta",
        "Ado-Odo/Ota",
        "Ayetoro",
        "Ewekoro",
        "Ifo",
        "Ijebu",
        "Ijebu Ode",
        "Ikenne",
        "Ilaro",
        "Imeko Afon",
        "Ipokia",
        "Obafemi-Owode",
        "Odeda",
        "Odogbolu",
        "Ogun Waterside",
        "Remo North",
        "Sagamu",
    ],
    'Ondo': [
        "Akungba",
        "Akure",
        "Ese-Odo",
        "Idanre",
        "Ifedore",
        "Iju/Itaogbolu",
        "Ikare Akoko",
        "Ilaje",
        "Ile-Oluji-Okeigbo",
        "Irele",
        "Isua",
        "Odigbo",
        "Oka",
        "Okeagbe",
        "Okeigbo",
        "Okitipupa",
        "Ondo",
        "Ose",
        "Owo"
    ],
    'Osun': [
        "Aiyedade",
        "Aiyedire",
        "Atakumosa",
        "Boluwaduro",
        "Ede",
        "Egbedore",
        "Ife",
        "Ifedayo",
        "Ifelodun-Osun",
        "Ila",
        "Ilesa",
        "Irepodun-Osun",
        "Irewole",
        "Isokan",
        "Iwo",
        "Obokun",
        "Ola-Oluwa",
        "Olorunda-Osun",
        "Oriade",
        "Orolu",
        "Osogbo"
    ],
    'Oyo': [
        "Afijio",
        "Akinyele",
        "Atiba",
        "Atisbo",
        "Ayete",
        "Egbeda",
        "Eruwa",
        "Ibadan",
        "Ido",
        "Igbo Ora",
        "Irepo",
        "Iseyin",
        "Itesiwaju",
        "Iwajowa",
        "Kajola",
        "Lagelu",
        "Ogbomosho",
        "Ogo Oluwa",
        "Olorunsogo",
        "Oluyole",
        "Ona-Ara",
        "Orelope",
        "Ori Ire",
        "Oyo",
        "Saki",
        "Surulere-Oyo"

    ],
    'Plateau': [
        "Barkin Ladi",
        "Bassa-Plateau",
        "Bokkos",
        "Jos",
        "Kanam",
        "Kanke",
        "Langtang",
        "Mangu",
        "Mikang",
        "Pankshin",
        "Quaan Pan",
        "Riyom",
        "Shendam",
        "Wase"
    ],
    'Sokoto': [
        "Binji",
        "Bodinga",
        "Dange-Shuni",
        "Gada",
        "Goronyo",
        "Gudu",
        "Gwadabawa",
        "Illela",
        "Isa",
        "Kebbe",
        "Kware",
        "Rabah",
        "Sabon Birni",
        "Shagari",
        "Silame",
        "Sokoto",
        "Tambuwal",
        "Tangaza",
        "Tureta",
        "Wamako",
        "Wurno",
        "Yabo"
    ],
    'Taraba': [
        "Ardo-Kola",
        "Bali",
        "Donga",
        "Gashaka",
        "Gassol",
        "Ibi",
        "Jalingo",
        "Karim-Lamido",
        "Kurmi",
        "Lau",
        "Sardauna",
        "Takum",
        "Ussa",
        "Wukari",
        "Yorro",
        "Zing"
    ],
    'Yobe': [
        "Bade",
        "Bursari",
        "Damaturu",
        "Fika",
        "Fune",
        "Geidam",
        "Gujba",
        "Gulani",
        "Jakusko",
        "Karasuwa",
        "Machina",
        "Nangere",
        "Nguru",
        "Potiskum",
        "Tarmua",
        "Yunusari",
        "Yusufari"
    ],
    'Zamfara': [
        "Anka",
        "Bakura",
        "Birnin Magaji",
        "Bukkuyum",
        "Bungudu",
        "Gummi",
        "Gusau",
        "Kaura Namoda",
        "Maradun",
        "Maru",
        "Shinkafi",
        "Talata Mafara",
        "Tsafe",
        "Zurmi"
    ]
};
