<template>
  <div class="tw-flex tw-px-6 tw-py-4 tw-w-full tw-flex-col">

  <div class="below ">
    <div class="tw-flex tw-w-full tw-flex-col" >
      <p :style="{color:'#004AAD'}" class="header my-4">Primary Traveller</p>
      <div class="tw-flex tw-w-full tw-flex-col">
        <div class="tw-flex tw-flex-row tw-items-center">
          <div class="tw-mr-2">
            <icons name="user"  />
          </div>
          <div  class="content">{{hiringDetails.travellerDetail.firstName+ " "+hiringDetails.travellerDetail.lastName}} </div>
        </div>
        <div class="tw-hidden tw-flex-row tw-items-center">
          <div class="tw-mr-2">
            <icons name="user-gender"  />
          </div>
          <div  class="content">{{hiringDetails.travellerDetail.gender}} </div>
        </div>
        <div class="tw-flex tw-flex-row tw-items-center">
          <div class="tw-mr-2">
            <icons name="user-phone-number"  />
          </div>
          <div  class="content">{{hiringDetails.travellerDetail.phoneNumber}} </div>
        </div>
        <div class="tw-flex tw-flex-row tw-items-center">
          <div class="tw-mr-2">
            <icons name="user-email"  />
          </div>
          <div  class="content">{{hiringDetails.travellerDetail.email}} </div>
        </div>
        <div class="tw-flex tw-flex-row tw-items-center">
          <div class="tw-mr-2">
            <icons name="user-date-of-birth"  />
          </div>
          <div  class="content" v-if="hiringDetails.travellerDetail.dateOfBirth">{{getDateOfBirth(hiringDetails.travellerDetail.dateOfBirth)}} </div>
        </div>
        <div class="tw-flex tw-flex-row tw-items-center">
          <div class="tw-mr-2">
            <icons name="user-address"  />
          </div>
          <div  class="content text-capitalize" v-if="hiringDetails.travellerDetail.address">{{hiringDetails.travellerDetail.address.houseNumber+" "+
          hiringDetails.travellerDetail.address.streetName+ ","+
          hiringDetails.travellerDetail.address.city+" "+hiringDetails.travellerDetail.address.state+" "+
          hiringDetails.travellerDetail.address.country}} </div>
        </div>
      </div>
    </div>

  </div>
    <v-checkbox  class="tw-hidden">
      <template #label>
        <h6> Add insurance plan to my total price </h6>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import dayjs from "dayjs";
import Icons from "@/components/reuseables/Icons.vue";

export default {
    name:"CheckoutPassengerSummary",
  components: {Icons},
data(){
    return{
      passengers:[
        {
        name: "Anielila Inetimi",
        phoneNumber:"08162827546",
        email:"anielila@spacebus.com"
      },
        {
          name: "Anielila Inetimi",
          phoneNumber:"08162827546",
          email:"anielila@spacebus.com"
        },
        {
          name: "Anielila Inetimi",
          phoneNumber:"08162827546",
          email:"anielila@spacebus.com"
        }
      ],
    }
},
  computed: {
    ...mapGetters("hire", ["hiringDetails"]),
  },
  methods:{
    getDateOfBirth(dateOfBirth){
      return dayjs(dateOfBirth).format("ll")
    }
  }
}
</script>

<style lang="scss" scoped>
.below {
  width: 100%;
  border: 2px dashed #000000;
  padding: 40px;
  background: #F2F2F4;
}

.header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #004AAD;

}
.content{

  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 17, 39, 0.73);
  padding: 0.5rem 0;
}
</style>