<script>
import DemoButton from "@/components/reuseables/DemoButton.vue";
import GetStartedBtn from "@/components/reuseables/GetStartedBtn.vue";


export default {
  name: 'TanLastSection',
  components: { GetStartedBtn, DemoButton}
}
</script>

<template>
<div class="section-parent tw-px-5 lg:tw-px-40">
  <div class="text-body ">
  <p class="header ">Ready to Join Nomadicpod in Business?</p>
  <p class="sub-head tw-my-8">Experience the power of TAN and join us as a ticket sales agent. Sign up now and become<br v-if="$vuetify.breakpoint.lgOnly">
    an affiliate seller.</p>
  <div class="tw-flex justify-center tw-my-8 btn">
    <demo-button/>
    <get-started-btn/>
  </div>
  </div>

</div>
</template>

<style scoped lang="scss">
.section-parent{
  margin-bottom: 100px;
  min-height: 60vh;
  margin-top: 100px;
  @media screen and (max-width: 576px){
    width: 100%;
  }

}
.header{
  font-family: Raleway;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
  color: #263238;
  @media (max-width: 576px) {
    font-size: 32px;
    line-height: 48px;
    text-align: left;

  }
}
.sub-head{
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #2D3348;
  @media screen and (max-width: 576px){
    font-size: 16px;
    line-height: 23px;
    text-align: left;

  }
}
.text-body{
  background-color: #FCF1F4;
  padding: 120px;
  border-radius: 20px;
  background-image: url("../../../assets/tan-last-img.svg");
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 576px){
    width: 100%;
    padding: 30px;
  }
}
.btn{
  @media screen and (max-width: 576px){
   display: flex;
    justify-content: left;
    margin-right: 50px;
  }
}
</style>