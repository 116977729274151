<template>
<div class="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-center tw-mt-20">
  <div class="tw-flex tw-flex-col tw-py-8" v-for="(step,index) in steps" :key="index">
    <div class="tw-flex-row tw-flex tw-items-center">
      <div class="tw-flex tw-flex-col size">
        <div :class="[step.stepCount <= currentStepCount ? 'active-step active-text' : 'in-active-step in-active-text']">{{step.stepCount}}</div>
      </div>
      <div :class="[step.stepCount <= currentStepCount ?'active-line': 'in-active-line']" class=" tw-mx-3" v-if="step.stepCount < 4"/>
    </div>
     <h6 :class="[step.stepCount <= currentStepCount ?'active-text': 'in-active-text']" class="tw-mt-4"> {{step.stepName}}</h6>
  </div>

</div>
</template>

<script>
export default {
  name: "BookingSteps",
  data(){
    return{
      currentStepCount : 1,
      steps:[
        {
        stepCount : 1,
        stepName: "Select Vehicle"
       },
        {
          stepCount : 2,
          stepName: "Location"
        },
        {
          stepCount : 3,
          stepName: "Input Details"
        },
        {
          stepCount : 4,
          stepName: "Payment"
        },
      ]
    }
  },
  created(){
    if(this.$route.name === 'HireSelectVehicle'){
      this.currentStepCount = 1
    }
    else if(this.$route.name === 'HireLocation'){
      this.currentStepCount = 2
    } else if(this.$route.name === 'HireInputDetails'){
      this.currentStepCount = 3
    } else if(this.$route.name === 'HirePayment'){
      this.currentStepCount = 4
    }
  }
}
</script>

<style scoped lang="scss">
.active-step{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34.3px;
  height: 34.3px;
  border: 1.74398px solid #004AAD;
  border-radius: 50%;
}

.active-line{
  border: 0.581325px dashed #004AAD;
  width: 45px;

  @media screen and(max-width: 400px) {
    width: 35px;
  }
}
.size{
  
}
.in-active-step{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34.3px;
  height: 34.3px;
  border: 1.74398px solid rgba(79, 79, 79, 0.57);
  border-radius: 50%;
}

.in-active-line{
  border: 0.581325px dashed rgba(79, 79, 79, 0.57);
  width: 45px;

  @media screen and(max-width: 400px) {
    width: 35px;
  }
}

.active-text{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11.6265px;
  line-height: 14px;
  color: #004AAD;
padding-left: -20px;
}

.in-active-text{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11.6265px;
  line-height: 14px;
  color: rgba(79, 79, 79, 0.57);
}
</style>