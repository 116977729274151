<template>
  <footer class="footer">
    <div class="
        tw-flex tw-justify-center
        md:tw-items-start md:tw-flex-row
        tw-flex-col tw-flex-wrap
      ">
      <v-col sm="12" md="4" class="first-box">
        <div class="logo-div">
          <img src="@/assets/logos/blue.png" width="160" height="40" alt="logo" class="tw-cursor-pointer" @click="routeToHome" />

          <!-- <img :src="logo" alt="" @click="routeToHome" class="clickable-logo" width="160
" height="40" /> -->
        </div>
        <div class="tw-flex tw-items-start tw-mt-5 address-box">
          <img src="@/assets/location.svg" alt="" />
          <div>
            <p class="address-text lg:tw-pr-4">
              371 Borno Way, Alagomeji, Yaba, Lagos
            </p>
          </div>
        </div>
        <SocialMedia />
      </v-col>
      <v-col sm="6" md="2" class="second-box">
        <p class="header">Company</p>
        <router-link to="/about">About Us</router-link>
        <!--        <router-link to="/">Why RoadPadi</router-link>-->
        <!--        <router-link to="/">Services</router-link>-->

        <router-link to="/career">Career</router-link>
        <router-link to="/terms-and-policies">Terms & Policies</router-link>
      </v-col>
      <v-col sm="6" md="2" class="third-box">
        <p class="header">Explore</p>
        <div class="tw-cursor-pointer tw-pt-3 dev-class" @click="routeToNpod">
          Book Trip
        </div>
        <div class="tw-cursor-pointer tw-pt-3 dev-class" @click="routeToDeveloper">
          Developer
        </div>
        <router-link to="/">Learn</router-link>

        <router-link to="/blog">Blog</router-link>

        <!--        <router-link to="/">Clients</router-link>-->
      </v-col>
      <v-col sm="6" md="2" class="third-box">
        <p class="header">Help</p>
        <router-link to="/contact">Contact us</router-link>

        <router-link to="/faqs">F.A.Q</router-link>
      </v-col>
      <v-col sm="6" md="2" class="third-box">
        <p class="header">News Letter</p>
        <p class="box-text">Stay connected with our News Letter</p>
        <form class="tw-w-full" @submit.prevent="subscribe">
          <v-text-field type="email" placeholder=" email address" hide-details dense class="" outlined
            v-model="subscriberEmail" />
          <div>
            <v-btn text :loading="loading" type="submit" class="mt-4">Get News</v-btn>
          </div>
        </form>
      </v-col>
    </div>

    <div class="right-reserved">
      <v-divider class="my-5"></v-divider>
      <div class="right-reserved-inner">
        All Rights Reserved
        <img src="@/assets/copy.svg" class="tw-mx-2" style="width: 22px; height: 22px" alt="" />
        {{ year }}
      </div>
    </div>
  </footer>
</template>

<script>
  import SocialMedia from "@/components/reuseables/SocialMedia.vue";
  import {
    subscribeToNewsLetter
  } from "@/services/api/APIService";
  export default {
    components: {
      SocialMedia,
    },
    name: "Footer",
    data() {
      return {
        year: "",
        subscriberEmail: "",
        loading: false,
        logo: "https://res.cloudinary.com/myroadpadi/image/upload/v1631672100/assets/frontend/logo_kpzfg4.png",
      };
    },

    methods: {
      routeToHome() {
        if (this.$route.name !== "HomePage")
          this.$router.push({
            name: "HomePage"
          });
      },
      routeToDeveloper() {
        window.open(process.env.VUE_APP_DEVELOPER_FRONTEND_URL, "_self");
      },
      getYear() {
        let date = new Date();
        this.year = date.getFullYear();
      },
      routeToNpod(){
        window.open(process.env.VUE_APP_NPOD_URL,"_blank")

      },
      subscribe() {
        if (this.subscriberEmail) {
          this.loading = true;
          let data = {};
          data.subscriberEmail = this.subscriberEmail;
          subscribeToNewsLetter(data)
            .then((res) => {
              this.loading = false;
              this.$displaySnackbar({
                message: res.data.Detail,
                success: true,
              });
            })
            .catch((err) => {
              this.$displaySnackbar({
                message: err.response.data.details[0],
                success: false,
              });
              this.loading = false;
            });
        } else {
          this.$displaySnackbar({
            message: "Please enter your email address",
            success: false,
          });
        }
      },
    },
    mounted() {
      this.getYear();
    },
  };
</script>

<style lang="scss" scoped>
  .footer {
    padding: 9.2rem 11rem 0 9.2rem;
    min-height: 60vh
;

    @media screen and (max-width: 1264px) {
      padding: 2rem;
    }

    @media screen and (max-width: 1024px) {
      padding: 1.5rem;
    }

    @media screen and (max-width: 768px) {
      padding: 0.8rem;
    }

    @media screen and (max-width: 360px) {
      padding: 0.3rem;
    }

    position: relative;
    background-color: white;
    background-image: url('../../assets/newlandingpage/footer-img1.svg'),
    url('../../assets/newlandingpage/footer-img2.svg');
    background-repeat: no-repeat,
    no-repeat;
    background-position: left top, right bottom;
    background-size: auto, auto;

    .header {
      font-family: "Raleway", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0;
      text-align: left;
    }

    .first-box {
      .logo-div {
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }

      .address-box {
        img {
          margin-top: 7px;
          margin-right: 8px;
        }
      }

      .address-text {
        height: 72px;
        width: 269px;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0;
        text-align: left;
        color: rgba(38, 50, 56, 1);

        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
      }
    }

    .second-box a,
    .third-box a {
      display: block;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      color: rgba(38, 50, 56, 1);

      margin-top: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: left;

      @media screen and (max-width: 768px) {
        font-size: 14px;
        margin-top: 15px;
      }
    }

    .box-text {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      margin: 18px 0;

      font-style: normal;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: left;
    }

    .right-reserved {
      margin-top: 10rem;
      bottom: 0;
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
    }

    .right-reserved-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #1D262D;

      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      padding-right: 5rem !important;

      @media screen and (max-width: 768px) {
        padding-right: 0 !important;
      }
    }

    .third-box {
      input {
        border: 1px solid rgba(38, 50, 56, 0.461);
        padding: 10px;
        height: 42px;
        font-size: 12px;
        border-radius: 5px;
        color: rgba(38, 50, 56, 0.761) !important;

        @media (max-width: 960px) {
          width: 180px;
        }
      }

      input:focus {
        outline: 1px solid rgba(38, 50, 56, 0.261) !important;
        border: none;
      }

      button {
        height: 40px;
        width: 124px;
        background: #004aad;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        color: white;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        text-transform: none;
      }
    }
  }

  .dev-class {
    @media screen and (max-width: 768px) {
      font-size: 14px !important;
    }
  }
</style>