<script>
export default {
  name: 'WaterWay'
}

</script>

<template>
  <div class=" parent-div">
    <div class="tw-flex">

      <div class="avatar-container">
        <div style="background: #D0BAFF;" class="avatar-inner">
          <img src="@/assets/icons/waterway-icon.svg" alt="boat icon" />
        </div>
      </div>


      <div class="tw-flex flex-column tw--mr-10" >
<router-link to="/wave-jotter">
        <div class="tw-pl-3 tw-pt-3">
          <p class="product-txt">Wave-Jotter</p>
          <p class="paragraph-txt">For waterways transport providers to manage your waterway<br>
            trip-inventory, sales, and booking operations seamlessly.</p>
          <p class="paragraph-txt-mobile">For waterways transport providers to manage<br>your waterway
            trip-inventory, sales, and<br>booking operations seamlessly.</p>
        </div>
</router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.parent-div{
  width: 587px;
  height: 248px;
  background: #FFFFFF;
  @media (max-width: 576px) {
    padding: 20px 100px 10px 10px;
  }
}
.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background: #F3EDFF;
  @media (max-width: 576px) {
    width: 50px;
    height: 50px;
  }
}

.avatar-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: #E3F0FF;
  @media (max-width: 576px) {
    width: 45px;
    height: 45px;
  }
}

.avatar-inner img {
  object-fit: contain;
  display: flex;
  align-self: center;
}
.product-txt{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #000F23;
}
.paragraph-txt{
  font-family: Raleway,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #000000;
  white-space: nowrap;
  padding-top: 10px;
  @media (max-width: 576px) {
    display: none;
  }
}

.paragraph-txt-mobile{
  display: none;
  @media (max-width: 576px) {
    display: flex;
    font-family: Raleway,sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #00193A;
  }
}
</style>