<template>
<div class="tw-flex tw-w-auto lg:tw-items-center tw-flex-row tw-justify-end" v-if="oneWayBookingShowTimer">
  <img src="@/assets/countdown.svg" alt="count down" style="height: 18px; width: 18px">
  <div class="count">
    <span class="tw-pl-3">Complete your booking in less than
      {{ oneWayBookingMinutesTimer }} mins : {{secondsTimer}} secs
    </span>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
// import {getTripByIdAndWaterTransportOperatorId, getTripByTripId} from "@/services/api/APIService";
import {getTripByIdAndWaterTransportOperatorId, getTripByTripId} from "@/services/api/APIService";

export default {
  name: "SeatTimer",
  props:{
    tripId: [Number]
  },
  data(){
    return{
      minutesTimer : 0,
      secondsTimer: 0

    }
  },
  computed:{
    ...mapGetters('booking',['oneWayBookingMinutesTimer','oneWayBookingShowTimer'])
  },
  watch: {
    secondsTimer: {
      async handler(value) {
        let minutesTimer = 0
        let oneWayBookingMinutesTimer = sessionStorage.getItem('oneWayBookingMinutesTimer')
        let minusFromTimer = JSON.parse(sessionStorage.getItem('minusFromTimer'))
        minutesTimer = Number.parseInt(oneWayBookingMinutesTimer)
        if (value > 0) {
          setTimeout(() => {
            this.secondsTimer--;
          }, 1000);
        } else if (value === 0 && minutesTimer > 0) {
          if(!minusFromTimer){
            sessionStorage.setItem('minusFromTimer',JSON.stringify( true))
          }
          else {
            minutesTimer--
          }
          sessionStorage.setItem('oneWayBookingMinutesTimer',JSON.stringify(minutesTimer))
          await this.$store.dispatch('booking/setOneWayBookingMinutesTimer', minutesTimer)
          this.secondsTimer = 60

        } else if (  value === 0 && minutesTimer === 0 && this.oneWayBookingShowTimer) {
          let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))
          if (userSelectedTrip && Object.keys(userSelectedTrip).length) {
            if (userSelectedTrip.transportCompanyId) {
              let data = {}
              data.id = userSelectedTrip.id
              data.tripId = userSelectedTrip.id
              await getTripByTripId(data).then(res => {
                this.$store.dispatch("booking/setUserSelectedTrip", res.data)
              })
            }
            else if (userSelectedTrip.waterTransportOperatorId){
              let data = {}
              data.tripId = userSelectedTrip.id
              data.waterTransportOperatorId = userSelectedTrip.waterTransportOperatorId
              await getTripByIdAndWaterTransportOperatorId(data).then(res => {
                this.$store.dispatch("booking/setUserSelectedTrip", res.data)
              })
            }
          }
          let passengerDetail = JSON.parse(sessionStorage.getItem('passengerDetail'))
          if (passengerDetail){
            passengerDetail.seats = []
            sessionStorage.setItem('tripDetails', JSON.stringify(passengerDetail))
            await this.$store.dispatch("booking/updateCurrentSeats", [])

            if (this.$route.name !== 'OneWaySelectSeats'){
              await this.$router.push({name: 'OneWaySelectSeats'})
            }
            if (this.oneWayBookingShowTimer) {
              await this.$store.dispatch('booking/setOneWayBookingMinutesTimer', minutesTimer)
              await this.$store.dispatch('booking/setOneWayBookingShowTimer', false)
              sessionStorage.removeItem('oneWayBookingMinutesTimer')
              sessionStorage.removeItem('oneWayBookingShowTimer')
              sessionStorage.removeItem("currentSelectedSeats")
              sessionStorage.removeItem("passengerDetail")
              sessionStorage.removeItem("tripDetails")
              window.location.reload()
            }
          }
        }

      },
      immediate: true,
      deep:true
    }

  },
  created() {
     let oneWayBookingMinutesTimer = sessionStorage.getItem('oneWayBookingMinutesTimer')
     let oneWayBookingShowTimer =  sessionStorage.getItem('oneWayBookingShowTimer')
    if (oneWayBookingMinutesTimer){
      this.$store.dispatch('booking/setOneWayBookingMinutesTimer',JSON.parse(oneWayBookingMinutesTimer))
    }
    if (oneWayBookingShowTimer){
      this.$store.dispatch('booking/setOneWayBookingShowTimer',JSON.parse(oneWayBookingShowTimer))
    }
  }
}
</script>

<style scoped lang="scss">
.count{
  font-family: var(--fontInter);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #D3766A;
}
</style>