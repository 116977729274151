<script>
import NavBar from "@/home/Navbar.vue"
import Icons from "@/components/reuseables/Icons.vue";
import Footer from "@/home/landingpage/Footer.vue";
import DemoButton from "@/components/reuseables/DemoButton.vue";
import GetStartedBtn from "@/components/reuseables/GetStartedBtn.vue";

export default {
  name: "WaveJotter",
  components: {GetStartedBtn, DemoButton, Footer, Icons, NavBar}
}
</script>

<template>
  <div class="tw-w-full justify-center align-center column main-body">
    <NavBar/>

    <div  class="first-sec tw-flex lg:tw-px-50">
      <div class=" first-sec-left tw-px-5">
        <div class="hero-heading-left">
          <p style="color: #263238">Experience Waterway<br>
            Logistics Management with
            <span style="color: #004aad"><br>
                WAVE-JOTTER
            </span>
          </p>
        </div>
        <div class="first-sec-paragraph">
          <p>Optimize route planning, reduce operational expenses, and elevate overall performance by maximizing your waterway-business efficiency and
            transform your transportation business into a compelling and convenient choice for urban commuters<br v-if="$vuetify.breakpoint.lgOnly"> operation experience</p>
        </div>

        <div class="btn tw-flex tw-mt-10">
          <DemoButton/>
          <GetStartedBtn button-text="Get Started"/>
        </div>
      </div>

      <div class="first-sec-img tw-px-0 lg:tw-px-50">
        <img src="https://res.cloudinary.com/myroadpadi/image/upload/v1711238284/assets/frontend/skip_xg4eh2.png" class="img-sec1">
      </div>
    </div>

    <div class="third-sec tw-mt-36 tw-px-5 lg:tw-px-40">
      <div class="small-txt tw-mb-3" style="color: #2B10C5; text-align: center"><p>Features</p></div>
      <div class="bold-txt tw-mb-5"><p style="text-align: center; color: #263238">Setting Sail Made Simple</p></div>
      <p class="medium-text" style="font-size: 18px; text-align:center; color:#263238">Streaming Waterway Operations Feature by Feature</p>

      <div class="tw-flex tw-mt-20  third-sec-img-container">
        <img src="@/assets/boat-img.png" class="third-sec-img-1 tw-mr-10" alt="" style="">
        <img src="@/assets/cards-img.png" alt="" class="third-sec-img-2 tw-ml-10" >
      </div>
    </div>

    <div class="fourth-sec tw-px-5 lg:tw-px-40">
      <div class="fourth-sec-left" >
        <p class="bold-txt" style="font-size: 40px; color: #263238;">Comprehensive inventory<br>Control</p>

        <div class="tw-flex tw-mb-5 tw-mt-8">
          <div><icons name="calendar-icon"/></div>
          <p class="medium-text" style="font-size: 16px; color: #263238">Instant updates on the status of vessels and routes.</p>
        </div>

        <div class="tw-flex tw-mb-5">
          <div><icons name="boat-icon"/></div>
          <p class="medium-text" style="font-size: 16px; color: #263238">Adapting to specific vessel and route requirements.</p>
        </div>

        <div class="tw-flex tw-mb-5">
          <icons name="dashboard-icon"/>
          <p class="medium-text" style="font-size: 16px; color: #263238">Unified and simplified dashboard.</p>
        </div>


      </div>
      <div class="fourth-sec-right tw-pt-20" >
        <img src="@/assets/profiles-img.png">
      </div>

    </div>

    <div class="fifth-sec tw-w-full tw-px-5 lg:tw-px-40 ">
      <div class="fifth-sec-left">
        <img src="@/assets/diagram.png">
      </div>

      <div class="fifth-sec-right" >
        <p class="bold-txt" style="font-size: 40px; color: #263238;">Activity Analytics Dashboard</p>

        <div class="tw-flex tw-mt-8">
          <div><icons name="cards"/></div>
          <p class="medium-text" style="font-size: 16px; color: #263238">
            Gain valuable analytics into sales and operational performance for<br> waterway trips.</p>
        </div>

        <div class="tw-flex tw-mb-5">
          <div><icons name="arrow-icon"/></div>
          <p class="medium-text" style="font-size: 16px; color: #263238">Make informed decisions based on comprehensive reports, optimizing,<br> waterway resources.</p>
        </div>

        <div class="tw-flex tw-mb-5">
          <icons name="location-icon"/>
          <p class="medium-text" style="font-size: 16px; color: #263238">Access detailed reports on waterway trips whenever needed for insights<br>
            into performance.</p>
        </div>
      </div>
    </div>


    <div class="padding">
      <div class="sixth-sec tw-px-5 lg:tw-px-40">

        <div class="sixth-sec-left">
          <p class="bold-txt" id="sec6-bold-txt" style="font-size: 40px; color: #263238"> Ready to Transform Your<br> Transportation Operations ?</p>
          <div class="medium-text tw-mt-8" style="color: #2D3348"><p>Experience the power of Wave-Jotter and elevate your transport<br> management. </p></div>

          <div class="btn tw-flex">
            <DemoButton/>
            <GetStartedBtn/>
          </div>

        </div>

        <div class="sixth-sec-right tw-ml-20">
          <img src="@/assets/all-trips.png">
        </div>
      </div>
    </div>

    <div class="seventh-sec tw-mt-32">
      <Footer/>
    </div>



  </div>
</template>

<style scoped lang="scss">
.main-body{
  @media screen and (max-width: 375px){
    display: flex;
    flex-wrap: wrap;
  }

}
.first-sec{
  background-color: #F1EFFF;
  background-image: url("../../../assets/skip-background-img.svg");
  min-height: 100vh;
  object-fit: fill;
  padding-top: 120px;
  align-items: center;
  //justify-content: center;
  //object-fit: cover;

  @media screen and (width: 1920px){
    //display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0;
    padding-left: 8.5rem;
    object-fit: fill;
  }
  @media screen and (width: 1366px){
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-left: 9rem !important;
    object-fit: fill;
    min-height: 100vh;
  }
  @media screen and (width: 768px){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  @media screen and (width: 430px){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0;
    min-height: 50vh;
  }
  @media screen and (width: 428px){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0;
    min-height: 50vh;
  }
  @media screen and (width: 414px){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0;
    min-height: 50vh;
  }
  @media screen and (width: 390px){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0;
    min-height: 50vh;
  }
  @media screen and (width: 375px){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0;
    min-height: 50vh;
  }
  @media screen and (width: 360px){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0;
    min-height: 50vh;
  }
  @media screen and (width: 344px){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0;
    min-height: 50vh;
  }

  @media screen and (width: 576px){
    padding-top: 100px;
  }
}
.first-sec-left{
  width: 50%;
  //margin-bottom: 21%;
  @media screen and (max-width: 1920px){
    //padding-top: 2rem;
    //padding-left: 2rem;
  }
  @media screen and (max-width: 1366px){
    width: 50%;

    //padding-left: 2rem;
  }

  @media (max-width: 576px) {
    margin-bottom: 0;
    width: 100%;
  }
}
.hero-heading-left{
  width: 80%;
  font-size: 54px;
  font-weight: 750;
  line-height: 70px;
  padding-bottom: 3rem;

  @media screen and (min-width: 1300px) {
    font-size: 40px;
    font-weight: 750;
    line-height: 50px;
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 1.5rem;
  }

  @media screen and (max-width: 430px) {
    font-size: 42px;
    font-weight: 750;
    line-height: 60px;
    width: 100%;
    padding-bottom: 5px;
  }
  @media screen and (max-width: 428px) {
    font-size: 42px;
    font-weight: 750;
    line-height: 60px;
    width: 100%;
    padding-bottom: 5px;
  }
  @media screen and (width: 414px) {
    font-size: 42px;
    font-weight: 750;
    line-height: 60px;
    width: 100%;
    padding-bottom: 5px;
  }
  @media screen and (width: 412px) {
    font-size: 42px;
    font-weight: 750;
    line-height: 60px;
    width: 100%;
    padding-bottom: 5px;
  }
  @media screen and (width: 390px) {
    font-size: 36px;
    font-weight: 750;
    line-height: 45px;
    width: 100%;
    padding-bottom: 5px;
  }
  @media screen and (width: 375px) {
    font-size: 36px;
    font-weight: 750;
    line-height: 45px;
    width: 100%;
    padding-bottom: 5px;
  }
}

.first-sec-img{
  width: 50%;
  @media screen and (min-width: 1920px){
    padding-top: 2rem;
    width: 50%;
  }
  @media screen and (width: 1366px){
    width: 40%;
  }
  @media screen and (width: 430px){
    margin-top: 40px;
    width: 100%;
    height: auto;
  }
  @media screen and (width: 428px){
    margin-top: 40px;
    width: 100%;
    height: auto;
  }
  @media screen and (width: 414px){
    margin-top: 40px;
    width: 100%;
    height: auto;
  }
  @media screen and (width: 412px){
    margin-top: 40px;
    width: 100%;
    height: auto;
  }
  @media screen and (width: 390px){
    margin-top: 40px;
    width: 100%;
    height: auto;
  }
  @media screen and (width: 375px){
    margin-top: 40px;
    width: 100%;
    height: auto;
  }
  @media screen and (width: 360px){
    margin-top: 40px;
    width: 100%;
    height: auto;
  }
}
.first-sec-paragraph{
  @media screen and (min-width: 1920px){
    width: 80%;
    font-size: 18px;
    //padding-bottom: 1rem;
    text-align: justify;
  }
  @media screen and (max-width: 1366px){
    width: 100%;
    font-size: 14px;
    text-align: justify;
  }
  @media screen and (max-width: 768px){
    margin-top: 30px;
  }
  @media (max-width: 576px) {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #2D3348;
  }
  @media screen and (width: 430px){
    text-align: justify;
    font-size: 15px;
  }
  @media screen and (width: 428px){
    text-align: justify;
    font-size: 15px;
  }
  @media screen and (width: 414px){
    text-align: justify;
    font-size: 15px;
  }
  @media screen and (width: 412px){
    text-align: justify;
    font-size: 15px;
  }
  @media screen and (width: 390px){
    text-align: justify;
    font-size: 15px;
  }
  @media screen and (width: 375px){
    text-align: justify;
    font-size: 15px;
  }
  @media screen and (width: 360px){
    text-align: justify;
    font-size: 15px;
  }
  @media screen and (width: 344px){
    text-align: justify;
    font-size: 15px;
  }
}

.img-sec1{
  height: 90vh;
  width: 100%;
  @media screen and (min-width: 1920px) {
    width:100vh;
    padding-left: 2rem;
    padding-top: 6rem;
    //height: max-content;
  }
  @media screen and (max-width: 768px) {
    object-fit: fill;
    //height: max-content;
  }
  @media screen and (width: 430px) {
    height:45vh;
    padding-left: 1.3rem;
    //height: max-content;
  }
  @media screen and (width: 428px) {
    height:45vh;
    padding-left: 1rem;
  }
  @media screen and (width: 414px) {
    height:45vh;
    padding-left: 1.3rem;
  }
  @media screen and (width: 390px) {
    height:45vh;
    padding-left: 1.3rem;
  }
  @media screen and (width: 375px) {
    height:45vh;
    padding-left: 1.3rem;
  }
  @media screen and (width: 360px) {
    height:45vh;
    padding-left: 1.3rem;
  }
  @media screen and (width: 344px) {
    height:45vh;
    padding-left: 1.3rem;
  }
}
.bold-txt{
  font-family: Raleway,sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  @media screen and (max-width: 1366px){

  }
  @media screen and (max-width: 576px){
    font-size: 36px;
    line-height: 48px;
  }
}
.third-sec{
  @media screen and (width: 428px){
    padding-left: 1.3rem;
    padding-right: 1.3rem ;
  }
}
.third-sec-img-container{
  @media screen and (max-width: 768px){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  @media screen and (max-width: 576px){
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  @media screen and (width: 428px){
    display: flex;
    //flex-wrap: wrap;
    width: 100%;
  }
}
.third-sec-img-1{
  @media screen and (max-width: 1920px){
    width: 100%;
  }
  @media screen and (max-width: 768px){
    margin-bottom: 50px;
  }
  @media screen and (max-width: 576px){
    width: 100%;
    margin-bottom: 50px;
  }
}
.third-sec-img-2{
  @media screen and (max-width: 1920px){
    width: 100%;
  }
}

.medium-text{
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

  @media screen and (width: 428px){
    line-height: 24px;
    width: 100%;
    flex-wrap: wrap;
    padding-left: 1rem;
  }
}
.how-wj-works{
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.fourth-sec{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin-top: 30px;


  @media screen and (max-width: 768px){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  @media screen and (max-width: 576px){
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  @media screen and (width: 428px){
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 4rem;
  }
}
.fourth-sec-left{
  margin-right: 5%;

  @media screen and (width: 428px){
    line-height: 10px;
    width: 100%;
    flex-wrap: wrap;
  }
}

.fourth-sec-right{

  @media screen and (max-width: 768px){
    display: flex;
    justify-content: left;
    margin: 50px 0 0;
  }
  @media screen and (max-width: 576px){
    margin: 0;
    width: 100%;
  }
}

.fifth-sec{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin-top: 100px;


  @media screen and (max-width: 768px){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  @media screen and (max-width: 576px){
    display: flex;
    flex-wrap: wrap;
    //margin-left: 30px;
    //justify-content: left;
    //padding: 20px;
    flex-direction: column-reverse;
  }
}
.fifth-sec-left{
  margin-right: 5%;
}

.sixth-sec{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin-top: 250px;
  border-radius: 24px;
  background-color: #f5f3fe;
  background-image: url("../../../assets/skip-background-img.svg");
  height: fit-content;
  padding: 50px;

  @media screen and (max-width: 576px){
    display: flex;
    flex-wrap: wrap;
    //justify-content: left;
    border-radius: 0;
    margin-top: 100px;
  }
}
.sixth-sec-left{
  @media screen and (max-width: 576px){
    margin-bottom: 20px;

  }
}
#sec6-bold-txt{
  @media screen and (max-width: 576px){
    font-family: Raleway;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    white-space: inherit;
    width: 335px
  }
}
.padding{
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 576px) {
    justify-content: left;
  }
}
.sixth-sec-right{
  @media (max-width: 576px) {
    margin: 0;
  }
}

</style>