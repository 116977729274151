<template>
    <v-dialog
        v-model="dialog"
        max-width="80%" >
      <v-icon  v-if="$vuetify.breakpoint.mdAndUp" class="exit" @click="closeModal">mdi-close</v-icon>
      <Terms/>
      <TermsMobile  />

    </v-dialog>
</template>


<script>
import Terms from './Terms.vue'
import TermsMobile from './TermsMobile.vue'
export default {
  name: "TermsModal",
  components:{
    Terms, TermsMobile
  },
  data(){
    return{
      dialog: true
    }
  },
  methods:{
    closeModal(){
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog{
  overflow-x: hidden !important;
  overflow-y: hidden;
  padding: 20px !important;
}
::v-deep .v-dialog::-webkit-scrollbar {
  background: gray !important;
  width: 5px;
  height: 8px;
}
::v-deep .v-dialog::-webkit-scrollbar-thumb {
  background: transparent !important;
  max-height: 20px !important;
  border-radius: 6px;
}
.mobile{
  display: none;
  padding: 0;
  @media (max-width:1264px) {
    display: block;
  }}
.hide{
  display: flex;
  @media (max-width:1264px) {
    display: none;
  }}
.exit{
  color: #004aad;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 10%;
  width: 30px;
}


</style>
