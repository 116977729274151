<template>
  <div id="departureOneWayTrips">
    <div class="buses" v-if="oneWayData.tripType === 'Road Trip'">
      <div class="text-design">
        <span class="departure">Departure: Available Buses</span>
        <!--      <div class="display-center "> <span class="sort"> Sort by : </span>-->
        <!--        <v-select :items="items" label="select" class="sort" flat hide-details solo id="claim"></v-select>-->
        <!--      </div>-->
      </div>
      <!-- desktop -->
      <v-expansion-panels class="desktop" flat v-model="panel">
        <v-expansion-panel v-for="(trip, index) in oneWayTrips" :key="index">
          <v-expansion-panel-header>
            <v-row class="tw-items-center ">
              <v-col
                lg="2"
                sm="6"
                md="2"
                class="tw-flex tw-w-full tw-items-center tw-flex-row"
              >
                <img
                  :src="trip.transportCompanyLogo"
                  alt=""
                  class="company-logo"
                />
                <p class="company-name text-capitalize ml-7 mt-3">
                  {{ getFirstText(trip.transportCompanyName) }}
                </p>
              </v-col>
              <v-col lg="1" sm="6" md="1" class=" tw-w-full tw-items-center">
                <p class="route text-uppercase ">
                  {{ trip.routeDepartureCity.substring(0, 3) }}-{{
                    trip.routeDestinationCity.substring(0, 3)
                  }}
                </p>
              </v-col>
              <v-col lg="2" sm="6" md="2" class=" tw-w-full tw-items-center">
                <p class="departure-time">
                  {{ getDateTime(trip.takeOffDate, trip.takeOffTime) }}
                </p>
              </v-col>
              <v-col
                lg="2"
                sm="6"
                md="2"
                class=" tw-w-full tw-items-center tw-justify-center"
              >
                <p class="seats-available">
                  {{ getAvailableSeats(trip.fleetForThisTrip.seats) }} Seats
                  Available
                </p>
              </v-col>
              <v-col
                lg="1"
                sm="6"
                md="1"
                class=" tw-w-full tw-items-center"
                v-if="!trip.tripRegion || trip.tripRegion === 'local'"
              >
                <p class="amount">₦{{ formatNumber(trip.tripAmount) }}</p>

              </v-col>
              <v-col
                lg="1"
                sm="6"
                md="1"
                class=" tw-w-full tw-items-center"
                v-else
              >
                <p class="amount">Cross Border</p>
              </v-col>
              <v-col
                lg="3"
                sm="6"
                md="3"
                class="tw-flex tw-w-full tw-items-end md:tw-justify-center tw-flex-wrap button-handler"
              >
                <v-btn
                    style="width: 100%"
                  :style="{
                    backgroundColor:
                      getBookingStatus(
                        trip.fleetForThisTrip.seats,
                        trip.status
                      ) !== 'Book Now'
                        ? 'white'
                        : ''
                  }"
                  class="book cta"
                  text
                  @click="handleSelectedTrip(trip)"
                  :disabled="
                    getBookingStatus(
                      trip.fleetForThisTrip.seats,
                      trip.status
                    ) !== 'Book Now'
                  "
                  >{{
                    getBookingStatus(trip.fleetForThisTrip.seats, trip.status)
                  }}<span v-if="trip.discount && trip.discount.type === 'PERCENTAGE'">&nbsp;+ {{trip.discount.unitAmount}}% discount</span>
                  <span v-if="trip.discount && trip.discount.type === 'FIXED'">&nbsp;+ ₦{{trip.discount.unitAmount}} discount</span></v-btn
                >
                <!--              <v-btn class="schedule cta" text> Schedule Trip</v-btn>-->
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="dropdown tw-flex">
              <div style="width:30px" class="group">
                <img src="../../assets/from-to.svg" alt="" />
              </div>
              <div class="main-left">
                <div class="first-line ">
                  <div class="left tw-flex">
                    <p class=" show text-capitalize">
                      {{ getFirstText(trip.routeDepartureState) }} ({{
                        toSentenceCase(trip.routeDepartureCity)
                      }}
                      - {{ toSentenceCase(trip.routeDepartureTerminalName) }})
                    </p>
                    <p class=" hide text-capitalize">
                      {{ trip.routeDepartureCity.substring(0, 5) }}
                    </p>
                  </div>
                  <div
                    class="right"
                    v-if="!trip.tripRegion || trip.tripRegion === 'local'"
                  >
                    <div class="tw-flex tw-items-center">
                      <div class="amount tw-hidden md:tw-flex">
                      ₦{{ formatNumber(trip.tripAmount) }}
                        <span class="small-brown-text" v-if="trip.discount && trip.discount.type === 'FIXED'">&nbsp; plus ₦{{formatNumber(trip.discount.unitAmount)}}  </span>
                        <span class="small-brown-text" v-if="trip.discount && trip.discount.type === 'PERCENTAGE'">&nbsp; plus {{trip.discount.unitAmount}}% discount</span>
                        <span class="small-brown-text" v-if="trip.discount && trip.discount.additionalIncentive">&nbsp; with {{trip.discount.additionalIncentive}}
                          of  ₦{{formatNumber(trip.discount.additionalIncentiveUnitAmount)}}</span>
                    </div>

                    </div>


                  </div>
                </div>
                <div class="second-line my-4">
                  <div
                    class="left tw-flex "
                    :class="[
                      trip.tripRegion === 'crossCountry' ? 'tw-mt-5' : ''
                    ]"
                  >
                    <span class="time-etimate "
                      >( Stops ) {{ trip.tripStops }}</span
                    >
                  </div>
                  <div class="right">
                    <span class="available-seats  tw-hidden md:tw-flex">
                      Available Seats :
                      {{ getAvailableSeats(trip.fleetForThisTrip.seats) }}
                    </span>
                  </div>
                </div>
                <div class="third-line mt-7 tw-flex">
                  <span class=" show text-capitalize "
                    >{{ getFirstText(trip.routeDestinationState) }} ({{
                      toSentenceCase(trip.routeDestinationCity)
                    }}
                    -
                    {{
                      toSentenceCase(trip.routeDestinationTerminalName)
                    }})</span
                  >
                  <span class=" hide text-capitalize ">{{
                    trip.routeDestinationCity.substring(0, 5)
                  }}</span>
                </div>
                <span
                  class="available-seats second-as  tw-flex md:tw-hidden my-5"
                >
                  Available Seats :
                  {{ getAvailableSeats(trip.fleetForThisTrip.seats) }}
                </span>
                <div class="right" v-if="trip.tripRegion === 'crossCountry'">
                  <v-radio-group
                    class="tw-hidden md:tw-flex tw-flex tw-items-center"
                    row
                    v-model="trip.selectedTripCategory"
                  >
                    <v-radio
                      v-for="(tripCategory, index) in trip.tripCategories"
                      :key="index"
                      color="#004AAD"
                      :value="tripCategory"
                    >
                      <template #label>
                        <label class="trip-category text-capitalize"
                          >{{ tripCategory.type }} &#8358;
                          {{ tripCategory.tripAmount }}
                          <span class="small-brown-text" v-if="trip.discount && trip.discount.type === 'FIXED'">&nbsp; plus ₦{{formatNumber(trip.discount.unitAmount)}}  </span>
                          <span class="small-brown-text" v-if="trip.discount && trip.discount.type === 'PERCENTAGE'">&nbsp; plus {{trip.discount.unitAmount}}% discount</span>
                          <span class="small-brown-text" v-if="trip.discount && trip.discount.additionalIncentive">&nbsp; with {{trip.discount.additionalIncentive}}
                          of  ₦{{formatNumber(trip.discount.additionalIncentiveUnitAmount)}}</span></label
                        >
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
                <div class="footer my-5 ">
                  <span class="company-name-footer">
                    {{ trip.transportCompanyName }}</span
                  >
                  <span class="mx-2 dot">.</span>
                  <span class="bus-type">
                    {{ trip.fleetForThisTrip.vehicleType }}</span
                  >
                  <span class="mx-2 dot">.</span>
                  <span class="bus-class">
                    {{ trip.fleetForThisTrip.vehicleClass }}</span
                  >
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
          <v-divider inset></v-divider>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- //mobile -->
      <v-expansion-panels class="mobile" v-model="panel">
        <v-expansion-panel
          v-for="(trip, index) in oneWayTrips"
          :key="index"
          flat
        >
          <v-expansion-panel-header>
            <div class="tw-flex">
              <div class="up-left">
                <img
                  :src="trip.transportCompanyLogo"
                  alt=""
                  class="company-logo"
                />
              </div>
              <div class="up-right ">
                <v-row class="ml-1 justify-between">
                  <v-col sm="6" class=" tw-items-center ">
                    <p class="company-name text-capitalize mt-3">
                      {{ getFirstText(trip.transportCompanyName) }}
                    </p>
                  </v-col>
                  <v-col
                    lg="2"
                    sm="6"
                    md="2"
                    class=" tw-w-full tw-items-center text-uppercase "
                  >
                    <p class="route">
                      {{ trip.routeDepartureCity.substring(0, 3) }}-{{
                        trip.routeDestinationCity.substring(0, 3)
                      }}
                    </p>
                  </v-col>
                </v-row>
                <v-row class="ml-1">
                  <v-col sm="6" class="tw-items-center">
                    <p class="departure-time">
                      {{ getDateTime(trip.takeOffDate, trip.takeOffTime) }}
                    </p>
                  </v-col>
                  <v-col
                    sm="6"
                    class="tw-items-center"
                    v-if="!trip.tripRegion || trip.tripRegion === 'local'"
                  >
                    <p class="amount">₦{{ formatNumber(trip.tripAmount) }}</p>
                  </v-col>
                  <v-col sm="6" class="tw-items-center" v-else>
                    <p class="amount">Cross Border</p>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="dropdown tw-flex">
              <div style="width:30px" class="group">
                <img src="../../assets/from-to.svg" alt="" />
              </div>
              <div class="main-left">
                <div class="first-line ">
                  <div class="left tw-flex">
                    <p class=" show  text-capitalize ">
                      {{ getFirstText(trip.routeDepartureState) }} ({{
                        toSentenceCase(trip.routeDepartureCity)
                      }}
                      - {{ toSentenceCase(trip.routeDepartureTerminalName) }})
                    </p>
                    <p class=" hide text-capitalize">
                      {{ getFirstText(trip.routeDepartureState) }} ({{
                        toSentenceCase(trip.routeDepartureTerminalName)
                      }})
                    </p>
                  </div>
                  <div class="right">
                    <span class="amount tw-hidden md:tw-flex">
                      ₦{{ formatNumber(trip.tripAmount) }}

                    </span>

                  </div>
                </div>
                <div class="second-line my-4">
                  <div class="left tw-flex  tw-flex-col" :class="[trip.discount ? 'tw-mt-1' : 'tw-mt-5']">
                    <span class="time-etimate"
                      >( Stops ) {{ trip.tripStops }}</span
                    >
                    <span class="small-brown-text tw-mt-3" v-if="trip.discount && trip.discount.type === 'FIXED'">&nbsp; ₦{{formatNumber(trip.discount.unitAmount)}}  </span>
                    <span class="small-brown-text tw-mt-3" v-if="trip.discount && trip.discount.type === 'PERCENTAGE'">&nbsp;Plus {{trip.discount.unitAmount}}% discount</span>
                    <span class="small-brown-text" v-if="trip.discount && trip.discount.additionalIncentive">&nbsp; with {{trip.discount.additionalIncentive}}
                          of  ₦{{formatNumber(trip.discount.additionalIncentiveUnitAmount)}}</span>
                  </div>
                  <div class="right">
                    <span class="available-seats  tw-hidden md:tw-flex">
                      Available Seats :
                      {{ getAvailableSeats(trip.fleetForThisTrip.seats) }}
                    </span>
                  </div>
                </div>
                <div class="third-line mt-7 tw-flex">
                  <span class="show text-capitalize "
                    >{{ getFirstText(trip.routeDestinationState) }} ({{
                      toSentenceCase(trip.routeDestinationCity)
                    }}
                    -
                    {{
                      toSentenceCase(trip.routeDestinationTerminalName)
                    }})</span
                  >
                  <span class="hide text-capitalize "
                    >{{ getFirstText(trip.routeDestinationState) }} ({{
                      toSentenceCase(trip.routeDestinationTerminalName)
                    }})</span
                  >
                </div>
                <div
                  class="tw-flex tw-flex-col tw-w-full tw-my-0 tw-justify-between tw-items-start"
                  v-if="trip.tripRegion === 'crossCountry'"
                >
                  <div class="tw-flex tw-flex-col tw-justify-between tw-w-full">
                    <div
                      class="right"
                      v-if="trip.tripRegion === 'crossCountry'"
                    >
                      <v-radio-group
                        class="tw-flex tw-items-start"
                        v-model="trip.selectedTripCategory"
                        hide-details
                      >
                        <v-radio
                          v-for="(tripCategory, index) in trip.tripCategories"
                          :key="index"
                          color="#004AAD"
                          :value="tripCategory"
                        >
                          <template #label>
                            <div
                              class="trip-category text-capitalize tw-flex tw-flex-row"
                            >
                              <div class="tw-w-7/12">
                                {{ tripCategory.type }}
                              </div>
                              <div class="tw-w-5/12">
                                &#8358;
                                {{ tripCategory.tripAmount }}
                                <span class="small-brown-text" v-if="trip.discount && trip.discount.type === 'FIXED'">&nbsp; plus ₦{{formatNumber(trip.discount.unitAmount)}}  </span>
                                <span class="small-brown-text" v-if="trip.discount && trip.discount.type === 'PERCENTAGE'">&nbsp; plus {{trip.discount.unitAmount}}% discount</span>
                                <span class="small-brown-text" v-if="trip.discount && trip.discount.additionalIncentive">&nbsp; with {{trip.discount.additionalIncentive}}
                                   of  ₦{{formatNumber(trip.discount.additionalIncentiveUnitAmount)}}</span>
                              </div>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                </div>
                <span
                  class="available-seats second-as  tw-flex md:tw-hidden my-5"
                  :class="[
                    trip.tripRegion === 'crossCountry' ? 'tw-mb-10' : 'tw-my-10'
                  ]"
                >
                  Available Seats :
                  {{ getAvailableSeats(trip.fleetForThisTrip.seats) }}
                </span>
                <div class="footer my-5 ">
                  <span class="company-name-footer">
                    {{ trip.transportCompanyName }}</span
                  >
                  <span class="mx-2 dot">.</span>
                  <span class="bus-type">
                    {{ trip.fleetForThisTrip.vehicleType }}</span
                  >
                  <span class="mx-2 dot">.</span>
                  <span class="bus-class">
                    {{ trip.fleetForThisTrip.vehicleClass }}</span
                  >
                </div>
              </div>
            </div>
            <div class="tw-flex tw-items-end  tw-flex-wrap button-handler">
              <v-btn
                :style="{ backgroundColor: '#004AAD' }"
                class="book cta"
                @click="handleSelectedTrip(trip)"
                >{{
                  getBookingStatus(trip.fleetForThisTrip.seats, trip.status)
                }}<span v-if="trip.discount && trip.discount.type === 'PERCENTAGE'">&nbsp;plus {{trip.discount.unitAmount}}% discount</span>
                <span v-if="trip.discount && trip.discount.type === 'FIXED'">&nbsp;plus ₦{{trip.discount.unitAmount}} discount</span></v-btn
              >
              <!--            <v-btn class="schedule cta" text> Schedule Trip</v-btn>-->
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="tw-flex tw-w-full tw-justify-end tw-my-3">
        <v-btn
          :style="{ backgroundColor: '#004AAD' }"
          class="load-more"
          text
          v-if="getQueryData"
          @click="loadMoreTrip"
          :loading="moreLoading"
          >More</v-btn
        >
      </div>
    </div>
    <!--  Water Trip-->
    <div class="buses" v-if="oneWayData.tripType === 'Water Trip'">
      <div class="text-design">
        <span class="departure">Departure: Available Vessel</span>
        <!--      <div class="display-center "> <span class="sort"> Sort by : </span>-->
        <!--        <v-select :items="items" label="select" class="sort" flat hide-details solo id="claim"></v-select>-->
        <!--      </div>-->
      </div>
      <!-- desktop -->
      <v-expansion-panels class="desktop" flat v-model="panel">
        <v-expansion-panel v-for="(trip, index) in oneWayTrips" :key="index">
          <v-expansion-panel-header>
            <v-row class="tw-items-center ">
              <v-col
                lg="3"
                sm="6"
                md="3"
                class="tw-flex tw-w-full tw-items-center tw-flex-row"
              >
                <img
                  :src="trip.waterTransportOperatorLogo"
                  alt=""
                  class="company-logo"
                />
                <p class="company-name text-capitalize ml-7 mt-3">
                  {{ getFirstText(trip.waterTransportOperatorName) }}
                </p>
              </v-col>
              <v-col lg="2" sm="6" md="2" class=" tw-w-full tw-items-center">
                <p class="route text-uppercase ">
                  {{ trip.routeDepartureCity.substring(0, 3) }}-{{
                    trip.routeDestinationCity.substring(0, 3)
                  }}
                </p>
              </v-col>
              <v-col lg="2" sm="6" md="2" class=" tw-w-full tw-items-center">
                <p class="departure-time">
                  {{ getDateTime(trip.takeOffDate, trip.takeOffTime) }}
                </p>
              </v-col>
              <v-col
                lg="2"
                sm="6"
                md="2"
                class=" tw-w-full tw-items-center tw-justify-center"
              >
                <p class="seats-available">
                  {{ getAvailableSeats(trip.tripVessel.seats) }} Seats
                  Available
                </p>
              </v-col>
              <v-col
                lg="1"
                sm="6"
                md="1"
                class=" tw-w-full tw-items-center"
                v-if="!trip.tripRegion || trip.tripRegion === 'local'"
              >
                <p class="amount">₦{{ formatNumber(trip.tripAmount) }}</p>
              </v-col>
              <v-col
                lg="1"
                sm="6"
                md="1"
                class=" tw-w-full tw-items-center"
                v-else
              >
                <p class="amount">Cross Border</p>
              </v-col>
              <v-col
                lg="2"
                sm="6"
                md="2"
                class="tw-flex tw-w-full tw-items-end md:tw-justify-center tw-flex-wrap button-handler"
              >
                <v-btn
                  :style="{
                    backgroundColor:
                      getBookingStatus(
                        trip.tripVessel.seats,
                        trip.status
                      ) !== 'Book Now'
                        ? 'white'
                        : ''
                  }"
                  class="book cta"
                  text
                  @click="handleSelectedTrip(trip)"
                  :disabled="
                    getBookingStatus(
                      trip.tripVessel.seats,
                      trip.status
                    ) !== 'Book Now'
                  "
                  >{{
                    getBookingStatus(trip.tripVessel.seats, trip.status)
                  }}
                  <span v-if="trip.discount && trip.discount.type === 'PERCENTAGE'">&nbsp;plus {{trip.discount.unitAmount}}% discount</span>
                  <span v-if="trip.discount && trip.discount.type === 'FIXED'">&nbsp;plus ₦{{trip.discount.unitAmount}} discount</span>
                </v-btn
                >
                <!--              <v-btn class="schedule cta" text> Schedule Trip</v-btn>-->
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="dropdown tw-flex">
              <div style="width:30px" class="group">
                <img src="../../assets/from-to.svg" alt="" />
              </div>
              <div class="main-left">
                <div class="first-line ">
                  <div class="left tw-flex">
                    <p class=" show text-capitalize">
                      {{ getFirstText(trip.routeDepartureState) }} ({{
                        toSentenceCase(trip.routeDepartureCity)
                      }}
                      - {{ toSentenceCase(trip.routeDepartureJettyName) }})
                    </p>
                    <p class=" hide text-capitalize">
                      {{ trip.routeDepartureCity.substring(0, 5) }}
                    </p>
                  </div>
                  <div
                    class="right"
                    v-if="!trip.tripRegion || trip.tripRegion === 'local'"
                  >
                    <span class="amount tw-hidden md:tw-flex">
                      ₦{{ formatNumber(trip.tripAmount) }}
                    </span>
                    <span class="small-brown-text" v-if="trip.discount && trip.discount.type === 'FIXED'">&nbsp; plus ₦{{formatNumber(trip.discount.unitAmount)}}  </span>
                    <span class="small-brown-text" v-if="trip.discount && trip.discount.type === 'PERCENTAGE'">&nbsp; plus {{trip.discount.unitAmount}}% discount</span>
                    <span class="small-brown-text" v-if="trip.discount && trip.discount.additionalIncentive">&nbsp; with {{trip.discount.additionalIncentive}}
                          of  ₦{{formatNumber(trip.discount.additionalIncentiveUnitAmount)}}</span>
                  </div>
                </div>
                <div class="second-line my-4">
                  <div
                    class="left tw-flex "
                    :class="[
                      trip.tripRegion === 'crossCountry' ? 'tw-mt-5' : ''
                    ]"
                  >
                    <span class="time-etimate tw-flex tw-items-center"
                      >( Stops ) {{ trip.tripStops }}
                    <span class="tw-pl-3 tw-flex tw-items-center" v-if="trip.lifeGuard"> Life Guard &nbsp; ₦{{formatNumber(trip.lifeGuardCharge)}}&nbsp; <v-icon color="green">mdi-check</v-icon> </span>
                    </span
                    >
                  </div>
                  <div class="right">
                    <span class="available-seats  tw-hidden md:tw-flex">
                      Available Seats :
                      {{ getAvailableSeats(trip.tripVessel.seats) }}
                    </span>
                  </div>
                </div>
                <div class="third-line mt-7 tw-flex">
                  <span class=" show text-capitalize "
                    >{{ getFirstText(trip.routeDestinationState) }} ({{
                      toSentenceCase(trip.routeDestinationCity)
                    }}
                    -
                    {{
                      toSentenceCase(trip.routeDestinationJettyName)
                    }})</span
                  >
                  <span class=" hide text-capitalize ">{{
                    trip.routeDestinationCity.substring(0, 5)
                  }}</span>
                </div>
                <span
                  class="available-seats second-as  tw-flex md:tw-hidden my-5"
                >
                  Available Seats :
                  {{ getAvailableSeats(trip.tripVessel.seats) }}
                </span>
                <div class="right" v-if="trip.tripRegion === 'crossCountry'">
                  <v-radio-group
                    class="tw-hidden md:tw-flex tw-flex tw-items-center"
                    row
                    v-model="trip.selectedTripCategory"
                  >
                    <v-radio
                      v-for="(tripCategory, index) in trip.tripCategories"
                      :key="index"
                      color="#004AAD"
                      :value="tripCategory"
                    >
                      <template #label>
                        <label class="trip-category text-capitalize"
                          >{{ tripCategory.type }} &#8358;
                          {{ tripCategory.tripAmount }}
                          <span class="small-brown-text" v-if="trip.discount && trip.discount.type === 'FIXED'">&nbsp; plus ₦{{formatNumber(trip.discount.unitAmount)}}  </span>
                          <span class="small-brown-text" v-if="trip.discount && trip.discount.type === 'PERCENTAGE'">&nbsp; plus {{trip.discount.unitAmount}}% discount</span>
                          <span class="small-brown-text" v-if="trip.discount && trip.discount.additionalIncentive">&nbsp; with {{trip.discount.additionalIncentive}}
                          of  ₦{{formatNumber(trip.discount.additionalIncentiveUnitAmount)}}</span></label
                        >
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
                <div class="footer my-5 ">
                  <span class="company-name-footer">
                    {{ trip.waterTransportOperatorName }}</span
                  >
                  <span class="mx-2 dot">.</span>
                  <span class="bus-type">
                    {{ trip.tripVessel.type }}</span
                  >
                  <span class="mx-2 dot">.</span>
                  <span class="bus-class">
                    {{ trip.tripVessel.vesselClass }}</span
                  >
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
          <v-divider inset></v-divider>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- //mobile -->
      <v-expansion-panels class="mobile" v-model="panel">
        <v-expansion-panel
          v-for="(trip, index) in oneWayTrips"
          :key="index"
          flat
        >
          <v-expansion-panel-header>
            <div class="tw-flex">
              <div class="up-left">
                <img
                  :src="trip.waterTransportOperatorLogo"
                  alt=""
                  class="company-logo"
                />
              </div>
              <div class="up-right ">
                <v-row class="ml-1 justify-between">
                  <v-col sm="6" class=" tw-items-center ">
                    <p class="company-name text-capitalize mt-3">
                      {{ getFirstText(trip.waterTransportOperator) }}
                    </p>
                  </v-col>
                  <v-col
                    lg="2"
                    sm="6"
                    md="2"
                    class=" tw-w-full tw-items-center text-uppercase "
                  >
                    <p class="route">
                      {{ trip.routeDepartureCity.substring(0, 3) }}-{{
                        trip.routeDestinationCity.substring(0, 3)
                      }}
                    </p>
                  </v-col>
                </v-row>
                <v-row class="ml-1">
                  <v-col sm="6" class="tw-items-center">
                    <p class="departure-time">
                      {{ getDateTime(trip.takeOffDate, trip.takeOffTime) }}
                    </p>
                  </v-col>
                  <v-col
                    sm="6"
                    class="tw-items-center"
                    v-if="!trip.tripRegion || trip.tripRegion === 'local'"
                  >
                    <p class="amount">₦{{ formatNumber(trip.tripAmount) }}</p>
                  </v-col>
                  <v-col sm="6" class="tw-items-center" v-else>
                    <p class="amount">Cross Border</p>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="dropdown tw-flex">
              <div style="width:30px" class="group">
                <img src="../../assets/from-to.svg" alt="" />
              </div>
              <div class="main-left">
                <div class="first-line ">
                  <div class="left tw-flex">
                    <p class=" show  text-capitalize ">
                      {{ getFirstText(trip.routeDepartureState) }} ({{
                        toSentenceCase(trip.routeDepartureCity)
                      }}
                      - {{ toSentenceCase(trip.routeDepartureJettyName) }})
                    </p>
                    <p class=" hide text-capitalize">
                      {{ getFirstText(trip.routeDepartureState) }} ({{
                        toSentenceCase(trip.routeDepartureJettyName)
                      }})
                    </p>
                  </div>
                  <div class="right">
                    <span class="amount tw-hidden md:tw-flex">
                      ₦{{ formatNumber(trip.tripAmount) }}
                    </span>
                  </div>
                </div>
                <div class="second-line my-4">
                  <div class="left tw-flex  tw-flex-col" :class="[trip.discount ? 'tw-mt-1' : 'tw-mt-5']">
                    <span class="time-etimate"
                    >( Stops ) {{ trip.tripStops }}
                    <span class="small-brown-text tw-mt-3" v-if="trip.discount && trip.discount.type === 'FIXED'">&nbsp; ₦{{formatNumber(trip.discount.unitAmount)}}  </span>
                    <span class="small-brown-text tw-mt-3" v-if="trip.discount && trip.discount.type === 'PERCENTAGE'">&nbsp;Plus {{trip.discount.unitAmount}}% discount</span>
                    <span class="small-brown-text" v-if="trip.discount && trip.discount.additionalIncentive">&nbsp; with {{trip.discount.additionalIncentive}}
                          of  ₦{{formatNumber(trip.discount.additionalIncentiveUnitAmount)}}</span>
                    <span class="tw-pl-3 tw-flex tw-items-center" v-if="trip.lifeGuard"> Life Guard &nbsp; ₦{{formatNumber(trip.lifeGuardCharge)}}&nbsp; <v-icon color="green">mdi-check</v-icon> </span>
                    </span
                        >
                  </div>

                  <div class="right">
                    <span class="available-seats  tw-hidden md:tw-flex">
                      Available Seats :
                      {{ getAvailableSeats(trip.tripVessel.seats) }}
                    </span>
                  </div>
                </div>
                <div class="third-line mt-7 tw-flex">
                  <span class="show text-capitalize "
                    >{{ getFirstText(trip.routeDestinationState) }} ({{
                      toSentenceCase(trip.routeDestinationCity)
                    }}
                    -
                    {{
                      toSentenceCase(trip.routeDestinationJettyName)
                    }})</span
                  >
                  <span class="hide text-capitalize "
                    >{{ getFirstText(trip.routeDestinationState) }} ({{
                      toSentenceCase(trip.routeDestinationJettyName)
                    }})</span
                  >
                </div>
                <div
                  class="tw-flex tw-flex-col tw-w-full tw-my-0 tw-justify-between tw-items-start"
                  v-if="trip.tripRegion === 'crossCountry'"
                >
                  <div class="tw-flex tw-flex-col tw-justify-between tw-w-full">
                    <div
                      class="right"
                      v-if="trip.tripRegion === 'crossCountry'"
                    >
                      <v-radio-group
                        class="tw-flex tw-items-start"
                        v-model="trip.selectedTripCategory"
                        hide-details
                      >
                        <v-radio
                          v-for="(tripCategory, index) in trip.tripCategories"
                          :key="index"
                          color="#004AAD"
                          :value="tripCategory"
                        >
                          <template #label>
                            <div
                              class="trip-category text-capitalize tw-flex tw-flex-row"
                            >
                              <div class="tw-w-7/12">
                                {{ tripCategory.type }}
                              </div>
                              <div class="tw-w-5/12">
                                &#8358;
                                {{ formatNumber(tripCategory.tripAmount) }}
                                <span class="small-brown-text" v-if="trip.discount && trip.discount.type === 'FIXED'">&nbsp; plus ₦{{formatNumber(trip.discount.unitAmount)}}  </span>
                                <span class="small-brown-text" v-if="trip.discount && trip.discount.type === 'PERCENTAGE'">&nbsp; plus {{trip.discount.unitAmount}}% discount</span>
                                <span class="small-brown-text" v-if="trip.discount && trip.discount.additionalIncentive">&nbsp; with {{trip.discount.additionalIncentive}}
                                   of  ₦{{formatNumber(trip.discount.additionalIncentiveUnitAmount)}}</span>
                              </div>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                </div>
                <span
                  class="available-seats second-as  tw-flex md:tw-hidden my-5"
                  :class="[
                    trip.tripRegion === 'crossCountry' ? 'tw-mb-10' : 'tw-my-10'
                  ]"
                >
                  Available Seats :
                  {{ getAvailableSeats(trip.tripVessel.seats) }}
                </span>
                <div class="footer my-5 ">
                  <span class="company-name-footer">
                    {{ trip.waterTransportOperatorName }}</span
                  >
                  <span class="mx-2 dot">.</span>
                  <span class="bus-type">
                    {{ trip.tripVessel.type }}</span
                  >
                  <span class="mx-2 dot">.</span>
                  <span class="bus-class">
                    {{ trip.tripVessel.vesselClass }}</span
                  >
                </div>
              </div>
            </div>
            <div class="tw-flex tw-items-end  tw-flex-wrap button-handler">
              <v-btn
                :style="{ backgroundColor: '#004AAD' }"
                class="book cta"
                @click="handleSelectedTrip(trip)"
                >{{
                  getBookingStatus(trip.tripVessel.seats, trip.status)
                }}<span v-if="trip.discount && trip.discount.type === 'PERCENTAGE'">&nbsp;plus {{trip.discount.unitAmount}}% discount</span>
                <span v-if="trip.discount && trip.discount.type === 'FIXED'">&nbsp;plus ₦{{trip.discount.unitAmount}} discount</span></v-btn
              >
              <!--            <v-btn class="schedule cta" text> Schedule Trip</v-btn>-->
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="tw-flex tw-w-full tw-justify-end tw-my-3">
        <v-btn
          :style="{ backgroundColor: '#004AAD' }"
          class="load-more"
          text
          v-if="getQueryData"
          @click="loadMoreTrip"
          :loading="moreLoading"
          >More</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  components: {},
  data: () => ({
    items: ["Lowest Price", "Highest Price"],
    panel: 0,
    moreLoading: false,
    oneWayData: {},
  }),
  computed: {
    ...mapGetters("booking", ["oneWayTrips", "pendingTrips"]),
    ...mapGetters("booking", ["seats"]),
    getQueryData() {
      if(this.oneWayTrips) {
        return this.oneWayTrips[0].totalTrips > this.oneWayTrips.length;
      }
      else {
        return 0
      }
    }
  },
  methods: {
    async loadMoreTrip() {
      this.moreLoading = true;
      if (!this.oneWayData.page || this.oneWayData.page === 0) {
        this.oneWayData.page = 1;
      } else {
        this.oneWayData.page++;
      }
      let data = {};
      data.page = this.oneWayData.page;
      data.pageSize = 100;
      data.departureQuery = this.oneWayData.departure;
      data.destinationQuery = this.oneWayData.destination;
      data.takeOffDate = this.oneWayData.travelDates;
      data.departureIsCity = this.oneWayData.departureIsCity;
      data.departureIsState = this.oneWayData.departureIsState;
      data.destinationIsCity = this.oneWayData.destinationIsCity;
      data.destinationIsState = this.oneWayData.destinationIsState;
      data.tripType = this.oneWayData.tripType

      await this.$store
        .dispatch("booking/setPendingTrips", data)
        .then(() => {
          this.moreLoading = false;
          this.getQueryTrips();
        })
        .finally(() => (this.moreLoading = false));
    },
    getQueryTrips() {
      this.oneWayData.departure = this.getSubstringBeforeComma(
        this.getCity(this.oneWayData.departure)
      );
      this.oneWayData.destination = this.getSubstringBeforeComma(
        this.getCity(this.oneWayData.destination)
      );
      let queryTrips = [];
      this.pendingTrips.forEach(trip => {
        let tripDate = dayjs(trip.takeOffDate + trip.takeOffTime).format("LLL");
        if (dayjs(tripDate).date() === dayjs().date()) {
          let selectedDate = dayjs(
            this.getUserSelectedDate(this.oneWayData.travelDates)
          ).format("LLL");
          if (
            dayjs(tripDate).isSame(dayjs(selectedDate)) ||
            dayjs(tripDate).isAfter(dayjs(selectedDate))
          ) {
            queryTrips.push(trip);
          }
        } else if (dayjs(tripDate).date() > dayjs().date()) {
          queryTrips.push(trip);
        }
      });
      let uniqueTrips = new Set();
      queryTrips.forEach(trip => uniqueTrips.add(JSON.stringify(trip)));
      let filteredTrips = [];
      uniqueTrips.forEach(trip => filteredTrips.push(JSON.parse(trip)));

      if (filteredTrips.length) {
        let oneWayTrips = this.oneWayTrips;
        oneWayTrips = oneWayTrips.concat(queryTrips);
        sessionStorage.setItem("queryData", JSON.stringify(this.oneWayData));
        sessionStorage.setItem("queryTrips", JSON.stringify(oneWayTrips));
        this.$store.dispatch("booking/setOneWayTrips", oneWayTrips);
      } else {
        this.$displaySnackbar({
          message: "Your search query did not match any more trip"
        });
      }
    },
    getSubstringBeforeComma(str) {
      const index = str.indexOf(" - ");
      if (index === -1) {
        return str;
      } else {
        return str.substring(index + 3);
      }
    },
    getUserSelectedDate(date) {
      let todate = new Date(Date.now());
      let today = dayjs(
        date + "T" + todate.getHours() + ":" + todate.getMinutes()
      );
      if (date) {
        return dayjs(today).format("LLL");
      } else {
        return dayjs().format("YYYY-MM-DDTHH:mm:ss");
      }
    },
    getCity(city) {
      if (city.state) {
        return city.state + " - " + city.city;
      } else {
        return city;
      }
    },
    handleSelectedTrip(trip) {
      let userSelectedTrip = JSON.parse(
        sessionStorage.getItem("userSelectedTrip")
      );
      if (userSelectedTrip && userSelectedTrip.id !== trip.id) {
        this.closePrintTicketModal();
      }
      if (trip.tripRegion === "crossCountry") {
        if (trip.selectedTripCategory) {
          sessionStorage.setItem(
            "userSelectedCategory",
            JSON.stringify(trip.selectedTripCategory)
          );
          this.$store.dispatch("booking/setUserSelectedTrip", trip);
          this.$router.push({ name: "OneWaySelectSeats" });
        } else {
          this.$displaySnackbar({
            message: "Please Select Your Identification Category"
          });
        }
      } else {
        this.$store.dispatch("booking/setUserSelectedTrip", trip);
        this.$router.push({ name: "OneWaySelectSeats" });
      }
    },

    getTime(date, time) {
      return dayjs(date + time).format("LT");
    },
    getDateTime(date, time) {
      return dayjs(date + time).format("lll");
    },
    formatNumber(number){
      if(number){
        return number.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
      }
    },
    getAvailableSeats(seats) {
      let availableSeats = 0;
      seats.forEach(seat => {
        if (seat.status === "AVAILABLE") {
          availableSeats += 1;
        }
      });
      return availableSeats - 1 - this.seats.length;
    },
    toSentenceCase(text) {
      text = text.toLowerCase();
      return text.replace(text.charAt(0), text.charAt(0).toUpperCase());
    },
    getFirstText(text) {
      if (text) {
        let arrayText = text.split(" ");
        return this.toSentenceCase(arrayText[0]);
      }
    },

    getBookingStatus(seats, status) {
      let availableSeats = this.getAvailableSeats(seats);
      if (status === "PENDING" && availableSeats > 0) {
        return "Book Now";
      } else if (status === "PENDING" && availableSeats === 0) {
        return "Booked";
      } else if (status !== "PENDING") {
        return status;
      }
    },
    closePrintTicketModal() {
      sessionStorage.removeItem("tripDetails");
      sessionStorage.removeItem("userSelectedTrip");
      sessionStorage.removeItem("oneWaySeats");
      sessionStorage.removeItem("primaryTraveller");
      sessionStorage.removeItem("oneWayBookingShowTimer");
      sessionStorage.removeItem("minusFromTimer");
      this.$store.dispatch("booking/clearSeats");
      this.$store.dispatch("booking/setUserSelectedTrip", {});
      this.$store.dispatch("booking/updateTripAndTravellerDetails", {});
      this.$store.dispatch("booking/setOneWayBookingMinutesTimer", 0);
      this.$store.dispatch("booking/setOneWayBookingShowTimer", false);
    },

  },
  created() {
    let queryData = JSON.parse(sessionStorage.getItem("queryData"));
    if (queryData) {
      this.oneWayData = queryData;
    }
  }
};
</script>

<style lang="scss" scoped>
.desktop {
  @media (max-width: 960px) {
    display: none;
  }
}

.mobile {
  display: none;

  @media (max-width: 960px) {
    display: flex;
  }
}

.buses {
  margin: 0 11rem 5rem;

  @media (max-width: 1264px) {
    margin: 0 2rem 5rem;
  }
  @media screen and (max-width: 820px) {
    margin: 1rem;
  }
}

.col {
  padding: 5px;
}

.text-design {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.v-select {
  width: 150px !important;

  @media (max-width: 567px) {
    width: 70px !important;
  }
}

.departure {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #004aad;

  @media (max-width: 960px) {
    font-size: 13px;
  }
}

.sort,
.v-list-item__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4f4f4f;

  @media (max-width: 960px) {
    font-size: 10px;
  }
}

.available-buses {
  display: flex;
  justify-content: space-evenly;
}

.company-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: contain;
  @media (max-width: 960px) {
    width: 40px;
    height: 40px;
  }
}

.company-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #4f4f4f;
}

.route,
.departure-time,
.seats-available,
.amount {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 15px;
  line-height: 17px;
  color: #4f4f4f;

  @media (max-width: 960px) {
    font-size: 11px;
  }
}

.v-application p {
  margin-bottom: 0 !important;
}

.cta {
  text-transform: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.book {
  color: #fafafa;

  width: 123px;
  height: 37px;
  background-color: #004aad;
  border-radius: 4px;

  @media (max-width: 576px) {
    width: 100%;
    margin-bottom: 15px;
  }
}
.load-more {
  text-transform: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #fafafa !important;
}

.button-handler {
  align-items: flex-start;
  margin: 20px 0;

  @media (max-width: 1520px) {
    flex-direction: column;
  }

  @media (max-width: 960px) {
    flex-direction: row !important;
    margin: 20px 0 40px 80px;
  }

  @media (max-width: 768px) {
    margin: 20px 0 40px 53px;
  }
}

.schedule {
  color: #004aad;

  width: 123px;
  height: 37px;
  border: 1px solid #004aad;
  border-radius: 4px;
  margin-left: 10px;

  @media (max-width: 1520px) {
    margin-left: 0;
    margin-top: 20px;
  }

  @media (max-width: 960px) {
    margin-top: 0;
    margin-left: 15px;
  }

  @media (max-width: 576px) {
    width: 100%;
    margin-left: 0;
  }
}

.up-left {
  width: 70px;
}

.up-right {
  width: 100%;
}

.dropdown {
  margin-top: 30px;
}

.group {
  margin-left: 80px;

  @media (max-width: 768px) {
    margin-left: 57px;
  }
}

.show {
  display: flex;

  @media (max-width: 576px) {
    display: none;
  }
}

.hide {
  display: none;

  @media (max-width: 576px) {
    display: flex;
  }
}

.main-left {
  width: 70%;

  @media (max-width: 960px) {
    width: 90%;
  }
}

.first-line,
.second-line {
  display: flex;
  justify-content: space-between !important;
}

.dot {
  font-size: 15px;
}

.first-line .left,
.third-line {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
}

.first-line .right .amount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 24px;
  color: #004aad;
}

.second-line,
.footer,
.second-as {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4f4f4f;
}

.second-as {
  margin-left: -30px;
}

.footer {
  color: #878787;
  margin-left: -30px;
  line-height: 20px;
}
::v-deep .v-text-field input {
  padding: 0 !important;
}

.trip-category {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  width: 100%;
  @media (max-width: 960px) {
    font-size: 11px;
  }
}
.small-brown-text {
  color: #4F4F4F;
  font-family: Inter,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
