<script>
export default {
  name: 'CommutersMobileView',
  data() {
    return {
      isActive: false
    }
  },

  methods: {
    toggleActive() {
      this.isActive = !this.isActive;
    }
  }
}
</script>

<template>
<div>
  <p class="mobile-header-txt tw-pb-5 tw-pt-10 tw-pl-0">Commuters</p>
  <div class="tw-flex tw-pb-5 active">
    <img src="@/assets/icons/tabler_brand-booking.svg" alt="icon" class="tw-pb-24">
    <div class="tw-pl-5 tw-mt-1 commuters-container" @click="toggleActive" :class="{ 'active': isActive }">
      <img src="@/assets/icons/Npod-logo.svg" alt="Npod logo" class="tw-pb-3">
      <p class="mobile-paragraph-txt">
        Access multiple modes of transportation<br> options and services in one place
      </p>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.mobile-header-txt{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #000F23;
}
.mobile-paragraph-txt{
  font-family: Raleway,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #00193A;
}
.commuters-container {
  transition: rgba(0, 0, 0, 0.5) 0.3s ease;
}
.commuters-container:active{
  transition: rgba(0, 0, 0, 0.5) 0.3s ease;
}
</style>