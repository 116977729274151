<template>
  <div class="tw-w-full">
    <div
      class="tw-flex tw-w-full tw-flex-col sm:tw-flex-row tw-justify-between tw-py-5">
      <p :style="{color: '#004AAD'}" class="passenger">Primary Traveller's Details</p>
      <p class="tw-pt-5 sm:tw-pt-0 use-code tw-cursor-pointer" :style="{color: '#004AAD'}" @click="closeModal">Use Traveller’s Code</p>
    </div>

    <div class="tw-flex flipping">
      <v-col sm="12" lg="6" md="6" class="data left">
        <p class="form-header">First Name</p>
        <ValidationProvider name="First name" rules="required" v-slot="{classes, errors}">
          <div :class="classes">
            <v-text-field solo placeholder="John" type="text" color="#004aad" hide-details
              v-model="hiringDetails.travellerDetail.firstName"></v-text-field>
            <span> {{errors[0]}} </span>
          </div>
        </ValidationProvider>

      </v-col>
      <div class="spacer"></div>
      <v-col sm="12" lg="6" md="6" class="data right">
        <p class="form-header">Last Name</p>
        <ValidationProvider name="Last name" rules="required" v-slot="{classes, errors}">
          <div :class="classes">
            <v-text-field solo placeholder="Doe" type="text" color="#004aad" hide-details v-model="hiringDetails.travellerDetail.lastName">
            </v-text-field>
            <span> {{errors[0]}} </span>
          </div>
        </ValidationProvider>

      </v-col>
    </div>
    <div class="tw-flex flipping">
      <v-col sm="12" lg="6" md="6" class="data left">
        <p class="form-header"> Phone Number</p>
        <phone-number :phone-number-exist="hiringDetails.travellerDetail.phoneNumber" @getNumber="getTravellerNumber" color="#004aad" />
      </v-col>
      <div class="spacer"></div>
      <v-col sm="12" lg="6" md="6" class="data right">
        <p class="form-header">Email</p>
        <ValidationProvider name="Email" rules="required" v-slot="{classes, errors}">
          <div :class="classes">
            <v-text-field solo placeholder="example@gmail.com" hide-details type="email" color="#004aad"
              v-model="hiringDetails.travellerDetail.email"></v-text-field>
            <span> {{errors[0]}} </span>
          </div>

        </ValidationProvider>
      </v-col>

    </div>
    <div class="flipping">
      <v-col sm="12" lg="6" md="6" class="data left">
        <p class="form-header"> Date of Birth</p>
        <ValidationProvider name="Date of Birth" rules="required" v-slot="{classes, errors}">
          <div :class="classes">
            <div class="" :class="classes">
              <v-menu ref="fromDateMenu" v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="hiringDetails.travellerDetail.dateOfBirth" v-on="on" placeholder="2022-09-9" solo hide-details
                    readonly>
                    <template #prepend-inner>
                      <v-icon class="">
                        mdi-calendar-month-outline
                      </v-icon>
                    </template></v-text-field>
                </template>
                <v-date-picker locale="en-in" v-model="hiringDetails.travellerDetail.dateOfBirth" no-title :max="maxDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text @click="fromDateMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn text @click="$refs.fromDateMenu.save(hiringDetails.travellerDetail.dateOfBirth)">OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <span>{{ errors[0] }}</span>
            </div>
          </div>

        </ValidationProvider>
      </v-col>
      <div class="spacer"></div>

      <v-col sm="12" lg="6" md="6" class="data right">
        <p class="form-header">Gender</p>
        <ValidationProvider name="Gender" rules="required" v-slot="{classes, errors}">
          <div :class="classes">
            <v-select :items="genders" solo placeholder="MALE" hide-details type="text" class="text-capitalize"
              color="#004aad" v-model="hiringDetails.travellerDetail.gender" append-icon="mdi-chevron-down"></v-select>
            <span> {{errors[0]}} </span>
          </div>

        </ValidationProvider>
      </v-col>

    </div>
    <div class= "tw-flex flipping">
      <v-col sm="12"  md="6" class="data left">
        <p class="form-header">Address</p>
        <ValidationProvider name="Address" rules="required" v-slot="{classes, errors}">
          <div :class="classes" class="tw-cursor-pointer" @click="handleShowAddressModal">
            <v-combobox  solo placeholder="317, Borno way, Alagomeji Yaba, Lagos" hide-details type="text" class="text-capitalize"
                      color="#004aad" v-model="getCustomerAddress"  prepend-inner-icon="" append-icon="" ></v-combobox>
            <span> {{errors[0]}} </span>
          </div>

        </ValidationProvider>
      </v-col>
    </div>

    <div class="next-of-kin-details">
      <p :style="{color: '#004AAD'}" class="nof">Next Of Kin Details</p>

      <div class="tw-flex flipping">
        <v-col sm="12" lg="6" md="6" class="data left">

          <ValidationProvider name="Next of kin first name" rules="required" v-slot="{classes, errors}">
            <p class="form-header">First Name</p>
            <div :class="classes">
              <v-text-field solo placeholder="John" type="text" color="#004aad" hide-details
                v-model="hiringDetails.travellerDetail.nextOfKinFirstName"></v-text-field>
              <span> {{errors[0]}} </span>
            </div>
          </ValidationProvider>

        </v-col>
        <div class="spacer"></div>

        <v-col sm="12" lg="6" md="6" class="data right">
          <p class="form-header">Last Name</p>
          <ValidationProvider name="Next of kin last name" rules="required" v-slot="{classes, errors}">
            <div :class="classes">
              <v-text-field solo placeholder="Doe" type="text" color="#004aad" hide-details
                v-model="hiringDetails.travellerDetail.nextOfKinLastName"></v-text-field>
              <span> {{errors[0]}} </span>
            </div>
          </ValidationProvider>

        </v-col>
      </div>
      <div class="tw-flex flipping">
        <v-col sm="12" lg="6" md="6" class="data">
          <p class="form-header"> Phone Number</p>
          <phone-number :phone-number-exist="hiringDetails.travellerDetail.nextOfKinPhoneNumber" @getNumber="getNextOfKinNumber"
            color="#004aad" />
        </v-col>
        <div class="spacer"></div>

        <v-col sm="12" lg="6" md="6" class="data">
          <p class="form-header">Email (Optional)</p>
          <ValidationProvider name="Next of kin email" rules="required" v-slot="{classes, errors}">
            <div :class="classes">
              <v-text-field solo placeholder="example@gmail.com" hide-details type="email" color="#004aad"
                v-model="hiringDetails.travellerDetail.nextOfKinEmail"></v-text-field>
              <span> {{errors[0]}} </span>
            </div>
          </ValidationProvider>
        </v-col>

      </div>
      <div class="shift-end btn-divs">
          <button class="previous-btn mr-5" @click="$router.push({path:'/hire-location'})">Step 2</button>
          <button class="btn-design" @click="saveCustomerData">Next</button>
        </div>
    </div>

    <booking-code-modal :dialog="dialog" @close="closeModal" @bookingAction="enterBookingCode"
      :doneLoading="getCodeLoading" />
    <modal section="custom-address" :address="hiringDetails.travellerDetail.address"
           :dialog="showAddressModal" @handleAddress="handleAddress"  @close="closeHandleModal"/>
  </div>
</template>

<script>
  import PhoneNumber from '@/components/reuseables/PhoneNumber.vue'
  import BookingCodeModal from "@/components/reuseables/BookingCodeModal";
  import {
    getAllCitiesInAState,
    getAllCountries,
    getAllStatesByCountry,
    getPassengerDetail
  } from "@/services/api/APIService";
  import {
    mapGetters
  } from "vuex";
  import Lga from "@/assets/lga";
  import Modal from "../../components/reuseables/Modal.vue";


  export default {
    name: "CustomerDetails",
    components: {
      Modal,
      PhoneNumber,
      BookingCodeModal
    },
    data() {
      return {
        customerData: {
          address: {}
        },
        dialog: false,
        getCodeLoading: false,
        fromDateMenu: false,
        maxDate: new Date(new Date().getTime() + 60 * 60 * 1000)
          .toISOString()
          .substr(0, 10),
        genders: ["Male", "Female"],
        countries: [],
        states: [],
        cities: [],
        showAddressModal:false
      }
    },
    computed: {
      ...mapGetters("hire", ["hiringDetails"]),
      getCustomerAddress(){
        if (this.hiringDetails.travellerDetail.address.houseNumber) {
          return this.toSentenceCase(this.hiringDetails.travellerDetail.address.houseNumber) + " "
              +this.toSentenceCase(this.hiringDetails.travellerDetail.address.streetName) +
              ", " + this.toSentenceCase(this.hiringDetails.travellerDetail.address.city) + " "
              +this.toSentenceCase(this.hiringDetails.travellerDetail.address.state)
              + " "+this.toSentenceCase(this.hiringDetails.travellerDetail.address.country)
        }
        else {
          return ""
        }
      },
    },
    methods: {
      closeHandleModal(){
        this.showAddressModal = !this.showAddressModal
      },
      handleShowAddressModal(){
        this.showAddressModal = true
      },
      getNextOfKinNumber(number) {
        this.hiringDetails.travellerDetail.nextOfKinPhoneNumber = number
      },
      getTravellerNumber(number) {
        this.hiringDetails.travellerDetail.phoneNumber = number
      },
      closeModal() {
        this.dialog = !this.dialog
      },
      getPrimaryTraveller() {
        let primaryTraveller = JSON.parse(sessionStorage.getItem("primaryTraveller"))
        if (Object.keys(primaryTraveller).length) {
          this.customerData = primaryTraveller
        } else {
          this.$displaySnackbar({
            message: "Please fill in primary traveller's details",
            success: false,
          });
        }
      },
      async enterBookingCode(code) {
        this.getCodeLoading = true
        if (code && code.length === 10) {
          await getPassengerDetail(code).then(res => {
              if (res.data.address) {
                res.data.address.country = this.toSentenceCase(res.data.address.country)
                res.data.address.state = this.toSentenceCase(res.data.address.state)
                res.data.address.city = this.toSentenceCase(res.data.address.city)
                res.data.address.streetName = this.toSentenceCase(res.data.address.streetName)
              }
              this.hiringDetails.travellerDetail = res.data
              this.getCodeLoading = false
              this.closeModal()
            })
            .catch((err) => {
              this.getCodeLoading = false
              console.log(err.response);
              this.$displaySnackbar({
                message: err.response.data.details[0],
                success: false,
              });
            });
        } else {
          this.getCodeLoading = false
          this.$displaySnackbar({
            message: "Not a valid code",
            success: false,
          });
        }

      },
      handleAddress(address){
          this.hiringDetails.travellerDetail.address = address
      },
      toSentenceCase(text) {
        text = text.toLowerCase()
        return text.replace(text.charAt(0), text.charAt(0).toUpperCase())
      },
      getFirstText(text) {
        if (text) {
          let arrayText = text.split(" ")
          return this.toSentenceCase(arrayText[0])
        }
      },
      async getCountries() {
        await getAllCountries().then(res => {
          this.countries = res.data.data
        })
      },
      saveCustomerData(){
        // this.hiringDetails.travellerDetail = this.customerData
        this.$store.dispatch("hire/hiringDetails", this.hiringDetails)
        this.$router.push({path:'/hire-payment'}) 
      },
      async getState() {
        try {
          let data = {
            country: this.hiringDetails.travellerDetail.address.country
          }
          if (this.hiringDetails.travellerDetail.address.country.name) {
            data.country = this.hiringDetails.travellerDetail.address.country.name
          }
          const response = await getAllStatesByCountry(data)
          this.states = response.data.data.states
          this.states.push({
            name: 'Rivers State'
          })
          this.states.forEach(state => {
            if (state.name === 'Lagos State') {
              state.name = 'Lagos'
            }
          })
        } catch (err) {
          console.log(err.response)
        }
      },
      async getCity() {
        if (this.hiringDetails.travellerDetail.address.country.name === "Nigeria") {
          this.cities = Lga[this.hiringDetails.travellerDetail.address.state.name]
        } else {
          try {
            this.getCitiesLoading = true;
            let data = {
              country: this.hiringDetails.travellerDetail.address.country,
              state: this.hiringDetails.travellerDetail.address.state
            };
            if (this.hiringDetails.travellerDetail.address.country.name) {
              data.country = this.hiringDetails.travellerDetail.address.country.name;
            }
            if (this.hiringDetails.travellerDetail.address.state.name) {
              data.state = this.hiringDetails.travellerDetail.address.state.name
            }
            const response = await getAllCitiesInAState(data);
            console.log(response);
            this.cities = response.data.data;
          } catch (err) {
            console.log(err.response);
            this.cities = [];
            this.getCitiesLoading = false;
          }
        }
      },
    },

    async created() {
      await this.getCountries()

    }
  }
</script>

<style lang="scss" scoped>

  .flipping {
    display: flex;
    width: 100%;
    @media (max-width:960px) {
      flex-direction: column;
    }
  }

  .form-header {
    font-family: var(--fontInter);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 0.5rem;
    color: rgba(0, 17, 39, 0.73);
    @media (max-width: 768px) {
      font-size: 14px;
    line-height: 17px;
    }
  }

  .code {
    font-family: var(--fontInter);
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #004AAD;

    @media (max-width:576px) {
      font-size: 12px;
    }
  }

  ::v-deep .theme--light.v-text-field--solo>.v-input__control>.v-input__slot {
    background: transparent !important;
  }
  .spacer {
  flex-grow: 1;
  min-width: 20px;
  @media screen and (max-width: 768px) {
    min-width:0px
  }
}

  .next-of-kin-details {
    padding: 100px 0 50px !important;
    @media (max-width:1024px) {
      padding: 50px 0 !important;
    }
  }

  .nof {
    font-family: var(--fontInter);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #004AAD;
    @media (max-width: 768px) {
      font-size: 16px;
    line-height: 19px;
    }
  }

  .data {
    padding: 20px 0 !important;
    @media screen and (max-width: 1024px) {
      padding: 15px 0 !important;
    }
  }

  .passenger {
    font-family: var(--fontInter);
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 22px;
    color: #004AAD;
    @media (max-width: 768px) {
      font-size: 16px;
    line-height: 19px;
    }
  }

  .use-code {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 17px;
    color: #004AAD;
    @media (max-width: 768px) {
      font-size: 12px;
    line-height: 15px;
    }

  }
  
  .btn-design {
    border-radius: 4px;
    border: 1px solid #004AAD;
    background: #004AAD;
    color: #FFF;
    font-family: Inter;
    width: 150px;
    height: 50px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 15px;

    @media screen and (max-width:576px) {
      width: 100%;
    }
  }

  .previous-btn {
    border-radius: 4px;
    border: 2px solid #004AAD;
    color: #004aad !important;
    font-family: Inter;
    width: 150px;
    height: 50px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 15px;

    @media screen and (max-width:576px) {
      width: 100%;
    }
  }

  .btn-divs {
    margin-top: 50px;
    margin-bottom: 100px;
    margin-right: -20px;
    @media screen and (max-width: 960px){
      margin-right: 0px;
      margin-top: 10px;
    }
    @media screen and (max-width: 768px){
      margin-top: 0;
    }
  }
</style>