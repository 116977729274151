import Vue from "vue";
import Vuex from "vuex";
import snackBar from "./snackBar";
import hire from "./hire";

import webBookingComponent from "./webBookingComponent";
import booking from "./booking";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    snackBar,
    hire,
    webBookingComponent,
    booking,
  },
});