<template>
    <div class="final-step">
      <loader :loading="loading" v-if="loading" />
      <div class="tw-w-full" v-else>
       <NavBar/>
        <booking-steps />
      <div class="wrapper">
        <div class="back tw-cursor-pointer" @click="$router.push({name:'HireInputDetails'})"><v-icon>mdi-chevron-left</v-icon>Back</div>
      </div>
        <div class="rower tw-mt-5">
          <div class="left">
            <checkout-left-side @handlePayment="handleSubmit" :payment-loading="submitLoading" />
          </div>
            <div class="right display-center ">
                <select-insurance-plan class="tw-hidden" />

                <checkout-passenger-summary />
                <button  class="mt-9 cta-button tw-hidden"  @click="handleSubmit" >Submit hiring request </button>
            </div>
        </div>
      <modal section="print-ticket" :dialog="showBookingCompletedModal" color="#004AAD"
             @close="closePrintTicketModal" @printTicket="printHiringTicket" title="Hiring Successful" description="A hiring confirmation have been sent to your email"/>

      <section slot="pdf-content" v-if="isPrinting">
        <booking-receipt  :booking="currentBooking" ref="customerReceipt" />
      </section>

      <paystack
          :amount="getTotalPrice*100"
          :email="hiringDetails.travellerDetail.email"
          :paystackkey="getPayStackKey"
          :reference="reference"
          :callback="handlePaystackPayment"
          :close="handleClosePaytackModal"
          :embed="false"
          :channels= "['card']"
          :subaccount="paystackDetail.subaccountCode"
      >
        <div class="tw-hidden" ref="paystack">Pay</div>
      </paystack>
    </div>
    </div>
</template>

<script>
    import BookingSteps from '../../components/reuseables/hire/BookingSteps.vue'
    import CheckoutLeftSide from '../hire/CheckoutLeftSide.vue'
    import CheckoutPassengerSummary from '../hire/CheckoutPassengerSummary.vue'
    import paystack from "vue-paystack";
    import SelectInsurancePlan from '../hire/SelectInsurancePlan.vue'
    import NavBar from "@/home/Navbar.vue";
    import BookingReceipt from "@/components/reuseables/hire/BookingReceipt.vue";
    import Modal from "@/components/reuseables/Modal.vue";
    import {
      getPaystackAccountDetail,
      verifyPaystackPayment
    } from "@/services/api/APIService";
    import Loader from "@/components/reuseables/Loader.vue";
    import {mapGetters} from "vuex";
    import {makeAHiringRequest} from "../../services/api/APIService";
    export default {
      name: "HiringCheckout",
        components: {
          Loader,
          NavBar,
            BookingSteps,
            CheckoutPassengerSummary,
            CheckoutLeftSide,
            Modal,
          paystack,
          BookingReceipt,
          SelectInsurancePlan
        },
        data(){
         return{
           loading : false,
           submitLoading : false,
           paymentUrl : "",
           showPaymentModal : false,
           showBookingCompletedModal: false,
           currentBooking:{},
           isPrinting: false,
           paymentReference : "",
           paystackDetail:{}
         }
        },
      computed:{
        ...mapGetters("hire", ["hiringDetails"]),
        getActualPrice(){
          return (this.hiringDetails.query.duration * this.hiringDetails.vehicleSelected.hirePrice) * this.hiringDetails.query.quantity
        },
        getTotalDiscount(){
          return (this.getActualPrice * this.hiringDetails.vehicleSelected.discountValue) / 100
        },
        getTotalPrice(){
          return this.getActualPrice - this.getTotalDiscount
        },
        reference(){
          let text = "";
          let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

          for( let i=0; i < 10; i++ )
            text += possible.charAt(Math.floor(Math.random() * possible.length));
          return text;
        },
        getPayStackKey(){
          return process.env.VUE_APP_PAYSTACK_LIVE_PUBLIC_KEY
        },
        getPickupAddress(){
          if (this.hiringDetails.pickUpLocation) {
            return this.toSentenceCase(this.hiringDetails.pickUpLocation.houseNumber) + " " + this.toSentenceCase(this.hiringDetails.pickUpLocation.streetName) +
                ", " + this.toSentenceCase(this.hiringDetails.pickUpLocation.city) + " " + this.toSentenceCase(this.hiringDetails.pickUpLocation.state)+ " "+
                this.toSentenceCase(this.hiringDetails.pickUpLocation.country)
          }
          else {
            return ""
          }
        },
        getDropOffAddress(){
          if (this.hiringDetails.dropOffLocation) {
            return this.toSentenceCase(this.hiringDetails.dropOffLocation.houseNumber) + " " + this.toSentenceCase(this.hiringDetails.dropOffLocation.streetName) +
                ", " + this.toSentenceCase(this.hiringDetails.dropOffLocation.city) + " " + this.toSentenceCase(this.hiringDetails.dropOffLocation.state)+ " "+
                this.toSentenceCase(this.hiringDetails.dropOffLocation.country)
          }
          else {
            return ""
          }
        },
      },
      methods:{
        printHiringTicket() {
          this.isPrinting = !this.isPrinting
          this.$refs.customerReceipt.print()
        },
        async handleSubmitHiringRequest(){
           this.submitLoading = true
            this.hiringDetails.pickUpDate = this.hiringDetails.query.hireDate
            this.hiringDetails.amountPaid = this.getTotalPrice
            this.hiringDetails.numberOfSelectedVehicle= this.hiringDetails.query.quantity
            this.hiringDetails.hireDuration = this.hiringDetails.query.duration
            if (this.hiringDetails.hireDuration > 1){
              this.hiringDetails.hiringType = "full_hiring"
            }
            else {
              this.hiringDetails.hiringType = "drop_off"
            }
            this.hiringDetails.hiringCriteriaId = this.hiringDetails.vehicleSelected.id
          await makeAHiringRequest(this.hiringDetails).then(res =>{
            this.currentBooking = res.data
            this.showBookingCompletedModal = true
            this.submitLoading = false
          }).catch((err) => {
            this.submitLoading = false
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
          });
          console.log(this.hiringDetails)
        },
        toSentenceCase(text){
          text = text.toLowerCase()
          return text.replace(text.charAt(0),text.charAt(0).toUpperCase())
        },
        closePrintTicketModal(){
          this.showBookingCompletedModal = false
          sessionStorage.removeItem("hiringDetails")
          sessionStorage.removeItem("selectedInsuranceProvider")
          sessionStorage.removeItem("selectedPlan")
          this.$store.dispatch("booking/setSelectedInsuranceProvider",{})
          this.$store.dispatch("hire/hiringDetails",{
            vehicleSelected: {},
            pickUpLocation:{},
            dropOffLocation:{},
            travellerDetail:{
              address:{}
            },
            pickUpTime: ""})
          this.$router.push({name : 'HireSelectVehicle'})

        },
        handleClosePaytackModal(){},
        async handleSubmit(){
            this.$refs.paystack.click()
        },
        async handlePaystackPayment(){
          let selectedInsurancePlan = sessionStorage.getItem("selectedPlan")
          this.submitLoading = true
          let data = {}
          let travellerDetails = []
          travellerDetails.push(this.hiringDetails.travellerDetail)
          data.departure = this.getPickupAddress
          data.destination = this.getDropOffAddress
          data.selectedSeatNumbers = this.hiringDetails.numberOfSelectedVehicle + " vehicle(s)"+ "  for "+ this.hiringDetails.hireDuration+ " "+" day(s)"
          data.tripId = this.hiringDetails.vehicleSelected.id
          data.amount = this.getTotalPrice
          data.paymentReference = this.reference
          data.receivingAccountName = this.paystackDetail.businessName
          data.receivingAccountNumber = this.paystackDetail.accountNumber
          data.paymentProcessor = "Paystack"
          if (selectedInsurancePlan ){
            data.paymentPurpose = "Hiring with Insurance"
          }
          else {
            data.paymentPurpose = "Hiring"
          }
          data.customerEmail = this.hiringDetails.travellerDetail.email
          data.travellers = travellerDetails
          await verifyPaystackPayment(this.paystackDetail.userId, this.reference, data).then(async res => {
            if (res.data.detail === 'Transaction was successfully!!') {
              await this.handleSubmitHiringRequest()
            }
            else {
              this.$displaySnackbar({
                message: "Payment was unsuccessful, give it a minute and try again.",
                success: false,
              });
            }
          }).catch(err => {
            console.log(err)
            this.submitLoading = false})
          //
        },
        async getPaystackAccount(){
          if (this.hiringDetails.vehicleSelected && this.hiringDetails.vehicleSelected.transportCompanyId) {
            await getPaystackAccountDetail(this.hiringDetails.vehicleSelected.transportCompanyEmail).then(res => {
              this.paystackDetail = res.data
            })
          }
        }
      },
     async created() {
       let hiringDetails = JSON.parse(sessionStorage.getItem("hiringDetails"))
       if (hiringDetails){
         await this.$store.dispatch("hire/hiringDetails", hiringDetails)
       }
       await this.getPaystackAccount()
      }

    }
</script>

<style lang='scss' scoped>
    .rower {
        display: flex;
        flex-direction: row;
        padding-left: 15rem;
        padding-right: 15rem;
        width: 100%;
         @media (max-width:1440px) {
            padding: 50px;
        }
             @media (max-width:1024px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        @media (max-width:576px) {
            padding: 4rem 1rem;
        }

    }

    .left {
      width: 100%;
      padding: 0 100px 100px;
      @media (max-width:1440px) {
        padding: 50px;
      }
      @media (max-width:1264px) {
        padding: 0 50px 0 0;
      }
      @media (max-width:1024px) {
        margin-bottom: 100px;
        padding: 0;
      }
      @media (max-width:768px) {
        margin-bottom:40px;

      }
    }

    .seats {
        padding: 0 !important;
    }

    .right {
        width: 574px;
        height: fit-content;
        flex-direction: column;
        padding: 50px   20px;
        background: #ffff;
        box-shadow: 4px 4px 35px rgba(79, 79, 79, 0.15);
        @media (max-width:576px) {
            width: 360px;
        }
    }
    .cta-button{
        width: 330px;
         font-family: Inter, serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  background: #004aac;
  border-radius: 8px;
  width: 100%;
height: 53px;
          @media (max-width:576px) {
            width: 300px;
        }
    }

    .back{
      display: flex;
      align-items: center;
      font-family: 'Inter',sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #4F4F4F;
      width: max-content;
      text-transform: none;
      cursor: pointer;
    }

    .wrapper{
      width: 100%;
      padding: 0 10rem;

      @media screen and (max-width: 1024px) {
        padding: 1rem;
      }
    }
</style>