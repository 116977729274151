<template>
    <div class="search-container">
      <v-btn   class="show-text"  text :type="actionType"  v-if="showText" :loading="loading">
        <icons name="search" color="white"/> &nbsp;&nbsp; Search
      </v-btn>
      <v-btn   class="search"  text :type="actionType" v-else>
        <v-icon color="#ffff">mdi-magnify</v-icon>
      </v-btn>

    </div>
</template>

<script>
    import Icons from "@/components/reuseables/Icons.vue";

    export default {
      components: {Icons},
      props:{
        actionType:{
          type: [String],
          default: "none"
        },
        showText:{
          type: [Boolean],
          default: false
        },
        loading:{
          type: [Boolean],
          default : false
        }
      },
    }
</script>

<style lang='scss' scoped>
.search-container{
  @media screen and(max-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0.5rem;
  }
}
.search {
  border-radius: 10px;
  width: 52px;
  height: 49px !important;
  cursor: pointer;
  background: #004aad;

}

.show-text {
  border-radius: 10px;
  width: 100%;
  height: 49px !important;
  cursor: pointer;
  background: #004aad;
  color: #FDFFFC;
  font-size: 14px;
  font-family: Inter,sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;

}

.mobile {
  display: none;

  @media (max-width:1024px) {
    display: block;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    line-height: 50px;
    color: #FDFFFC;

    width: 100%;
    border-radius: 7px;
    height: 52px;
    margin-top: 30px;
  }
}
</style>