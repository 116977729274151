<script>
export default {
  name: 'TanSectionFour'
}

</script>


<template>
<div class="section-parent tw-w-full tw-flex tw-px-5 lg:tw-px-40">

<div class="left-side">
  <p class="bold-header-txt tw-pt-32">Effortless Booking Management</p>
  <p class="paragraph-sub-head tw-my-8">Effectively manage your complete transportation operations using RIBS.<br>
    Tailored to be resilient and perfectly suited to your needs.</p>
  <div class="tw-flex  tw-my-8">
    <img src="@/assets/icons/tan-icon-sec4(1).svg" alt="icon" class="tw-mr-3">
    <p class="list-txt">Get instant updates on inventory status, bookings and sales operations,<br>ensuring proactive decision-making.</p>
  </div>
  <div class="tw-flex tw-my-8">
    <img src="@/assets/icons/Tan-icon-sec4(2).svg" alt="icon" class="tw-mr-3">
    <p class="list-txt">Tailor the system to your specific needs, adapting to the unique<br>requirements of your transportation business.</p>
  </div>
  <div class="tw-flex tw-my-8">
    <img src="@/assets/icons/ttan-icon-sec4(3).svg" alt="icon" class="tw-mr-3">
    <p class="list-txt">Centralized control panel for efficient management of your trip inventory,<br>ensuring streamlined operations.</p>
  </div>
</div>
  <div class="right-side">
    <img src="@/assets/Tan-sec4-img.png" alt="">
  </div>







</div>





</template>

<style scoped lang="scss">
.section-parent{
  margin-bottom: 100px;
  min-height: 60vh;
  margin-top: 100px;
  @media (max-width: 576px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 0;
  }
}
.left-side{
  width: 50%;
  @media (max-width: 576px) {
    width: 100%;
  }

}
.right-side{
  width: 50%;
  @media (max-width: 576px) {
    width: 100%;
  }
}
.head-txt{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #A73C56;
}
.bold-txt {
  font-family: Raleway, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  text-align: center;
  color: #263238;
}
.sub-txt{
  font-family: Raleway,sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #263238;
}
.bold-header-txt{
  font-family: Raleway,sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  color: #263238;
  @media (max-width: 576px) {
    font-size: 32px;
    line-height: 38.4px;
    text-align: left;
    padding-top: 0;
  }
}
.paragraph-sub-head{
  font-family: Raleway,sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #263238;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
  }
}
.list-txt{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #263238;
}
</style>