<script >
import efex from "@/assets/newAboutPageImages/efex.jpg";
import sterling from "@/assets/newAboutPageImages/sterling-logo.png";
import abc from "@/assets/newAboutPageImages/abc.png";
import greenerline from "@/assets/newAboutPageImages/greener-line.png";
import chisco from "@/assets/newAboutPageImages/chisco.png";
import kuda from "@/assets/newAboutPageImages/kuda-logo.png";
import praisehigh from "@/assets/newAboutPageImages/praise-high.jpg";
import winifred from "@/assets/newAboutPageImages/winifred.jpg";
import sanlam from "@/assets/newAboutPageImages/salam-logo.png";

import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
export default {
  name : "Partners",
  components:{Splide,SplideSlide},
  data(){
    return{
      options:{
        rewind : true,
        rewindSpeed: 10000,
        pagination: false,
        speed: 10000,
        perPage: 3,
        gap    : '1rem',
        height : '8rem',
        autoplay: true,
        type: "slide",
        arrows: false,
      },
      slides:[
        {
          image: efex,
          alt : "Efex Executive Travel"
        },
        {
          image: sterling,
          alt : "Sterling Bank"
        },
        {
          image: abc,
          alt : "ABC Transport Company"
        },
        {
          image: greenerline,
          alt : "Greener Line Travel"
        },
        {
          image: chisco,
          alt : "Chisco  Transport Company Plc"
        },

        {
          image: kuda,
          alt : "Kuda Micro Finance Bank"
        },
        {
          image: praisehigh,
          alt : "Praise High Executive Travel"
        },
        {
          image: winifred,
          alt : "WiniFried Transport Company Travel"
        },


        {
          image: sanlam,
          alt : "Sanlam"
        },
        {
          image: efex,
          alt : "Efex Executive Travel"
        },
        {
          image: chisco,
          alt : "Chisco  Transport Company Plc"
        },
        {
          image: kuda,
          alt : "Kuda Micro Finance Bank"
        },
        {
          image: abc,
          alt : "ABC Transport Company"
        },
        {
          image: greenerline,
          alt : "Greener Line Travel"
        },
        {
          image: praisehigh,
          alt : "Praise High Executive Travel"
        },
        {
          image: sanlam,
          alt : "Sanlam"
        },
        {
          image: winifred,
          alt : "WiniFried Transport Company Travel"
        },

        {
          image: sterling,
          alt : "Sterling Bank"
        },

      ],
    }
  }
}
</script>

<template>
  <div class="section-three tw-py-8 tw-px-8 lg:tw-px-40">
    <splide :options="options">
      <splide-slide v-for="(slide,index) in slides" :key="index">
        <img class="logo-carousel" :src="slide.image" :alt="slide.alt">
      </splide-slide>
    </splide>
  </div>
</template>

<style scoped lang="scss">
.section-three{
  border-bottom: 1px solid rgba(79, 79, 79, 0.10);
  border-top : 1px solid rgba(79, 79, 79, 0.10);
}
.slide {
  background: #004aac;
  border-radius: 20px;
  width: 550px;
  height: 400px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.logo-carousel{
  height: 120px !important;
  width: 120px;
  object-fit: contain;
  filter: grayscale(100%);
}

</style>