<template>
  <div class="tw-w-full look">
    <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-justify-between tw-items-start lg:tw-items-center tw-py-5">
      <p :style="{color: '#004AAD'}"
         class="passenger">Passenger <span v-if="index > 0" class="or"> {{index+1}} </span>  Details</p>
      <p  class="tw-pt-8 md:tw-pt-0"><span :style="{color: '#004AAD'}"
                                           class="use-code tw-cursor-pointer" @click="closeModal">Use Traveller’s Code </span><span v-if="index > 0" class="or"> OR </span>
        <span v-if="index > 0" :style="{color:'#004AAD'}"
              class="use-code tw-cursor-pointer" @click="getPrimaryTraveller">Use Primary Details</span></p>
    </div>

    <div class="tw-flex flipping">
      <v-col sm="12" lg="6"  md="6" class="data left">
        <p class="form-header">First Name</p>
        <ValidationProvider name="First name" rules="required" v-slot="{classes, errors}">
          <div :class="classes">
            <v-text-field solo placeholder="John" type="text" color="#004aad" hide-details
                          v-model="traveller.firstName"></v-text-field>
            <span> {{errors[0]}} </span>
          </div>
        </ValidationProvider>

      </v-col>
      <v-col sm="12" lg="6"  md="6" class="data right">
        <p class="form-header">Last Name</p>
        <ValidationProvider name="Last name" rules="required" v-slot="{classes, errors}">
          <div :class="classes">
            <v-text-field solo placeholder="Doe" type="text" color="#004aad" hide-details
                          v-model="traveller.lastName"></v-text-field>
            <span> {{errors[0]}} </span>
          </div>
        </ValidationProvider>

      </v-col>
    </div>
    <div class="tw-flex flipping">
      <v-col sm="12" lg="6"  md="6" class="data left">
        <p class="form-header"> Phone Number</p>
        <phone-number :phone-number-exist="traveller.phoneNumber" @getNumber="getTravellerNumber" color="#004aad" />
      </v-col>
      <v-col sm="12" lg="6"  md="6" class="data right">
        <p class="form-header">Email</p>
        <ValidationProvider name="Email" rules="required" v-slot="{classes, errors}">
          <div :class="classes">
            <v-text-field solo placeholder="example@gmail.com" hide-details type="email"
                          color="#004aad" v-model="traveller.email"></v-text-field>
            <span> {{errors[0]}} </span>
          </div>

        </ValidationProvider>
      </v-col>

    </div>
    <div class="flipping tw-flex">
      <v-col sm="12" lg="6"  md="6" class="data left">
        <p class="form-header"> Date of Birth</p>
        <ValidationProvider name="Date of Birth" rules="required" v-slot="{classes, errors}">
          <div :class="classes">
            <div class="" :class="classes">
              <v-menu
                  ref="fromDateMenu"
                  v-model="fromDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="traveller.dateOfBirth"
                      v-on="on"
                      placeholder="2022-09-9"
                      solo
                      hide-details
                      readonly
                  >
                    <template #prepend-inner>
                      <v-icon class="">
                        mdi-calendar-month-outline
                      </v-icon>
                    </template></v-text-field
                  >
                </template>
                <v-date-picker
                    locale="en-in"
                    v-model="traveller.dateOfBirth"
                    no-title
                    :max="maxDate"

                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      @click="fromDateMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn text
                         @click="$refs.fromDateMenu.save(traveller.dateOfBirth)">OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <span>{{ errors[0] }}</span>
            </div>
          </div>

        </ValidationProvider>
      </v-col>
      <v-col sm="12" lg="6"  md="6" class="data right">
        <p class="form-header">Gender</p>
        <ValidationProvider name="Gender" rules="required" v-slot="{classes, errors}">
          <div :class="classes">
            <v-select :items="genders" solo placeholder="MALE" hide-details type="text" class="text-capitalize"
                      color="#004aad" v-model="traveller.gender" append-icon="mdi-chevron-down"></v-select>
            <span> {{errors[0]}} </span>
          </div>

        </ValidationProvider>
      </v-col>

    </div>
    <div class= "tw-flex flipping">
      <v-col sm="12"  md="6" class="data left">
        <p class="form-header">Address</p>
        <ValidationProvider name="Address" rules="required" v-slot="{classes, errors}">
          <div :class="classes" class="tw-cursor-pointer" @click="handleShowAddressModal">
            <v-combobox  solo placeholder="317, Borno way, Alagomeji Yaba, Lagos" hide-details type="text" class="text-capitalize"
                         color="#004aad" v-model="customerAddress"  prepend-inner-icon="" append-icon="" ></v-combobox>
            <span> {{errors[0]}} </span>
          </div>

        </ValidationProvider>
      </v-col>
    </div>

    <div class="next-of-kin-details">
      <p :style="{color: '#004AAD'}"
         class="nof">Next Of Kin Details</p>

      <div class="tw-flex flipping">
        <v-col sm="12" lg="6"  md="6" class="data left">

          <ValidationProvider name="Next of kin first name" rules="required" v-slot="{classes, errors}">
            <p class="form-header">First Name</p>
            <div :class="classes">
              <v-text-field solo placeholder="John" type="text" color="#004aad" hide-details
                            v-model="traveller.nextOfKinFirstName"></v-text-field>
              <span> {{errors[0]}} </span>
            </div>
          </ValidationProvider>

        </v-col>
        <v-col sm="12" lg="6"  md="6" class="data right">
          <p class="form-header">Last Name</p>
          <ValidationProvider name="Next of kin last name" rules="required" v-slot="{classes, errors}">
            <div :class="classes">
              <v-text-field solo placeholder="Doe" type="text" color="#004aad" hide-details
                            v-model="traveller.nextOfKinLastName"></v-text-field>
              <span> {{errors[0]}} </span>
            </div>
          </ValidationProvider>

        </v-col>
      </div>
      <div class="tw-flex flipping">
        <v-col sm="12" lg="6"  md="6" class="data left">
          <p class="form-header"> Phone Number</p>
          <phone-number :phone-number-exist="traveller.nextOfKinPhoneNumber" @getNumber="getNextOfKinNumber" color="#004aad" />
        </v-col>
        <v-col sm="12" lg="6"  md="6" class="data right">
          <p class="form-header">Email (Optional)</p>
          <ValidationProvider name="Next of kin email" rules="required" v-slot="{classes, errors}">
            <div :class="classes">
              <v-text-field solo placeholder="example@gmail.com" hide-details type="email"
                            color="#004aad" v-model="traveller.nextOfKinEmail"></v-text-field>
              <span> {{errors[0]}} </span>
            </div>
          </ValidationProvider>
        </v-col>

      </div>
    </div>

    <booking-code-modal
        :dialog="dialog"
        @close="closeModal"
        @bookingAction="enterBookingCode"
        :doneLoading="getCodeLoading"/>
    <modal section="custom-address" :address="traveller.address"  :dialog="showAddressModal" @handleAddress="handleAddress"  @close="closeHandleModal"/>
  </div>
</template>

<script>
import PhoneNumber from '@/components/reuseables/PhoneNumber.vue'
import BookingCodeModal from "@/components/reuseables/BookingCodeModal";
import {
  getAllCitiesInAState,
  getAllCountries,
  getAllStatesByCountry,
  getPassengerDetail
} from "@/services/api/APIService";
import {mapGetters} from "vuex";
import Lga from "@/assets/lga";
import Modal from "../../components/reuseables/Modal.vue";


export default {
  name:"CustomerDetails",
  components: {Modal, BookingCodeModal, PhoneNumber },
  props:{
    travellerDetail:{},
    index:[Number]
  },
  data() {
    return {
      traveller:{
        address:{}
      },
      dialog:false,
      getCodeLoading: false,
      fromDateMenu: false,
      maxDate :new Date(new Date().getTime() + 60 * 60 * 1000)
          .toISOString()
          .substr(0, 10),
      genders:["Male","Female"],
      countries:[],
      states:[],
      cities:[],
      showAddressModal: false,
      customerAddress:null
    }
  },
  watch:{
    traveller: {
      handler : function (val) {
        if (this.index === 0) {
          sessionStorage.setItem("primaryTraveller",JSON.stringify(val))
        }
        this.tripAndTravellerDetails.seats.forEach((seat,index) => {
          if (index === this.index){
            if (val.address.state && val.address.state.name){
              val.address.state = val.address.state.name
            }
            if (val.address.city && val.address.city.name){
              val.address.city = val.address.city.name
            }
            if (val.address.country && val.address.country.name){
              val.address.country = val.address.country.name
            }
            seat.travellerDetail = val
            this.getCustomerAddress()
          }
        })
        this.$store.dispatch("booking/updateTripAndTravellerDetails",this.tripAndTravellerDetails)
        sessionStorage.setItem("tripDetails",JSON.stringify(this.tripAndTravellerDetails))
      }
    },
    immediate: true,
    deep : true
  },
  computed:{
    ...mapGetters("booking",["tripAndTravellerDetails"]),
  },
  methods: {
    getCustomerAddress(){
      if (this.traveller.address.houseNumber) {
        this.customerAddress= this.toSentenceCase(this.traveller.address.houseNumber) + " "
            +this.toSentenceCase(this.traveller.address.streetName) +
            ", " + this.toSentenceCase(this.traveller.address.city) + " "
            +this.toSentenceCase(this.traveller.address.state)
            + " "+this.toSentenceCase(this.traveller.address.country)
      }
      else {
        this.customerAddress= ""
      }
    },
    closeHandleModal(){
      this.showAddressModal = !this.showAddressModal
    },
    handleShowAddressModal(){
      this.showAddressModal = true
    },
    handleAddress(address){
      this.traveller.address = address
      this.getCustomerAddress()
    },
    getNextOfKinNumber(number) {
      this.traveller.nextOfKinPhoneNumber = number
    },
    getTravellerNumber(number) {
      this.traveller.phoneNumber = number
    },
    closeModal(){
      this.dialog = !this.dialog
    },
    getPrimaryTraveller(){
      let primaryTraveller = JSON.parse(sessionStorage.getItem("primaryTraveller"))
      if (Object.keys(primaryTraveller).length){
        this.traveller = primaryTraveller
        this.getCustomerAddress()
      }
      else {
        this.$displaySnackbar({
          message: "Please fill in primary traveller's details",
          success: false,
        });
      }
    },
    async  enterBookingCode(code) {
      this.getCodeLoading = true
      if (code && code.length === 10){
        await getPassengerDetail(code).then(res =>{
          if (res.data.address){
            res.data.address.country = this.toSentenceCase(res.data.address.country)
            res.data.address.state = this.toSentenceCase(res.data.address.state)
            res.data.address.city = this.toSentenceCase(res.data.address.city)
            res.data.address.streetName = this.toSentenceCase(res.data.address.streetName)
          }
          this.traveller = res.data
          this.getCustomerAddress()
          this.getCodeLoading = false
          this.closeModal()
        })
            .catch((err) => {
              this.getCodeLoading = false
              console.log(err.response);
              this.$displaySnackbar({
                message: err.response.data.details[0],
                success: false,
              });
            });
      }
      else {
        this.getCodeLoading = false
        this.$displaySnackbar({
          message: "Not a valid code",
          success: false,
        });
      }

    },
    toSentenceCase(text){
      text = text.toLowerCase()
      return text.replace(text.charAt(0),text.charAt(0).toUpperCase())
    },
    getFirstText(text){
      if (text) {
        let arrayText = text.split(" ")
        return this.toSentenceCase(arrayText[0])
      }
    },
    async getCountries(){
      await getAllCountries().then(res =>{
        this.countries = res.data.data
      })
    },
    async getState() {
      try {
        let data = {
          country: this.traveller.address.country
        }
        if (this.traveller.address.country.name){
          data.country = this.traveller.address.country.name
        }
        const response = await getAllStatesByCountry(data)
        this.states = response.data.data.states
        this.states.push({name:'Rivers State'})
        this.states.forEach(state =>{
          if (state.name === 'Lagos State'){
            state.name = 'Lagos'
          }
        })
      } catch(err) {
        console.log(err.response)
      }
    },
    async getCity() {
      if (this.traveller.address.country.name === "Nigeria") {
        this.cities = Lga[this.traveller.address.state.name]
      }
      else {
        try {
          this.getCitiesLoading = true;
          let data = {
            country: this.traveller.address.country,
            state: this.traveller.address.state
          };
          if (this.traveller.address.country.name){
            data.country = this.traveller.address.country.name;
          }
          if (this.traveller.address.state.name){
            data.state = this.traveller.address.state.name
          }
          const response = await getAllCitiesInAState(data);
          console.log(response);
          this.cities = response.data.data;
        } catch (err) {
          console.log(err.response);
          this.cities = [];
          this.getCitiesLoading = false;
        }
      }
    },
  },

  async created() {
    await this.getCountries()
    if (Object.keys(this.tripAndTravellerDetails).length){
      this.tripAndTravellerDetails.seats.forEach((detail,index) => {
        if (index === this.index){
          if (!detail.travellerDetail.address){
            this.traveller = detail.travellerDetail
            this.traveller.address = {}
          }
          else {
            this.traveller = detail.travellerDetail
          }
          this.getCustomerAddress()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.look{
  padding: 5% 7% !important;

  @media screen and (max-width: 1024px) {
    padding: 0 !important;
  }
}
.flipping {
  @media (max-width:960px) {
    flex-direction: column;
  }
}
.form-header{
  font-family: var(--fontInter);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 0.5rem;

  color: rgba(0, 17, 39, 0.73);
}
.code {
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #004AAD;
  @media (max-width:576px) {
    font-size: 12px;
  }
}

::v-deep .theme--light.v-text-field--solo>.v-input__control>.v-input__slot {
  background: transparent !important;
}

.left {
  margin-right: 30px !important;
}

.next-of-kin-details {
  padding: 100px 0 !important;
}

.nof {
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #004AAD;
}

.data {
  padding: 20px 0 !important;
}

label {
  font-family: var(--fontInter);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: rgba(0, 17, 39, 0.73);

}

.passenger{
  font-family: var(--fontInter);
  font-style: normal;
  font-weight: 500;
  font-size: 18px!important;
  line-height: 22px;
  color: #004AAD;
}

.use-code{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px!important;
  line-height: 17px;
  color: #004AAD;

}
.or{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #263238;
}
</style>