<script>
import NavBar from "@/home/Navbar.vue"
import Icons from "@/components/reuseables/Icons.vue";
import Footer from "@/home/landingpage/Footer.vue";
import GetStartedBtn from "@/components/reuseables/GetStartedBtn.vue";
import DemoButton from "@/components/reuseables/DemoButton.vue";

export default {
  name: "Nibs",
  components: {DemoButton, GetStartedBtn, Footer, Icons, NavBar }
}
</script>

<template>
<div class="tw-w-full justify-center align-center main-body">
  <NavBar/>


  <div class="first-sec tw-w-full tw-px-5 lg:tw-px-40" style="border-radius: 16px">
<v-btn style="padding: 20px 10px 20px 15px; border-radius: 16px; background: #EAEFFE" class="feature-btn-parent">
  <v-btn  style="background: #F0F9FE; border-radius: 16px;
  margin-right: 10px; padding: 2px 10px 2px 10px" class="new-feature-btn">New feature</v-btn>
  Check out the vehicle hiring platform <icons name="arrow-right-blue" style="margin-left: 10px"/>
</v-btn>

    <div class="">
   <div class="bold-txt"><p>Revolutionize your Operations with <span style="color: #004aad"><br>Trip-Jotter</span></p></div>
    <div>

      <p class="first-sec-paragraph">An Inventory & Booking Systems for road Transport Service Providers <br>
    combines streamlined operations with&nbsp;unparalleled performance, empowering your<br> transportation business
    to soar to new levels&nbsp;of success.</p>
      <p class="first-sec-mobile-paragraph">An Inventory & Booking Systems for road Transport Service Providers combines streamlined operations<br>
        with unparalleled performance, empowering your transportation business to soar to new levels<br> of success.

      </p>

    </div>
    </div>
<div class="tw-flex btn tw-mt-10">
  <DemoButton/>
  <router-link to="/register-as"><GetStartedBtn button-text="Get Started"/></router-link>
</div>
    <div class="dashboard-img">
      <img src="https://res.cloudinary.com/myroadpadi/image/upload/v1711237325/assets/frontend/nibs-dashboard_gd5izl.png" alt=""></div>
  </div>

  <div class="second-sec tw-w-full tw-flex tw-px-5 lg:tw-px-40">

    <div class="second-sec-left">
      <div class="tw-mb-5 hw-it-works-txt">
        <p style="color: #004AAD; font-weight: 600; font-size:16px;line-height: 24px;">How It Works</p></div>
      <p class="second-sec-bold-txt">
        Manage all your <span style="color: #004aad">road</span> trip <br>inventory, and sales <br>operations in one place</p>
    </div>

    <div class="second-sec-right">
      <img src="@/assets/Timeline.png">
    </div>
  </div>

  <div class="third-sec tw-w-full tw-px-5 lg:tw-px-40">
    <p class="features-txt" style="color: #004AAD;">Features</p>
    <div><p class="third-sec-bold-txt">
      Road Transport has never been easier</p>
      <p  class="sec3-bold-txt-mobile">
        Road Transport has<br>never been easier</p>

    </div>
    <p class="third-sec-subhead">Tackling road travel and management, one feature at a time</p>
    <p class="third-sec-subhead2">Tackling road travel and management, one<br>feature at a time</p>
  </div>

  <div class="fourth-sec tw-flex tw-w-full tw-px-5 lg:tw-px-40">
    <div class="fourth-sec-left"><img src="@/assets/trips-image.png" alt=""></div>
    <div class="sec4-txt-body">
      <p class="sec4-head-txt">Create trips in seconds</p>
      <p class="sec4-subhead-txt">Experience a seamless creation of digital trip <br v-if="$vuetify.breakpoint.lgAndUp">within seconds.</p>


    <div class="fourth-sec-right">
    <div class="paragraph sec4-content  tw-flex tw-mb-5" style="font-size: 16px; text-align:left; color: #263238">
              <icons name="drop-off" class="mr-3"/>
              <p class="create-trips-txt">Dynamic trip capabilities, allowing multiple drop off locations in same<br>
              vehicle going at different prices.</p>
      <p class="create-trips-mobile-txt">Dynamic trip capabilities, allowing multiple drop<br> off locations in same
        vehicle going at different prices.</p>
    </div>
          <div class="paragraph tw-flex tw-mb-5" style="font-size: 16px;text-align:left;  color: #263238">
            <icons name="create-trips" class="mr-3"/>
            <p class="create-trips-txt">Create trips with dynamic pricing,
              allowing you transport customers paying <br>different prices in<br> same vehicle </p>
            <p class="create-trips-mobile-txt">Create trips with dynamic pricing,
              allowing you transport customers paying different prices in<br> same vehicle </p>
          </div>
    <div class="paragraph tw-flex tw-mb-5" style="font-size: 16px; text-align:left;  color: #263238">
              <icons name="trip-clock" class="mr-3"/>
              <p class="create-trips-txt">Keep your customers informed with up to date trip details. </p>
      <p class="create-trips-mobile-txt">Keep your customers informed with up to date<br> trip details. </p>
            </div>

    </div>
  </div>

  </div>

  <div class="fifth-sec tw-w-full tw-flex tw-px-5 lg:tw-px-40">

    <div class=" sec5-txt-body">
      <p class="sec5-bold-txt" style="margin-left: 0; margin-bottom: 0;">Inventory Management at a <br>glance</p>
      <p class="sec5-bold-txt-mobile"  style="margin-left: 0; margin-bottom: 0;">Inventory<br>Management at a<br>glance</p>

      <div class="paragraph tw-mt-5 tw-mb-10" style="font-size: 18px; text-align:left; color: #263238;">
        <p class="sec4-subhead-txt">Tailored to be resilient and perfectly suited for trip management and booking operations.</p>
      </div>

      <div class="paragraph tw-flex tw-mb-5" style="font-size: 16px; text-align:left; color: #263238" id="sec-5-paragraph">
        <icons name="updates" class="mr-3"/>
        <p class="create-trips-txt">Get instant updates on inventory status, bookings and sales operations,
          <br> ensuring proactive decision-making.</p>
        <p class="create-trips-mobile-txt">Get instant updates on inventory status, bookings<br>
          and sales operations, ensuring proactive<br> decision-making.</p>
      </div>



      <div class="paragraph tw-flex tw-mb-5" style="font-size: 16px;text-align:left;  color: #263238" id="sec-5-paragraph">
        <icons name="bus" class="mr-3"/>
        <p class="create-trips-txt">Tailor the system to your specific needs, adapting to the unique<br> requirements of your transportation business.</p>
        <p class="create-trips-mobile-txt">Tailor the system to your specific needs, adapting<br> to the unique
          requirements of your transportation business.</p>
      </div>

      <div class="paragraph tw-flex tw-mb-5" style="font-size: 16px; text-align:left;  color: #263238" id="sec-5-paragraph">
        <icons name="trip-inventory" class="mr-3"/>
        <p class="create-trips-txt">Centralized control panel for efficient management of your trip inventory,<br> ensuring streamlined operations.</p>
        <p class="create-trips-mobile-txt">Centralized control panel for efficient<br> management
          of your trip inventory, ensuring<br> streamlined operations.</p>
      </div>

    </div>
    <img src="@/assets/inventory-img.png">
  </div>


  <div class="sixth-sec tw-w-full tw-flex tw-px-5 lg:tw-px-40">
    <div class="tw-mr-20 sec-6-img-div"><img src="@/assets/revenue-img.png" alt=""></div>
    <div class="  paragraph-header-txt">
      <p class="sec6-bold-txt" style="margin-left: 0; margin-bottom: 0;" >Reporting and Analytics </p>

      <div class="paragraph tw-mt-5 tw-mb-10" style="font-size: 18px; text-align:left; color: #263238;">
        <p class="sec4-subhead-txt">Stay ahead with reports and analytics and strategically position your transport business for success.</p>
      </div>

      <div class="paragraph tw-flex tw-mb-5" style="font-size: 16px;text-align:left;  color: #263238">
        <icons name="analytics" class="mr-3"/>
        <p class="create-trips-txt">Gain valuable insights into sales and operational performance through <br>intuitive analytics.</p>
        <p class="create-trips-mobile-txt">Gain valuable insights into sales and operational performance through <br>intuitive analytics.</p>
      </div>

      <div class="paragraph tw-flex tw-mb-5" style="font-size: 16px;text-align:left;  color: #263238">
        <icons name="reports" class="mr-3"/>
        <p class="create-trips-txt">Make informed decisions based on comprehensive reports, enhancing <br>overall efficiency.</p>
        <p class="create-trips-mobile-txt">Make informed decisions based on comprehensive reports, enhancing <br>overall efficiency.</p>
      </div>

      <div class="paragraph tw-flex tw-mb-5" style="font-size: 16px;text-align:left;  color: #263238">
        <icons name="operations" class="mr-3"/>
        <p class="create-trips-txt">Monitor the performance of routes, drivers, and overall operations to
          <br>enhance efficiency and service delivery.</p>
        <p class="create-trips-mobile-txt">Monitor the performance of routes, drivers, and overall operations to
          <br>enhance efficiency and service delivery.</p>
      </div>


    </div>
  </div>

  <div class="padding tw-px-5 lg:tw-px-40">
  <div class="seventh-sec ">
  <div class="tw-mb-7 sec-7-headtxt"><p id="sec-7" class="sec-7-head-txt" style="margin-left: 0; margin-bottom: 0;">
    Ready to Transform Your Transportation Operations ?</p></div>
    <p class="paragraph tw-flex tw-mb-5" style="font-size: 20px; color: #2D3348" id="sec-7-paragraph">
      Experience the power of Trip-Jotter and elevate your transport management.
      Sign up now to<br> revolutionize how you manage your trips, drivers,- fleet, bookings, and performance analytics.</p>
    <div class="tw-flex tw-mt-10 sec-7-btn">
      <DemoButton/>
      <router-link to="/register-as"><GetStartedBtn button-text="Get Started Now"/></router-link>
<!--      <v-btn class="tw-mr-5 demo-btn" style="background:#FFFFFF; border: 1px solid #D0D5DD;-->
<!--  border-radius: 10px; width: 130px; text-transform: none; padding: 20px"-->
<!--      > <icons name="play-icon" class="tw-mr-2"/>Demo</v-btn>-->
<!--      <v-btn class="get-started-btn" style="background:#004aad; border: 1px solid #3982F0;-->
      <!--  border-radius: 10px; width: 130px; text-transform: none; padding: 20px">Get Started</v-btn></div>--></div>
  </div>
  </div>


  <div class="eight-sec tw-mt-32">
    <Footer/>

  </div>





</div>
</template>

<style scoped lang="scss">
.main-body{

  @media screen and (max-width: 375px){
    display: flex;
    flex-wrap: wrap;
  }
}

.first-sec{
  background-color: #F0F9FE;
  background-image:
      url("../../../assets/ribs-background1.svg"),
      url("../../../assets/ribs-background2.svg");
  background-position:
      top right,
      bottom left;
  background-repeat: no-repeat;
  min-height: 100vh;
  object-fit: cover;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 200px;

  @media (max-width: 576px){
    padding-top: 0px;
    margin: 0;
  }
}
.feature-btn-parent{
  font-family: Raleway,sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: none;
  color: #004AAD;
  @media (max-width: 576px){
    width: 22.5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    margin-right: 30px;
  }
}
.new-feature-btn{
  font-family: Raleway,sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: none;
  color: #004AAD;
  @media (max-width: 576px) {
    font-size: 12px;
  }
}
.bold-txt{
  font-family: Raleway,sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #263238;
  @media (max-width: 576px){
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
  }

}

.first-sec-paragraph{
  font-family: Raleway,sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #2D3348;
  @media (max-width: 576px) {
    display: none;
  }
}
.first-sec-mobile-paragraph{
  display: none;
  @media (max-width: 576px) {
    display: flex;
    font-family: Raleway,sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #2D3348;
  }
}

.paragraph{
  font-family: Raleway,sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #2D3348;

  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }
}
.btn{
  @media screen and (max-width: 576px){
    margin-right: 100px;
  }
}
.dashboard-img{
  margin-top: 60px;
  @media (max-width: 576px) {
    width: 100%;
    margin-top: 25%;
    //height: 50vh;
  }
}
.second-sec{
  text-align: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 10%;

  @media screen and (max-width: 576px){
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }

}
.second-sec-bold-txt{
  @media screen and (max-width: 576px){
    font-family: Raleway;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;

  }
}
.hw-it-works-txt{
  @media screen and (max-width: 375px){
    //margin-left: -50px;
  }
}
.second-sec-left{
  font-family: Raleway,sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #263238;
  padding-left: 10%;
  margin-bottom: 25%;

  @media screen and (max-width: 768px){
    margin-bottom: 0;
    width: 60%;
    padding-left: 5%;
  }
  @media (max-width: 576px) {
    padding-left: 0;
  }

}
#second-sec-left{
  padding-left: 0;
  @media screen and (max-width: 578px){
    text-align: left;
    font-family: Raleway;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;

  }
}
.second-sec-right{
  padding-right: 10%;
  @media screen and (max-width: 576px){
    margin-top: 50px;
    padding: 0;
  }
}


.third-sec{
  text-align: center;
  align-items: center;
  margin-top: 6%;
  @media screen and (max-width: 1366px){
    margin-top: 13%;
  }
  @media screen and (max-width: 576px){
margin-top: 20%;
  }
}
.third-sec-bold-txt{
  font-family: Raleway,sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  text-align: center;
  color: #263238;
  @media (max-width: 576px) {
    display: none;
  }
}
.sec3-bold-txt-mobile{
  display: none;
  @media (max-width: 576px) {
    display: flex;
    margin-top: 20px;
    font-family: Raleway,sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: left;
    color: #263238;
  }
}
.third-sec-subhead{
  font-family: Raleway,sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #263238;
  @media (max-width: 576px) {
    display: none;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
  }
}
.third-sec-subhead2{
  display: none;
  @media (max-width: 576px) {
    display: flex;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin-top: 20px;
  }
}

.third-sec-txt{
  font-family: Raleway,sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: center;
  color: #263238;
  @media screen and (max-width: 768px){
    margin-bottom: 0;
  }

}
.features-txt{
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  @media screen and (max-width: 576px){
    font-size: 14px;
    line-height: 21px;
    margin-right: 50px;

  }
}
.paragraph-header-txt{
  font-family: Raleway;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #263238;

  @media screen and (max-width: 768px) {
    margin: 0
  }
  @media screen and (max-width: 375px){
    font-family: Raleway;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 7px;
  }
}

.fourth-sec{
  margin-top: 10%;
  @media (max-width: 576px) {
    margin-top: 3%;
    display: flex;
    flex-wrap: wrap;

  }
}
.create-trips-txt{

  @media (max-width: 576px) {
    display: none;
  }
}
.create-trips-mobile-txt{
  display: none;
  @media (max-width: 576px) {
    display: flex;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #263238;
  }
}

.sec4-txt-body{
  margin-left: 5rem;
  padding-top: 7rem;
  @media screen and (max-width: 1920px){
   margin-left: 15rem;
  }
  @media (max-width: 576px) {
    margin-top: 40px;
    margin-left: 0;

  }
}
.sec4-head-txt{
  font-family: Raleway,sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  color: #263238;
  padding-left: 35px;
  padding-bottom: 20px;
  @media(max-width: 576px){
    font-size: 32px;
    line-height: 38.4px;
    padding-left: 0;
  }
}
.sec4-subhead-txt{
  font-family: Raleway;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #263238;
  padding-left: 35px;
  padding-bottom: 20px;
  @media (max-width: 576px) {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    padding-left: 0;

  }
}
.fourth-sec-right{
  margin-left: 30px;
  @media screen and (max-width: 768px){
    margin: 0;
  }
  @media screen and (max-width: 576px){
  margin-top: 50px;
  }
}
.fourth-sec-left{
  @media screen and (max-width: 1920px){

  }
  @media screen and (max-width: 768px){
    margin-top: 40px;
    margin-left: 0;
    margin-right: 50px;
  }
  @media screen and (max-width: 576px){
        margin-top: 40px;


  }
}

.fifth-sec{
  text-align: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 8%;
  @media screen and (max-width: 768px){
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 576px){
    display: flex;
    flex-wrap: wrap;
    margin-top: 5rem;
  }
}

.sec5-bold-txt{
  font-family: Raleway;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  color: #263238;
  @media (max-width: 576px) {
   display: none;
  }
}
.sec5-bold-txt-mobile{
  display: none;
  @media (max-width: 576px) {
    display: flex;
    font-family: Raleway;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: left;
    color: #263238;
  }
}

#sec-5-paragraph{
  @media screen and (max-width: 375px){
    padding-right: 10px;
  }
}

.fifth-sec-left{
  @media screen and (max-width: 768px){
  }
}
.fifth-sec-bold-txt{
  @media screen and (max-width: 768px){
    margin: 0;
  }
  @media screen and (max-width: 375px){
    margin: 0px;
  }
}

.fifth-sec-img{
  @media screen and (max-width: 768px){
    padding: 0;
    margin: 40px 150px 0 0;
  }
  @media screen and (max-width: 576px){
    width: 90%;
    margin-right: 50px;
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
.sixth-sec {
  text-align: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 8%;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 576px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-top: 4rem;
  }
}
.sec6-bold-txt{
  font-family: Raleway;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  color: #263238;
  @media (max-width: 576px) {
    font-size: 32px;
    line-height: 38.4px;
  }
}

.sec-6-img-div{

  @media screen and (max-width: 768px){
    margin: 50px 80px 0 0;
  }
  @media screen and (max-width: 576px){

  }

}
.sec-6-txt-div{
  margin-left: 30px;
  @media screen and (max-width: 768px){
    margin: 50px 0 0;
  }
  @media screen and (max-width: 375px){
   margin-right: 0;
    margin-top: 50px;
  }
}
.seventh-sec{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 13%;
  height: fit-content;
  padding: 100px;
  border-radius:24px;
  background-color: #D7E6FC;
  background-image: url("../../../assets/ribs-7th-sec-background-img.svg");
  @media screen and (max-width: 576px){
    display: flex;
    justify-content: left;
    text-align: left;
    width: 375px;
    height: fit-content;
    border-radius: 0;
    margin: 60px 0 0;
    padding: 20px;
  }
}
.sec-7-head-txt{
  font-family: Raleway;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  color: #263238;
  @media screen and (max-width: 576px){
    font-size: 32px;
    line-height: 48px;
    text-align: left;
  }
}

#sec-7-paragraph{
  @media screen and (max-width: 576px){
    font-family: Raleway,sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #2D3348;
    margin-top: 10px;
    width: 100%;
  }
}
.padding{
    display: flex;
    flex-direction: column;
    align-items: center;
  @media (max-width: 576px) {
    display: flex;
   width: 100%;
  }

}
.sec-7-btn{
  @media screen and (max-width: 576px){
    margin-right: 50px;
    margin-bottom: 20px;

  }
}

</style>