<template>
    <v-slide-y-transition>

  <section class="second-section tw-flex tw-flex-col tw-justify-center tw-items-center hundred-vh">
    <div class="second-section-header  group-1">
      <p>Your Favourite Travel Buddy</p>
    </div>

    <div class="second-section-subtext tw-flex-col tw-mt-5 md:tw-mt-1 group-1">
      <p>Leverage Nomadicpod For a Splendid Travel Experience</p>
    </div>

    <div class="
            tw-flex tw-w-full
            lg:tw-items-center
            text-center
            tw-flex-col tw-justify-center
            lg:tw-flex-row lg:tw-h-full
            md:tw-flex-row
            tw-pt-28 tw-flex-wrap
          ">
      <v-col sm="12" md="4" class="
              tw-flex tw-flex-row tw-mt-4
              md:tw-mt-0
              tw-items-center
              lg:tw-justify-between
              tw-justify-center
            ">
        <div class="tw-flex tw-items-center tw-flex-col">
          <img src="../assets/homepage/box-one.svg" alt="" class="second-section-img" />
          <div class="notes mt-12">
            <span class="heading ">Save Money</span>
            <p class="text-box">Get discounted prices when booking with Nomadicpod</p>

          </div>
        </div>
        <img src="../assets/Line-box.svg" alt="" class="tw-hidden lg:tw-flex" />
      </v-col>
      <v-col sm="12" md="4" class="
              tw-flex tw-flex-row tw-mt-4
              md:tw-mt-0
              tw-items-center tw-justify-end
            ">
        <div class="
                tw-flex tw-items-center
                lg:tw-justify-start
                tw-justify-center
                lg:tw-w-11/12
                tw-w-full tw-flex-col
              ">
          <img src="../assets/homepage/box-two.svg" alt="" class="second-section-img-two" />
          <div class="notes mt-5">
            <span class="heading">Avoid Long Queues</span>
            <p class="text-box ">With your booking code you can fill all your details in before getting to
              the terminal</p>

          </div>
        </div>
        <div class="tw-w-1/12 tw-justify-end tw-hidden lg:tw-flex">
          <img src="../assets/Line-box.svg" alt="" />
        </div>
      </v-col>
      <v-col sm="12" md="4" class="
              tw-flex tw-flex-row tw-mt-4
              md:tw-mt-0
              tw-items-center tw-justify-center
            ">
        <div class="tw-flex tw-items-center tw-flex-col">
          <img src="../assets/homepage/box-three.svg" alt="" class="second-section-img-third" />
          <div class="notes mt-1 ">
            <span class="heading ">Book With Comfort</span>
            <p class="text-box ">Book online and avoid the hassle of standing on a queue</p>

          </div>
        </div>
      </v-col>
    </div>
  </section>
      </v-slide-y-transition>

</template>

<script>
  export default {

  }
</script>

<style lang='scss' scoped>
  .second-section {
    background-image: url("../assets/sec-three.png");
    object-fit: cover;
    background-size: cover;
    padding-top: 5%;
    padding: 5rem 10rem;

    @media (max-width:1440px) {
      padding: 5rem;

    }

    @media (max-width:1264px) {
      padding: 5rem 0;

    }

    @media (max-width:576px) {
      padding: 5rem 2rem;

    }
  }

  .second-section-header {
    font-family: Raleway, serif !important;
    font-weight: 600;
    font-size: 38px;
    // width: 450px;
    line-height: 56px;
    text-align: center;
    color: #263238;

    @media screen and (max-width: 768px) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .second-section-subtext {
    font-family: Inter, serif !important;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    width: 420px;
    text-align: center;
    color: #263238;
  }

  // .second-section-img {
  //   width: 225px;
  //   height: 160px !important;
  //   @media screen and (min-width: 768px) {
  //     height: 257px;
  //   }
  // }

  // .second-section-img-two {
  //   width: 180px;
  //   height: 160px !important;
  //   @media screen and (min-width: 768px) {
  //     height: 157px;
  //   }
  // }

  // .second-section-img-third {
  //   width: auto;
  //   height: 160px !important;
  //   @media screen and (min-width: 768px) {
  //     height: 157px;
  //   }
  // }


  .text-box {
    font-family: 'Inter';
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #263238;
    padding-top: 20px;
    width: 270px;
  }

  .notes {
    width: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .heading {
      font-family: 'Raleway';
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #004AAD;
    }
  }
</style>