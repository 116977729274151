<template>
  <div class="step-two">
    <loader :loading="loading" v-if="loading" />
    <div class="tw-w-full" v-else>
      <nav-bar />
      <booking-steps />
      <div class="display-center">
        <div class="div-sculptor">
          <validation-observer ref="observer" v-slot={}>
            <form>
              <div class="from sizing sm:tw-mb-20 tw-mb-10">
                <p class="medium-text sm:tw-pb-8 tw-pb-4">Enter your preferred pickup details</p>

                <div class="tw-flex">
                  <Icons class="mr-3 mb-1" name="clock" color="#004aad" />
                  <span class="small-brown-text">
                    PickUp Time
                  </span>
                </div>
                <ValidationProvider name="Pickup Time" rules="required" v-slot="{ classes, errors }">
                  <div class="mt-4" :class="classes">
                    <v-combobox color="#004aad" v-model="hiringDetails.pickUpTime" :items="timeOfTheDay"
                      placeholder="6:00AM" append-icon="mdi-chevron-down" solo hide-details>
                    </v-combobox>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div>
                <div class="from sizing">
                  <div class="tw-flex tw-items-center">
                    <Icons class="mr-3" name="from2" color="#004aad" />
                    <label class="small-brown-text">
                    Pickup address
                  </label>
                  </div>
                  <div class="tw-py-4 tw-cursor-pointer" @click="handlePickUpAddress">
                    <ValidationProvider name="Pickup address" rules="required" v-slot="{ classes, errors }">
                      <div class="mt-4" :class="classes">
                    <v-text-field  v-model="getPickupAddress" solo hide-details placeholder="371, Borno way, Alagomeji Yaba, Lagos"></v-text-field>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <Icons name="line" class="tw-mb-2 ml-2 line" />
                <div class="from sizing">
                  <div class="tw-flex tw-items-center">
                    <Icons class="mr-3" name="to" color="#004aad" />
                    <label class="small-brown-text">Drop off address</label>
                  </div>
                  <div  class="tw-py-4 tw-cursor-pointer" @click="handleDropOffAddress">
                    <ValidationProvider name="Drop off address" rules="required" v-slot="{ classes, errors }">
                      <div class="mt-4" :class="classes">
                       <v-text-field  v-model="getDropOffAddress" solo hide-details placeholder="31 Akpakpava road way Benin city, Edo"></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                  </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="shift-end btn-divs">
                <button class="previous-btn mr-5" @click="$router.push({path:'/hire'})">Step 1</button>
                <button class="btn-design" @click="saveLocationData">Next</button>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
    <modal section="custom-address" :address="selectedAddress"  :dialog="showAddressModal" @handleAddress="handleAddress"  @close="closeHandleModal"/>

  </div>
</template>

<script>
  import BookingSteps from '../../components/reuseables/hire/BookingSteps.vue'
  import NavBar from "@/home/Navbar.vue";
  import Loader from "@/components/reuseables/Loader.vue";
  import Icons from '../../components/reuseables/Icons.vue';
  import StateAndCountry from "@/assets/citiesandstateandcountry.json"
  import {
    mapGetters
  } from 'vuex';
  import Modal from "../../components/reuseables/Modal.vue";
  export default {
    name: "Location",
    components: {
      Modal,

      Loader,
      BookingSteps,
      NavBar,
      Icons
    },
    data() {
      return {
        selectedTrip: [],
        selectedTripBtn: false,
        loading: false,
        menu2: false,
        pickUpAddress:{},
        dropOffAddress:{},
        selectedAddress:{},
        pickupTime:"",
        addressType:"",
        locationAndTime: {
          pickUpAddress: "",
          pickUpTime: "",
          dropOffAddress: ""
        },
        pickupSearchResults: [],
    pickupLocationInput: '',
    pickupIsLoading: false,
    pickupLocation: '',

    dropOffSearchResults: [],
    dropOffLocationInput: '',
    dropOffIsLoading: false,
    dropOffLocation: '',
        timeOfTheDay: [
          "12:00am",
          "12:15am",
          "12:30am",
          "12:45am",
          "1:00am",
          "1:15am",
          "1:30am",
          "1:45am",
          "2:00am",
          "2:15am",
          "2:30am",
          "2:45am",
          "3:00am",
          "3:15am",
          "3:30am",
          "3:45am",
          "4:00am",
          "4:15am",
          "4:30am",
          "4:45am",
          "5:00am",
          "5:15am",
          "5:30am",
          "5:45am",
          "6:00am",
          "6:15am",
          "6:30am",
          "6:45am",
          "7:00am",
          "7:15am",
          "7:30am",
          "7:45am",
          "8:00am",
          "8:15am",
          "8:30am",
          "8:45am",
          "9:00am",
          "9:15am",
          "9:30am",
          "9:45am",
          "10:00am",
          "10:15am",
          "10:30am",
          "10:45am",
          "11:00am",
          "11:15am",
          "11:30am",
          "11:45am",

          "12:00pm",
          "12:15pm",
          "12:30pm",
          "12:45pm",
          "1:00pm",
          "1:15pm",
          "1:30pm",
          "1:45pm",
          "2:00pm",
          "2:15pm",
          "2:30pm",
          "2:45pm",
          "3:00pm",
          "3:15pm",
          "3:30pm",
          "3:45pm",
          "4:00pm",
          "4:15pm",
          "4:30pm",
          "4:45pm",
          "5:00pm",
          "5:15pm",
          "5:30pm",
          "5:45pm",
          "6:00pm",
          "6:15pm",
          "6:30pm",
          "6:45pm",
          "7:00pm",
          "7:15pm",
          "7:30pm",
          "7:45pm",
          "8:00pm",
          "8:15pm",
          "8:30pm",
          "8:45pm",
          "9:00pm",
          "9:15pm",
          "9:30pm",
          "9:45pm",
          "10:00pm",
          "10:15pm",
          "10:30pm",
          "10:45pm",
          "11:00pm",
          "11:15pm",
          "11:30pm",
          "11:45pm",
        ],
        showAddressModal:false,
        statesAndCountry:StateAndCountry
      }
    },
    metaInfo() {
      return {
        script: [{
          src: `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}&libraries=places`,
          async: true,
          defer: true,
          callback: () => this.MapsInit() // will declare it in methods
        }]
      }
    },
    watch: {
      location(newValue) {
        if (newValue) {
          this.service.getPlacePredictions({
            input: this.location,
            types: ['address']
          }, this.displaySuggestions)
        }
      },
      hiringDetails: {
        handler : function (val) {
          if (val){
            let originCity = this.getSelectedState(val.vehicleSelected.departureCity)
            this.hiringDetails.pickUpLocation.country = originCity.country
            this.hiringDetails.pickUpLocation.state = originCity.state
            this.hiringDetails.pickUpLocation.city = originCity.city
            let  arrivalCity = this.getSelectedState(val.vehicleSelected.destinationCity)
            this.hiringDetails.dropOffLocation.country = arrivalCity.country
            this.hiringDetails.dropOffLocation.state = arrivalCity.state
            this.hiringDetails.dropOffLocation.city = arrivalCity.city
          }
        }
      },
    },
    computed: {
      ...mapGetters('hire', ['hiringDetails']),
      getPickupAddress(){
        if (this.hiringDetails.pickUpLocation.houseNumber) {
          return this.toSentenceCase(this.hiringDetails.pickUpLocation.houseNumber) + " " + this.toSentenceCase(this.hiringDetails.pickUpLocation.streetName) +
              ", " + this.toSentenceCase(this.hiringDetails.pickUpLocation.city) + " " + this.toSentenceCase(this.hiringDetails.pickUpLocation.state)
        }
        else {
          return ""
        }
      },
      getDropOffAddress(){
        if (this.hiringDetails.dropOffLocation.houseNumber) {
          return this.toSentenceCase(this.hiringDetails.dropOffLocation.houseNumber) + " " + this.toSentenceCase(this.hiringDetails.dropOffLocation.streetName) +
              ", " + this.toSentenceCase(this.hiringDetails.dropOffLocation.city) + " " + this.toSentenceCase(this.hiringDetails.dropOffLocation.state)
        }
        else {
          return ""
        }
      },
    },
    methods: {
      getSelectedState(cityName){
        return this.statesAndCountry.find(city => city.city.toLowerCase() === cityName.toLowerCase())
      },
      MapsInit() {
        this.service = new window.google.maps.places.AutocompleteService()
      },
      displaySuggestions(predictions, status) {
        this.isLoading = true
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          this.searchResults = []
          return
        }
        this.searchResults = predictions.map(prediction => prediction.description)
        this.isLoading = false
      },
      saveLocationData() {
        // this.hiringDetails.pickUpLocation = this.pickUpAddress
        // this.hiringDetails.dropOffLocation = this.dropOffAddress
        this.hiringDetails.pickupTime = this.pickupTime
        this.$store.dispatch("hire/hiringDetails", this.hiringDetails);
        // console.log(this.locationAndTime);
        this.$router.push({
          path: '/hire-input-details'
        })
      },
      closeHandleModal(){
        this.showAddressModal = !this.showAddressModal
      },
      handlePickUpAddress(){
        this.showAddressModal = true
        this.addressType = "pickup"
        this.selectedAddress = this.hiringDetails.pickUpLocation
      },
      handleDropOffAddress(){
        this.showAddressModal = true
        this.addressType = "dropOff"
        this.selectedAddress = this.hiringDetails.dropOffLocation
      },
      handleAddress(address){
        if (this.addressType === "pickup"){
          this.hiringDetails.pickUpLocation = address
        }
        else if (this.addressType === "dropOff"){
          this.hiringDetails.dropOffLocation = address
        }
      },
      toSentenceCase(text) {
        text = text.toLowerCase();
        return text.replace(text.charAt(0), text.charAt(0).toUpperCase()).trim();
      }
    },
    created() {
      let hiringDetails = JSON.parse(sessionStorage.getItem("hiringDetails"))
      if (hiringDetails){
        this.$store.dispatch("hire/hiringDetails",hiringDetails)
        // this.pickupTime = hiringDetails.pickupTime
        // this.pickUpAddress = hiringDetails.pickUpAddress
        // this.dropOffAddress = hiringDetails.dropOffAddress
      }
    }

  }
</script>
<style lang="scss" scoped>
  .div-sculptor {
    width: 700px;
    margin-top: 30px;
    margin-bottom: 70px;

    @media screen and (max-width: 768px) {
      width: 500px;
      margin-top: 50px;
      margin-bottom: 50px;

    }

    @media screen and (max-width: 576px) {
      width: 100%;
      padding-left: 2rem;
      padding-right: 2rem;
      margin-top: 30px;
      margin-bottom: 50px;

    }
  }

  .medium-text {
    font-family: Inter,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    color: #004AAD;
    text-transform: none;

    @media screen and (max-width:576px) {
      font-size: 16px;
    }
  }

  .small-brown-text {
    color: #4F4F4F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width:576px) {
      font-size: 14px;
    }
  }

  .btn-design {
    border-radius: 4px;
    border: 1px solid #004AAD;
    background: #004AAD;
    color: #FFF;
    font-family: Inter;
    width: 150px;
    height: 50px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 15px;

    @media screen and (max-width:576px) {
      width: 100%;
    }
  }

  .previous-btn {
    border-radius: 4px;
    border: 2px solid #004AAD;
    color: #004aad !important;
    font-family: Inter;
    width: 150px;
    height: 50px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 15px;

    @media screen and (max-width:576px) {
      width: 100%;
    }
  }

  .btn-divs {
    margin-top: 70px;

    @media screen and (max-width:768px) {
      margin-top: 50px;
    }
  }
  .address-btn{
    font-family: "Inter", sans-serif;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    color: #004AAD;
  }
</style>