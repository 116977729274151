<template>
  <div class="blogpage">
    <Navbar />
    <div class="section-one">
      <v-col lg="5" sm="12" class="intro-text">
        <h1>Get the Juicy Scoop On Our Exciting Journey and More.</h1>
      </v-col>
      <v-col lg="7" sm="12" class="tw-hidden md:tw-flex  tw-items-center">
        <div class="tw-flex tw-flex-wrap intro-div">
          <v-card flat class="tw-flex tw-flex-row card-img">
            <img
              style="width: 50%; height: 100%" alt=""
              src="../assets/blogimages/intro.png"
            />
            <v-card flat class="tw-p-10">
              <v-btn style="text-transform: none">Recent</v-btn>
              <h1 class="title pt-8 pb-4">
                The Building Blocks For Developing Travel Applications With Ease
              </h1>
              <div class="title-text one ">{{ recentDesc }}</div>
              <div class="title-text two">{{ anotherSize }}</div>
              <div class="title-text three">{{ displayRecent }}</div>
            </v-card>
          </v-card>
        </div>
      </v-col>
    </div>

    <Blogpost  />
    <v-divider></v-divider>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../home/Navbar.vue";
import Footer from "../home/Footer.vue";
import Blogpost from "../components/reuseables/Blogpost.vue";

export default {
  name: "Blog",
  components: {
    Navbar,
    Footer,
    Blogpost,
  },
  data() {
    return {
      intropic: "https://unsplash.com/photos/2THdTdKs0yU",
      recentDesc:
        "We re inspired by a vision of millions of road commuters and the thousands of businesses across the country who use the road to carry out their daily activities and businesses.",
      displayRecent: "We re inspired by a vision of millions of road commuters",
      anotherSize:
        "We re inspired by a vision of millions of road commuters and the thousands of businesses across the country",
    };
  },
};
</script>

<style lang="scss" scoped>
.blogpage {
  background-color: #fdfffc;
}

.section-one {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 0 10rem;
  justify-content: center;

  @media screen and (max-width: 1264px) {
    flex-direction: column;
  }

  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
  }

  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
}

.intro-div {
  width: 85%;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.05) !important;
  border-radius: 10px;

  @media screen and (max-width: 1600px) {
    margin: 0 auto;
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    width: 90%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.card-img {
  width: 100%;
  height: 450px;

  @media screen and (max-width: 600px) {
    height: 400px;
    padding: 20px;
  }
}
.card-img img {
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.intro-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;

  @media screen and (max-width: 1264px) {
    padding-top: 10%;
    padding-bottom: 10%;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {

    padding-top: 30%;
    padding-bottom: 15%;
  }

  @media screen and (max-width: 591px) {
    padding-left: 1rem;

    width: 100%;
  }
}

.intro-text h1 {
  font-family: 'Raleway',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 54px;
  color: #4F4F4F;
  width: 58%;

  @media screen and (max-width: 1600px) {
    width: 75%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }

}

.v-image__image {
  border-radius: 1px !important;
}

.v-btn {
  background: #54b1dd !important;
  border-radius: 4px;
  color: #fff;
  padding: 5px 10px;
  margin-top: 4%;
}

.title-text {
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #4F4F4F;
}

.title {
  font-family: 'Raleway',sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  line-height: 36px;
  color: #4F4F4F;

}

@media screen and (max-width: 600px) {
  .one {
    display: none !important;
  }

  .two {
    display: block !important;
  }
}

@media screen and (max-width: 1024px) {
  .two {
    display: block;
  }

  .three,
  .one {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  .one {
    display: block;
  }

  .two,
  .three {
    display: none !important;
  }
}
</style>