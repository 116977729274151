<template>

     <v-col sm="12" class="left-side-body">
        <div class="page-three group-padding">
     <img src="@/assets/logos/white.png" alt="" width="150">
     <div class="top-section">

        <h1 class="greeting mb-5 text-1">Hey Anielila!</h1>
        <p class="setup">Let’s Get Your Profile Set Up</p>
        <p class="text-2">It’s great to have you on this space!</p>
         <ol class="pl-0 onboarding-lists">
          <li class="onboarding-lists-list">
            <div class="circle" :class="currentPage === 'OnboardingIdentity' ? 'active' : ''">
              <span class="span-tag" :class="currentPage === 'OnboardingIdentity' ? 'active' : ''">1</span>
            </div>
            <span class="span-tag" :class="currentPage === 'OnboardingIdentity' ? 'active' : ''">Personal Identity</span>
          </li>
          <li class="onboarding-lists-list">
            <div class="circle" :class="currentPage === 'OnboardingAddress' ? 'active' : ''">
              <span class="span-tag" :class="currentPage === 'OnboardingAddress' ? 'active' : ''">2</span>
            </div>
            <span class="span-tag" :class="currentPage === 'OnboardingAddress' ? 'active' : ''">Address</span>
          </li>
          
        </ol>
     </div>
      
       </div>

    </v-col>  

</template>

<script>
export default {
   computed: {
      currentPage() {
        // console.log(this.$route)
        return this.$route.name
      }
    },
    methods:{
      goHome(){
        window.open(process.env.VUE_APP_MAIN_FRONTEND_URL,'_self')
      },
    }

}
</script>

<style lang="scss" scoped>

.span-tag{
    color: #fff;
}
.onboarding-lists {
  margin-top: 36px;
}

.onboarding-lists .onboarding-lists-list {
  display: flex;
  align-items: center;
  margin: 16px 0px;
  font-size: 16px;
  font-style: normal; 
  font-weight: 500; 
  line-height: 24px;
  font-family: "Inter";  
  letter-spacing: 0.05em;
}
.onboarding-lists .onboarding-lists-list > .circle {
  margin-right: 12px;
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  width: 33px;
  border-radius: 50%;
}
.onboarding-lists .onboarding-lists-list .circle.active {
  height: 44px;
  width: 44px;
  margin-left: -5px;
  border-width: 3px;
  font-size: 20px;
  font-weight: 600;
}
.onboarding-lists .onboarding-lists-list > .span-tag:first-child {
  margin-right: 12px;
  border: 1px solid #ffffff;
  height: 50px;
  width: 50px;
}
.onboarding-lists .onboarding-lists-list > .span-tag.active:first-child {
  border: 2px solid #ffffff;
  padding: 13px 14px;
  font-weight: 600;
  font-size: 21px;
}
.onboarding-lists .onboarding-lists-list > .span-tag.active:last-child {
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.text-2{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 15px;
color: #FFFFFF;

}
.setup{
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 33px;
color: #FFFFFF;
text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);  

}
.identity{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 24px;
color: #004AAD;
}

.left-side-body{
 
 padding: 5rem 8rem ;
 @media (max-width:1500px) {
   padding: 3rem 4rem;
 }
}
.top-section{
    padding-top: 10rem;
}
.text-1{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 19px;
line-height: 23px;
color: #FFFFFF;
}
</style>