<template>
  <div class="complete my-auto">
    <div class="linear my-6">
        <v-progress-linear
      color="#004aad"
      rounded
      height="9"
      value="100"
    ></v-progress-linear>
    <p v-if="status" class="note mt-2">Complete</p>
    <p v-else class="note mt-2">100% Done</p>
    </div>
     
      <h1>You're All Set!</h1>
      <div class="adjustments mt-4" v-if="status">
      <p>Now you’re ready to use this  space with ease and please</p>

      </div>
      <div class="adjustments mt-4" v-else>
      <p>Don't forget to fill in your remaining details later</p>

      </div>
      <a>Go to Dashboard</a>
  </div>
</template>

<script>
export default {
  name:"RegistrationComplete",
data(){
  return{
    status: false,
  }
}
}
</script>

<style lang="scss" scoped>
.complete{
    text-align: center;
     margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}
.v-progress-linear{
 width: 300px;
 
}
.v-application a{
    color: #004aad !important;
}
h1{
    
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 24px;
color: #004AAD;

}
p{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
color: #828282;
width: 250px;


}
.adjustments, .linear{
display: flex;
justify-content: center;
align-items: center ;
}
.linear{
  flex-direction: column;
  padding-bottom: 20px;
}
.note{
font-family: 'Inter';
font-weight: 500;
font-size: 18px;
color: #004AAD;
}
</style>