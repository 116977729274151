<template>
<div class="mobile">
  <div class="cover">
       <h1 class="heading mb-5">   Terms Of Use</h1>
        
      
 <div id="Acceptance of Terms">
            <h1 class="header">1.0 Acceptance of Terms</h1>
            <br />
            <p>
              1.1. By using the Nomadicpod website at www.nomadicpod.com as well as
              any service, content or data available via it, you as a Service
              User herein referred to as “The User” agrees to be bound by the
              terms of this Agreement with Nomadicpod Technologies Limited trading
              as Nomadicpod hereinafter referred to as “Nomadicpod”;
            </p>
            <p>
              1.2. This Agreement is to be read together with the supplemental
              terms and policies found on the website. The User hereby agrees to
              the binding effects of the supplemental terms and policies by
              using the website.
            </p>

            <p>These terms can be updated from time to time.</p>

            <p>
              1.3. To interpret this Agreement, headings where used are for
              reference purposes only and in no way define, or limit the scope
              or extent of its content. Parties to this Agreement will include
              the Parent company, and its related affiliates or entities,
              directors and employees;
            </p>
          </div>
          <div id="Definition of Terms" class="pt-6">
            <h1 class="header">2.0 Definition of Terms</h1>
            <br />
            <p>
              2.1. The under listed terms as used in this Agreement are defined
              as follows:
            </p>
            <p>
              2.1.1 “Username” refers to the unique identification recorded on
              the website to identify each provider and by which the provider
              may access and use the site.
            </p>

            <p>
              Affiliate refers to a party who represents or is connected with
              any entity or person who uses the site Agreement refers to this
              document in its entirety as well as any other terms and conditions
              reffered to on the website.
            </p>
            <p>
              Provider refers to any individual, person or entity who has
              registered on the website with an intention to provide travel
              services in accordance with this Agreement
            </p>
            <p>
              Provider Services refers to the wide range of travel and tour
              related services that can be listed on the site
            </p>
            <p>
              Provider Terms refers to the terms and conditions issued by the
              Provider and agreed to by the User in line with this Agreement ,
              and which governs the fulfillment of the request by the User
            </p>
            <p>
              Service User refers to any individual, person or entity who has
              registered on the website with an intention to submit travel
              requests and get services of providers in accordance with this
              Agreement.
            </p>
            <p>Service Users will be generally referred to as “Users”.</p>
            <p>
              Site refers to the entire web platform operated by Nomadicpod,
              accessible to the public at
              <a href="/" class="link"> nomadicpod.com </a>
              and as updated from time to time.
            </p>
          </div>
          <div id="Disclaimer of Warranties and Liability" class="pt-6">
            <h1 class="header">3.0 Disclaimer of Warranties and Liability</h1>
            <p>
              3.1 Nomadicpod connects Service Providers to Users and provides this
              service via the website on “as is” and “as available” basis
              without any representation or warranties, express, or implied as
              to the reliability, trustworthiness, credit worthiness or other
              attribute or characteristic of any User or Provider.
            </p>
            <p>
              3.2 Without prejudice to the foregoing, Nomadicpod does not warrant
              that:
            </p>
            <p>(a) the website will be available constantly, or at all</p>
            <p>
              (b) the information on the website, provided by any party is true,
              complete, and accurate, up-to-
            </p>
            <p>
              (c) it is liable for any loss or damage due to a delay of the
              webiste in transmitting information between Users and Providers or
              due to removing any information by User or Provider from the
              website.
            </p>
            <p>
              (e) any information on the website be taken as a professional
              advice;
            </p>
            <p>
              (f) it is a party to any transaction between User and Provider and
              that it guarantees the ability of Users to make the complete
              payment for any of the Provider Services rendered.
            </p>
            <p>
              (g) it or its affiliates and entities have any responsibility for
              the actions of Users and other Providers. 3.3 Nomadicpod will not be
              liable for any inconvenience, loss, or damages, caused by
              unavailability, interruptions or difficulties accessing the
              website, for any period of time.
            </p>
            <p>
              3.4 Nomadicpod provides no guarantee of the ability of the Provider
              to provide any service or of the User to procure a service; or of
              the ability of Users to pay for any service listed on the website.
              Nomadicpod is not to be held accountable for any action, inaction,
              or obligations of the Provider or User.
            </p>
            <p>
              3.5. Exclusion and limitation of liability will apply to the
              greatest extent consistent with applicable law of the
              jurisdiction.
            </p>
          </div>
          <div id="User Account Creation and Eligibility" class="pt-6">
            <h1 class="header">4.0. User Account Creation and Eligibility</h1>
            <br />
            <p>
              4.1. To register, the intending User is required to create a
              unique Username and password with which to access the website. The
              User agrees that any person using the website with the Username
              and/or password is the User or where the User is an entity, the
              User’s authorized representative. Hence, the User is liable for
              all actions taken by such person with respect to their use of the
              website.
            </p>
            <p>
              4.2. Users understand that Nomadicpod may lock the User’s IP Address
              for security purposes.
            </p>
            <p>
              4.3. Persons under the age of 18 are not eligible to open a User
              account on the website. Where the User is a company or other legal
              entity, only a designate with the authority to bind the entity to
              this agreement should represent the entity with respect to
              creating an account on the website.
            </p>
            <p>
              4.4. The User is responsible to ensure their registration
              information on the website is up to date and accurate at all times
              and User understands that the User account cannot be transferred
              to another person or entity.
            </p>
            <p>
              4.5. All User and Provider information obtained at the point of
              registration on the website or subsequently would be used by
              Nomadicpod in accordance with its Privacy Policy which is available
              here.
            </p>
          </div>
          <div id="User Warranties" class="pt-6">
            <h1 class="header">5.0. User Warranties</h1>
            <br />
            <p>
              5.1 To continue to use the website, the User hereby agrees and
              warrants to Nomadicpod that:
            </p>
            <p>
              (a) you are under no legal or other impediments that may prevent
              complying and executing the obligations under this Agreement.
            </p>
            <p>
              (b) you and any representative will in all respect, act in a
              professional manner in dealing with Providers and any other
              personnel
            </p>
            <p>
              (c)you will pay your fees due to the Provider as agreed on the
              Provider Terms you agreed to.
            </p>
            <p>
              (d) you will not use the website for illegal, criminal, or
              unauthorized purposes.
            </p>
            <p>
              (e) you will notify the Administrator of any unauthorized access
              to your Account
            </p>
            <p>
              (f) you will not copy, download, reproduce, store, post,
              distribute and provide access to, or in anyother way use worms,
              viruses, trojans, malware or any types of malicious file.
            </p>
            <p>
              (g) you will not use anything on the website or make direct and
              indirect references to any website that is or might be in
              connection with any entity in competition with Nomadicpod.
            </p>
            <p>
              (h) you will not abuse any other person’s privacy by collecting
              information about addresses, phone numbers, and emails without
              consent.
            </p>
            <p>
              (i) you will not use the website to engage in marketing activities
              that are seen as misleading or deceptive.
            </p>
            <p>
              (j) you will not use the website to broadcast by any means
              unsolicited chain messages, commercial and junk emails, spam,
              Ponzi schemes to other Users of the website;
            </p>
          </div>
          <div id="Feedback on the website" class="pt-6">
            <h1 class="header">6.0 Feedback on the website</h1>
            <br />
            <p>
              6.1 The Users understand that while they may choose to resolve
              disputes with the Provider outside the website, they may post
              their feedback about the services of, and their experience with
              any Provider on the website and this will be available publicly on
              the public profile of the Provider. Feedback that can be submitted
              may include compliments, criticisms, comments and other
              observations. The feedback system will also have a star rating
              system on a scale between Poor and Excellent. Other Users on the
              Market place will be able to see this feedback and rating at a
              glance.
            </p>
            <p>
              6.2. Users are expected to keep their comments clean, honest,
              reasonable and fair at all times.
            </p>
            <p>
              6.3. The User understands that all information provided by all
              parties on the website feedback section will be treated as
              non-confidential and non-proprietary, as such Nomadicpod may use
              such information without notice and without restriction.
            </p>
            <p>
              6.4. The User must not post any information which might disrupt
              the flow of dialogue to the website, or information which is
              deemed as offensive, harassing, harmful, abusive, defamatory,
              unlawful, or vulgar or otherwise objectionable (to a person acting
              reasonably).Non-compliance with this term will result in Nomadicpod
              taking disciplinary action on the User.
            </p>
            <p>
              6.5. The User agrees not to impersonate any other person or access
              the website with a phony account in order to provide feedback on
              the website.
            </p>
            <p>
              6.6. The User understands that any of their post, comments or
              information provided on the website may be removed from the
              website by Nomadicpod at their sole discretion, and without notice,
              if such information is deemed to breach any of these terms.
            </p>
            <p>
              6.7. Users understand that while Providers are expected to respond
              to comments especially when a dispute has been raised by the User,
              Nomadicpod has no capacity to ensure compliance. Nomadicpod is not a
              party but an observer to the discussion and may take any action at
              its sole discretion and not with any obligation.
            </p>
            <p>
              6.8 Without limiting any of the rights under this Agreement,
              Nomadicpod may take a disciplinary action on a Provider or User’s
              account which may include restrictions to access the website
              especially if the Provider has received substantially negative
              feedback consistently over a period of time. This will be at the
              sole discretion of Nomadicpod
            </p>
          </div>
          <div id="Intellectual Property" class="pt-6">
            <h1 class="header">7.0 Intellectual Property</h1>
            <br />
            <p>
              7.1 All intellectual property rights for information and data
              found on the website including all software, trademarks, logos,
              processes, and other materials developed by Nomadicpod shall remain
              the exclusive property of Nomadicpod and its licensors. The User or
              anyone shall not acquire such rights.
            </p>
            <p>
              7.2 All information the Provider or any user at all submits during
              registration and any other information or material submitted to
              the website as a user generated content remain the Intellectual
              property of the User who uploaded or added such content.
              Notwithstanding the foregoing, the User agrees that Nomadicpod can
              store copies of these information for use as reasonably necessary
              for the running of the website.
            </p>
            <p>
              7.3 By virtue of using the website, you grant Nomadicpod the
              non-exclusive, worldwide, transferable and irrevocable right to
              store, publish, distribute, modify, adapt, and publicly display
              applicable content on the website and for use by Nomadicpod in
              marketing and advertising.
            </p>
            <p>
              7.4 The User acknowledges that Nomadicpod grants its access to the
              website, and all information on the website solely for the use of
              the User in accessing travel and tour services from a wide range
              of Providers. As such, any information gotten from the website
              cannot be sold, or repurposed outside the website.
            </p>
            <p>
              7.5 The User may not use any kind of data gathering or data
              extraction technology such as screen scraping, data mining devices
              on the website with an intent to reproduce any information
              contained therein except with Nomadicpod’s prior written consent.
            </p>
          </div>
          <div id="Suspension & Termination of accounts" class="pt-6">
            <h1 class="header">8.0 Suspension & Termination of accounts</h1>

            <br />
            <p>
              8.1 Where the User no longer wishes to access services on the
              website now or in the future, the User may delete its account
              without notice to Nomadicpod, as long as there are no Providers the
              User has an obligation towards.
            </p>
            <p>
              8.2 Without limiting other remedies available to Nomadicpod at law,
              in equity or under this Agreement, Nomadicpod may at any time, and
              without notice issue a warning, temporarily or indefinitely
              suspend a User by restricting their temporal or permanent access
              to the website, especially if:
            </p>
            <p>(a) The User has breached any terms of this Agreement;</p>
            <p>
              (b) The User cannot satisfactorily explain any act of misconduct
              or breach of the Agreement and/or;
            </p>
            <p>
              (c) The User’s actions may cause damage and/or legal liability for
              Nomadicpod, other Users or other Providers.
            </p>
            <p>
              8.3 This Agreement can be terminated by Nomadicpod at any time if:
            </p>
            <p>
              (a) the terms of this Agreement have been breached and the User
              has made no attempt to rectify the breach within a reasonable
              number of days of the User being notified by Nomadicpod in writing;
            </p>
            <p>
              (b)the terms have been breached and is not capable of remedy; or
            </p>
            <p>(c) the User becomes subject to any kind of insolvency event.</p>
          </div>
          <div id="Limitation of Liability" class="pt-6">
            <h1 class="header">9.0. Limitation of Liability</h1>
            <br />
            <p>
              9.1 In no event shall Nomadicpod and its affiliates be liable to the
              User, Provider or any third-party for any loss in profit or any
              direct, indirect consequential, special, indirect, incidental, or
              punitive damages arising from these terms or use of, or inability
              to use the website or in connection to any:
            </p>
            <p>
              (a) actions, inactions or omissions of any User, or Provider,
              including where the same results in a loss of or damage to goods.
            </p>
            <p>
              (b) use of the website and all information on it, provided by
              anyone even if Nomadicpod has advised of the possibility of such
              loss or damage.
            </p>
            <p>
              (c) removal or termination of the User or Provider’s access to the
              website.
            </p>
          </div>
          <div id="Indemnity" class="pt-6">
            <h1 class="header">10.0. Indemnity</h1>
            <br />

            <p>
              The User indemnifies and holds Nomadicpod non-liable to any claims,
              demands, or proceedings leading to losses and damages, including
              direct, indirect or special damages, made by any User, third-party
              or representative arising out of or in relation to the User’s use
              of the website, breach of Agreement or violation of any law or
              rights.
            </p>
          </div>
          <div id="Reservation of Rights" class="pt-6">
            <h1 class="header">11.0 Reservation of Rights</h1>
            <br />
            <p>11.1. Nomadicpod reserves the right to:</p>
            <p>
              (a) in its absolute discretion, refuse to post or relay, or to
              pull down any information and/or materials, whether in whole or in
              part, of any applicable law or of this Agreement without notice to
              the User; and
            </p>
            <p>
              (b) change or stop any of the services Nomadicpod offers via the
              website.
            </p>
          </div>
          <div id="Force Majeure" class="pt-6">
            <h1 class="header">12.0. Force Majeure</h1>
            <br />
            <p>
              Nomadicpod, Providers, and Users of the platform will attach no
              liability for any failure or delay in the performance of its
              obligations if it is attributable to an event of force majeure
              which includes system downtime of the website, server failure,
              civil unrest, strikes, flood, war or any act of God provided
              however, that in the event of a force majeure subsisting for more
              than one month, all parties affected will be deemed to have
              excused voluntarily from the transaction contemplated by this
              Agreement.
            </p>
          </div>
          <div id="Governing Law" class="pt-6">
            <h1 class="header">13.0 Governing Law</h1>
            <br />
            <p>
              13.1. The terms of this Agreement shall be governed in accordance
              with laws of the Federal Republic of Nigeria.
            </p>
          </div>
          <div id="miscellaneous" class="pt-6">
            <h1 class="header">14.0. Miscellaneous Provisions</h1>
            <br />
            <p>
              14.1 Severability - Except as otherwise provided, any provision in
              this Agreement, if held to be invalid, void or unenforceable such
              provision can be severed while the remaining provisions shall be
              enforced.
            </p>
            <p>
              14.2. No Waiver- Nomadicpod does not waive its right to take action
              in response to any breach by the User at any time, even if it
              waives a similar breach in the past.
            </p>
            <p>
              14.3 Entire Agreement - This Agreement and those policies
              incorporated by reference herein set out the entire understanding
              and agreement between the parties with respect to the subject
              matter hereof.
            </p>
            <p>
              14.4 Survival -Those clauses capable of surviving termination of
              this Agreement shall do so.
            </p>
            <p>
              14.5 Variation- Nomadicpod reserves the right to update this
              Agreement at any time and such revised agreements will be updated
              on the website.
            </p>
            <p>
              If you have any questions concerning this agreement, you may
              contact us by email : info@nomadicpod.com
            </p>
          </div>      
</div>
</div>
</template>

<script>
export default {
  name:"TermsMobile",
data: () => ({
      panel: [0, 1],
    }),
}
</script>

<style lang="scss" scoped>
.cover{
  background-color: #fff;
  padding: 30px;
}
.tree,
.open-tree {
  background-color: #ffff;
}
.lp-text{
    
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 54px;
color: #FFFFFF;

}
.open-tree {
  margin-left: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 3rem 4rem;
    overflow-y: auto;
  max-height: 100vh !important;
}
.open-tree p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  color: #4e5b64;

}

.link {
  color: #004aad;
  text-decoration: underline;
}
.header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #4e5b64;
}
.tree{
    background: #FFFFFF;
box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
border-radius: 10px;
    max-height: 100vh;
     overflow-x: hidden;
    overflow-y: auto;
}
.v-application a{
    
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 20px;
color: rgba(29, 38, 45, 0.71);

}
.v-application a:hover {
    color: #004AAD;
}
span{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
color: #004AAD;
}
.heading{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
color: #004AAD;

}
</style>