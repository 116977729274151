import Vue from "vue";
import VueRouter from "vue-router";
// import nonlandingpageView from "../views/nonlandingpageView.vue";
import TransportCompanyRegisterView from "../views/TransportCompanyRegisterView";
import CorporateAgentRegisterView from "../views/CorporateAgentRegisterView";

import LoginView from "../views/LoginView";
import ResetPasswordView from "../views/ResetPasswordView";


import HomePage from "@/home/landingpage/Main.vue";
import About from "@/views/About.vue";
import Blog from "@/views/Blog.vue";
import Faq from "@/views/Faq.vue";
import Contact from "@/views/Contact.vue";
import Career from "@/views/Career.vue";
import signup from "@/views/Customer/SignUp.vue"
import OnboardingIdentity from "@/views/Customer/OnboardingIdentity.vue"
import OnboardingAddress from "@/views/Customer/OnboardingAddress.vue"
import newlandingpage from "@/home/New.vue"
import TermsPage from "@/components/reuseables/TermsPage";
import complete from "@/views/Customer/Complete.vue"
import OneWaySelectTrip from "@/views/OneWay/SelectBus.vue"

import HireLocation from "@/views/hire process/Location.vue"
import HireInputDetails from "@/views/hire process/InputDetails"
import HirePayment from "@/views/hire process/Payment.vue"
import HireSelectVehicle from "@/views/hire process/SelectVehicle.vue"

import OneWaySelectSeats from "@/views/OneWay/SelectSeats.vue"
import OneWayInputDetails from "@/views/OneWay/InputDetails"
import OneWayCheckout from "@/views/OneWay/CheckOut.vue"
import ChooseYourNiche from "@/views/ChooseYourNiche.vue"
import blogpages from '../components/reuseables/Blogpages.vue'

import CompanyOnboardingIdentity from '@/views/TransportCompany/CompanyOnboardingIdentity.vue'
import CompanyOnboardingAddress from '@/views/TransportCompany/CompanyOnboardingAddress.vue'
import CompanyOnboardingContact from '@/views/TransportCompany/CompanyOnboardingContact.vue'
import CompanyOnboardingVerification from '@/views/TransportCompany/CompanyOnboardingVerification.vue'
import CompanyOnboardingAccountDetails from '@/views/TransportCompany/CompanyOnboardingAccountDetails.vue'

import CorporateAgentOnboardingIdentity from '@/views/CorporateAgent/OnboardingIdentity.vue'
import CorporateAgentOnboardingVerification from '@/views/CorporateAgent/OnboardingVerification.vue'
import CorporateAgentAccountDetails from '@/views/CorporateAgent/OnboardingAccountDetails.vue'
import CorporateAgentOnboardingAddress from '@/views/CorporateAgent/OnboardingAddress.vue'
import NewAbout from "@/views/NewAbout.vue";
import landingpage from "@/home/landingpage/Main"
import Nibs from "@/home/landingpage/products/Ribs.vue";
import Flair from "@/home/landingpage/products/Flair.vue";
import Skip from "@/home/landingpage/products/Skip.vue";
import Tan from "@/home/landingpage/products/Tan.vue";



Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "HomePage",
    component: HomePage,
  }, {
    path: '*',
    redirect: '/404'
  },
  {
    path: "/new-lp",
    name: "LandingPage",
    component: landingpage,
  },
  {
    path: '/corporate-agent/onboarding-verification',
    name: "CorporateAgentOnboardingVerification",
    component: CorporateAgentOnboardingVerification
  },
  {
    path: '/corporate-agent/onboarding-identity',
    name: "CorporateAgentOnboardingIdentity",
    component: CorporateAgentOnboardingIdentity
  },
  {
    path: '/corporate-agent/onboarding-address',
    name: "CorporateAgentOnboardingAddress",
    component: CorporateAgentOnboardingAddress
  },
  {
    path: '/corporate-agent/onboarding-account-details',
    name: "CorporateAgentAccountDetails",
    component: CorporateAgentAccountDetails
  },
  {
    path: "/round-trip-select-trip",
    name: "RoundTripSelectTrip",
    component: () => import("@/views/bookings/ReturnTrips")
  },

  {
    path: "/round-trip-select-seats",
    name: "RoundTripSelectSeats",
    component: () => import("@/views/bookings/SelectSeatsForReturnTrip")
  },
  {
    path: "/round-trip-input-traveller-details",
    name: "RoundTripInputTravellerDetails",
    component: () => import("@/views/bookings/ReturnTripTravellerDetails")
  },
  {
    path: "/awaiting-verification",
    name: "AwaitingVerification",
    component: () => import("../components/reuseables/CompanyAwaitVerification.vue")
  },
  {
    path: "/round-trip-check-out",
    name: "RoundTripCheckout",
    component: () => import("@/views/bookings/CheckOutView")
  },
  {
    path: "/blog/:title",
    name: "BlogPost",
    component: blogpages,
  },
  {
    path: "/choose-your-niche",
    name: "ChooseYourNiche",
    component: ChooseYourNiche
  },
  {
    path: '/company-onboarding-identity',
    name: "CompanyOnboardingIdentity",
    component: CompanyOnboardingIdentity
  },
  {
    path: '/company-onboarding-address',
    name: "CompanyOnboardingAddress",
    component: CompanyOnboardingAddress
  },
  {
    path: '/company-onboarding-contact',
    name: "CompanyOnboardingContact",
    component: CompanyOnboardingContact
  }, {
    path: '/company-onboarding-account-details',
    name: "CompanyOnboardingAccountDetails",
    component: CompanyOnboardingAccountDetails
  },
  {
    path: '/company-onboarding-verification',
    name: "CompanyOnboardingVerification",
    component: CompanyOnboardingVerification
  },
  {
    path: "/signup",
    name: "CustomerSignUp",
    component: signup
  },
  {
    path: "/one-way-checkout",
    name: "OneWayCheckout",
    component: OneWayCheckout
  },
  {
    path: "/newlandingpage",
    name: "NewLandingPage",
    component: newlandingpage
  },

  {
    path: "/input-details",
    name: "OneWayInputDetails",
    component: OneWayInputDetails
  },
  {
    path: "/booking",
    name: "OneWaySelectTrip",
    component: OneWaySelectTrip
  },
  {
    path: "/nibs",
    name: "Nibs",
    component: Nibs
  },
  {
    path: "/wave-jotter",
    name: "WaveJotter",
    component: Skip
  },
  {
    path: "/flair",
    name: "Flair",
    component: Flair
  },
  {
    path: "/tan",
    name: "Tan",
    component: Tan
  },
  {
    path: "/select-seats",
    name: "OneWaySelectSeats",
    component: OneWaySelectSeats
  },
  {
    path: "/hire-payment",
    name: "HirePayment",
    component: HirePayment
  },

  {
    path: "/hire-input-details",
    name: "HireInputDetails",
    component: HireInputDetails
  },

  {
    path: "/hire",
    name: "HireSelectVehicle",
    component: HireSelectVehicle
  },
  {
    path: "/hire-location",
    name: "HireLocation",
    component: HireLocation
  },
  {
    path: "/complete",
    name: "RegistrationComplete",
    component: complete
  },
  {
    path: "/personalidentity",
    name: "OnboardingIdentity",
    component: OnboardingIdentity
  },
  {
    path: "/address",
    name: "OnboardingAddress",
    component: OnboardingAddress
  },
  {
    path: "/404",
    name: "errorpage",
    component: () => import("@/components/reuseables/ErrorPage.vue")
  },

  {
    path: "/faqs",
    name: "F.A.Qs",
    component: Faq,
  },
  {
    path: "/career",
    name: "Career",
    component: Career,
  },

  {
    path: "/about",
    name: "About",
    component: NewAbout
  },

  {
    path: "/new-about",
    name: "NewAbout",
    component: About
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog
  },
  {
    path: "/terms-and-policies",
    name: "TermsAndPolicies",
    component: TermsPage
  },
  {
    path: "/register-as",
    name: "RegisterAs",
    component: () => import("@/views/RegisterAs")
  },
  {
    path: "/transport-company/register",
    name: "TransportCompanyRegister",
    component: TransportCompanyRegisterView,
  },
  {
    path: "/insurance-provider/register",
    name: "InsuranceProviderRegister",
    component: TransportCompanyRegisterView,
  },
  {
    path: "/insurance-provider/onboarding-identity",
    name: "InsuranceProviderOnboardingIdentity",
    component: () => import('@/views/InsuranceProvider/CompanyOnboardingIdentity.vue')
  },
  {
    path: "/insurance-provider/onboarding-address",
    name: "InsuranceProviderOnboardingAddress",
    component: () => import('@/views/InsuranceProvider/CompanyOnboardingAddress.vue')
  },
  {
    path: "/insurance-provider/onboarding-verification",
    name: "InsuranceProviderOnboardingVerification",
    component: () => import("@/views/InsuranceProvider/CompanyOnboardingVerification.vue")
  },
  {
    path: "/insurance-provider/onboarding-account-details",
    name: "InsuranceProviderOnboardingAccountDetails",
    component: () => import("@/views/InsuranceProvider/CompanyOnboardingAccountDetails.vue")
  },
  {
    path: "/water-transport-operator/register",
    name: "WaterTransportOperatorRegister",
    component: () => import('@/views/WaterTransportOperatorRegisterView.vue')
  },
  {
    path: "/water-transport-operator/onboarding-identity",
    name: "WaterTransportOperatorOnboardingIdentity",
    component: () => import("@/views/WaterTransportOperator/WaterTransportOperatorOnboardingIdentity.vue")
  },
  {
    path: "/water-transport-operator/onboarding-address",
    name: "WaterTransportOperatorOnboardingAddress",
    component: () => import("@/views/WaterTransportOperator/WaterTransportOperatorOnboardingAddress.vue")
  },
  {
    path: "/water-transport-operator/onboarding-verification",
    name: "WaterTransportOperatorOnboardingVerification",
    component: () => import("@/views/WaterTransportOperator/WaterTransportOperatorOnboardingVerification.vue")
  },
  {
    path: "/water-transport-operator/onboarding-account-details",
    name: "WaterTransportOperatorOnboardingAccountDetails",
    component: () => import("@/views/WaterTransportOperator/WaterTransportOperatorOnboardingAccountDetails.vue")
  },
  {
    path: "/fleet-management/register",
    name: "FleetManagementRegister",
    component: () => import("@/views/FleetManagementRegisterView.vue")
  },
  {
    path: "/fleet-management-onboarding-identity",
    name: "FleetManagementOnboardingIdentity",
    component: () => import("@/views/FleetManagement/OnboardingIdentity.vue")
  },
  {
    path: "/fleet-management-onboarding-address",
    name: "FleetManagementOnboardingAddress",
    component: () => import("@/views/FleetManagement/OnboardingAddress.vue")
  },
  {
    path: "/fleet-management-onboarding-verification",
    name: "FleetManagementOnboardingVerification",
    component: () => import("@/views/FleetManagement/OnboardingVerification.vue")
  },
  {
    path: "/fleet-management-onboarding-account-detail",
    name: "FleetManagementOnboardingAccountDetail",
    component: () => import("@/views/FleetManagement/OnboardingAccountDetails.vue")
  },
  {
    path: "/corporate-agent/register",
    name: "CorporateAgentRegister",
    component: CorporateAgentRegisterView,
  },
  {
    path: "/mobile-agent/register",
    name: "IndividualAgentRegister",
    component: () => import("@/views/MobileAgentRegisterView"),
  },
  {
    path: "/mobile-agent-identity",
    name: "IndividualAgentIdentity",
    component: () => import("@/views/mobileAgent/OnboardingIdentity")
  },
  {
    path: "/mobile-agent-address",
    name: "IndividualAgentAddress",
    component: () => import("@/views/mobileAgent/OnboardingAddress")
  },
  {
    path: "/mobile-agent-verification",
    name: "IndividualAgentVerification",
    component: () => import("@/views/mobileAgent/OnboardingVerification")
  },
  {
    path: "/mobile-agent-account-details",
    name: "IndividualAgentAccountDetails",
    component: () => import("@/views/mobileAgent/OnboardingAccountDetails")
  },
  {
    path: "/developer/register",
    name: "DeveloperRegister",
    component: () => import("@/views/DeveloperRegisterView")
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPasswordView,
  },

  {
    path: "/new-password",
    name: "NewPassword",
    component: () => import("@/views/NewPassword.vue"),
  },
  {
    path: "/user-new-password",
    name: "UserNewPassword",
    component: () => import("@/views/StaffNewPassword.vue"),
  },
  {
    path: "/traveller-code",
    name: "TravelerCode",
    component: () => import("@/views/bookings/TravellerCodeComponent.vue"),
  },

  {
    path: "/jobopenings/:jobname",
    name: "JobOpenings",
    component: () => import("@/components/reuseables/JobOpenings.vue")
  },
  {
    path: "/product-card",
    name: "ProductCard",
    component: () => import("@/components/reuseables/ProductCard.vue")
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
      behavior: "smooth"
    };
  },
});
router.beforeEach((to, from, next) => {
  let userData = JSON.parse(sessionStorage.getItem('userData'))
  if (to.meta.userType) {
    if (!userData) {
      next({
        name: 'HomePage'
      })
    } else if (to.meta.userType === "companyStaff" && userData.userRole === "transportCompany") {
      next({
        name: "MainDashboard"
      })
    } else if (to.meta.userType === "transportCompany" && userData.userRole === "transportCompanyManager") {
      next({
        name: "CompanyStaffDashboard"
      })
    }
  }
  next();
})
export default router;