<template>
    <div class="section-sculptor">
        <v-col lg="6" sm="12" class="display-center tw-mb-8 lg:tw-mb-0">
            <img src="@/assets/newlandingpage/m-agent.svg" alt="mobile-agent-services" class="width-ctrl">

        </v-col>
        <v-col lg="6" sm="12">
            <p class="blue-small-bold-text mb-8">For Agents</p>
            <p class="header">
                Become a Mobile Travel Agent
            </p>
            <p class="text-subheader py-8">With simplified access to transport companies inventory, new & existing travel agents can use our <span style="color: #004aad;"> powerful decentralized booking platform </span> to facilitate bookings for transport companies.</p>
            <router-link to="/mobile-agent/register" class="
                        fs-title
                        blue-small-bold-text
                        text-center
                        tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-2
                      ">Sign Up Now</router-link>
        </v-col>
    </div>
    </template>
    
    <script>
    export default {
        name: "SectionFive"
    }
    </script>
    
    <style lang="scss" scoped>
    .section-sculptor {
        display: flex;
        align-items: center;
        min-height: 60vh;
        padding-top: 100px;
        padding-bottom: 100px;
       background: var(--npodLightBlue01);

      @media screen and (min-width: 1920px){
        padding-right: 9rem !important;
        padding-left: 9rem !important;
      }
      @media screen and (width: 1366px){
        padding-right: 9rem !important;
        padding-left: 9rem !important;
      }
      @media (max-width:1264px) {
        flex-direction: column;
        min-height: 60vh;
        padding-bottom: 50px;
        padding-top: 50px;
      }
      @media (max-width:576px) {
        min-height: auto;
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 30px;
        padding-top: 30px;
      }
      @media screen and (min-width: 768px) and (max-width: 1264px) {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
      }
      @media (max-width:1264px) {
        padding-left: 3rem;
        padding-right: 3rem;
      }
      @media screen and (max-width: 1040px) {
        flex-direction: column;
        background-size: cover;
        background-position: right center;
      }
      @media screen and (width:768px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (max-width:430px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (width:428px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (max-width:414px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
        //height: 90vh;
      }
      @media screen and (width:390px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (width:375px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 30px;
      }
      @media screen and (max-width:360px) {
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        padding-top: 30px;
      }
    }

    .header {
    color: #263238;
    font-family: Raleway;
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    // width: 90%;

    @media screen and (max-width:768px) {
        width: 100%;
            font-size: 43px;
            line-height: 60px;
        }
        @media screen and (max-width:576px) {
            font-size: 35px;
            line-height: 50px;
        }
        @media screen and (min-width: 1920px) {
            font-size: 70px;
      line-height: 100px;

      }
}
   

    .text-subheader {
        font-family: "Raleway", sans-serif !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 38px;
        letter-spacing: 0;
        text-align: left;
        color: #263238;

        @media screen and (min-width: 1920px) {
            width: 90%;
            font-size: 22px;
            line-height: 38px;
        }

        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 28px;
            width: 100%;
        }
    }

    .blue-small-bold-text{
        color: #004AAD;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    font-weight: 600;
    line-height: 30px; 
    @media screen and (max-width:768px) {
        font-size: 16px;
    }
    @media screen and (max-width:576px) {
            font-size: 13px !important;
        }
    }
    
    .fs-title {
        width: fit-content;
        height: 50px;
        text-align: center;
        border-radius: 10px;
    border: 2px solid var(--RoadPadi-06, #004AAD);
    box-shadow: 0px 4px 8px 0px rgba(57, 130, 240, 0.10);
        background-color: transparent;
    }
    .width-ctrl {
        @media (max-width:768px) {
            width: 400px;
        }

        @media (max-width:576px) {
            width: 300px;
        }
    }
    </style>
    