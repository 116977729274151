<template>
  <div class="seven">
    <div class="desktop">
      <v-card flat>
        <div class="tw-flex">
          <v-col md="4" class="image-bgd">
          </v-col>
          <v-col md="8" class="text-section">
            <h1 class="heading">Nomadicpod's Got You Covered</h1>
            <p class="sub-text">Leverage Nomadicpod for a more Comfortable and Easy Travel Experience. From Booking and
              Scheduling trips, to Hiring Vehicles, we got you covered.</p>
            <div class="cta">
              <button class="button-one clickables">Get Started</button>
              <button class="button-two clickables text">Book A Trip</button>

            </div>
          </v-col>

        </div>
      </v-card>
    </div>
    <div class="mobile">
      <div class="mobile-image">
        <img src="../assets/homepage/seven.jpg" alt="" class="notcard">
        <div class="text-section">
          <h1 class="heading">Nomadicpod's Got You Covered</h1>
          <p class="sub-text">Leverage Nomadicpod for a more Comfortable and Easy Travel Experience. From Booking and
            Scheduling trips, to Hiring Vehicles, we got you covered.</p>
          <div class="cta">
            <button class="button-one clickables">Get Started</button>
            <button class="button-two clickables text">Book A Trip</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang='scss' scoped>
  .desktop {
    display: flex;

    justify-content: center;
    align-items: center;

    @media (max-width:992px) {
      display: none;
    }
  }

  .mobile {
    display: none;

    @media (max-width:992px) {
      display: block;
    }
  }

  .seven {
    background-color: #ffff;
    min-height: 60vh
;
    padding: 15rem 0;
    @media (max-width:992px) {
      padding: 7rem 0;
    }
  }

  .col {
    padding: 0;
  }

  .image-bgd {
    background-image: url('../assets/homepage/seven.jpg');
    background-size: cover;
    background-position: right center;
    height: 400px;
    border-radius: 10px 0 0 10px;

  }

  .v-card {
    width: 60%;
    border-radius: 10px;
    border: 1px solid rgba(29, 38, 45, 0.2);
    height: 400px;

    @media (max-width:1264px) {
      width: 70%;
    }

    @media (max-width: 1024px) {
      width: 90%;
    }

    @media (max-width: 992px) {
      width: 100%;
      height: fit-content;
    }
  }


  .card-image {
    width: 100%;
    height: 100% !important;
  }

  .text-section {
    padding: 4rem;
    @media (max-width:992px) {
      margin: 3rem 0;
      width: 90%;
      padding: 0;

    }

    @media (max-width:576px) {
      width: 100%;
    }

    .heading {
      font-family: 'Raleway';
      font-weight: 600;
      font-size: 24px;
      line-height: 48px;
      color: #1D262D;
      padding-bottom: 15px;

    }

    .sub-text {

      font-family: 'Inter';
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: #2A466C;

    }
  }

  .cta {
    .clickables {
      font-family: 'Inter';
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #004AAD;
      width: 139px;
      height: 52px;

    }

    .button-one {
      border: 2px solid #004AAD;
      border-radius: 6px;
    }

    .button-two {
      margin-left: 10px;
    }
  }

  .mobile-image {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
@media (max-width:576px) {
  padding: 0 1.5rem ;
}
  }

  .notcard {
    border-radius: 15px;
    width: 90%;

    @media (max-width:576px) {
      width: 100%;

    }
  }
</style>