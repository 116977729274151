<template>
  <div class="all-vehicle tw-pb-5">
    <div class="tw-flex tw-w-full tw-justify-between">
      <p class="medium-text" style="color:#004aad">Available Vehicles</p>
    </div>
    <div class="card-container display-center tw-w-full">
      <div v-for="(vehicle, index) in availableVehiclesForHire" :key="index" class="each-card">
        <div class="" style="height:200px">
          <img :src="vehicle.vehicle.vehicleImage" alt="vehicle image" />
        </div>
        <div>
          <div class="tw-flex tw-flex-row tw-items-center tw-pt-5">
            <img :src="vehicle.transportCompanyLogo" class="logo" alt="logo">
            <p class="medium-text" style="color:#4F4F4F">{{ vehicle.transportCompanyName || 'Nomadicpod'}}</p>
          </div>
          <div class="tw-flex tw-my-5">
            <p class="sort text-capitalize">{{ vehicle.vehicle.vehicleType }}</p>
            <v-spacer></v-spacer>
            <p class="small-brown-text">₦{{ formatNumber(vehicle.hirePrice) }}/day</p>
          </div>
          <div class="tw-flex tw-flex-row tw-pb-4 tw-items-center" v-if="vehicle.discount">
            <v-icon class="mr-3"  color="#004aad" >mdi-cash-multiple</v-icon>
            <label class="small-brown-text ">{{vehicle.discountValue}}% Discount after {{vehicle.numberOfDiscountedDays}} day(s) </label>
          </div>
        </div>
        <div class="tw-flex tw-flex-wrap">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">

              <v-chip v-bind="attrs" v-on="on" class="small-brown-text" style="color:#004aad" color="#F3F3F3">
                <v-icon color="#004aad" class="mr-1">mdi-car-seat</v-icon>
                {{vehicle.vehicle.vehicleCapacity}}
              </v-chip>

            </template>
            <div>
              <strong>Seats:</strong> {{ vehicle.vehicle.vehicleCapacity }}
              <br>
              <p>Number of available seats in the vehicle.</p>
            </div>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" class="small-brown-text ml-2" style="color:#004aad" color="#F3F3F3">
                <v-icon color="#004aad" class="mr-1" v-if="vehicle.vehicleACType">mdi-snowflake</v-icon>
                <v-icon color="#004aad" class="mr-1" v-else>mdi-snowflake-off</v-icon>
                A/C
              </v-chip>
            </template>
            <div>
              <strong>Air-Conditioner:</strong>
              <br>
              <p v-if="vehicle.vehicleACType">Vehicle has a functioning Air conditioning system</p>
              <p v-else>Vehicle has no functioning Air conditioning system</p>
            </div>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" class="small-brown-text ml-2" style="color:#004aad" color="#F3F3F3">
                <v-icon color="#004aad" class="mr-1" v-if="vehicle.vehicleVideoType">mdi-video</v-icon>
                <v-icon color="#004aad" class="mr-1" v-else>mdi-video-off</v-icon>
                Video
              </v-chip>
            </template>
            <div>
              <strong>Video Screen:</strong>
              <br>
              <p v-if="vehicle.vehicleVideoType">Vehicle has a functioning video system</p>
              <p v-else>Vehicle has no functioning video system</p>
            </div>
          </v-tooltip>
          <v-spacer></v-spacer>
          <button class="btn-design tw-mt-10 md:tw-mt-0 tw-w-full md:tw-w-auto" @click="handleSelectedVehicle(vehicle)">Hire bus</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {
    mapGetters
  } from 'vuex';
  export default {
    components: {},
    data: () => ({
      items: ["Price: Low to High", "Price: High to Low"],
      panel: 0,
      sortOption: "Price: Low to High",

    }),
    computed: {
      ...mapGetters('hire', ['availableVehiclesForHire', 'hiringDetails'])
    },
    methods: {
      handleSelectedVehicle(vehicle) {
        this.hiringDetails.vehicleSelected = vehicle
        this.$store.dispatch("hire/hiringDetails", this.hiringDetails);
        this.$router.push({
          path: "/hire-location"
        });
      },
      formatNumber(number){
        if(number){
          return number.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        }
      },
    },
    mounted() {

    }
  };
</script>

<style lang="scss" scoped>
  .all-vehicle {
    margin-left: 8rem;
    margin-right: 8rem;
    @media (max-width:1264px) {
      margin-left: 2rem;
      margin-right: 2rem;
    }
    @media (max-width:576px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }


  .medium-text {
    font-family: Inter,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .sorting {
    width: 250px;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    @media screen and (max-width:768px) {
      margin-top: 10px;
    }
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 50px;
  }

  .v-input,
  .sort {
    color: #4F4F4F;
    font-family: Inter,sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    word-break: keep-all;
    line-height: normal;
  }

  :deep(.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot) {
    padding: 0 6px;
  }

  :deep(.v-input) {
    width: 200px !important;

  }

  .each-card {
    width: 450px;
    min-height: 450px;
    border-radius: 10px;
    border: 1px solid #F2F2F2;
    background: #FFF;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.04);
    padding: 40px;
  }

  .small-brown-text {
    color: #4F4F4F;
    font-family: Inter,sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .btn-design {
    border-radius: 4px;
    border: 1px solid #004AAD;
    background: #004AAD;
    color: #FFF;
    font-family: Inter,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 15px;
  }
  .logo{
    height: 50px;
    width: 50px;
    border-radius: 10%;
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
</style>