<script>
export default {
  name: 'TravelAgents'
}

</script>

<template>
  <div class=" parent-div tw-cursor-pointer" @click="$router.push({name:'Tan'})">
    <div class="tw-flex">

      <div class="avatar-container">
      <div style="background: #FFEAE3;" class="avatar-inner">
        <img src="@/assets/icons/travel-agent-icon.svg" alt="bus icon" />
      </div>
      </div>


      <div class="tw-flex flex-column tw--mr-10" >

        <div class="tw-pl-3 tw-pt-3">
          <p class="product-txt">Assistant</p>
          <p class="paragraph-txt">Make extra income as a travel agent. Sign up for free <br>and begin to sell tickets</p>
          <p class="paragraph-txt-mobile">Make extra income as a travel agent.<br>Sign up for free and begin to sell tickets</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.parent-div{
  width: 587px;
  height: 248px;
  background: #FFFFFF;
  @media (max-width: 576px) {
    padding: 20px 100px 10px 10px;
  }
}
.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background: #FFF7F5;
  @media (max-width: 576px) {
    width: 50px;
    height: 50px;

  }

}

.avatar-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: #E3F0FF;
  @media (max-width: 576px) {
    width: 45px;
    height: 45px;
  }
}

.avatar-inner img {
  object-fit: contain;
  display: flex;
  align-self: center;
}
.product-txt{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #000F23;
}
.paragraph-txt{
  font-family: Raleway,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #000000;
  white-space: nowrap;
  padding-top: 10px;
  @media (max-width: 576px) {
    display: none;
  }
}
.paragraph-txt-mobile{
  display: none;
  @media (max-width: 576px) {
    display: flex;
    font-family: Raleway,sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #00193A;
  }
}
</style>