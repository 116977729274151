<script>
export default {
  name: 'HeroSectionRightSide',
  data() {
    return {
      showDescription: ''
    };
  }
}

</script>

<template>
   <div class="hero-sec-right">
          <div class="hero-sec-right-img">
          <img class="image" src="@/assets/newHeroSectionImages/Ellipse 460.svg" alt="" />
          <img class="ellipse-img" src="@/assets/newHeroSectionImages/Ellipse 478.svg" alt="" />



            <div class="icon bus" @mouseover="showDescription = 'Intercity Travel Ticketing'" @mouseleave="showDescription = ''">
              <img src="@/assets/icons/mdi_bus.svg" alt="" />
              <div class="hover-text" v-if="showDescription === 'Intercity Travel Ticketing'">{{ showDescription }}</div>
            </div>
            <div class="icon rail" @mouseover="showDescription = 'Rail Travel Booking'" @mouseleave="showDescription = ''">
              <img src="@/assets/icons/maki_rail-light.svg" alt="" />
              <div class="hover-text" v-if="showDescription === 'Rail Travel Booking'">{{ showDescription }}</div>
            </div>


            <div class="icon car" @mouseover="showDescription = 'Car Rental Services'" @mouseleave="showDescription = ''">
              <img src="@/assets/icons/mingcute_car-fill.svg" alt="" />
              <div class="hover-text" v-if="showDescription === 'Car Rental Services'">{{ showDescription }}</div>
            </div>

            <div class="icon solar" @mouseover="showDescription = 'Solar Delivery Services'" @mouseleave="showDescription = ''">
              <img src="@/assets/icons/solar_delivery-bold.svg" alt="" />
              <div class="hover-text" v-if="showDescription === 'Solar Delivery Services'">{{ showDescription }}</div>
            </div>

            <div class="icon boat" @mouseover="showDescription = 'Waterways Travel'" @mouseleave="showDescription = ''">
              <img class="icon boat" src="@/assets/icons/mdi_boat.svg" alt="" />
              <div class="hover-text" v-if="showDescription === 'Waterways Travel'">{{ showDescription }}</div>
            </div>

            <div>
          </div>
        </div>
    <div class="hover-text" v-if="showDescription !== ''">{{ showDescription }}</div>
    </div>
</template>

<style scoped lang="scss">
.hero-sec-right {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding-top: 8rem;
  }
  @media screen and (max-width: 375px){
    width: 100% !important;
    height: auto !important;
    zoom: 100%;
  }
}

.hero-sec-right-img{
  width: 500px;
  height: 500px;
  position: relative;
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 300px;
  }

}

.ellipse-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: right;

  @media screen and (max-width: 1024px) {
    object-position: center;
  }
}

.image {
  position: absolute;
  width: 500px;
  height: 500px;
  object-fit: fill;
  aspect-ratio: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  @media screen and (max-width: 1024px){
   width: 80%;
    left: 30%;
  }
  @media screen and (max-width: 375px){
    @media screen and (max-width: 375px){
      width: 100%;
      height: 100% ;
    }
  }
}

.bus {
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 375px){
    left: 0;
  }
}

.rail {
  position: absolute;
  top: -20px;
  right: -20px;
  @media screen and (max-width: 1024px) {
    right: 0;
  }
  @media screen and (max-width: 375px){
    left: 0;
  }
}

.car {
  position: absolute;
  top: 50%;
  right: -80px;
  transform: translateY(-50%);
  @media screen and (max-width: 1024px) {
    right: 0;
  }
  @media screen and (max-width: 375px){
   right: -40px;
  }
}

.solar {
  position: absolute;
  right: -20px;
  bottom: -20px;
  @media screen and (max-width: 1024px) {
    right: 0;
  }
  @media screen and (max-width: 375px){
    left: 0;
  }
}

.boat {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 75px;
  @media screen and (max-width: 1024px) {
    right: 0;
  }
  @media screen and (max-width: 375px){
    left: 0;
  }
}

.icon {
  border-radius: 50%;
  padding: 12px;
  background-color: transparent;
}

.icon:hover {
  background-color: #2222cc33;
}

.hover-text {
  position: absolute;
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 0.9);
  left: 150%;
  bottom: 30%;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 600;
  z-index: 9999;
  line-height: 19.2px;
  text-align: left;
  color: #00193A;

}


</style>