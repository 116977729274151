<template>
    <div class="three hundred-vh">
        <div class=" explanations">
            <p class="main-header ">Travel Has Never Been Easier</p>
        </div>
        <div class="desktop">
            <v-carousel hide-delimiter-background light :show-arrows="false" height="600">
                <v-carousel-item>
                    <div class="explanations ">
                        <div class="with-icon">
                            <img src="../assets/greyclocks4.svg" alt="" class="grey-avatar">

                            <v-skeleton-loader min-width="350" type="article, article " elevation="1" depressed  class="gone" style="padding:30px">
                            </v-skeleton-loader>
                        </div>
                        <div class="with-icon">
                            <img src="../assets/jets4.png" alt="" width="50" class="avatar2">
                            <div class="middle first">
                                <p class="heading">Hire Vehicles For Comfort At Cheap Rates</p>
                                <p class="text-body">You can make bookings from the web application by inputing your
                                    travel
                                    details, compare prices and it is confirmed once payment is made</p>
                                <base-button buttonText="Hire Vehicles" :outlined="true" :isOutlined="true"
                                    class="whiteButton" />
                            </div>
                        </div>
                       <div class="with-icon">
                            <img src="../assets/greysmile.svg" alt="" class="grey-avatar">

                            <v-skeleton-loader min-width="350" type="article, article " elevation="1" depressed  class="gone" style="padding:30px">
                            </v-skeleton-loader>
                        </div>

                    </div>

                </v-carousel-item>
                <v-carousel-item>
                    <div class="explanations ">
                      <div class="with-icon">
                            <img src="../assets/greyjet.svg" alt="" class="grey-avatar">

                            <v-skeleton-loader min-width="350" type="article, article " elevation="1" depressed  class="gone" style="padding:30px">
                            </v-skeleton-loader>
                        </div>
                        <div class="with-icon">
                            <img src="../assets/smile.svg" alt="" class="avatar2">
                            <div class="middle first">
                                <p class="heading">Get Amazing Features When You Register</p>
                                <p class="text-body">You can make bookings from the web application by inputing your
                                    travel
                                    details, compare prices and it is confirmed once payment is made</p>
                                <base-button buttonText="Get Started" :outlined="true" :isOutlined="true"
                                    class="whiteButton" />
                            </div>
                        </div>
                      <div class="with-icon">
                            <img src="../assets/greyclocks4.svg" alt="" class="grey-avatar">

                            <v-skeleton-loader min-width="350" type="article, article " elevation="1" depressed  class="gone" style="padding:30px">
                            </v-skeleton-loader>
                        </div>
                    </div>
                </v-carousel-item>
                <v-carousel-item>
                    <div class="explanations ">
                       <div class="with-icon">
                            <img src="../assets/greysmile.svg" alt="" class="grey-avatar">

                            <v-skeleton-loader min-width="350" type="article, article " elevation="1" depressed  class="gone" style="padding:30px">
                            </v-skeleton-loader>
                        </div>
                        <div class="with-icon">
                            <img src="../assets/clocks4.svg" alt="" class="avatar2">
                            <div class="middle first">
                                <p class="heading">Get Discounts for Scheduling 1-2 Days Before Trips</p>
                                <p class="text-body">You can make bookings from the web application by inputing your
                                    travel
                                    details, compare prices and it is confirmed once payment is made</p>
                                <base-button buttonText="Schedule A Trip" :outlined="true" :isOutlined="true"
                                    class="whiteButton" />
                            </div>
                        </div>
                       <div class="with-icon">
                            <img src="../assets/greyjet.svg" alt="" class="grey-avatar">

                            <v-skeleton-loader min-width="350" type="article, article " elevation="1" depressed  class="gone" style="padding:30px">
                            </v-skeleton-loader>
                        </div>
                    </div>
                </v-carousel-item>


            </v-carousel>
        </div>
        <div class="mobile">
            <div class="with-icon">
                <img src="../assets/jets4.png" alt="" width="50" class="avatar">
                <div class="middle first">
                    <p class="heading">Hire Vehicles For Comfort At Cheap Rates</p>
                    <p class="text-body">You can make bookings from the web application by inputing your travel
                        details, compare prices and it is confirmed once payment is made</p>
                    <base-button buttonText="Hire Vehicles" :outlined="true" :isOutlined="true" class="whiteButton" />
                </div>
            </div>
            <div class="with-icon">
                <img src="../assets/smile.svg" alt="" class="avatar">
                <div class="middle first">
                    <p class="heading">Get Amazing Features When You Register</p>
                    <p class="text-body">You can make bookings from the web application by inputing your travel
                        details, compare prices and it is confirmed once payment is made</p>
                    <base-button buttonText="Get Started" :outlined="true" :isOutlined="true" class="whiteButton" />
                </div>
            </div>
            <div class="with-icon">
                <img src="../assets/clocks4.svg" alt="" class="avatar">
                <div class="middle first">
                    <p class="heading">Get Discounts for Scheduling 1-2 Days Before Trips</p>
                    <p class="text-body">You can make bookings from the web application by inputing your travel
                        details, compare prices and it is confirmed once payment is made</p>
                    <base-button buttonText="Schedule A Trip" :outlined="true" :isOutlined="true" class="whiteButton" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseButton from '../components/reuseables/BaseButton.vue'
    export default {
        components: {
            BaseButton
        },
        data() {
            return {}
        },
    }
</script>

<style lang='scss' scoped>
    .three {
        padding: 7rem 0;
    }
.main-header{
    
font-family: 'Raleway';
font-weight: 600;
font-size: 36px;
line-height: 56px;
text-align: center;
color: #263238;
padding-bottom: 50px;
    width: 400px;
 @media screen and (max-width: 768px) {
      font-size: 30px;
      line-height: 40px;
    }

}

    .explanations {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .desktop {
        @media (max-width: 1264px) {
            display: none;
        }
    }

    .middle {
        background-color: #FFFFFF;
        border: 1px solid rgba(103, 102, 102, 0.18);
        box-shadow: 0px 4px 60px rgba(44, 44, 45, 0.08);
        padding: 60px 40px 40px;
        width: 400px;
        height: 450px;
        margin: 40px 50px;

        @media (max-width:1264px) {
            margin: 40px 0;

        }

        @media (max-width:576px) {
            width: fit-content;
            margin: 20px;
            height: fit-content;
        }
    }

    .heading {
        font-family: 'Raleway';
        font-weight: 600;
        font-size: 28px;
        line-height: 48px;
        color: #263238;
         @media screen and (max-width: 768px) {
      font-size: 23px;
      line-height: 40px;
    }
    }

    .text-body {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: #6F7F94;
    }

    .with-icon {
        position: relative;
    }

    .avatar {
        position: absolute;
        left: 50px;
        top: 10px;
        @media (max-width:576px) {
               top: -5px;
        }
    }

    .avatar2 {
        position: absolute;
        left: 100px;
    }
    .grey-avatar{
        position: absolute;
        top: -35px;
        left: 50px;
        z-index: 10000;
    }


    .mobile {
        display: none;

        @media (max-width: 1264px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
</style>