<script>
import NavBar from "@/home/Navbar.vue"
import DemoButton from "@/components/reuseables/DemoButton.vue";
import GetStartedBtn from "@/components/reuseables/GetStartedBtn.vue";
import TanSectionTwo from "@/home/landingpage/products/TanSectionTwo.vue";
import TanSectionThree from "@/home/landingpage/products/TanSectionThree.vue";
import TanSectionFour from "@/home/landingpage/products/TanSectionFour.vue";
import TanSectionFive from "@/home/landingpage/products/TanSectionFive.vue";
import TanLastSection from "@/home/landingpage/products/TanLastSection.vue";
import Footer from "@/home/Footer.vue";

export default {
  name: 'Tan',
  components: {
    Footer,
    TanLastSection,
    TanSectionFive, TanSectionFour, TanSectionThree, TanSectionTwo, GetStartedBtn, DemoButton, NavBar}
}

</script>

<template>
<div class="tw-w-full">
  <NavBar/>
  <div class="main-layout-wrapper">
    <div class="first-sec tw-w-full tw-px-5 lg:tw-px-40">
      <div class="first-sec-left">
        <p class="hero-sec-bold-txt">Start A Business<br>Providing Travel<br>Assistance As A<br> <span style="color:#014AAC">Travel Agent</span></p>
        <p class="hero-sec-paragraph tw-my-8">Nomadicpod's Agency Registration allows you to become an<br>
          affiliate of Nomadicpod, making extra income selling<br>Nomadicpod services</p>
        <div class="tw-flex tw-my-8">
        <DemoButton/> <GetStartedBtn/>
        </div>
      </div>
      <div class="first-sec-right" >
        <img src="@/assets/Tan-heroSec-img.png" alt="A travel agent image" class="image">
      </div>
    </div>
    <div class="all-sec tw-w-full">
      <TanSectionTwo/>
      <TanSectionThree/>
      <TanSectionFour/>
      <TanSectionFive/>
      <TanLastSection/>
    </div>
  </div>
<Footer/>
</div>
</template>

<style scoped lang="scss">
.main-layout-wrapper{
  overflow-y: clip;
  @media (max-width: 576px) {

  }
}

.first-sec{
  background-image: url("../../../assets/skip-background-img.svg");
  background-color: #F2E4E7;
  object-fit: cover;
  background-size: 100%;
  display: flex;
  align-items: center;
  min-height: 100vh;
  @media (max-width: 576px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-top: 20%;
    min-height: 50vh;
    justify-content: left;
    align-items: flex-start;
  }
}
.first-sec-left{
  width: 50%!important;
  @media (max-width: 576px) {

  }
}
.first-sec-right{
  width: 50%!important;
  padding-left: 7rem;
  @media (max-width: 576px) {
    padding-left: 0!important;
  }
}
.hero-sec-bold-txt{
  font-family: Raleway,sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-align: left;
  color: #263238;
  @media (max-width: 576px) {
    font-size: 36px;
    line-height: 48px;
    white-space: nowrap;
  }
}
.hero-sec-paragraph{
  font-family: Raleway,sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: #2D3348;
  @media (max-width: 576px) {
    font-size: 14px;
    white-space: nowrap;
  }
}
.image {
  border-radius: 50%;
  width: 100%;
}
</style>