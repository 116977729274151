<template>
  <div class="insurance">
    <v-expansion-panels v-model="panel" flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="header tw-flex">
            <span class="title" :style="{color : '#004AAD'}">Insurance Plans</span>
            <span class="collapse mt-1 mr-2">Collapse</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="explanation">Travel Insurance covers risks associated with travel.... <span
              class="clickables" :style="{color : '#004AAD'}">View more</span> </p>
          <div class="select-plan">
            <p class="cta">Choose a plan</p>
            <v-expansion-panels flat>
              <v-expansion-panel v-for="(provider,index) in insuranceProviderProviders" :key="index"  v-model="selectedProvider" @click = selectInsuranceProvider(provider)>
                <v-expansion-panel-header >
                  <div class="tw-flex tw-items-center">
                  <img class="company-logo tw-mr-2" :src="provider.companyLogo" v-if="provider.companyLogo" alt="logo"/>
                  <label class="company-name">{{provider.companyName}}</label>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-radio-group v-for="(plan, planIndex) in provider.plans" :key="planIndex+provider.companyName" v-model="selectedPlan">
                    <v-radio   :value="plan" :color="'#004AAD'">
                      <template #label >
                               <v-tooltip top color="#004AAD" class="tw-w-full">
                                 <template v-slot:activator="{ on, attrs }" class="tw-w-full">
                                    <span v-bind="attrs"
                                        v-on="on" class="tw-w-full">
                                        <span class="text-capitalize tw-w-full tw-flex tw-justify-between tw-items-center">
                                        <span class="tw-w-4/12">{{plan.name}}</span> - <span >&#8358;{{plan.planAmount}}</span>
                                        <span >
                                          <v-icon >mdi-information-outline</v-icon>
                                        </span>
                                      </span>
                                    </span>
                                 </template>
                                 <span>{{plan.description}}</span>
                               </v-tooltip>

                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <p class="note mt-3">NB: The insurance plans are optional</p>
          <v-divider/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
    import {getAllPlans} from "@/services/api/APIService";
    import {mapGetters} from "vuex";

    export default {
        name: "SelectInsurancePlan",
        data() {
            return {
                panel: 0,
                radios: '',
              selectedProvider: {},
              selectedPlan:{},
              refresh: false,
              insuranceProviderProviders:[]
            }
        },
      computed:{
          ...mapGetters("booking",["oneWayTotalAmount","selectedInsuranceProvider"])
      },
      watch:{
          'selectedPlan': function (val) {
            if (val && Object.keys(val).length){
              let selectedPlan = sessionStorage.getItem("selectedPlan")
              if (selectedPlan && this.refresh){
                let plan = JSON.parse(selectedPlan)
                this.$store.dispatch("booking/removeSelectedInsuranceAmount", plan.planAmount )
              }
              this.refresh = true
              sessionStorage.setItem("selectedPlan", JSON.stringify(val))
              this.$store.dispatch("booking/setSelectedInsuranceProvider",this.selectedProvider)
              this.$store.dispatch("booking/setSelectedInsuranceAmount", val.planAmount)
            }
          }

      },
      methods:{
         async getPlans(){
          await  getAllPlans().then(res =>{
            this.insuranceProviderProviders = res.data.filter(provide => provide.plans !== null)
            })
          },
        toSentenceCase(text) {
          text = text.toLowerCase();
          return text.replace(text.charAt(0), text.charAt(0).toUpperCase());
        },
        getFirstText(text) {
          if (text) {
            let arrayText = text.split(" ");
            return this.toSentenceCase(arrayText[0]);
          }
        },
        selectInsuranceProvider(provider){
           if (Object.keys(this.selectedProvider).length && JSON.stringify(this.selectedProvider) !== JSON.stringify(provider)){
             this.selectedProvider = {}
             this.selectedPlan = {}
             this.$store.dispatch("booking/setSelectedInsuranceProvider",{})
             sessionStorage.removeItem("selectedInsuranceProvider")
           }
           else {
             this.selectedProvider = provider

           }
        }
      },
      async created() {
          let selectedInsuranceProvider = sessionStorage.getItem("selectedInsuranceProvider")
          let selectedPlan = sessionStorage.getItem("selectedPlan")
          if (selectedInsuranceProvider){
            await this.$store.dispatch("booking/setSelectedInsuranceProvider", JSON.parse(selectedInsuranceProvider))
            this.selectedProvider = JSON.parse(selectedInsuranceProvider)
          }
          if (selectedPlan){
            this.selectedPlan = JSON.parse(selectedPlan)
          }
            await this.getPlans()

      }
    }
</script>

<style lang='scss' scoped>
    .insurance {
        width: 100%;
      //display: none;

    }

    .header .title {
        font-family: 'Inter',sans-serif;
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
        color: #004AAD;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .col {
        padding: 0 !important;
    }

    .header {
        justify-content: space-between;
    }

    .select-plan {
        border: 2px dashed #000000;
        padding: 20px;
        background: #F2F2F4;
        @media (max-width:768px) {
            padding: 7px;
        }

    }

    .collapse {
        font-family: 'Inter',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: rgba(79, 79, 79, 0.65);
    }

    .explanation {
        font-family: 'Inter',sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: rgba(0, 17, 39, 0.73);
    }

    .clickables {
        cursor: pointer;
        color: #004AAD;
    }

    .each-line {
        justify-content: center;
        align-items: center;
        width: 90%;

        @media (max-width:768px) {
            width: 100%;
        }
    }

    .plan-type,
    .amount {
        font-family: 'Inter',sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: rgba(0, 17, 39, 0.73);

    }

    .amount {
        color: #004aad;

    }

    .cta {
        font-family: 'Inter',sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: rgba(0, 17, 39, 0.73);
    }

    .note {
        font-family: 'Inter',sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 32px;
        color: #83868A;
    }
    .v-expansion-panel-header {
      font-family: 'Inter',sans-serif !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 32px !important;
      color: rgba(0, 17, 39, 0.73);
    }
    .theme--light.v-expansion-panels .v-expansion-panel {
      background: none !important;
    }

    .company-logo{
      width: auto;
      height: 40px;
      border-radius: 50% !important;
      object-fit: contain;
    }
    .company-name{
      font-family: 'Inter',sans-serif;
      font-weight: 500;
      font-size: 17px;
      line-height: 22px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #83868A;
    }
</style>