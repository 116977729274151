<script>

import NavBar from "@/home/Navbar.vue";

import Teamcard from "../components/reuseables/Teamcard.vue";
import icon1 from "@/assets/valueicons/icon1.png";
import icon2 from "@/assets/valueicons/icon2.png";
import icon3 from "@/assets/valueicons/icon3.png";
import icon4 from "@/assets/valueicons/icon4.png";
import akinyele from "@/assets/newAboutPageImages/Akinyele_Makinwa.png"
import sam from "@/assets/newAboutPageImages/Sam_Immanuel.png"
import yetunde from "@/assets/newAboutPageImages/Yetunde_Falore.png"
import Footer from "@/home/Footer.vue";
import Partners from "@/components/reuseables/Partners.vue";
import {getAllPublishPost} from "@/services/api/APIService";
import dayjs from "dayjs";
export default {
  name: "NewAbout",
  components: {Partners, Footer, NavBar , Teamcard },
  data(){
    return{

      values: [
        {
          image: icon1,
          title: "Empathy &  Graciousness",
          subtitle:
              "We live a life of positivity and kindness, we imagine ourselves in the shoes of others as we do our work with colleagues and customers.",
        },
        {
          image: icon2,
          title: "Collaboration & Teamwork ",
          subtitle:
              "Our success is dependent on how well we can collaborate as teams and with partners to build and deliver travel solutions for our customers. ",
        },
        {
          image: icon3,
          title: "Clarity & Communication",
          subtitle:
              "We emphasize clarity in our dealings with customers, partners and our colleagues. Communicating clearly through our solution is a constant. ",
        },
        {
          image: icon4,
          title: "Growth & Development ",
          subtitle:
              "Our success is greatly impacted by our continuous effort in empowering our people for career growth and self development. Equipping them with the right skill and knowledge that helps us build solutions that cater to the growing needs of our customers at all times.",
        },
      ],
      toggle_exclusive: 1,
      image1: akinyele,
      boardMembers:[
        {
          image : akinyele,
          isAdvisor: true,
          name: "Akinyele Makinwa",
          position: "VP Logistics Operations, Haul247"
        },
        {
          image : yetunde,
          isAdvisor: false,
          name: "Yetunde Falore",
          position: "ED M&A, Standard Chartered Bank"
        },
        {
          image : sam,
          isAdvisor: false,
          name: "Sam Immanuel",
          position: "CEO, Semicolon"
        },

      ],
      blogposts: [],
    }
  },
  methods:{
    async getAllPost(){
      await getAllPublishPost().then(res =>{
        this.blogposts = res.data.slice(-3)
        this.blogposts.forEach(post => post.category = this.getColor(post.tags))
        this.blogposts.sort(function (a, b) {
          return dayjs(b.createdOn) - dayjs(a.createdOn);
        });
      })
    },
    routeToPost(post){
      if (post.postTitle !== this.$route.params.title) {
        sessionStorage.setItem("post", JSON.stringify(post))
        this.post = post
        this.$router.push({name: "BlogPost", params: {"title": post.postTitle}});
      }
    },
    getColor(tags){
      let category = {}
      if (tags.length){
        category.name = tags[0].tagName

        if (category.name === 'Workshops'){
          category.color = 'green'
        }
        else if (category.name === 'Fun Times'){
          category.color = 'pink'
        }
        else if (category.name === 'Fundraise'){
          category.color = 'red'
        }
      }
      else {
        category.name = "General"
        category.color = "purple"
      }
      return category
    }
  },
  async created() {
    await this.getAllPost()
  }
}


</script>


<template>
  <div class="tw-flex tw-w-full tw-flex-col">
    <NavBar/>
      <div class="section-one ">
        <div class="tw-w-full tw-flex-col lg:tw-px-40 lg:tw-pt-40 tw-pb-10 tw-px-5 tw-pt-20">
          <h4 class="about">About Nomadicpod</h4>
          <h1 class="about-bold">Simplifying Commute<br>Experience</h1>
        </div>
        <div class="image-container">
            <img class="team-image" src="../assets/aboutus.jpeg" alt="Image">

            <div class="vector"><img src="../assets/newAboutPageIcons/vector.png" alt=""></div>
        </div>
      </div>
    <div class="section-two">
      <div class="section-two-web">
      <div class="tw-flex tw-flex-col tw-w-5/12 tw-justify-between">
        <div>
        <h6 class="section-two-header-1">Vision</h6>
        <label class="section-two-desc-1">Promoting economic growth and environmental preservation through seamless cross broder transportation of people
        and freight.</label>
        </div>
        <div class="tw-flex tw-justify-end">
          <img class="section-two-img-1" src="../assets/newAboutPageImages/sunRayLady.png" alt="Image">
        </div>
      </div>
      <div class="tw-mx-8">
        <img src="../assets/newAboutPageImages/blackWomenSmiling.png" alt="women-smiling">
      </div>
      <div class="tw-flex tw-w-5/12 tw-justify-between tw-flex-col">
        <img class="section-two-img-2 " src="../assets/newAboutPageImages/building.png" alt="building">
        <div class="">
        <h6 class="section-two-header-1">Mission</h6>
        <label class="section-two-desc-1">To empower individuals, institutions and businesses with efficient and reliable mobility and transportation
          solutions that accelerate growth and promote sustainable development.</label>
        </div>
      </div>
      </div >
      <div class="section-two-mobile tw-pb-10 tw-mt-28">
        <div class="tw-flex tw-w-full tw-flex-row tw-justify-between">
          <div class="tw-flex tw-w-11/12 tw-flex-col tw-justify-between tw-pl-12">
            <div class="tw-flex tw-justify-between tw-flex-col tw-h-full">
              <div>
             <h6 class="section-two-header-1">Vision</h6>
            <label class="section-two-desc-1">Increasing Productivity and propelling growth by setting Africa in motion</label>
              </div>
            <img class="section-two-img-1" src="../assets/newAboutPageImages/sunRayLady.png" alt="Image">
            </div>
          </div>
          <div class="tw-flex tw-w-4/12 tw--mr-20">
            <img class="section-two-img-mobile-1" src="../assets/newAboutPageImages/blackWomenSmiling.png" alt="women-smiling">
          </div>
        </div>
        <div class="tw-flex tw-w-full tw-flex-row tw-justify-between tw-mt-5">
          <div class="tw-flex tw--ml-16">
          <img class="section-two-img-mobile-2" src="../assets/newAboutPageImages/blackWomenSmiling.png" alt="women-smiling">
          </div>
          <div class="tw-flex tw-flex-col tw-justify-between tw-w-8/12">
            <img class="section-two-img-2" src="../assets/newAboutPageImages/building.png" alt="building">
            <div class="">
              <h6 class="section-two-header-1">Mission</h6>
              <label class="section-two-desc-1 tw-pr-2">Empowering individuals and businesses with efficient & reliable transportation solutions
                that accelerate growth and promote sustainable development.</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <partners/>

    <div class="tw-flex tw-w-full tw-justify-center tw-items-center">
      <div class="section-four-body tw-py-32">
        <div class="tw-flex tw-flex-row tw-h-auto tw-justify-center lg:tw-justify-between tw-px-5 lg:tw-px-0">
        <div class="tw-flex tw-flex-col tw-mr-5 lg:tw-mr-10 tw-h-full tw-justify-between">
          <img class="street-light" src="../assets/newAboutPageImages/street-light.png" alt="traffic light">
          <img class="express-way tw-mt-5 lg:tw-mt-0 " src="../assets/newAboutPageImages/express-way-image.png" alt="high way">
        </div>

        <div class="tw-flex tw-flex-col tw-h-full tw-justify-between">
          <img class="canoe" src="../assets/newAboutPageImages/canoe-image.png" alt="men on canoe">
          <img class="rainy-day tw-mt-5 lg:tw-mt-0" src="../assets/newAboutPageImages/rainy-day-image.png" alt="rainy day">
        </div>
        </div>
        <div class="tw-flex tw-h-full tw-justify-between tw-flex-col tw-w-full lg:tw-w-6/12 tw-px-5 lg:tw-pl-10 tw-pt-10 lg:tw-pt-0">
          <h2 class="great-journey-header">The Great Journey</h2>
          <label class="great-journey-desc">We are inspired by a vision of improving the daily
            experiences of millions of road commuters as well as the
            thousands of businesses that rely on road transportation
            to carry out their activities. Our mission is to empower
            every commuter,from transporters, travelers, and market
            leaders to students, tourists, and more, by providing them
            with the information and tools they need to travel safely,
            efficiently, and with ease. We are also committed to
            launching innovative business models that are centered
            around commute lines, to help further improve the
            transportation landscape for all stakeholders involved. </label>
        </div>

      </div>
    </div>

    <div class="section-five tw-px-8 lg:tw-px-40">
      <h2 class="section-five-header">Our Journey-as-a-Timeline</h2>
      <div class="section-five-container tw-my-20 lg:tw-mb-0 lg:tw-mt-20">

        <div class="date-container">
        <h4 class="section-five-container-date">Jan 2022</h4>
          <div class="dashed-line" id="dashed-line-one"></div>
          <div class="section-five-container-box" ></div>
        </div>

        <div class="date-container tw-mt-5 lg:tw-mt-0">
          <h4 class="section-five-container-date">Mar 2022</h4>
          <div class="dashed-line" id="dashed-line-two"></div>
          <div class="section-five-container-box" ></div>
        </div>

        <div class="date-container tw-mt-5 lg:tw-mt-0">
          <h4 class="section-five-container-date">June 2022</h4>
          <div class="dashed-line" id="dashed-line-three"></div>
          <div class="section-five-container-box" ></div>
        </div>


        <div class="date-container tw-mt-5 lg:tw-mt-0">
          <h4 class="section-five-container-date">Oct 2022</h4>
          <div class="dashed-line" id="dashed-line-four"></div>
          <div class="section-five-container-box" ></div>
        </div>



        <div class="date-container tw-mt-5 lg:tw-mt-0">
          <h4 class="section-five-container-date">Jan 2023</h4>
          <div class="dashed-line" id="dashed-line-five"></div>
          <div class="section-five-container-box" ></div>
        </div>

        <div class="date-container tw-mt-5 lg:tw-mt-0" >
          <h4 class="section-five-container-date">2023</h4>
          <div class="dashed-line" id="dashed-line-six"></div>
          <div class="section-five-container-box" ></div>
        </div>

      </div>
    </div>

    <teamcard />
    <div class="section-seven">
      <v-col lg="5" sm="12" class="ourvalues">
        <h1>Our Values</h1>
        <p>
          Our values define the Nomadicpod culture, who we are, and what we do
          every day. They are the foundation of our identity and the compass of
          our interaction with all stakeholders.
        </p>
      </v-col>
      <div class="tw-flex tw-w-full tw-flex-wrap card-div">
        <div v-for="(value, index) in values" :key="index" >
          <v-card class="mx-auto card-box" elevation="0" >
            <v-img height="70px" width="70px" :src="value.image"></v-img>
            <v-card-title> {{ value.title }} </v-card-title>
            <v-card-subtitle> {{ value.subtitle }}</v-card-subtitle>
          </v-card>
        </div>
      </div>
    </div>
    <!-- <div class="section-eight">
      <v-col lg="5" sm="12" class="ourvalues">
      <h2 class="the-board-text">The Boards</h2>
      </v-col>
      <div class="tw-flex tw-w-full tw-justify-center tw-items-center">
      <div class="section-eight-image-container">
          <v-card
              flat >
            <v-card-text>
              <v-btn-toggle v-model="toggle_exclusive">
                <v-btn text :value="1" class="section-eight-toggle">
                  Board of Directors
                </v-btn>
                <v-btn text :value="2" class="section-eight-toggle">
                  Board of Advisors
                </v-btn>
              </v-btn-toggle>

            </v-card-text>
          </v-card>
        <div class="tw-flex tw-flex-wrap">
          <div  v-for="(board,index) in boardMembers" :key="index"
                :class="[ index % 2 !== 0 ? 'lg:tw-mr-10' : '']">
            <div v-if="board.isAdvisor && toggle_exclusive === 2" class="section-eight-board-card tw-flex tw-items-end tw-mt-10"
                 :style="{backgroundImage:`url(${board.image})`}" >
              <div class="section-eight-board-card-content tw-flex tw-flex-col tw-justify-center tw-pl-5">
                 <p class="section-eight-board-name">{{board.name}}</p>
                 <p class="section-eight-board-title">{{board.position}}</p>
              </div>
            </div>
            <div v-if="!board.isAdvisor && toggle_exclusive===1" class="section-eight-board-card tw-flex tw-items-end tw-mt-10"
                 :style="{backgroundImage:`url(${board.image})`}">
              <div class="section-eight-board-card-content tw-flex tw-flex-col tw-justify-center tw-pl-5">
                <p class="section-eight-board-name">{{board.name}}</p>
                <p class="section-eight-board-title">{{board.position}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div> -->

    <div class="section-nine tw-px-5 lg:tw-px-40 ">
      <h2 class="blog-highlight-text tw-mt-20 lg:tw-mt-0">Blog Highlight</h2>
      <div class="section-nine-container scroll">
      <div class="section-nine-blog-container tw-py-10 tw-mr-10 lg:tw-mr-0" v-for="(blogpost, index) in blogposts" :key="index">
          <img class="section-nine-container-img" :src="blogpost.postImage" alt="">
        <div class="tw-flex tw-flex-col section-nine-container-content  lg:tw-pl-8 tw-justify-between">
          <v-chip label
              :color="blogpost.category.color+' white'"
              class="tw-my-4"

          >
            {{ blogpost.category.name }}
          </v-chip>
          <h2 class="blog-subheading">{{blogpost.postTitle}}</h2>
          <p class="section-nine-paragraph">{{blogpost.postContent}}
          </p>
          <v-btn width="100"  color="blue darken-4" style="text-transform: none" text @click="routeToPost(blogpost)">
            Read more
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    </div>
    <Footer/>
  </div>
</template>



<style scoped lang="scss">
.about{
  color: var(--padiGrey);
  font-family: Raleway,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.about-bold{
  color: #263238;
  font-family: Raleway,sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 62px;

  @media screen and (max-width: 820px){
    font-size: 32px;
    line-height: 48px;
  }
}
.image-container{
  width: 100%;
  position: relative;
}
.vector{
  width: 200px;
  height: 150px;
  position: absolute;
  top: -3rem;
  right: 20rem;
}

.team-image {
  display: flex;
  filter: grayscale(100%);
  width: 100%;
  opacity: 0.7;
  @media screen and (max-width: 375px) {
    margin-top: 90px;
    transform: scale(2.4);
  }
}


// section 2 for desktop
.section-two{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  min-height: 60vh;
}
.section-two-header-1{
  color: #263238;
  font-family: Raleway,sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;


  @media screen and (max-width: 820px) {
    font-size: 28px;
  }
}
.section-two-desc-1{
  color: #263238;
  font-family: Raleway,sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  @media screen and (max-width: 820px) {
    display: flex;
    font-size: 14px;
    line-height: 24px;
    width: 90%;
  }
}

.section-two-web{
  display: flex;
  width: 65rem;
  flex-direction: row;
  @media screen and(max-width: 820px) {
    display: none;
  }
}
.section-two-mobile{
  display: none;
  @media screen and (max-width: 820px){
    display: flex !important;
    width: 100%;
    flex-direction: column;
  }
}

.section-two-img-1{
  width: 182px;
  height: 124px;
  border-radius: 2px;
  @media screen and (max-width: 820px){
    width: 273px;
    height: 156px;
  }
}
.section-two-img-2{
  width: 182px;
  height: 118px;
  border-radius: 2px;
}
.section-two-img-mobile-1{
  width: auto;
  height: 100%;
  clip-path: polygon(0 0, 40% 0, 40% 100%, 0 100%);
}
.section-two-img-mobile-2{
  width: auto;
  height: 100%;
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 30% 100%);
}

  // section four for mobile

  //section four for desktop

.section-four-body{
  display: flex;
  width: 65rem;
  flex-direction: row;
  @media screen and(max-width: 820px) {
    flex-direction: column;
    width: 100%;
  }
}
.great-journey-header{
  color: #263238;
  font-family: DM Sans,sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
}
.great-journey-desc{
  color: #4F4F4F;
  font-family: Raleway,sans-serif;
  font-size: 18px;
  //font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

// section five
.dashed-line{
  width: 2px;
  height: 100px;
  border-left: 1px dashed rgba(0,74,173, 0.63);

  @media screen and (max-width: 820px){
    height: 110px;
  }
}

#dashed-line-one{
  @media screen and (max-width: 820px) {
    height: 110px;

  }
}

#dashed-line-two{
  height: 150px;

  @media screen and (max-width: 820px){
    height: 110px;
  }
}

#dashed-line-three{
  height: 200px;

  @media screen and (max-width: 820px){
    height: 110px;
  }
}

#dashed-line-three{
  height: 250px;

  @media screen and (max-width: 820px){
    height: 110px;
  }
}

#dashed-line-four{
  height: 300px;

  @media screen and (max-width: 820px){
    height: 110px;
  }
}

#dashed-line-five{
  height: 350px;

  @media screen and (max-width: 820px){
    height: 110px;
  }
}

#dashed-line-six{
  height: 400px;

  @media screen and (max-width: 820px){
    height: 110px;
  }
}

.box {
  width: 120px;
  height: 50px;
  border: 1px solid rgba(0, 74, 173, 0.09);
  border-radius: 4px;
  box-shadow: 0 5px 20px rgba(234, 234, 234, 0.5);
  background: rgba(250, 250, 250, 0.11);
  margin-top: 3rem;

  @media screen and (max-width: 768px){
    width: 90px;
    height: 40px;
  }

  @media screen and (max-width: 375px){

    width:150px;
    height: 80px;
  }
}

#box-1{
  @media screen and (max-width: 768px){
    margin-top: 4rem;
  }

  @media screen and (max-width: 375px){

    margin-top: 3px;
  }
}
#box-2{
  margin-top: 6.5rem;


  @media screen and (max-width: 768px) {
    margin-top: 7rem;

  }

  @media screen and (max-width: 375px){
    margin-top: 2rem;

  }
}

#box-3{
  margin-top: 12.5rem;

  @media screen and (max-width: 768px) {
    margin-top: 13.3rem;

  }

  @media screen and (max-width: 375px){
    margin-top: 2rem;
  }
}

#box-4{
  margin-top: 15.5rem;

  @media screen and (max-width: 768px) {
    margin-top: 16.4rem;

  }


  @media screen and (max-width: 375px){
    margin-top: 2rem;
  }
}

#box-5{
  margin-top: 19rem;

  @media screen and (max-width: 768px) {
    margin-top: 19.4rem;

  }

  @media screen and (max-width: 375px){
    margin-top: 1.8rem;
  }
}

#box-6{
  display: none;
  @media screen and(max-width: 375px){
    display: flex;
    margin-top: 2rem;
  }
}

.section-five-header{
  color: #263238;
  font-family: DM Sans,sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  @media screen and (max-width: 820px) {
    font-size: 28px;
  }
}

.section-five-container{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  @media screen and (max-width: 820px) {
    flex-direction: column;
    justify-content: center;
  }
}

.section-five-container-box{
  width: 171px;
  height: 69px;
  border-radius: 10px;
  border: 1px solid rgba(0, 74, 173, 0.09);
  background: rgba(250, 250, 250, 0.11);
  box-shadow: 0 5px 20px 0 rgba(234, 234, 234, 0.50);
  @media screen and (max-width: 820px){
    width: 202px;
    height: 68px;
  }
}

.section-five-container-date{
  color: #4F4F4F;
  font-family: Raleway,sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  @media screen and (max-width: 820px){
    width: 6rem;
  }
}

.date-container{
  display: flex;
  align-items: flex-end;
}

.date-1{
  margin-top: 4.8rem;

  @media screen and (max-width: 768px) {
    margin-top: 5.3rem;

  }

  @media screen and (max-width: 375px){
    margin-top: 3.6rem;

  }
}

.date-2{
  margin-top: 8rem;

  @media screen and (max-width: 768px) {
    margin-top: 8.3rem;
  }

  @media screen and (max-width: 375px){
    margin-top: 5.4rem;
  }
}

.date-3{
  margin-top: 14.3rem;


  @media screen and (max-width: 768px) {
    margin-top: 14.6rem;

  }

  @media screen and (max-width: 375px){
    margin-top: 5.5rem;
  }
}

.date-4{
  margin-top: 17.3rem;


  @media screen and (max-width: 768px) {
    margin-top: 17.7rem;

  }

  @media screen and (max-width: 375px){
    margin-top: 5.5rem;
  }
}

.date-5{
  margin-top: 20.6rem;


  @media screen and (max-width: 768px) {
    margin-top: 21rem;

  }

  @media screen and (max-width: 375px){
    margin-top: 5.5rem;
  }
}

.date-6{
  margin-top: 23.6rem;

  @media screen and (max-width: 768px) {
    margin-top: 24rem;
  }

  @media screen and (max-width: 375px) {
      margin-top: 5.5rem;
  }
}

#date-container-6{
  margin-left: 35px;
}

//section six
.section-six{
  border-bottom: 1px solid #4f4f4f;
}
//section seven

.section-seven {
  background-color: #f9f9f9;
  min-height: 100vh;
  padding: 10rem ;

  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
}

.ourvalues {
  @media screen and (max-width: 1024px) {
    width: 70%;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.ourvalues h1 {
  font-family: "Raleway",sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  color: var(--padiGrey);
  padding-bottom: 5%;
}

.ourvalues p {
  font-family: "DM Sans",sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  color: rgba(0, 0, 0, 0.6);
}

.card-div {
  padding: 5% 0;
  justify-content: center;

}

.card-box{
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.05) !important;
}

.section-seven .v-card__title {
  padding-bottom: 30px;
  font-family: "DM Sans",sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  word-break: normal;
  width: 65%;
}

.section-seven .v-card__subtitle {
  font-family: "DM Sans",sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.6);
}

.section-seven .v-card {
  padding: 40px;
  margin: 20px !important;
  height: 500px;
  width: 500px;
  border-radius: 20px;
  box-shadow: 3px 4px 18px 0 rgba(203, 200, 200, 0.99);

  @media screen and (max-width: 576px) {
    margin: 20px 0 !important;
    width: 100%;
    padding: 1.5rem;
    height: auto;
  }
  @media (max-width: 400px) {
    height: fit-content;
  }
}


//section eight
.section-eight {
  background-color: white;
  min-height: 100vh;
  padding: 10rem ;

  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
}
.section-eight-toggle{
  color: #4F4F4F;
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-transform: capitalize;
}
.the-board-text{
  color: #4F4F4F;
  font-family: Raleway,sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px;
}
.section-eight-image-container{
  display: flex;
  flex-direction: column;
  width: 50rem;
  @media screen and (max-width: 820px){
    width: 100%;
  }
}
.section-eight-board-card{
  width: 380px;
  height: 371px;
  border-radius: 0 0 0 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color:rgba(243, 246, 246, 1) !important;
}
.section-eight-board-card-content{
  width: 286px;
  height: 105px;
  border-radius: 0 5px 5px 20px;
  background: rgba(38, 50, 56, 0.81);
}
.section-eight-board-name{
  color: #FFF;
  font-family: Raleway,sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.section-eight-board-title{
  color: #FFF;
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
//section 9

.section-nine{

}
.section-nine-container{
 display: flex;
 flex-direction: row;
  width: max-content;
  max-width: 80vw;
}
.scroll {
  overflow-y: hidden;
  overflow-x: scroll;
}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}
.section-nine-blog-container{
  display: flex;
  flex-direction: row;
  min-width: 30vw !important;
  padding-right: 3rem !important;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    min-width: 100% !important;
    padding-right: 0!important;
  }
}
.section-nine-container-img{
  width: 228px !important;
  height: 211px !important;
  border-radius: 10px !important;
}
.section-nine-container-content{
  min-width: 15vw !important;
}
.blog-highlight-text{
  color: #4F4F4F;
  font-family: Raleway,sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px;
}
.blog-subheading{
  font-family: Inter sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}
.section-nine-paragraph{
  font-family: Inter sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #4F4F4F;
  //text-overflow: ellipsis;
  overflow: hidden;
  //white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
::v-deep .v-chip__content {
  font-family: "Inter",sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff !important;
}
::v-deep .v-chip{
  width: 120px !important;
  height: 38px !important;
}
.fundraisers-box{
  font-family: Inter, sans-serif;
  background:#4D87D4;
  color: white;
  width: 170px;
  height: 38px;
  border-radius: 4px;
  padding: 10px 10px 34px 40px;
  margin-bottom: 20px;

  @media screen and (max-width: 768px){
      width: 130px;
      height: 20px;
      padding-right: 13px;
      padding-left: 24px;
  }

  @media screen and (max-width: 375px){
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    width: 104px;
    height: 25px;
    padding: 4px 0 0 15px;
    margin-left: 0;
    margin-top: 20px;
  }
}
.fun-times{
  background: #C84867;
  color: white;
  width: 170px;
  height: 38px;
  border-radius: 4px;
  padding: 10px 10px 34px 40px;
  margin-bottom: 20px;

  @media screen and (max-width: 768px){
    width: 130px;
    height: 20px;
    padding-right: 13px;
    padding-left: 24px;
  }

  @media screen and (max-width: 375px){
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    width: 104px;
    height: 25px;
    padding: 4px 0 0 15px;
    margin-left: 6px;
    margin-top: 20px;
  }

}
::v-deep .v-btn-toggle > .v-btn.v-btn {
  border: none !important;
}
</style>




