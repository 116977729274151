<script>
import Icons from "@/components/reuseables/Icons.vue";

export default {
  name: 'DemoButton',
  components: {Icons}
}

</script>

<template>
<div>
  <v-btn class="tw-mr-5 demo-btn" style="background:#FFFFFF; border: 1px solid #D0D5DD;
  border-radius: 10px; width: 130px; text-transform: none; padding: 20px"
  > <icons name="play-icon" class="tw-mr-2"/>Demo</v-btn>
</div>
</template>

<style scoped lang="scss">
.demo-btn{
  font-family: Raleway,sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
}
</style>