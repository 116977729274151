<template>
    <div class="step-three">
      <loader :loading="loading" v-if="loading" />
      <div class="tw-w-full" v-else>
      <NavBar/>
        <booking-steps />
      <div class="wrapper">
        <div class="back tw-cursor-pointer " @click="$router.push({name:'OneWaySelectSeats'})"><v-icon>mdi-chevron-left</v-icon>Back</div>
      </div>
      <div class="form-input">
        <div class="tw-flex tw-w-full lg:tw-items-center tw-flex-col lg:tw-flex-row tw-justify-between">
          <seat-timer/>
        </div>
        <div v-for="(seat,index) in tripAndTravellerDetails.seats" :key="index">
          <customer-details :index="index" :traveller-detail="seat.travellerDetail"/>
        </div>

      </div>
      <checkout-details />
       <Footer/>
      </div>
    </div>
</template>

<script>
    import BookingSteps from '../../components/reuseables/bookingAndHiring/OneWayBookingSteps.vue'
    import CheckoutDetails from '../bookings/CheckoutDetails.vue'
import CustomerDetails from '../bookings/CustomerDetails.vue'
    import Footer from "@/home/Footer.vue";
    import NavBar from "@/home/Navbar.vue";
    import {mapGetters} from "vuex";
    import SeatTimer from "@/components/reuseables/bookingAndHiring/SeatTimer.vue";
    import Loader from "@/components/reuseables/Loader.vue";
    export default {
        name: "InputDetails",
        components: {
          Loader,
          SeatTimer,
          NavBar,
          Footer,
            BookingSteps,
            CheckoutDetails,
                CustomerDetails
        },
      data(){
          return{
            loading: false
          }
      },
      computed: {
        ...mapGetters("booking", ["tripAndTravellerDetails"]),
      },
      created() {
          this.loading = true
        let passengerDetail = JSON.parse(sessionStorage.getItem("passengerDetail"))
        let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))

        if (userSelectedTrip && Object.keys(userSelectedTrip).length){
          this.$store.dispatch("booking/setUserSelectedTrip", userSelectedTrip)
          if (Object.keys(passengerDetail).length) {
            this.$store.dispatch("booking/updateCurrentSeats", passengerDetail.seats)
          }
          this.$store.dispatch("booking/updateTripAndTravellerDetails",passengerDetail)
          this.$store.dispatch("booking/getTotalAmount", userSelectedTrip.tripAmount)
        }
        this.loading = false
      }
    }
</script>

<style lang='scss' scoped>
    .step-three {
        background-color: #fafafa;
    }

    .form-input {
        padding: 2rem 15rem;
        @media (max-width:1024px) {
            padding: 2rem 8rem;
        }
           @media (max-width:1024px) {
            padding: 2rem 8rem;
        }
           @media (max-width:768px) {
            padding: 2rem 4rem;
        }
            @media (max-width:576px) {
            padding: 2rem ;
        }
    }

    .sub-heading {

        font-family: 'Inter',sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #4F4F4F;
    }

    .group1 {
        display: flex;
        justify-content: space-between;
    }

    .sub-title {
        font-family: 'Inter',sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #004AAD;
            @media (max-width:576px) {
        font-size: 14px;
        }
    }
    .wrapper{
      width: 100%;
      padding: 0 10rem;

      @media screen and (max-width: 1024px) {
        padding: 1rem;
      }
    }
</style>