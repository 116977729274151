var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all-vehicle tw-pb-5"},[_vm._m(0),_c('div',{staticClass:"card-container display-center tw-w-full"},_vm._l((_vm.availableVehiclesForHire),function(vehicle,index){return _c('div',{key:index,staticClass:"each-card"},[_c('div',{staticStyle:{"height":"200px"}},[_c('img',{attrs:{"src":vehicle.vehicle.vehicleImage,"alt":"vehicle image"}})]),_c('div',[_c('div',{staticClass:"tw-flex tw-flex-row tw-items-center tw-pt-5"},[_c('img',{staticClass:"logo",attrs:{"src":vehicle.transportCompanyLogo,"alt":"logo"}}),_c('p',{staticClass:"medium-text",staticStyle:{"color":"#4F4F4F"}},[_vm._v(_vm._s(vehicle.transportCompanyName || 'Nomadicpod'))])]),_c('div',{staticClass:"tw-flex tw-my-5"},[_c('p',{staticClass:"sort text-capitalize"},[_vm._v(_vm._s(vehicle.vehicle.vehicleType))]),_c('v-spacer'),_c('p',{staticClass:"small-brown-text"},[_vm._v("₦"+_vm._s(_vm.formatNumber(vehicle.hirePrice))+"/day")])],1),(vehicle.discount)?_c('div',{staticClass:"tw-flex tw-flex-row tw-pb-4 tw-items-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"#004aad"}},[_vm._v("mdi-cash-multiple")]),_c('label',{staticClass:"small-brown-text "},[_vm._v(_vm._s(vehicle.discountValue)+"% Discount after "+_vm._s(vehicle.numberOfDiscountedDays)+" day(s) ")])],1):_vm._e()]),_c('div',{staticClass:"tw-flex tw-flex-wrap"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"small-brown-text",staticStyle:{"color":"#004aad"},attrs:{"color":"#F3F3F3"}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#004aad"}},[_vm._v("mdi-car-seat")]),_vm._v(" "+_vm._s(vehicle.vehicle.vehicleCapacity)+" ")],1)]}}],null,true)},[_c('div',[_c('strong',[_vm._v("Seats:")]),_vm._v(" "+_vm._s(vehicle.vehicle.vehicleCapacity)+" "),_c('br'),_c('p',[_vm._v("Number of available seats in the vehicle.")])])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"small-brown-text ml-2",staticStyle:{"color":"#004aad"},attrs:{"color":"#F3F3F3"}},'v-chip',attrs,false),on),[(vehicle.vehicleACType)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#004aad"}},[_vm._v("mdi-snowflake")]):_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#004aad"}},[_vm._v("mdi-snowflake-off")]),_vm._v(" A/C ")],1)]}}],null,true)},[_c('div',[_c('strong',[_vm._v("Air-Conditioner:")]),_c('br'),(vehicle.vehicleACType)?_c('p',[_vm._v("Vehicle has a functioning Air conditioning system")]):_c('p',[_vm._v("Vehicle has no functioning Air conditioning system")])])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"small-brown-text ml-2",staticStyle:{"color":"#004aad"},attrs:{"color":"#F3F3F3"}},'v-chip',attrs,false),on),[(vehicle.vehicleVideoType)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#004aad"}},[_vm._v("mdi-video")]):_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#004aad"}},[_vm._v("mdi-video-off")]),_vm._v(" Video ")],1)]}}],null,true)},[_c('div',[_c('strong',[_vm._v("Video Screen:")]),_c('br'),(vehicle.vehicleVideoType)?_c('p',[_vm._v("Vehicle has a functioning video system")]):_c('p',[_vm._v("Vehicle has no functioning video system")])])]),_c('v-spacer'),_c('button',{staticClass:"btn-design tw-mt-10 md:tw-mt-0 tw-w-full md:tw-w-auto",on:{"click":function($event){return _vm.handleSelectedVehicle(vehicle)}}},[_vm._v("Hire bus")])],1)])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-justify-between"},[_c('p',{staticClass:"medium-text",staticStyle:{"color":"#004aad"}},[_vm._v("Available Vehicles")])])}]

export { render, staticRenderFns }