<template>
  <div class="tw-flex tw-flex-row tw-w-full" style="background: #f54c35; height: 40px;">
    <div class="tw-flex tw-w-full" >
      <v-row align="center" style="padding-bottom: 12px; ">

        <v-col >
          <p class="notice-text">
            {{notice}}
          </p>
        </v-col>


      </v-row>
    </div>
    <v-icon class="times-icon tw-cursor-pointer" @click="closeAppBar">mdi-close</v-icon>
  </div>
</template>

<script>


export default {
  name: 'NoticeNavbar',

  props: {
    notice: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.startAnimation();
  },
  methods: {

    closeAppBar() {
      this.$emit('close-app-bar');
    },

    startAnimation() {
      const noticeText = this.$el.querySelector('.notice-text');
      const containerWidth = this.$el.clientWidth;
      const textWidth = noticeText.offsetWidth;

      const distance = containerWidth + textWidth;
      const duration = 30;

      noticeText.style.transform = `translateX(${containerWidth}px)`;

      noticeText.animate(
          [{ transform: `translateX(${containerWidth}px)` }, { transform: `translateX(-${distance}px)` }],
          {
            duration: duration * 1000,
            iterations: Infinity
          }
      );
    }
  }
}
</script>

<style scoped>


.notice-text {
  white-space: nowrap;
  display: inline-block;
  position: absolute;
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #FFFFFF;
}





</style>
