<template>
  <div id="shortcut">
    <div class="options tab">
      <span
        class="trip-types look "
        @click="activeTab = '1'"
        :class="[activeTab === '1' ? 'active' : '']"
        >One-Way</span
      >
      <!--            <v-divider vertical></v-divider>-->
      <!--            <span class="trip-types look" :class="[activeTab === '2' ? 'active' : '']" @click="activeTab = '2'">Round-->
      <!--                Trip</span>-->
      <!--            <v-divider vertical></v-divider>-->
      <!--            <span class="trip-types look" :class="[activeTab === '3' ? 'active' : '']" @click="activeTab = '3'">Hire-->
      <!--                Vehicles</span>-->
    </div>
    <div class="input-fields options ">
      <validation-observer
        ref="observer"
        v-slot="{ handleSubmit }"
        class="tw-w-full"
      >
        <form @submit.prevent="handleSubmit(handleOneWaySearch)">
          <div class="one-way" v-if="activeTab === '1'">
            <div class="tw-hidden tw-w-full tw-flex-col lg:tw-flex-row">
              <v-radio-group
                v-model="oneWayData.tripType"
                row
                class="tw-w-full heading look mb-4 "
                hide-details
              >
                <div class="tw-w-1/2">
                  <v-radio
                    value="Road Trip"
                    label="Road Trip"
                    background-color="#fdfffc"
                    color="#004aad"
                  ></v-radio>
                </div>
                <div class="tw-w-1/2 ">
                  <v-radio
                    value="Water Trip"
                    label="Water Trip"
                    background-color="#fdfffc"
                    color="#004aad"
                  ></v-radio>
                </div>
              </v-radio-group>
            </div>
<!--            <v-divider class="tw-pb-5 tw-mt-5" />-->
            <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row">
              <div class="location sizing">
                <div class="heading look mb-4">
                  From
                </div>
                <ValidationProvider
                  name="Location"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <div class="" :class="classes">
                    <v-combobox
                      v-model="oneWayData.departure"
                      :items="cities"
                      :placeholder="oneWayData.tripType === 'Road Trip' ? 'Lagos' : 'Five Cowries'"
                      prepend-inner-icon="mdi-map-marker-outline"
                      solo
                      :item-text="getCity"
                      hide-details
                      :flat="$vuetify.breakpoint.mdAndUp"
                      background-color="#fdfffc"
                      type="text"
                      color="#004aad"
                      append-icon=""
                    >
                    </v-combobox>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="destination sizing">
                <div class="heading look mb-4">
                  To
                </div>

                <ValidationProvider
                  name="Destination"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <div class="" :class="classes">
                    <v-combobox
                      v-model="oneWayData.destination"
                      :items="cities"
                      :placeholder="oneWayData.tripType === 'Road Trip' ? 'Asaba' : 'Ikorodu'"
                      prepend-inner-icon="mdi-crosshairs-gps"
                      solo
                      hide-details
                      :flat="$vuetify.breakpoint.mdAndUp"
                      background-color="#fdfffc"
                      color="#004aad"
                      append-icon=""
                      type="text"
                      :item-text="getCity"
                    >
                    </v-combobox>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <v-divider class="tw-pb-5 tw-mt-5" />
            <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row">
              <div class="tw-w-full ">
                <div class="heading look mb-4">
                  Date
                </div>

                <v-menu
                  v-model="oneWayDate"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <ValidationProvider
                      name="Date"
                      rules="required"
                      v-slot="{ classes, errors }"
                    >
                      <div class="" :class="classes">
                        <v-text-field
                          v-model="oneWayData.travelDates"
                          :flat="$vuetify.breakpoint.mdAndUp"
                          v-on="on"
                          placeholder="2022-09-9"
                          solo
                          hide-details
                          readonly
                        >
                          <template #prepend-inner>
                            <v-icon class="">
                              mdi-calendar-month-outline
                            </v-icon>
                          </template></v-text-field
                        >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </template>

                  <v-date-picker
                    locale="en-in"
                    v-model="oneWayData.travelDates"
                    no-title
                    @input="dateMenu = false"
                    :min="minDate"
                  >
                  </v-date-picker>
                </v-menu>
              </div>
              <!--                        <div class="seats sizing">-->
              <!--                            <div class="heading look mb-4">-->
              <!--                                Passenger(s)-->
              <!--                            </div>-->
              <!--                            <select-passengers @getTotalPassengers="getTotalPassengers" />-->
              <!--                        </div>-->
            </div>
            <v-divider class="tw-pb-5 tw-mt-5" />
            <search-button
              :show-text="true"
              action-type="submit"
              :loading="oneWayLoading"
            />
          </div>
          <!--                    <v-progress-linear indeterminate color="#004AAD" v-if="oneWayLoading"></v-progress-linear>-->
        </form>
      </validation-observer>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(handleRoundTripSearch)">
          <div class="round-trip flex-handler" v-if="activeTab === '2'">
            <div class="location sizing">
              <span class="heading look">
                Location
              </span>
              <ValidationProvider
                name="Location"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="mt-4" :class="classes">
                  <v-combobox
                    v-model="roundTripData.location"
                    :items="citiesData.cities"
                    placeholder="Lagos Island"
                    prepend-inner-icon="mdi-map-marker-outline"
                    solo
                    hide-details
                    flat
                    background-color="#fdfffc"
                    type="text"
                    color="#004aad"
                    append-icon=""
                  >
                  </v-combobox>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="destination sizing">
              <span class="heading look">
                Destination
              </span>

              <ValidationProvider
                name="Destination"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="mt-4" :class="classes">
                  <v-combobox
                    v-model="roundTripData.destination"
                    :items="citiesData.cities"
                    placeholder="Bende"
                    prepend-inner-icon="mdi-crosshairs-gps"
                    solo
                    hide-details
                    flat
                    background-color="#fdfffc"
                    color="#004aad"
                    append-icon=""
                    type="text"
                  >
                  </v-combobox>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="departure sizing">
              <span class="heading look">
                Departure Date
              </span>

              <v-menu
                v-model="roundTripDepartureDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <ValidationProvider
                    name=" Departure Date"
                    rules="required"
                    v-slot="{ classes, errors }"
                  >
                    <div class="mt-4" :class="classes">
                      <v-text-field
                        v-model="roundTripData.departureDate"
                        flat
                        v-on="on"
                        placeholder="2022-09-9"
                        solo
                        hide-details
                        readonly
                      >
                        <template #prepend-inner>
                          <v-icon class="">
                            mdi-calendar-month-outline
                          </v-icon>
                        </template></v-text-field
                      >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </template>

                <v-date-picker
                  locale="en-in"
                  v-model="roundTripData.departureDate"
                  no-title
                  @input="dateMenu = false"
                  :min="minDate"
                >
                </v-date-picker>
              </v-menu>
            </div>
            <div class="return sizing">
              <span class="heading look">
                Return Date
              </span>

              <v-menu
                v-model="roundTripReturnDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <ValidationProvider
                    name=" Return Date"
                    rules="required"
                    v-slot="{ classes, errors }"
                  >
                    <div class="mt-4" :class="classes">
                      <v-text-field
                        v-model="roundTripData.returnDate"
                        flat
                        v-on="on"
                        placeholder="2022-09-19"
                        solo
                        hide-details
                        readonly
                      >
                        <template #prepend-inner>
                          <v-icon class="">
                            mdi-calendar-month-outline
                          </v-icon>
                        </template></v-text-field
                      >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </template>

                <v-date-picker
                  locale="en-in"
                  v-model="roundTripData.returnDate"
                  no-title
                  @input="dateMenu = false"
                  :min="minDate"
                >
                </v-date-picker>
              </v-menu>
            </div>
            <div class="seats sizing">
              <span class="heading look">
                Seats
              </span>
              <select-passengers @getTotalPassengers="getTotalPassengers" />
            </div>
            <search-button type="submit" :show-text="true" />
          </div>
          <v-progress-linear
            indeterminate
            color="#004AAD"
            v-if="roundTripLoading"
          ></v-progress-linear>
        </form>
      </validation-observer>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(handleHireVehicleSearch)">
          <div class="hire-vehicles flex-handler" v-if="activeTab === '3'">
            <div class="from sizing">
              <span class="heading look">
                From
              </span>

              <ValidationProvider
                name="Location"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div :class="classes" class="mt-4">
                  <v-combobox
                    v-model="hireVehiclesData.from"
                    :items="citiesData.cities"
                    class="vtf"
                    placeholder="Lagos Island"
                    prepend-inner-icon="mdi-map-marker-outline"
                    solo
                    hide-details
                    flat
                    background-color="#fdfffc"
                    color="#004aad"
                    append-icon=""
                  >
                  </v-combobox>
                  <span> {{ errors[0] }} </span>
                </div>
              </ValidationProvider>
            </div>
            <div class="to sizing">
              <span class="heading look">
                To
              </span>

              <ValidationProvider
                name="Destination"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div :class="classes" class="mt-4">
                  <v-combobox
                    v-model="hireVehiclesData.to"
                    :items="citiesData.cities"
                    placeholder="Uyo"
                    prepend-inner-icon="mdi-crosshairs-gps"
                    solo
                    hide-details
                    flat
                    background-color="#fdfffc"
                    color="#004aad"
                    append-icon=""
                  >
                  </v-combobox>
                  <span> {{ errors[0] }} </span>
                </div>
              </ValidationProvider>
            </div>
            <div class="hire-date sizing">
              <span class="heading look">
                Hire Date
              </span>

              <v-menu
                v-model="hirevehicledate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <ValidationProvider
                    name="Hire date"
                    rules="required"
                    v-slot="{ classes, errors }"
                  >
                    <div :class="classes" class="mt-4">
                      <v-text-field
                        v-model="hireVehiclesData.hireDate"
                        flat
                        v-on="on"
                        placeholder="2022-09-9"
                        solo
                        hide-details
                        readonly
                      >
                        <template #prepend-inner>
                          <v-icon>
                            mdi-calendar-month-outline
                          </v-icon>
                        </template></v-text-field
                      >
                      <span> {{ errors[0] }} </span>
                    </div>
                  </ValidationProvider>
                </template>

                <v-date-picker
                  locale="en-in"
                  v-model="hireVehiclesData.hireDate"
                  no-title
                  @input="dateMenu = false"
                  :min="minDate"
                >
                </v-date-picker>
              </v-menu>
            </div>
            <div class="duration sizing">
              <span class="heading look">
                Duration
              </span>
              <ValidationProvider
                name="Duration"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div :class="classes" class="mt-4">
                  <v-text-field
                    v-model="hireVehiclesData.duration"
                    solo
                    flat
                    hide-details
                    placeholder=" 48 Hours"
                    prepend-inner-icon="mdi-clock-time-nine-outline"
                  />
                  <span> {{ errors[0] }} </span>
                </div>
              </ValidationProvider>
            </div>
            <div class="type sizing">
              <span class="heading look">
                Type
              </span>
              <ValidationProvider
                name="Vehicle Type"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div :class="classes" class="mt-4">
                  <v-text-field
                    v-model="hireVehiclesData.vehicleType"
                    solo
                    flat
                    hide-details
                    placeholder=" Coaster"
                    prepend-inner-icon="mdi-bus"
                  />

                  <span> {{ errors[0] }} </span>
                </div>
              </ValidationProvider>
            </div>
            <div class="capacity sizing">
              <span class="heading look">
                Capacity
              </span>
              <ValidationProvider
                name="Vehicle Capacity"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div :class="classes" class="mt-4">
                  <v-text-field
                    v-model="hireVehiclesData.vehicleCapacity"
                    solo
                    flat
                    hide-details
                    placeholder=" 23"
                    prepend-inner-icon="mdi-car-seat"
                  />

                  <span> {{ errors[0] }} </span>
                </div>
              </ValidationProvider>
            </div>
            <div class="number sizing">
              <span class="heading look">
                No
              </span>
              <ValidationProvider
                name="Vehicle Quantity"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div :class="classes" class="mt-4">
                  <v-text-field
                    v-model="hireVehiclesData.quantity"
                    solo
                    flat
                    hide-details
                    placeholder=" 1"
                    prepend-inner-icon="mdi-bus-multiple"
                  />

                  <span> {{ errors[0] }} </span>
                </div>
              </ValidationProvider>
            </div>
            <search-button />
          </div>
          <v-progress-linear
            indeterminate
            color="#004AAD"
            v-if="hireVehicleLoading"
          ></v-progress-linear></form
      ></validation-observer>
    </div>
  </div>
</template>

<script>
import SearchButton from "../components/reuseables/SearchButton.vue";
import SelectPassengers from "../components/reuseables/SelectPassengers.vue";

import {
  getAllAvailableRoundTrips, getAllJettiesOfWaterTransportOperators,
  getTransportCompanyTerminals
} from "../services/api/APIService";
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  components: {
    SelectPassengers,
    SearchButton
  },
  data() {
    return {
      activeTab: "1",
      oneWayDate: false,
      date: null,
      cities: [],
      hirevehicledate: null,
      oneWayDeparturedate: null,
      roundTripDepartureDate: null,
      roundTripReturnDate: null,
      dateMenu: false,
      minDate: new Date(new Date().getTime() + 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10),

      oneWayData: {
        departure: "",
        destination: "",
        travelDates: "",
        totalPassengers: 0,
        departureIsCity: false,
        departureIsState: false,
        destinationIsCity: false,
        destinationIsState: false,
        tripType: "Road Trip"
      },

      roundTripData: {
        location: "",
        destination: "",
        departureDate: "",
        returnDate: "",
        totalPassengers: 0
      },
      oneWayLoading: false,
      roundTripLoading: false,
      hireVehicleLoading: false,
      hireVehiclesData: {
        from: "",
        to: "",
        hireDate: "",
        duration: "",
        vehicleType: "",
        vehicleCapacity: "",
        quantity: ""
      }
    };
  },
  watch: {
    "oneWayData.tripType": {
      handler: async function(tripType)  {
        if (tripType === "Road Trip") {
          this.cities = []
         await this.getAllTerminals()
        }
        else {
          this.cities = []
         await this.getAllJetties()
        }
      },
      immediate : true
    }
  },
  computed: {
    ...mapGetters("booking",["pendingTrips"])
  },
  methods: {
    getTotalPassengers(total) {
      this.oneWayData.totalPassengers = total;
      this.oneWayData.totalPassengers = total;
      this.roundTripData.totalPassengers = total;
    },
    async handleOneWaySearch() {
      console.log("Him olo-----")
      this.oneWayLoading = true;
      this.oneWayData.departure = this.getSubstringBeforeComma(
        this.getCity(this.oneWayData.departure)
      );
      this.oneWayData.destination = this.getSubstringBeforeComma(
        this.getCity(this.oneWayData.destination)
      );
      this.getIsDepartureState(this.oneWayData.departure);
      this.getIsDestinationState(this.oneWayData.destination);
      this.oneWayData.page = 0;
      this.oneWayData.pageSize = 100;
      let data = {};
      data.page = this.oneWayData.page;
      data.pageSize = this.oneWayData.pageSize;
      data.departureQuery = this.oneWayData.departure;
      data.destinationQuery = this.oneWayData.destination;
      data.departureIsCity = this.oneWayData.departureIsCity;
      data.departureIsState = this.oneWayData.departureIsState;
      data.destinationIsCity = this.oneWayData.destinationIsCity;
      data.destinationIsState = this.oneWayData.destinationIsState;
      data.currentDate = dayjs().format("YYYY-MM-DD")
      data.tripType = this.oneWayData.tripType
      data.takeOffDate = this.getDateForSearchQuery(
        this.oneWayData.travelDates
      );
      console.log(this.pendingTrips, "the pending trips -------------")
      await this.$store
        .dispatch("booking/setPendingTrips", data)
        .then(() => {
          this.oneWayLoading = false;
          this.getQueryTrips();
        });
    },
    getQueryTrips() {
      this.oneWayData.departure = this.getSubstringBeforeComma(
        this.getCity(this.oneWayData.departure)
      );
      this.oneWayData.destination = this.getSubstringBeforeComma(
        this.getCity(this.oneWayData.destination)
      );
      let queryTrips = [];
      this.pendingTrips.forEach(trip => {
        let tripDate = dayjs(trip.takeOffDate + trip.takeOffTime).format("LLL");
          let selectedDate = dayjs(
            this.getUserSelectedDate(this.oneWayData.travelDates)
          ).format("LLL");
          if (
            dayjs(tripDate).isSame(dayjs(selectedDate)) ||
            dayjs(tripDate).isAfter(dayjs(selectedDate))
          ) {
            queryTrips.push(trip);
          }

      });
      let uniqueTrips = new Set();
      queryTrips.forEach(trip => uniqueTrips.add(JSON.stringify(trip)));
      let filteredTrips = [];
      uniqueTrips.forEach(trip => filteredTrips.push(JSON.parse(trip)));

      if (filteredTrips.length) {
        sessionStorage.setItem("queryData", JSON.stringify(this.oneWayData));
        sessionStorage.setItem("queryTrips", JSON.stringify(filteredTrips));
        this.$store.dispatch(
          "booking/setOneWayTrips",
          filteredTrips
        );
        this.$router.push({ name: "OneWaySelectTrip" });
      } else {
        console.log("--------------------<<<<<<<<<<<Hi")
        this.$displaySnackbar({
          message: "Your search query did not match any trip"
        });
      }
    },
    handleRoundTripSearch() {
      getAllAvailableRoundTrips(this.roundTripData)
        .then(res => {
          console.log(res.data);
        })
        .catch(err => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false
          });
        });
    },
    getSubstringBeforeComma(str) {
      const index = str.indexOf(" - ");
      if (index === -1) {
        return str;
      } else {
        return str.substring(index + 3);
      }
    },
    getIsDepartureState(departure) {
      this.cities.forEach(city => {
        if (departure.toLowerCase() === city.city.toLowerCase()) {
          this.oneWayData.departureIsCity = true;
          this.oneWayData.departureIsState = false;
        }
        if (departure.toLowerCase() === city.state.toLowerCase()) {
          this.oneWayData.departureIsCity = false;
          this.oneWayData.departureIsState = true;
        }
      });
    },
    getIsDestinationState(destination) {
      this.cities.forEach(city => {
        if (destination.toLowerCase() === city.city.toLowerCase()) {
          this.oneWayData.destinationIsCity = true;
          this.oneWayData.destinationIsState = false;
        }
        if (destination.toLowerCase() === city.state.toLowerCase()) {
          this.oneWayData.destinationIsCity = false;
          this.oneWayData.destinationIsState = true;
          console.log("is it");
        }
      });
    },
    getUserSelectedDate(date) {
      let todate = new Date(Date.now());
      let today = dayjs(
        date + "T" + todate.getHours() + ":" + todate.getMinutes()
      );
      if (date) {
        console.log(todate);
        return dayjs(today).format("LLL");
      } else {
        return dayjs().format("YYYY-MM-DDTHH:mm:ss");
      }
    },
    getDateForSearchQuery(date) {
      if (date) {
        return date;
      } else {
        return dayjs().format("YYYY-MM-DD");
      }
    },
    async getAllJetties(){
     const response = await getAllJettiesOfWaterTransportOperators()
      this.cities = [];
      let uniqueCity = new Set();
      response.data.forEach(jetty => {
        let citydata = {};
        citydata.state = this.getFirstText(jetty.address.state);
        citydata.city = this.toSentenceCase(jetty.address.city);
        uniqueCity.add(JSON.stringify(citydata));
      });
      uniqueCity.forEach(city => this.cities.push(JSON.parse(city)));
      this.cities
          .sort((a, b) => a.city.localeCompare(b.city))
          .sort((a, b) => a.state.localeCompare(b.state));
    },
    async getAllTerminals() {
      this.cities = [];
      let uniqueCity = new Set();

      getTransportCompanyTerminals().then(res => {
        res.data.forEach(address => {
          let citydata = {};
          citydata.state = this.getFirstText(address.state);
          citydata.city = this.toSentenceCase(address.city);
          uniqueCity.add(JSON.stringify(citydata));
        });
        uniqueCity.forEach(city => this.cities.push(JSON.parse(city)));
        this.cities
          .sort((a, b) => a.city.localeCompare(b.city))
          .sort((a, b) => a.state.localeCompare(b.state));
      });
    },
    getCity(city) {
      if (city.state) {
        return city.state + " - " + city.city;
      }
      return city;
    },
    getFirstText(text) {
      if (text) {
        let arrayText = text
          .replace("State", "")
          .replace("state", "")
          .replace("Department", "")
          .replace("department", "");
        return this.toSentenceCase(arrayText);
      }
    },
    toSentenceCase(text) {
      text = text.toLowerCase();
      return text.replace(text.charAt(0), text.charAt(0).toUpperCase()).trim();
    }
  },

  async created() {

  }
};
</script>

<style lang="scss" scoped>
#shortcut {
  padding: 0;
  margin-top: 0;

  @media (max-width: 1264px) {
    padding: 0 3rem 6rem;
  }

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 0;
    padding: 4rem 0 3rem;
  }
}

.sizing {
  padding: 10px 5px;
  text-align: left;

  @media (max-width: 1024px) {
    text-align: left;
  }
}

.options {
  background: #fdfffc;
  border: 2px solid rgba(42, 70, 108, 0.07);
  box-shadow: 0px 2px 60px rgba(42, 70, 108, 0.1);
  border-radius: 10px 10px 0 0;
  width: fit-content;
  display: flex;
  // @media (max-width:1024px) {
  //     width: 100%;
  //     justify-content: center;
  //     align-items: center;
  // }
}

.tab .active {
  color: #576d8a;
}

.look {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.trip-types {
  padding: 10px 15px;
  line-height: 40px;
  color: rgba(42, 70, 108, 0.37);
  cursor: pointer;
  width: 200px;

  @media screen and(max-width: 1024px) {
    width: auto;
  }
}

.flex-handler {
  display: flex;
  flex-direction: row;
  padding: 0 2rem;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
}

.input-fields {
  border: none !important;
  width: 564px;
  height: max-content;
  padding: 50px 20px;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 30px 10px;
    height: auto;
  }
}

.heading {
  color: #2a466c;
  font-size: 16px;
  margin-bottom: 2rem;

  @media (max-width: 1024px) {
    text-align: left;
    padding: 10px 0 10px 50px;
  }
  @media screen and (max-width: 820px) {
    padding: 0;
  }
}

.v-text-field--placeholder,
.sizing div.v-text-field input {
  font-size: 8px !important;
  background-color: #fdfffc !important;
}

.v-text-field input,
.v-text-field.v-text-field--solo .v-input__control input,
.v-input input {
  background: #fdfffc !important;
}

::v-deep
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 !important;

  @media screen and(max-width: 820px) {
    padding: 16px !important;
  }
}
</style>
