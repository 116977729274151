<template>
  <div class="container tw-w-full">
    <div class="input-fields">
      <validation-observer ref="observer" v-slot={handleSubmit}>
        <form @submit.prevent="handleSubmit(hireSearch)">
          <div class="hire-vehicles flex-handler">
            <div class="from sizing">
              <span class="heading look">
                Departure city
              </span>

              <ValidationProvider name="departure" rules="required" v-slot="{ classes, errors }">
                <div :class="classes" class="mt-4">
                  <v-combobox v-model="hireData.from" :items="cities" class="vtf" placeholder="Lagos Island" :item-text="getItemCity"
                    prepend-inner-icon="mdi-map-marker-outline" solo hide-details flat color="#004aad" append-icon="">
                  </v-combobox>
                  <span> {{errors [0] }} </span>
                </div>
              </ValidationProvider>
            </div>
            <div class="to sizing">
              <span class="heading look">
                Destination city
              </span>

              <ValidationProvider name="Destination" rules="required" v-slot="{ classes, errors }">
                <div :class="classes" class="mt-4">
                  <v-combobox v-model="hireData.to" :items="cities" placeholder="Uyo" :item-text="getItemCity"
                    prepend-inner-icon="mdi-crosshairs-gps" solo hide-details flat color="#004aad" append-icon="">
                  </v-combobox>
                  <span> {{errors [0] }} </span>
                </div>
              </ValidationProvider>
            </div>
            <div class="hire-date sizing">
              <span class="heading look">
                Hire Date
              </span>

              <v-menu v-model="date" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <ValidationProvider name="date" rules="required" v-slot="{ classes, errors }">
                    <div :class="classes" class="mt-4">
                      <v-text-field v-model="hireData.hireDate" flat v-on="on" placeholder="2022-09-9" solo hide-details
                        readonly>
                        <template #prepend-inner>
                          <v-icon>
                            mdi-calendar-month-outline
                          </v-icon>
                        </template></v-text-field>
                      <span> {{errors [0] }} </span>
                    </div>
                  </ValidationProvider>
                </template>

                <v-date-picker locale="en-in" v-model="hireData.hireDate" no-title @input="dateMenu = false"
                  :min="minDate">
                </v-date-picker>
              </v-menu>


            </div>
            <div class="duration sizing">
              <span class="heading look">
                Duration
              </span>
              <ValidationProvider name="Duration" rules="required" v-slot="{ classes, errors }">
                <div :class="classes" class="mt-4">
                  <v-text-field v-model="hireData.duration" color="#004aad" solo flat hide-details placeholder="2 days"
                    prepend-inner-icon="mdi-clock-time-nine-outline" />
                  <span> {{errors [0] }} </span>
                </div>
              </ValidationProvider>
            </div>
            <div class="type sizing">
              <span class="heading look">
                Seater
              </span>
              <validation-provider name="vehicle type" rules="required" v-slot="{ classes, errors }">
                <v-select class="mt-4 input-field" :items="vehicleTypeList" v-model="hireData.vehicleType" flat
                  hide-details placeholder="Minivan" solo :item-text="getItemVehicle" item-value="vehicleType">

                </v-select>
                <span>{{errors[0]}}</span>
              </validation-provider>
            </div>
            <div class="number sizing">
              <span class="heading look">
                Quantity
              </span>
              <ValidationProvider name="Quantity" rules="required" v-slot="{ classes, errors }">
                <div :class="classes" class="mt-4">
                  <v-text-field v-model="hireData.quantity" solo flat hide-details placeholder=" 1"
                    prepend-inner-icon="mdi-bus-multiple" color="#004aad" />

                  <span> {{errors [0] }} </span>
                </div>
              </ValidationProvider>
            </div>
            <search-button />
          </div>
          <v-progress-linear indeterminate color="#004AAD" v-if="loading"></v-progress-linear>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
  import SearchButton from '@/components/reuseables/SearchButton.vue'
  import citiesData from '@/assets/cities.js'
  import {
    getAllHiringCities,
    getAllVehicleType,
    getAllVehicleTypeBasedOnSearchQuery
  } from '../../services/api/APIService'
  import {mapGetters} from "vuex";
  export default {
    name: "HireSearch",
    components: {
      SearchButton
    },
    data() {
      return {
        date: null,
        dateMenu: false,
        minDate: new Date(new Date().getTime() + 60 * 60 * 1000)
          .toISOString()
          .substr(0, 10),

        citiesData,
        cities: [],
        loading: false,
        vehicleTypeList: [],
        hireData: {
          from: "",
          to: "",
          hireDate: "",
          duration: "",
          vehicleType: "",
          quantity: "",
        },


      }
    },
    computed:{
      ...mapGetters("hire",["hiringDetails"])
    },
    methods: {
      async hireSearch() {
        this.loading = true
        this.hireData.page = 0
        this.hireData.pageSize = 1

        let data = {}
        data.pageNumber = this.hireData.page
        data.pageSize = this.hireData.pageSize
        data.departureIsCity = true
        data.destinationIsCity = true
          data.departureCity = this.hireData.from.city
        data.destinationCity = this.hireData.to.city
        data.vehicleType = this.hireData.vehicleType
        await getAllVehicleTypeBasedOnSearchQuery(data)
          .then((res) => {
            sessionStorage.setItem("queryData", JSON.stringify(data))
            sessionStorage.setItem("availableVehiclesForHire", JSON.stringify(res.data))
            this.$store.dispatch("hire/setAvailableVehiclesForHire", res.data)
            this.hiringDetails.query = this.hireData
            this.$store.dispatch("hire/hiringDetails", this.hiringDetails)


          })
          .catch((err) => {
            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            })
          });
        this.loading = false
      },
      getItemCity(item){
        return this.toSentenceCase(item.state)+ " "+ this.toSentenceCase(item.city)
      },
      getItemVehicle(item){
        return this.toSentenceCase(item.vehicleType)+ " "+ item.vehicleCapacity
      },
      toSentenceCase(text) {
        text = text.toLowerCase();
        return text.replace(text.charAt(0), text.charAt(0).toUpperCase());
      },
      async getVehicleType() {
        await getAllVehicleType()
          .then((res) => {
            this.vehicleTypeList = res.data
          })
          .catch((err) => {
            console.log(err.response)
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            })
          })
      },
      async getAllCities(){
        await  getAllHiringCities().then(res =>{
          this.cities = res.data
        }).catch((err) => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          })
        })
      }
    },
    async created() {
      await this.getVehicleType();
      await this.getAllCities()
    }
  }
</script>

<style lang="scss" scoped>
  #shortcut {
    padding: 0;
    margin-top: 0;
  }

  .container {
    padding: 2rem 4rem;

    @media (max-width:1264px) {
      padding: 1.5rem;
    }
  }

  .sizing {
    padding: 10px 5px;
    text-align: left;
    width: 25%;

    @media (max-width:1024px) {
      text-align: left;
      width: 100%;

    }


  }

  .input-fields {
    width: 100%;
    height: 160px;
    padding: 20px 0;
    border-radius: 5px;
    background-color: #fff;
    border: 2px solid #2A466C12;

    @media (max-width:1024px) {
      width: 100%;
      padding: 30px 20px;
      height: auto;
    }

  }

  .tab .active {
    color: #576D8A;
  }



  .look {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .trip-types {
    padding: 10px 15px;
    line-height: 40px;
    color: rgba(42, 70, 108, 0.37);
    cursor: pointer;
    width: 210px;

    @media screen and(max-width: 1024px) {
      width: auto;
    }

  }

  .flex-handler {
    display: flex;
    flex-direction: row;
    padding: 0 2rem;
    align-items: center;
    width: 100%;

    @media (max-width:1024px) {
      flex-direction: column;
      width: 100%;
      padding: 0;
    }
  }


  .input-fields>span {
    width: 100%;
  }


  .heading {
    color: #2A466C;
    font-size: 16px;
    margin-bottom: 2rem;

    @media (max-width:1024px) {
      text-align: left;
    }
  }

  .v-text-field--placeholder,
  .sizing div.v-text-field input {
    font-size: 18px !important;
    background-color: #fdfffc !important;

  }

  .v-text-field input,
  .v-text-field.v-text-field--solo .v-input__control input,
  .v-input input {
    background: #fdfffc !important;
  }

  ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 !important;

    @media screen and(max-width: 820px) {
      padding: 16px !important;
    }
  }

  .is-invalid span {
    color: #f54c35 !important;
    font-size: 9px;
    display: block;
    font-weight: 500;
    height: 0em;
  }
</style>