<template>
  <div class="mobile">
    <div class="cover">
        <h1 class="heading">
          Privacy Policies
        </h1>
       
          <div id="Overview" class="pt-6">
            <h1 class="header">Overview</h1>
            <br>
            <p>This privacy policy applies to services accessible under the domain and subdomains of <a class="link"
                href="/">nomadicpod.com</a> (the “Website”).</p>
            <p>Visiting the website suggests that you consent to be bound by the terms and conditions of this Privacy
              Policy. In any case, if you disagree, kindly do not get on the website. Also, if at any point you disagree
              with the privacy policy, kindly use the opt-out option.</p>
            <p>By agreeing to the Privacy Policy and the User Arrangement upon registration, you explicitly agree to our
              utilization and the disclosure of your personal information in accordance with this Privacy Policy.</p>
          </div>

          <div id="Personal Information" class="pt-6">
            <h1 class="header">Personal Information</h1>
            <br>
            <p>This website requires you to provide direct personal information such as your name, address, telephone
              number and email address when registering. This allows us to provide services and features that suit your
              needs and to modify our services to make navigation of the website safer and easier.</p>
            <p>We only collect personal information about you that we believe is necessary to achieve these goals.
            </p>
            <p>You can search and view content on the website without registering. But you can enjoy a better use of the
              website when you register.
            </p>
            <p>If you list or quote travel requirements on the website, we may collect information about your business
              and preferences. We also collect feedback from other users about you in the feedback area.
            </p>
          </div>
          <div id="Use of Your Information" class="pt-6">
            <h1 class="header">Use of Your Information</h1>
            <br>
            <p>Information collected is used to support members and may be used to provide you with information about
              online and offline offers, products, services, and updates; detect and protect us against mistakes, fraud,
              and other criminal activities; create a personal experience for you; bring to bear our User Agreement as
              aforementioned to you at the time of collection.
            </p>
            <p>We may look at your personal information to identify users with multiple user IDs. We will also compare
              your personal information and check for errors and omissions to ensure accuracy. In addition, by visiting
              the website, you consent to the use of your personal data, including your email address, to support our
              marketing and promotional activities.
            </p>
          </div>
          <div id="Altering & Deleting Personal Information" class="pt-6">
            <h1 class="header">Altering & Deleting Personal Information.</h1>
            <br>
            <p>You can request for modification and erasure of the data in your record at any time. Erased data might be
              held on the website’s systems for a while after this. Nomadicpod -maintains all authority to deactivate or
              erase accounts whenever.
            </p>
          </div>
          <div id="Collection & Use of Non-personal Information" class="pt-6">
            <h1 class="header">Collection & Use of Non-personal Information.</h1>
            <br>
            <p>On the off chance that you decide to utilize our website, we may expect you to give certain non
              -individual data as shown on the structures all through the website. This incorporates, yet is not
              restricted to:
            </p>
            <p>Data identifying length of visits to the website, how frequently the website is visited, information
              gotten while visiting the website, different actions carried out when visiting the website.
            </p>
            <p>On the forms, there are required fields which are indicated with the use of the (*) symbol. There are
              also optional fields, and you have the option to not give information by deciding to not utilize a
              specific service or feature.
            </p>
            <p>We may follow certain information dependent on your conduct on the website. This non individual data is
              utilized carefully to investigate the utilization of the website and subsequently improve and upgrade the
              nature of Nomadicpod administrations. This information may include data, for example, the URL that you came
              from, which URL you go to next, your PC program data, and your IP address. Due to its non-individual
              nature, this information might be shared to partners of Nomadicpod to help offer the best services to you.
            </p>
            <p>IP addresses are likewise accumulated by Nomadicpod web servers. These are gathered as non-individual
              information to assist us with diagnosing issues with our administrations.
            </p>
          </div>
          <div id="Publishing personal information" class="pt-6">
            <h1 class="header">Publishing personal information.</h1>
            <br>
            <p>Nomadicpod suggests that you do not distribute individual data in such openly available zones of the
              website, for example, the Questions/Answers area. Nomadicpod shall assume no liability for any collection or
              utilization of any of this disclosed information by third parties.
            </p>
          </div>
          <div id="Use of Cookies" class="pt-6">
            <h1 class="header">Use of Cookies</h1>
            <br>
            <p>To ensure our website works correctly we may at times place a small piece of data known as cookies on
              your computer or mobile device.
            </p>
            <p>Like other internet providers, we use cookies to improve user experience. Session cookies are deleted
              after each visit while the persistent cookies remain in place across multiple visits. This means a user
              does not need to reset preference for every visit.
            </p>
            <p>The website may utilize cookies to upgrade the use of our website. On the off chance that you do not wish
              to get them, you can alter your internet browser settings to deny the cookies. If you decide to reject the
              cookies, access might be restricted to certain zones of the website and negatively affect user experience
              on our website.
            </p>
          </div>
          <div id="Security and confidentiality" class="pt-6">
            <h1 class="header">Security and confidentiality</h1>
            <br>
            <p>Nomadicpod endeavors to guarantee the security of your data. Nonetheless, we cannot be considered liable
              for any unapproved admittance to your own data. Our workers maintain the security of our systems and
              regard consistently the privacy of your own data. If you speculate any unapproved admittance to or
              utilization of your record, or any other breach of security, please inform Nomadicpod -----right away.

            </p>
            <p>Nomadicpod may utilize third parties to examine any non-individual, total data gathered. This may likewise
              be made accessible to media associates and think-tanks for examination purposes.
            </p>
          </div>
          <div id="Account purchase, payment information" class="pt-6">
            <h1 class="header">Account purchase, payment information</h1>
            <br>
            <p>We utilize third party payment processor for payment on our website.

            </p>
            <p>When making a payment for services with Nomadicpod, your monetary subtleties are passed through a secure
              server making use of the most recent encryption technology.
            </p>
            <p>Nomadicpod makes no guarantee in regard of the strength or adequacy of that encryption and acknowledges no
              obligation regarding occasions emerging from unapproved access of the data you give.
            </p>
          </div>
          <div id="Overseas access to information" class="pt-6">
            <h1 class="header">Overseas access to information</h1>
            <br>
            <p>Please note that from time to time your information may be transferred outside of Nigeria.

            </p>
          </div>
          <div id="Access" class="pt-6">
            <h1 class="header">Access</h1>
            <br>
            <p>Registered users can access, alter, and delete information at any time.

            </p>
          </div>
          <div id="Updates" class="pt-6">
            <h1 class="header">Updates</h1>
            <br>
            <p>Any updated versions of this policy will be available on the website.

            </p>

          </div>
          <div id="Third party Cookies" class="pt-6">
            <h1 class="header">Third party Cookies</h1>
            <br>
            <p>In the course of servicing advertisements to this website, our third party ad servers may place or
              recognize a unique cookie in your browser. Information about your visit to this site such as number of
              times you have viewed and/or clicked an ad ( but not any direct personal information) is used for internal
              reporting and advertising optimization only.


            </p>
          </div>
          <div id="Communications, Email address collection" class="pt-6">
            <h1 class="header">Communications, Email address collection</h1>
            <br>
            <p> In order to receive certain communications from us such as responses to user inquiries, you will be
              required to submit your email address to us. This may also be required to receive security updates and
              service related news even if you do not sign up for them or make a purchase. Any non-service email you
              receive from us will include an unsubscribe link that will allow you opt-out of receiving communication
              emails. You may also receive communications from third parties who receive correspondence about your
              activities or publications on the website.
            </p>
          </div>
          <div id="Children’s privacy" class="pt-6">
            <h1 class="header">Children’s privacy</h1>
            <br>
            <p>Our services are not designed for and are not marketed to people under the age of Eighteen (18) “Minors”.
              We do not Knowingly collect or ask for personal information from minors. We also, do not knowingly allow
              minors to use our services or send us personal information. We delete personal information that we learn
              is collected from a minor without verified parent consent.
            </p>
          </div>
          <div id="Contact us" class="pt-6">
            <h1 class="header">
              Contact us
            </h1>
            <br>
            <p>For questions about our privacy policy, to make choices about receiving promotional communications, to
              update your Information or to request for our service you can contact us through email or telephone:
            </p>
            <p>Telephone: 09122063069
            </p>
            <p>Email: info@nomadicpod.com
            </p>

          </div>
    </div>
  </div>
</template>

<script>
  export default {

    data() {
      return {
        panel: [0]
      }
    }

  }
</script>

<style lang="scss" scoped>
.cover{
  background-color: #FFFFFF;
    padding: 30px;

}
  .tree,
  .open-tree {
    background-color: #ffff;
  }

  .lp-text {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 54px;
    color: #FFFFFF;

  }

  .open-tree {
    margin-left: 40px;
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 3rem 4rem;
    overflow-y: auto;
    max-height: 100vh !important;
  }

  .open-tree p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 31px;
    color: #4e5b64;

  }

  .v-application a {
    color: #004aad !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 31px;
    text-decoration: underline;
    cursor: pointer;
  }

  .header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #4e5b64;
  }

  .tree {
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .v-application a {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: rgba(29, 38, 45, 0.71);

  }

  .v-application a:hover {
    color: #004AAD;
  }

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #004AAD;
  }

  .heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #004AAD;

  }
</style>