<template>
  <div class="blogpost-div">
    <v-card elevation="0" class="tab-card">
      <v-tabs v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#all"> All </v-tab>
        <v-tab href="#workshops"> Workshops </v-tab>

        <v-tab href="#fun times"> Fun times </v-tab>
        <v-tab href="#fundraiser"> Fundraiser </v-tab>
      </v-tabs>
    </v-card>

    <div class="tw-flex tw-flex-wrap post-div">
      <div class="post-card" v-for="(blogpost, index) in posts" :key="index">
        <v-card class="mx-auto my-12" elevation="0">
          <div class="scroll">
            <div class="imagediv">
              <v-img height="350" :src="blogpost.postImage">
                <div class="date">{{ getDate(blogpost.createdOn) }}</div>
              </v-img>
            </div>
            <v-card-title class="tw-ml-1">{{ blogpost.postTitle }}</v-card-title>
            <v-chip
              label :color="blogpost.category.color+' white'">
              {{ blogpost.category.name }}
            </v-chip>
            <v-card-text class="post-content tw-ml-4">
              {{ blogpost.postContent }}
            </v-card-text>
          </div>
          <v-card-actions>
            <v-btn color="blue darken-4" style="text-transform: none" text @click="routeToPage(blogpost)">
              Read more
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import pic2 from "../../assets/blogimages/pic2.jpg";
import pic3 from "../../assets/blogimages/pic3.jpg";
import pic1 from "../../assets/blogimages/pic1.jpg";
import pic4 from "../../assets/blogimages/pic4.jpg";
import {getAllPublishPost} from "@/services/api/APIService";
import dayjs from "dayjs";

export default {
  data() {
    return {
      blogposts: [
        {
          date: "Fri May. 20th",
          image: pic2,
          section: "Workshops",
          title: "How our APIs help to improve travel services",
          content:
              "We’re inspired by a vision of millions of road commuters and the thousands of businesses across the country who use the road to carry out their daily activities and businesses.",
        },
        {
          date: "Mon May. 23rd",
          image: pic4,
          section: "Fun Times",
          title: "Activities to Engage in while on Road Travel",
          content:
              "Road Travel is a totally amazing experience. The travellers on the journey, spots you get to visit, meals or snacks you munch on. Its a whole vibe and these are things that make it memorable",
        },
        {
          date: "Fri May. 20th",
          image: pic1,
          section: "Workshops",
          title: "Nomadicpod's Travellers Code ",
          content:
              "The interesting thing about our traveller code is that you can use it across all transport companies. It is a one time code you get access to without signing up on our platform.",
        },
        {
          date: "Mon May. 23rd",
          image: pic3,
          section: "Fun Times",
          title: "Monthly Team Bonding Activities",
          content:
              "Our monthly team building activities are the right amount of fun with lots of food, drinks, games, and chitchat moment. This is a great way to keep the team vibrant and relaxed",
        },
      ],
      tab: null,
      posts: [],
    };
  },
  watch: {
    tab: {
      handler: function (val) {
            console.log(val)
        if (val === "all") {
          this.posts = this.blogposts;
      
        } else {
          this.posts = this.blogposts.filter(
            (post) => post.section.toLowerCase() === val
          );
        }
      },
      immediate: true,
    },
  },
  methods: {
    routeToPage(post) {
      if (this.$route.name !== "BlogPost") {
        sessionStorage.setItem("post", JSON.stringify(post))
        this.$router.push({ name: "BlogPost", params:{"title":post.postTitle} });
      }
    },
    async getAllPost(){
      await getAllPublishPost().then(res =>{
        this.posts = res.data
        this.posts.forEach(post => post.category = this.getColor(post.tags))
        this.posts.sort(function (a, b) {
          return dayjs(b.createdOn) - dayjs(a.createdOn);
        });
      })
    },
    getDate(date){
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      if (dayjs(date).toDate().toDateString()=== today.toDateString()) {
        return "Today";
      } else if (dayjs(date).toDate().toDateString() === yesterday.toDateString()) {
        return "Yesterday";
      } else {
        return dayjs(date).toDate().toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
      }
    },
    getColor(tags){
      let category = {}
      if (tags.length){
        category.name = tags[0].tagName

        if (category.name === 'Workshops'){
          category.color = 'green'
        }
        else if (category.name === 'Fun Times'){
          category.color = 'pink'
        }
        else if (category.name === 'Fundraise'){
          category.color = 'red'
        }
      }
      else {
        category.name = "General"
        category.color = "purple"
      }
      return category
    }
  },
  async created() {
    await this.getAllPost()
  }
};
</script>

<style lang="scss" scoped>
.v-tabs {
  text-transform: none !important;
  color: rgba(79, 79, 79, 0.473) !important;
  @media screen and (max-width: 1264px) {
    padding-left: 5%;
  }
  @media screen and (max-width: 768px) {
    padding-left: 0% !important;
  }
}

.theme--light.v-tabs > .v-tabs-bar,
.v-application .primary--text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(79, 79, 79, 0.452) !important;
  @media screen and (max-width: 600px) {
    line-height: 20px;
  }
}

.v-tab {
  min-width: 0 !important;
}
.blogpost-div {
  min-height: 100vh;
  padding-left: 15rem;
  @media screen and (max-width: 1264px) {
    padding-left: 2rem;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    background-color: #fdfffc;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
.post-div {
  width: 100%;
  padding: 3% 9% 7% 0;
  @media screen and (max-width: 1264px) {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
}
.post-content{

}

.date {
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #ffffff;
  padding: 10px;
  background: rgba(0, 74, 173, 0.5);
  border-radius: 0 0 0 15px;
}

.post-card {
  width: 400px;
  margin: 3%;

  @media screen and (min-width: 1800px) {
    margin: 2% 3%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.imagediv {
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 1rem;
  }
  @media screen and (max-width: 768px) {
    padding: 0;
  }
}

.v-image {
  border-radius: 15px;
}

.v-chip {
  margin-left: 15px;
}

::v-deep .v-chip__content {
  font-family: "Inter",sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff !important;
}

.v-card__title {
  font-family: "Inter",sans-serif;
  font-style: normal;
  font-weight: 500;
  word-break: initial;
  font-size: 24px;
  color: var(--padiGrey);
  padding: 16px 10px;
}
.v-tabs-bar__content {
  margin: 0 auto;
}
.v-card__text {
  font-family: "Inter",sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: var(--padiGrey);
  padding: 16px 10px 0 0;
  display: -webkit-box;
  overflow: hidden !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

@media screen and (max-width: 463px) {
  .blogpost-div {
    padding: 0 3% !important;
  }

}
::v-deep .theme--light.v-sheet {
  background-color: transparent !important;
}
//.scroll {
//  overflow-y: auto;
//  overflow-x: hidden;
//  max-height: 650px;
//  height: 652px;
//}
//.scroll::-webkit-scrollbar {
//  background: transparent !important;
//  width: 5px;
//  height: 8px;
//}
//.scroll::-webkit-scrollbar-thumb {
//  background: #c4c4c4 !important;
//  max-height: 20px !important;
//  border-radius: 6px;
//}

::v-deep .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none !important;
}
</style>