<template>
  <div class="nonlandingpage">
    <Navbar />
    <div class="main-layout-wrapper">
      <section class="first-section tw-pb-20 lg:tw-pb-0 body-bg hundred-vh">
        <div class="including-search tw-w-full">
          <div class="lg:tw-px-5 tw-px-0 tw-mt-20 tw-w-full">
            <div class="tw-items-center tw-justify-center tw-h-full together">
              <v-col class="lg:tw-pr-0 col1">
                <p class="text-header md:tw-pt-0">
                  Transforming the Future of Travel.
                </p>
                <p class="text-subheader md:tw-mt-5 tw-mt-8">
                  RoadPadi is the most reliable and convenient way to explore
                  travel in Africa.
                </p>
                <!-- <img class="mobile-phone tw-flex md:tw-hidden" src="../assets/first-sec-phone.png" alt="" /> -->
                <div class="tw-flex md:tw-mt-20 tw-mt-12 tw-flex-wrap">
                  <router-link to="/register-as" class="
                    fs-title
                    signin
                    text-center
                    tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-2
                  ">Get Started</router-link>
                  <router-link to="/" class="
                    fs-title
                    signup
                    tw-flex tw-flex-wrap
                  tw-justify-center tw-items-center tw-ml-5
                    py-4
                    play
                  ">
                    <img src="../assets/play-circle.svg" alt="" /> <span class="mt-1">How It
                      Works</span></router-link>
                </div>
              </v-col>
              <v-col class="hide-col">
                <img class="phone" src="../assets/first-sec-phone.png" alt="" />
              </v-col>
            </div>
          </div>
          <div class="tw-flex tw-w-full tw-justify-center">
            <quick-search />
          </div>

        </div>
      </section>

      <section-two />
      <section-three />
      <section-four />
      <section-five/>
      <section-six />
      <section-seven />
      <Footer />
    </div>
  </div>
</template>

<script>
  import Navbar from "./Navbar";
  import Footer from "./Footer";
  import QuickSearch from "./QuickSearch.vue"
  import SectionSeven from './SectionSeven.vue';
  import SectionSix from './SectionSix.vue';
  import SectionFive from './SectionFive.vue';
  import SectionTwo from './SectionTwo.vue';
  import SectionThree from './SectionThree.vue';
  import SectionFour from './SectionFour.vue';
  export default {
    name: "NewLandingPage",
    components: {
      Navbar,
      Footer,
      QuickSearch,
      SectionSeven,
      SectionSix,
      SectionFive,
      SectionTwo,
      SectionThree,
      SectionFour
    },
    data() {

      return {}
    }

  };
</script>

<style lang="scss" scoped>
  .play {
    margin-left: -0.5rem;
  }


  .main-layout-wrapper {
    overflow-y: clip;
  }

  .body-bg {
    background-image: url("../assets/first-sec-back.svg");
    object-fit: cover;
    background-size: 100%;
    background-position: top right;

    @media screen and (max-width: 960px) {
      background-size: cover;
      background-position: center right -200px;
    }
  }

  .first-section {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1264px) {
      padding: 3rem;
    }

    @media screen and (max-width: 576px) {
      padding: 1.5rem;
    }

    @media screen and (max-width: 370px) {
      padding: 1rem;
    }


    .hide-col {
      display: flex;
      justify-content: left;
      height: auto;
      padding-left: 5rem;

      @media (max-width: 1024px) {
        padding-left: 1rem;
      }

      @media (max-width: 960px) {
        justify-content: center;
      }

      @media (max-width: 768px) {
        display: none;
      }

      img {
        height: 604px;
        width: 600px;

        @media screen and (min-width: 1920px) {
          padding-left: 0;
          height: auto;
          width: 655px;
        }

        @media screen and (max-width: 768px) {
          padding-left: 0;
          height: auto;
          width: 100%;
        }
      }
    }

    .col1 {
      @media (min-width: 1264px) {
        padding-left: 9.7rem;
      }

      @media (max-width: 1264px) {
        padding-left: 0rem;
      }



    }

    .together {
      display: flex;
      flex-direction: row;

      @media (max-width: 960px) {
        flex-direction: column;
      }
    }

    .text-header {
      font-family: "Raleway", sans-serif !important;
      font-size: 50px;
      font-weight: 600;
      font-style: normal;
      line-height: 64px;
      letter-spacing: 0;
      text-align: left;
      color: #263238;

      @media screen and (min-width: 1920px) {
        font-size: 50px;
        line-height: 68px;
      }

      @media screen and (max-width: 768px) {
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0;
      }
    }

    .text-subheader {
      font-family: "Inter", sans-serif !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 38px;
      letter-spacing: 0;
      text-align: left;
      color: #263238;
      width: 97%;

      @media screen and (min-width: 1920px) {
        width: 90%;
        font-size: 22px;
        line-height: 38px;
      }

      @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 28px;
        width: 100%;
      }
    }

    .fs-title {
      width: 152px;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.01em;
      text-align: left;
      height: 50px;
    }

    .signin {
      background: #004aac;
      border-radius: 6px;
      margin-right: 10px;
      color: white;
    }

    .signup {
      color: rgba(0, 74, 173, 1);
      margin-left: 10px;
    }
  }



  @media screen and (max-width: 768px) {
    .back-img {
      height: 700px;
      background-image: url("../assets/homepage/four-mobile-bg.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .signup {
    width: 172px;
    color: white;
  }

  .no-stress {
    padding-top: 25rem;

    @media screen and (max-width: 768px) {
      padding-top: 14rem;
    }
  }
</style>