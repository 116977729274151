<template>
  <div id="contact">
    <Navbar />
    <div class="together">
      <div class="alone1">
        <h1>Get in Touch</h1>
        <p class="my-3">If you need to get in touch with us, Drop a message.</p>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitMessage)">
            <div class="input-space tw-flex tw-justify-between">
              <div class="name-field mr-5 mb-7">
                <ValidationProvider
                    v-slot="{ classes, errors }"
                    rules="required" name="Full name">
                  <v-text-field
                    outlined
                    v-model="fullName"
                    label="Full Name"
                    hide-details
                    required
                  ></v-text-field>
                  <span class="error-msg">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="mail-field mb-7">
                <ValidationProvider v-slot="{ classes, errors }"
                                    rules="required" name="E-mail">
                  <v-text-field
                    v-model="email"
                    outlined
                    hide-details
                    label="E-mail"
                    type="email"
                    required
                  ></v-text-field>
                  <span class="error-msg">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <v-spacer></v-spacer>
            <div class="mb-6">
              <ValidationProvider
                  v-slot="{ classes, errors }"
                  rules="required" name="Subject"
                class="mb-7"
              >
                <v-text-field
                  v-model="subject"
                  outlined
                  hide-details
                  label="Subject"
                  required
                ></v-text-field>
                <span class="error-msg">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="mb-6">
              <ValidationProvider v-slot="{ classes, errors }"
                                  rules="required" name="Message">
                <v-textarea
                  counter
                  label="Message"
                  outlined
                  required
                  v-model="message"
                  hide-details
                ></v-textarea>
                <span class="error-msg">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="btndiv">
              <v-btn class="sendbtn" :loading="loading" type="submit">
                send
              </v-btn>
            </div>

          </form>
        </validation-observer>
      </div>
      <div class="contactinfo alone2">
        <h2 class="grey-inter">Contact Us</h2>
        <div class="space">
          <div class="tw-flex">
            <v-icon class="pr-4">mdi-map-marker</v-icon>
            <p class="pt-1">371 Borno way by Spencer, Alagomeji, Yaba, Lagos</p>
          </div>

          <p><v-icon class="pr-4">mdi-email</v-icon> hello@nomadicpod.com</p>

          <p><v-icon class="pr-4">mdi-phone</v-icon>+234-912 312 1378</p>
        </div>
        <hr />
        <SocialMedia />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/home/Navbar.vue";
import SocialMedia from "@/components/reuseables/SocialMedia.vue";
import { addQuery } from "../services/api/APIService";
export default {
  components: {
    Navbar,
    SocialMedia,
  },
  data: () => ({
    rules: [(v) => v.length <= 225 || "Max 225 characters"],
    fullName: "",
    email: "",
    subject: "",
    message: "",
    loading: false,
  }),
  methods: {
    async submitMessage() {
      this.loading = true;
      let data = {};
      data.fullName = this.fullName;
      data.email = this.email;
      data.subject = this.subject;
      data.message = this.message;
      await addQuery(data).then(res => {
          console.log(res.data)
          this.loading = false;
          this.$displaySnackbar({ message: res.data.detail, success: true });
          this.fullName = "";
          this.email = "";
          this.subject = "";
          this.message = "";
        })
        .catch(err => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#contact {
  min-height: 100vh;
  overflow: hidden;
}

hr {
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.together {
  padding: 10% 11rem 5%;
  display: flex;

  @media (max-width: 1024px) {
    padding: 10rem 7rem;
  }

  @media (max-width: 768px) {
    padding: 7rem 10%;
  }

  @media (max-width: 500px) {
    padding: 7rem 5%;
  }

  @media (max-width: 1400px) {
    display: block;
  }

  .alone1 {
    width: 60%;

    @media (max-width: 1400px) {
      width: 100%;
      margin: 0 auto;
    }
  }

  .alone2 {
    width: 40%;

    @media (max-width: 1400px) {
      width: 100% !important;
      margin: 0 auto;
    }
  }
}

.alone1 {
  width: fit-content;
  height: fit-content;
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  h1 {
    font-family: "Inter";
    font-weight: 500;
    font-size: 28px;
    line-height: 54px;
    color: var(--padiGrey);
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: var(--padiGrey);
  }
}

.contactinfo {
  padding: 50px 0 50px 100px;

  @media (max-width: 1400px) {
    padding: 50px;
  }

  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 54px;
    color: var(--padiGrey);
  }
}

.space {
  margin-top: 20px;
  margin-bottom: 40px;

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding-top: 10px;
    line-height: 28px;
  }
}

.sendbtn {
  background-color: #004aad !important;
  border-radius: 6px;
  padding: 20px 40px !important;
  color: #ffffff;
}

.btndiv {
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}
.alone1 span {
  margin-bottom: 20px !important;
}
.input-space {

  @media (max-width: 768px) {
    flex-direction: column;
      }
}
.name-field,
.mail-field {
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>