import {getAllPendingTripsBySearchQueryAndPage, getUserSelectedDate,getAllPendingTripsByPage,getAllPendingTripsOfWaterTransportOperators} from "@/services/api/APIService"
import dayjs from "dayjs";
export default {
    updateTripAndTravellerDetails({ commit }, payload) {
        commit("UPDATE_TRIP_AND_TRAVELLER_DETAILS", payload);
    },
   async setPendingTrips({commit},payload){
        if (payload.tripType === "Road Trip") {
            return await getAllPendingTripsBySearchQueryAndPage(payload).then(res => {
                    res.data.forEach(trip => {
                        if (trip.tripRegion === 'crossCountry') {
                            trip.tripCategories.forEach(category => category.selected = false)
                        }
                    })
                    let currentDateTime = dayjs(getUserSelectedDate())
                    res.data = res.data.filter(trip => dayjs(trip.takeOffDate + trip.takeOffTime).isSame(currentDateTime) || dayjs(trip.takeOffDate + trip.takeOffTime).isAfter(currentDateTime))
                    commit("SET_PENDING_TRIPS", res.data);
                    return Promise.resolve()
                },
                error => {
                    return Promise.reject(error)
                })
        }
        else {
            return await getAllPendingTripsOfWaterTransportOperators(payload).then(res => {
                    res.data.forEach(trip => {
                        if (trip.tripRegion === 'crossCountry') {
                            trip.tripCategories.forEach(category => category.selected = false)
                        }
                    })
                    let currentDateTime = dayjs(getUserSelectedDate())
                    res.data = res.data.filter(trip => dayjs(trip.takeOffDate + trip.takeOffTime).isSame(currentDateTime) || dayjs(trip.takeOffDate + trip.takeOffTime).isAfter(currentDateTime))
                    commit("SET_PENDING_TRIPS", res.data);
                    return Promise.resolve()
                },
                error => {
                    return Promise.reject(error)
                })
        }
   },
    async setPendingTripsByPage({commit},payload){
        return await getAllPendingTripsByPage(payload).then(res => {
                res.data.forEach(trip =>{
                    if (trip.tripRegion === 'crossCountry'){
                        console.log("found")
                        trip.tripCategories.forEach(category => category.selected = false)
                    }
                })
                let currentDateTime =    dayjs(getUserSelectedDate())
                res.data = res.data.filter(trip => dayjs(trip.takeOffDate+trip.takeOffTime).isSame(currentDateTime) || dayjs(trip.takeOffDate+trip.takeOffTime).isAfter(currentDateTime))
                commit("SET_PENDING_TRIPS", res.data);
                return Promise.resolve()},
            error => {
                return Promise.reject(error)
            })
    },
   setOneWayTrips({commit},payload){
        commit('SET_ONE_WAY_TRIPS',payload)
   },

   setUserSelectedTrip({commit},payload){
        commit('SET_USER_SELECTED_TRIP',payload)
   },
    addSeat({ commit }, payload) {
        commit("ADD_SEAT", payload);
    },
    removeSeat({ commit }, payload) {
        commit("REMOVE_SEAT", payload);
    },
    setOneWayBookingMinutesTimer({commit},payload){
        commit('SET_ONE_WAY_BOOKING_MINUTES_TIMER',payload)
    },

    setOneWayBookingShowTimer({commit},payload){
        commit('SET_ONE_WAY_BOOKING_SHOW_TIMER',payload)
    },
    clearSeats({commit}){
        commit("CLEAR_SEATS")
    },
    getTotalAmount({commit},value){
        commit("GET_TOTAL_AMOUNT",value)
    },
    updateCurrentSeats({commit},payload){
        commit("UPDATE_CURRENT_SEATS",payload)
    },

    setSelectedInsuranceProvider({commit},payload){
        commit("SET_SELECT_INSURANCE_PROVIDER",payload)
    },
    setInsuranceProvider({commit},payload){
        commit("SET_INSURANCE_PROVIDER",payload)
    },
    setSelectedInsurancePlan({commit},payload){
        commit("SET_SELECTED_INSURANCE_PLAN",payload)
    },
    setSelectedInsuranceAmount({commit}, payload){
        commit('SET_SELECTED_INSURANCE_AMOUNT',payload)
    },
    removeSelectedInsuranceAmount({commit},payload){
        commit('REMOVE_SELECTED_INSURANCE_AMOUNT',payload)
    }
};