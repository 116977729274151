var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-two"},[(_vm.loading)?_c('loader',{attrs:{"loading":_vm.loading}}):_c('div',{staticClass:"tw-w-full"},[_c('nav-bar'),_c('booking-steps'),_c('div',{staticClass:"display-center"},[_c('div',{staticClass:"div-sculptor"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('form',[_c('div',{staticClass:"from sizing sm:tw-mb-20 tw-mb-10"},[_c('p',{staticClass:"medium-text sm:tw-pb-8 tw-pb-4"},[_vm._v("Enter your preferred pickup details")]),_c('div',{staticClass:"tw-flex"},[_c('Icons',{staticClass:"mr-3 mb-1",attrs:{"name":"clock","color":"#004aad"}}),_c('span',{staticClass:"small-brown-text"},[_vm._v(" PickUp Time ")])],1),_c('ValidationProvider',{attrs:{"name":"Pickup Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"mt-4",class:classes},[_c('v-combobox',{attrs:{"color":"#004aad","items":_vm.timeOfTheDay,"placeholder":"6:00AM","append-icon":"mdi-chevron-down","solo":"","hide-details":""},model:{value:(_vm.hiringDetails.pickUpTime),callback:function ($$v) {_vm.$set(_vm.hiringDetails, "pickUpTime", $$v)},expression:"hiringDetails.pickUpTime"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',[_c('div',{staticClass:"from sizing"},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('Icons',{staticClass:"mr-3",attrs:{"name":"from2","color":"#004aad"}}),_c('label',{staticClass:"small-brown-text"},[_vm._v(" Pickup address ")])],1),_c('div',{staticClass:"tw-py-4 tw-cursor-pointer",on:{"click":_vm.handlePickUpAddress}},[_c('ValidationProvider',{attrs:{"name":"Pickup address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"mt-4",class:classes},[_c('v-text-field',{attrs:{"solo":"","hide-details":"","placeholder":"371, Borno way, Alagomeji Yaba, Lagos"},model:{value:(_vm.getPickupAddress),callback:function ($$v) {_vm.getPickupAddress=$$v},expression:"getPickupAddress"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)]),_c('Icons',{staticClass:"tw-mb-2 ml-2 line",attrs:{"name":"line"}}),_c('div',{staticClass:"from sizing"},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('Icons',{staticClass:"mr-3",attrs:{"name":"to","color":"#004aad"}}),_c('label',{staticClass:"small-brown-text"},[_vm._v("Drop off address")])],1),_c('div',{staticClass:"tw-py-4 tw-cursor-pointer",on:{"click":_vm.handleDropOffAddress}},[_c('ValidationProvider',{attrs:{"name":"Drop off address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"mt-4",class:classes},[_c('v-text-field',{attrs:{"solo":"","hide-details":"","placeholder":"31 Akpakpava road way Benin city, Edo"},model:{value:(_vm.getDropOffAddress),callback:function ($$v) {_vm.getDropOffAddress=$$v},expression:"getDropOffAddress"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)])],1),_c('div',{staticClass:"shift-end btn-divs"},[_c('button',{staticClass:"previous-btn mr-5",on:{"click":function($event){return _vm.$router.push({path:'/hire'})}}},[_vm._v("Step 1")]),_c('button',{staticClass:"btn-design",on:{"click":_vm.saveLocationData}},[_vm._v("Next")])])])]}}])})],1)])],1),_c('modal',{attrs:{"section":"custom-address","address":_vm.selectedAddress,"dialog":_vm.showAddressModal},on:{"handleAddress":_vm.handleAddress,"close":_vm.closeHandleModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }