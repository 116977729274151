<template>
  <div class="tw-flex tw-w-full">
  <div class="seats tw-w-full" v-if="hiringDetails.vehicleSelected.transportCompanyId">
    <div class="top">
      <div class="small-brown-text separate">
        <span>Hiring Details Summary</span>
      </div>
      <div class="huge-headers separate-div mt-5">
        <p class="top-route text-capitalize">
          {{toSentenceCase(hiringDetails.vehicleSelected.departureState)}}  {{toSentenceCase(hiringDetails.vehicleSelected.departureCity)}} <v-icon>mdi-arrow-right-thin</v-icon>
          {{toSentenceCase(hiringDetails.vehicleSelected.destinationState)}} {{toSentenceCase(hiringDetails.vehicleSelected.destinationCity)}}
        </p>
      </div>
    </div>
    <div class="each-card">
      <div class="" style="">
        <img :src="hiringDetails.vehicleSelected.vehicle.vehicleImage" alt="vehicle image" class="tw-w-auto" style="height: 200px" />
      </div>
      <div class="tw-flex tw-flex-wrap tw-py-4">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">

            <v-chip v-bind="attrs" v-on="on" class="small-brown-text" style="color:#004aad" color="#F3F3F3">
              <v-icon color="#004aad" class="mr-1">mdi-car-seat</v-icon>
              {{hiringDetails.vehicleSelected.vehicle.vehicleCapacity}}
            </v-chip>

          </template>
          <div>
            <strong>Seats:</strong> {{ hiringDetails.vehicleSelected.vehicle.vehicleCapacity }}
            <br>
            <p>Number of available seats in the vehicle.</p>
          </div>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" class="small-brown-text ml-2" style="color:#004aad" color="#F3F3F3">
              <v-icon color="#004aad" class="mr-1" v-if="hiringDetails.vehicleSelected.vehicle.vehicleACType">mdi-snowflake</v-icon>
              <v-icon color="#004aad" class="mr-1" v-else>mdi-snowflake-off</v-icon>
              A/C
            </v-chip>
          </template>
          <div>
            <strong>Air-Conditioner:</strong>
            <br>
            <p v-if="hiringDetails.vehicleSelected.vehicle.vehicleACType">Vehicle has a functioning Air conditioning system</p>
            <p v-else>Vehicle has no functioning Air conditioning system</p>
          </div>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" class="small-brown-text ml-2" style="color:#004aad" color="#F3F3F3">
              <v-icon color="#004aad" class="mr-1" v-if="hiringDetails.vehicleSelected.vehicle.vehicleVideoType">mdi-video</v-icon>
              <v-icon color="#004aad" class="mr-1" v-else>mdi-video-off</v-icon>
              Video
            </v-chip>
          </template>
          <div>
            <strong>Video Screen:</strong>
            <br>
            <p v-if="hiringDetails.vehicleSelected.vehicle.vehicleVideoType">Vehicle has a functioning video system</p>
            <p v-else>Vehicle has no functioning video system</p>
          </div>
        </v-tooltip>
      </div>
      <div class="tw-flex tw-flex-row tw-items-center">
        <img :src="hiringDetails.vehicleSelected.transportCompanyLogo" class="logo" alt="logo">
        <p class="medium-text">{{ hiringDetails.vehicleSelected.transportCompanyName || 'Nomadicpod'}}</p>
      </div>
      <div class="tw-mt-5">
        <p class="medium-text">Pick-up Details</p>
        <div class="tw-flex tw-flex-col tw-pl-3">
          <div class="tw-flex tw-flex-row tw-py-4 tw-items-center">
            <v-icon color="#004AAD">mdi-clock-outline</v-icon> &nbsp;&nbsp;
            <label class="small-brown-text">{{getDate(hiringDetails.query.hireDate)}} {{hiringDetails.pickupTime}}</label>
          </div>
          <div class="tw-flex tw-flex-row tw-pb-4 tw-items-center">
            <Icons class="mr-3" name="from2" color="#004aad" />
            <label class="small-brown-text text-capitalize">{{getPickupAddress}}</label>
          </div>

        </div>

      </div>
      <div class="tw-mt-5">
        <p class="medium-text">Drop-off Details</p>
        <div class="tw-flex tw-flex-col tw-pl-3">
          <div class="tw-flex tw-flex-row tw-py-4 tw-items-center">
            <Icons class="mr-3" name="from2" color="#004aad" />
            <label class="small-brown-text text-capitalize">{{getDropOffAddress}}</label>
          </div>

        </div>

      </div>

      <div class="tw-mt-5">
        <p class="medium-text" style="color: #004AAD">Price Details</p>
        <div class="tw-flex tw-flex-col tw-pl-3">
          <div class="tw-flex tw-w-full tw-justify-between tw-items-center">
            <div class="tw-flex tw-flex-row tw-py-4 tw-items-center">
              <v-icon class="mr-3"  color="#004aad" >mdi-cash-multiple</v-icon>
              <label class="small-brown-text ">Price per Day </label>
            </div>
            <div class="small-brown-text " v-if="hiringDetails.vehicleSelected && hiringDetails.vehicleSelected.hirePrice">
              ₦ {{formatNumber(hiringDetails.vehicleSelected.hirePrice)}}
            </div>
          </div>
          <div class="tw-flex tw-w-full tw-justify-between tw-items-center">
            <div class="tw-flex tw-flex-row tw-py-4 tw-items-center">
              <v-icon class="mr-3"  color="#004aad" >mdi-cash-multiple</v-icon>
              <label class="small-brown-text ">{{hiringDetails.query.quantity}} Vehicle(s) X {{hiringDetails.query.duration}} Hiring day(s) </label>
            </div>
            <div class="small-brown-text ">
              ₦ {{formatNumber(getActualPrice)}}
            </div>
          </div>
          <div class="tw-flex tw-w-full tw-justify-between tw-items-center" v-if="hiringDetails.vehicleSelected.discount">
            <div class="tw-flex tw-flex-row tw-py-4 tw-items-center">
              <v-icon class="mr-3"  color="#004aad" >mdi-cash-multiple</v-icon>
              <label class="small-brown-text ">{{hiringDetails.vehicleSelected.discountValue}}% Discount after {{hiringDetails.vehicleSelected.numberOfDiscountedDays}} day(s) </label>
            </div>
            <div class="small-brown-text ">
              ₦ {{formatNumber(getTotalDiscount)}}
            </div>
          </div>
          <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-4">
            <div class="tw-flex tw-flex-row  tw-items-center">
              <b class="medium-text text-capitalize">Total price</b>
            </div>
            <div class="medium-text " style="color: #004AAD">
              ₦ {{formatNumber(getTotalPrice)}}
            </div>
          </div>
        </div>

      </div>
      <div class="tw-flex tw-w-full tw-justify-end tw-pt-8">
        <base-button
            width="150px" buttonText="Make Payment" :isOutlined="true"
            :outlined="true" class="px-10 " @click="$emit('handlePayment')" :loading="paymentLoading"
        />
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import BaseButton from '../../components/reuseables/BaseButton.vue'
import {mapGetters} from "vuex";
import dayjs from "dayjs";
import Icons from "../../components/reuseables/Icons.vue";
  export default {
    components: {Icons, BaseButton},
    name: "PassengerTravelInformation",
    props:{
      paymentLoading : {
        type: [Boolean],
        default : false
      }
    },
    data() {
      return {
        panel: 0,
        selectedTrip: [],
        selectedTripBtn: false
      }
    },
    computed:{
      ...mapGetters("hire",["hiringDetails"]),
      getPickupAddress(){
        if (this.hiringDetails.pickUpLocation) {
          return this.toSentenceCase(this.hiringDetails.pickUpLocation.houseNumber) + " " + this.toSentenceCase(this.hiringDetails.pickUpLocation.streetName) +
              ", " + this.toSentenceCase(this.hiringDetails.pickUpLocation.city) + " " + this.toSentenceCase(this.hiringDetails.pickUpLocation.state)+ " "+
              this.toSentenceCase(this.hiringDetails.pickUpLocation.country)
        }
        else {
          return ""
        }
      },
      getDropOffAddress(){
        if (this.hiringDetails.dropOffLocation) {
          return this.toSentenceCase(this.hiringDetails.dropOffLocation.houseNumber) + " " + this.toSentenceCase(this.hiringDetails.dropOffLocation.streetName) +
              ", " + this.toSentenceCase(this.hiringDetails.dropOffLocation.city) + " " + this.toSentenceCase(this.hiringDetails.dropOffLocation.state)+ " "+
              this.toSentenceCase(this.hiringDetails.dropOffLocation.country)
        }
        else {
          return ""
        }
      },
      getActualPrice(){
        return (this.hiringDetails.query.duration * this.hiringDetails.vehicleSelected.hirePrice) * this.hiringDetails.query.quantity
      },
      getTotalDiscount(){
        return (this.getActualPrice * this.hiringDetails.vehicleSelected.discountValue) / 100
      },
      getTotalPrice(){
        return this.getActualPrice - this.getTotalDiscount
      }
    },
    methods:{
      getDateTime(date,time){
        return dayjs(date+time).format("LL")
      },
      getDate(date){
        return dayjs(date).format("LL")
      },
      getTime(date,time){
        return dayjs(date+time).format('LT')
      },
      toSentenceCase(text){
        text = text.toLowerCase()
        return text.replace(text.charAt(0),text.charAt(0).toUpperCase())
      },
      formatNumber(number){
        if(number){
          return number.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        }
      },
      getFirstText(text){
        if (text) {
          let arrayText = text.split(" ")
          return this.toSentenceCase(arrayText[0])
        }
      },

    }
  }
</script>

<style lang='scss' scoped>
  .separate {
    display: flex;
    justify-content: space-between;
  }
  .separate-div{
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    @media screen and (max-width: 1024px){
      justify-content: flex-start;
      flex-direction: column;
    }
  }
.top{
  padding-bottom: 30px ;
  @media screen  and( max-width: 820px){
    padding-left: 1rem;
  }

}
  .tiny-headers {
    font-family: 'Inter',sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 15px;
    color: #4F4F4F;
  }

  .huge-headers {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #4F4F4F;

  }

  .each-card {
    width: 80%;
    min-height: 450px;
    border-radius: 10px;
    border: 1px solid #F2F2F2;
    background: #FFF;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.04);
    padding: 40px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .medium-text {
    font-family: Inter,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #4F4F4F;
  }
  .logo{
    height: 50px;
    width: 50px;
    border-radius: 10%;
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .small-brown-text {
    color: #4F4F4F;
    font-family: Inter,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
.gone{
  @media (max-width:1264px) {
    display: none;
  }
}


  .v-expansion-panel-header {
    @media (max-width:960px) {
      padding: 8px 12px !important;
    }
  }
.heading{
font-family: 'Inter',sans-serif;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #004AAD;

}
  .col {
    padding: 5px;
  }

  .text-design {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  .v-select {
    width: 150px !important;

    @media (max-width:567px) {
      width: 70px !important;
    }
  }


  .departure {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #004AAD;

    @media (max-width:960px) {
      font-size: 13px;
    }
  }

  .sort,
  .v-list-item__title {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #4F4F4F;

    @media (max-width:960px) {
      font-size: 10px;
    }
  }

  .available-buses {
    display: flex;
    justify-content: space-evenly;
  }

  .company-logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: contain;
    @media (max-width:960px) {
      width: 40px;
      height: 40px;
    }
  }

  .company-name {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #4F4F4F;
  }

  .route,
  .departure-time,
  .seats-available,
  .amount,
  .full-date {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;

    @media (MAX-WIDTH:960px) {
      font-size: 11px;

    }

  }

  .v-application p {
    margin-bottom: 0 !important;
  }

  .cta {
    text-transform: none;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .book {
    color: #FAFAFA;

    width: 123px;
    height: 37px;
    background-color: #004AAD;
    border-radius: 4px;

    @media (max-width: 576px) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .button-handler {
    align-items: flex-start;
    margin: 20px 0;

    @media (max-width:1520px) {
      flex-direction: column;
    }

    @media (max-width:960px) {
      flex-direction: row !important;
      margin: 20px 0 40px 80px;
    }

    @media (max-width:768px) {
      margin: 20px 0 40px 53px;

    }

  }

  .schedule {
    color: #004aad;

    width: 123px;
    height: 37px;
    border: 1px solid #004AAD;
    border-radius: 4px;
    margin-left: 10px;

    @media (max-width:1520px) {
      margin-left: 0;
      margin-top: 20px;
    }

    @media (max-width:960px) {
      margin-top: 0;
      margin-left: 15px;

    }

    @media (max-width:576px) {
      width: 100%;
      margin-left: 0;
    }


  }

  .up-left {
    width: 70px;

  }

  .up-right {
    width: 100%;
  }

  .dropdown {
    margin-top: 30px;
  }

  .group {
    margin-left: 80px;

    @media (max-width:768px) {
      margin-left: 59px;
    }
  }

  .show {
    display: flex;

    @media (max-width:576px) {
      display: none
    }
  }

  .hide {
    display: none;

    @media (max-width:576px) {
      display: flex
    }
  }

  .main-left {
    width: 90%;

  }

  .first-line,
  .second-line {
    display: flex;
    justify-content: space-between !important;
  }

  .dot {
    font-size: 15px;
  }

  .first-line .left,
  .third-line {

    font-family: 'Inter',sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;

  }

  .amount,
  .top-amount {
    color: #004aad;
  }

  .first-line .right .amount {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 24px;
    color: #004AAD;

  }

  .second-line,
  .footer,
  .second-as {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #4F4F4F;

  }

  .second-as {
    margin-left: -30px;
  }

  .footer {
    color: #878787;
    margin-left: -30px;
    line-height: 20px;

  }
  .top .footer{
    margin-left: 0;
  }

  ::v-deep .v-text-field input {
    padding: 0 !important;
  }
</style>