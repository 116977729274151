<template>
    <div class="smaller-vh section-sculptor">
        <div class="display-center">
            <p class="header mb-14">How Nomadicpod Works</p>
        </div>
        <div class="display-center process-sculptor">
            <div class="each-process display-center">
                <img src="@/assets/newlandingpage/process2.svg"/>
                <span class="text-subheader">
                    Enter your destination
                </span>
            </div>
            <div>
                <Icons class="mx-5" name="arrow-right"/>
            </div>
            <div class="each-process display-center">
                <img src="@/assets/newlandingpage/process1.svg"/>
                <span class="text-subheader">
                    Choose your Transport
                </span>
            </div>
            <div>
                <Icons class="mx-5" name="arrow-right"/>
            </div>           
            <div class="each-process display-center" style="width: 180px;">
                <img src="@/assets/newlandingpage/process3.svg"/>
                <span class="text-subheader">
                    Book your Ticket
                </span>
            </div>
            <div>
                <Icons class="mx-5" name="arrow-right"/>
            </div>
            <div class="each-process display-center" style="width: 180px;">
                <img src="@/assets/newlandingpage/process4.svg"/>
                <span class="text-subheader">
                    Travel with Ease
                </span>
            </div>
        </div>
        <!-- mobile view -->
        <div class="display-center process-sculptor-mobile">
            <div class="each-process display-center">
                <img src="@/assets/newlandingpage/process2.svg"/>
                <span class="text-subheader">
                    Enter your destination
                </span>
            </div>
            <div>
                <Icons class="mx-5" name="arrow-down"/>
            </div>
            <div class="each-process display-center">
                <img src="@/assets/newlandingpage/process1.svg"/>
                <span class="text-subheader">
                    Choose your Transport
                </span>
            </div>
            <div>
                <Icons class="mx-5" name="arrow-down"/>
            </div>           
            <div class="each-process display-center">
                <img src="@/assets/newlandingpage/process3.svg"/>
                <span class="text-subheader">
                    Book your Ticket
                </span>
            </div>
            <div>
                <Icons class="mx-5" name="arrow-down"/>
            </div>
            <div class="each-process display-center">
                <img src="@/assets/newlandingpage/process4.svg"/>
                <span class="text-subheader">
                    Travel with Ease
                </span>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import Icons from "@/components/reuseables/Icons.vue"
    export default {
        name: "SectionTenb",
        components:{Icons}
    }
    </script>
    
    <style lang="scss" scoped>
    .smaller-vh{
        min-height: 35vh;
    }
    .section-sculptor {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 100px;
        margin-bottom: 100px;
        flex-direction: column;
        @media (max-width:768px) {
        margin-bottom: 50px;
        margin-top: 50px;
    }
        @media (max-width:576px) {
        min-height: auto;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    }
    .process-sculptor{
        margin-top: 70px;
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 768px) {
            display: none;
        }

    }
    .header {
        color: #263238;
        font-family: Raleway;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 70px;
        @media screen and (max-width: 768px) {
            font-size: 33px;
            line-height: 50px;
        }
        @media screen and (max-width: 576px) {
            font-size: 30px;
            line-height: 40px;
        }
    }
    
    .text-subheader {
        font-family: "Raleway", sans-serif !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 38px;
        letter-spacing: 0;
        text-align: left;
        color: #263238;
        margin-top: 20px;
    
        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 28px;
        }
    }
    .each-process{
        flex-direction: column;
        width: 220px;
        @media (max-width:576px) {
            width: 100%;
        }
    }
    .process-sculptor-mobile{
        display: none;
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }
    }
    .process-sculptor-mobile .each-process{
        width: 100%;
        margin-top: 30px ;
    }
    </style>
    