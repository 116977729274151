<template>
  <div class="step-two">
    <loader :loading="loading" v-if="loading" />
    <div class="tw-w-full" v-else>
    <nav-bar/>
     <div class="tw-mt-20"></div>
    <booking-steps />
    <div class="wrapper">
      <div class="back " @click="routeBack"><v-icon>mdi-chevron-left</v-icon>Back</div>
    </div>
    <selected-trip-component />
    <div class="display-center">
    <div class="selectseat ">
      <div class="tw-w-full">
        <seat-timer :tripId="userSelectedTrip.id"/>
      </div>
      <vehicle-seats :trip="userSelectedTrip" />
    </div>
    </div>
    <checkout-details />
    <Footer/>
    </div>
  </div>
</template>

<script>
  import BookingSteps from '../../components/reuseables/bookingAndHiring/OneWayBookingSteps.vue'
  import VehicleSeats from '../../components/reuseables/bookingAndHiring/VehicleSeats.vue'
  import CheckoutDetails from '../bookings/CheckoutDetails.vue'
  import NavBar from "@/home/Navbar.vue";
  import selectedTripComponent from '../bookings/SelectedTripComponent.vue'
  import Footer from "@/home/Footer.vue";
  import {getTripByIdAndWaterTransportOperatorId, getTripByTripId} from "@/services/api/APIService";
  import {mapGetters} from "vuex";
  import SeatTimer from "@/components/reuseables/bookingAndHiring/SeatTimer.vue";
  import Loader from "@/components/reuseables/Loader.vue";
  export default {
    name : "SelectSeats",
    components: {
      Loader,
      SeatTimer,
      Footer,
      selectedTripComponent,
      CheckoutDetails,
      BookingSteps,
      VehicleSeats,
      NavBar
    },
    data(){
      return{
          selectedTrip: [],
          selectedTripBtn: false,
          loading : false
      }
      },
    computed:{
      ...mapGetters("booking", ["userSelectedTrip"])
    },
    methods:{
      routeBack(){
        this.$router.push({name: 'OneWaySelectTrip'})
      },
    },
    created() {
      this.loading = true
      let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))
      let passengerDetail = JSON.parse(sessionStorage.getItem("passengerDetail"))
      let userSelectedCategory = JSON.parse(sessionStorage.getItem("userSelectedCategory"))
      if (userSelectedTrip && Object.keys(userSelectedTrip).length){
        if (userSelectedTrip.transportCompanyId) {
          let data = {}
          data.transportCompanyId = userSelectedTrip.transportCompanyId
          data.tripId = userSelectedTrip.id
          data.id = userSelectedTrip.id
          data.scheduleId = userSelectedTrip.scheduleId
          data.departureQuery = userSelectedTrip.routeDepartureCity
          data.destinationQuery = userSelectedTrip.routeDestinationCity
          getTripByTripId(data).then(res => {
            if (res.data.tripRegion === 'crossCountry' && userSelectedCategory && Object.keys(userSelectedCategory).length) {
              res.data.tripAmount = userSelectedCategory.tripAmount
            }
            this.$store.dispatch("booking/setUserSelectedTrip", res.data)
            this.$store.dispatch("booking/getTotalAmount", res.data.tripAmount)
            this.$store.dispatch("booking/updateTripAndTravellerDetails", passengerDetail)
            this.loading = false
          }).catch(() => this.loading = false)
        }
        else if (userSelectedTrip.waterTransportOperatorId){
          let data = {}
          data.waterTransportOperatorId = userSelectedTrip.waterTransportOperatorId
          data.tripId = userSelectedTrip.id
          getTripByIdAndWaterTransportOperatorId(data).then(res => {
            if (res.data.tripRegion === 'crossCountry' && userSelectedCategory && Object.keys(userSelectedCategory).length) {
              res.data.tripAmount = userSelectedCategory.tripAmount
            }
            this.$store.dispatch("booking/setUserSelectedTrip", res.data)
            this.$store.dispatch("booking/getTotalAmount", res.data.tripAmount)
            this.$store.dispatch("booking/updateTripAndTravellerDetails", passengerDetail)
            this.loading = false
          }).catch(() => this.loading = false)
        }
      }
    }
    
  }
</script>

<style lang='scss' scoped >
 .selectseat{
    width: 70%;
   padding-bottom: 2rem;

   @media screen and (max-width: 768px){
     width: 90%;
   }
  }
 .wrapper{
   width: 100%;
   padding: 0 10rem;

   @media screen and (max-width: 1024px) {
     padding: 1rem;
   }
 }

</style>