<template>
  <div>
    <Navbar />
      <div class="section-one">
        <loader :loading="loading" v-if="loading" />
        <div class="post-div tw-mb-10" v-else>
          <div class="post-div-content">
            <img
              :src="post.postImage"
              class="intro-pic" :alt="post.postTitle"
            />
            <v-card flat>
              <v-card-title class="text-capitalize">{{post.postTitle}}</v-card-title>
              <v-card-text>{{post.postContent}}</v-card-text>
              <v-card-text v-if="post.mediumLink">
                <a class="read-more" :href="post.mediumLink">Read more</a>
              </v-card-text>
              <v-card-subtitle>{{getDate(post.createdOn)}}</v-card-subtitle>
              <v-card-text>Written By {{post.writtenBy}}</v-card-text>
            </v-card>
        </div>
        </div>

      </div>
      <v-divider ></v-divider>
      <div class="section-two">
        <h1 class="tw-px-5">Popular</h1>
        <v-card flat>
          <div class="tw-flex tw-items-center tw-flex-col lg:tw-flex-row tw-w-full">
            <div
              v-for="(blogPost, index) in posts"
              :key="index"
              class="eachcard tw-cursor-pointer" @click="readPost(blogPost)"
            >
              <v-img class="image" :src="blogPost.postImage">
                <div class="title text-capitalize">{{ blogPost.postTitle }}</div>

                <div class="date">{{ getDate(blogPost.createdOn) }}</div>
              </v-img>
            </div>
          </div>
        </v-card>
      </div>


    <Footer />
  </div>
</template>

<script>
import Navbar from "../../home/Navbar.vue";
import Footer from "../../home/Footer.vue";
import dayjs from "dayjs";
import {getAllPublishPost} from "@/services/api/APIService";
import {getBlogPostByTitle} from "../../services/api/APIService";
import Loader from "./Loader.vue";

export default {
  name: "Blogpages",
  components: {
    Loader,
    Navbar,
    Footer,
  },
  data() {
    return {
      post:{},
      posts: [],
      loading:false
    };
  },
  methods:{
    getDate(date){
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      if (dayjs(date).toDate().toDateString()=== today.toDateString()) {
        return "Today";
      } else if (dayjs(date).toDate().toDateString() === yesterday.toDateString()) {
        return "Yesterday";
      } else {
        return dayjs(date).toDate().toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
      }
    },
    async getAllPost(){
      await getAllPublishPost().then(res =>{
        this.posts = res.data.slice(-3)
        this.posts.sort(function (a, b) {
          return dayjs(b.createdOn) - dayjs(a.createdOn);
        });
      })
    },
    readPost(post){
      if (post.postTitle !== this.$route.params.title) {
        sessionStorage.setItem("post", JSON.stringify(post))
        this.post = post
        this.$router.push({name: "BlogPost", params: {"title": post.postTitle}});
      }
    }
  },
  async created() {
    let postTitle = this.$route.params.title
    if (postTitle){
      this.loading = true
      let data = {}
      data.postTitle = postTitle
      await getBlogPostByTitle(data).then(res =>{
        this.post = res.data
        this.$meta().addApp(postTitle)
        this.loading = false
      }).catch(()=> {
        this.loading = false
      })
          .finally(() => this.loading = false)
    }
    let post =  JSON.parse(sessionStorage.getItem("post"))
    if (post){
      this.post = post
    }
    await this.getAllPost()
  }
};
</script>

<style lang="scss" scoped>
 .post-div{
   width: 100%;
   height: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   padding-top: 10rem;

   @media screen and (max-width: 768px) {
     padding-top: 5rem;
   }
 }
 .post-div-content{
   display: flex;
   flex-direction: column;
   width: 600px;
   min-height: 500px;
   height: max-content;
   box-shadow:  rgba(0, 0, 0, 0.1) 0 10px 50px;
   border-radius: 10px;

   @media screen and (max-width: 768px) {
     width: 100%;
   }
 }
.intro-pic {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center center;
  border-radius: 0 0 10px 10px;
  @media screen and (max-width: 768px) {

  }
}

.v-card__title {
  font-family: "Inter",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 34px;
  color: var(--padiGrey);
  word-break: normal;
}

.v-card__subtitle {
  padding-top: 10px;
  font-family: "Raleway",sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(79, 79, 79, 0.62);
}

 .read-more {
   font-family: "Raleway",sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   color: #004AAD;
 }

.v-card__text {
  font-family: "Raleway",sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  color: var(--padiGrey);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.section-two {
    margin-left: 10rem;
  @media screen and (max-width: 1024px) {
    margin-left: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}

.section-two h1 {
  font-family: "Inter",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 54px;
  color: var(--padiGrey);
  padding-bottom: 30px;
  margin-left: 1rem;

  @media screen and (max-width: 1024px) {
    margin-left: 0;
  }
}

.v-image {
  border-radius: 15px;
  height: 360px;
  width: 360px;

  @media screen and (max-width: 500px) {
    height: 300px;
    width: 300px;
  }
}

.date {
  position: absolute;
  width: 100px;
  height: 35px;
  padding: 5px;
  bottom: 33px;
  right: -34px;
  background: rgba(0, 74, 173, 0.5);
  border-radius: 10px 10px 0px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #ffffff;
  transform: rotate(-90deg);
}

.title {
  position: absolute;
  width: 250px;
  height: 60px;
  background: rgba(79, 79, 79, 0.658);
  border-radius: 0px 0px 0px 10px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  line-height: 54px;
  color: #ffffff;
  bottom: 0;
  text-align: center;
}

.eachcard {
  margin: 15px;
  position: relative;
}
</style>