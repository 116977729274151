<script>


export default {
  name: 'CommutersForProducts',
  methods:{
    routeToNpod(){
      window.open(process.env.VUE_APP_NPOD_URL,"_blank")

    },
  }
}
</script>

<template>
  <div class="parent-div tw-pr-16">
    <div>
    <div class="avatar-container">
      <div class="avatar-inner" >
        <img class="" src="@/assets/icons/tabler_brand-booking.svg" alt="Avatar Image">
      </div>
    </div>
    </div>

    <div class="tw-px-5">
      <img class="logo" src="@/assets/icons/Npod-logo.svg" alt="company logo"/>
      <p class="product-txt">Npod</p>
      <p class="paragraph-txt ">Access multiple modes of transportation options and services in one place</p>
      <p class="paragraph-txt-mobile">Access multiple modes of transportation<br> options and services in one place</p>
    <div class="tw-mt-5">
      <div @click="routeToNpod" class="fs-title book-a-trip text-center tw-flex tw-items-center tw-justify-center tw-cursor-pointer
          tw-px-5 tw-py-2">Book a Trip On
        <img src="@/assets/icons/Npod-logo.svg" alt="company logo"/>
      </div>
    </div>
    <div class="tw-flex tw-mt-10 btn" >
      <img class="mobile-btn tw-mr-2" src="@/assets/Apple.svg" alt="apple store button">
      <img class="mobile-btn" src="@/assets/Google.svg" alt="play store button">
      <img class="tw-mr-5 web-btn" src="@/assets/icons/google-play-btn.svg" alt="play store button">
      <img class="web-btn" src="@/assets/icons/apple-store-btn.svg" alt="apple store button">
    </div>
  </div>
  </div>
</template>

<style scoped lang="scss">
.parent-div{
  display: flex;
  flex-direction: row;
  width: 587px;
  height: 248px;
  background: #FFFFFF;
  @media (max-width: 576px) {
    //padding: 20px 100px 10px 10px;
  }
}
.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px !important;
  height: 88px !important;
  border-radius: 50%;
  background: #F5F9FF;
  @media (max-width: 576px) {
    width: 50px !important;
    height: 50px !important;
  }

}

.avatar-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: #E3F0FF;
  @media (max-width: 576px) {
    width: 45px;
    height: 45px;
  }
}

.avatar-inner img {
  object-fit: contain;
  display: flex;
  align-self: center;
}
.product-txt{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #000F23;
  @media (max-width: 576px) {
    display: none;
  }
}
.paragraph-txt{
  font-family: Raleway,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #000000;
  padding-top: 10px;
  flex-wrap: wrap;
  @media (max-width: 576px) {
 display: none;
  }
}
.book-a-trip{
  width: 284.03px;
  height: 56px;
  gap: 8px;
  border-radius: 10px;
  border: 2px solid #004AAD;
  color: #004AAD;
  font-family: Raleway,sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  @media (max-width: 576px) {
    display: none;
  }
}

.web-btn{
  @media (max-width: 576px) {
    display: none;
  }
}
.mobile-btn{
  display: none;
  @media (max-width: 576px) {
    display: flex;
    width: 40%;
  }
  @media (max-width: 375px) {
    display: flex;
    width: 35%;
  }
}
.avatar-img{
  display: none;
  @media (max-width: 576px) {
    display: flex;
  }
}
.logo{
  display: none;
  @media (max-width: 576px) {
    display: flex;
  }
}
.paragraph-txt-mobile{
  display: none;
  @media (max-width: 576px) {
    display: flex;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #00193A;
    margin-top: 15px;
  }
}
</style>