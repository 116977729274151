<template>
    <div class="tw-w-full">
        <Navbar class="navbar"/>
        <div class="main-layout-wrapper tw-mt-10">
            <div class="first-section tw-w-full">
                <div class="left-side">
                    <vue-typer
                        :text='$vuetify.breakpoint.mdAndUp ? ["Connecting\nYou To Your\nDestination Today!", "Optimized Mobility\nFor Businesses \nAnd Commuters",
                         "Fulfilling Your\nDreams Today!\nOne Trip At a Time"] :
                         ["Connecting\nYou\nTo Your\nDestination!", "Optimized\nMobility\nFor Businesses\nand Commuters",
                         "Fulfilling\nYour Dreams,\nOne Trip\nAt a Time"]'
                        :repeat='Infinity' :shuffle='false' initial-action='typing' :pre-type-delay='70'
                        :type-delay='70' :pre-erase-delay='2000' :erase-delay='250' erase-style='clear'
                        :erase-on-complete='false' caret-animation='expand' class="md:tw-pt-0" v-scroll-reveal.reset>
                    </vue-typer>
                    <p class="text-subheader my-8" v-scroll-reveal.reset="{ delay: 200 }">The easiest way to book intercity trips with any transport provider,
                      access various modes of transport, plan and embark on your journeys, all from one platform.</p>
                    <p class="download-text">Download the Npod mobile App</p>
                    <div class="tw-flex tw-flex-col lg:tw-flex-row tw-py-4 tw-mt-2 btn-container">
                      <img src="@/assets/icons/google-play-btn.svg"  alt="google" class="lg:tw-mr-5 tw-cursor-pointer google-btn" @click="openGooglePlay"/>
                      <img src="@/assets/icons/apple-store-btn.svg"  alt="apple" class="tw-cursor-pointer google-btn   lg:tw-mt-0" id="apple-btn"/>
                    </div>
                </div>
                <div class="right-side">
                  <hero-section-right-side class="hero-sec-right"/>
                  <img class="mobile-right-side" src="@/assets/new_hero_image.svg" alt="">
<!--                    <img src="@/assets/newlandingpage/lp2.svg" width="300" alt="ad-cards" class="top-img"-->
<!--                        v-scroll-reveal.reset="{ delay: 250 }" />-->
<!--                    <div class="tw-flex middle-nav">-->
<!--                        <TripSearch class="search-sculptor" v-scroll-reveal.reset />-->
<!--                        <img src="@/assets/newlandingpage/lp3.svg" alt="ad-cards" class="middle-img"-->
<!--                            v-scroll-reveal.reset="{ delay: 300 }" />-->
<!--                    </div>-->
<!--                    <img src="@/assets/newlandingpage/lp1.svg" width="300" alt="ad-cards" class="bottom-img"-->
<!--                        v-scroll-reveal.reset="{ delay: 350 }" />-->

              </div>
            </div>

            </div>
<!--            <NewHeroSection />-->
            <div class="all-sec tw-flex tw-flex-col tw-w-full">

                <section-two />
                <section-three />
                <!-- <SectionFour /> -->
                <!-- <SectionFive /> -->
                <!-- <SectionSix />
            <SectionSeven /> -->
                <SectionEight />
                <SectionNine />
                <!-- <SectionTen /> -->
                <SectionTenb />
<!--                <DownloadApp />-->
                <div class="partner-section">
                    <div class="display-center" style="font-weight: 700 !important">
                        <p class="small-bold-header ">COMPANIES THAT TRUST <span
                                style="color:#004aad">NOMADICPOD </span></p>
                    </div>
                    <Partners class="md:mt-15 mt-7" />
                </div>
                <!-- <SectionEleven /> -->
            </div>
            <Footer />
        </div>
<!--    </div>-->
</template>
<script>
    import {
        VueTyper
    } from "vue-typer"

    import Navbar from '../Navbar.vue';
    // import TripSearch from './TripSearch.vue'
    import SectionTwo from './SectionTwo.vue';
    import SectionThree from './SectionThree.vue';
    // import SectionFour from './SectionFour.vue';
    // import SectionFive from './SectionFive.vue';
    // import SectionSix from './SectionSix.vue'
    // import SectionSeven from './SectionSeven.vue';
    import SectionEight from './SectionEight.vue';
    import SectionNine from './SectionNine.vue';
    // import SectionTen from './SectionTen.vue';
    // import SectionEleven from './SectionEleven'
    import Footer from './Footer.vue';
    // import DownloadApp from './DownloadApp.vue';
    import SectionTenb from './SectionTenb.vue';
    import Partners from './Partners.vue';

    import HeroSectionRightSide from "./HeroSectionRightSide.vue";
    export default {
        name: "LandingPage",
        components: {
          HeroSectionRightSide
          ,
            Navbar,
            // TripSearch,
            SectionTwo,
            SectionThree,
            // SectionFour,
            SectionEight,
            // SectionFive,
            // SectionSix,
            // SectionSeven,
            SectionNine,
            // SectionTen,
            // SectionEleven,
            Footer,
            // DownloadApp,
            SectionTenb,
            VueTyper,
            Partners
        },
      data(){
          return{
            discount:null,
            appbarVisible: true,
          }
      },
      methods:{
        closeAppBar() {
          this.appbarVisible = false;
        },
        openGooglePlay(){
          window.open("https://play.google.com/store/apps/details?id=com.nomadicpod.npod&pli=1","_blank")
        }

      },
      async created() {
      }
    }
</script>
<style lang="scss" scoped>

    //.navbar{
    //  @media screen and (width: 853px){
    //    padding-left: 1.8rem;
    //    padding-right: 1.8rem;
    //  }
    //  @media screen and (max-width: 430px){
    //    margin-left: 5px;
    //  }
    //  @media screen and (max-width: 375px){
    //    margin-left: 5px;
    //  }
    //  @media screen and (max-width: 360px){
    //    padding-left: 0.8rem;
    //    padding-right: 0.8rem;
    //  }
    //}
    .hundred-vh{
      min-height: 100vh;
    }
    .nonlandingpage {}
    .play {
      margin-left: -0.5rem;
    }
    .main-layout-wrapper {
      overflow-y: clip;
    }

.navbar{
  @media screen and (max-width: 430px){
    margin-left: 5px;
  }
  @media screen and (max-width: 375px){
    margin-left: 5px;
  }
}
.hundred-vh{
  min-height: 100vh;
}
.nonlandingpage {}
.play {
  margin-left: -0.5rem;
}
.main-layout-wrapper {
  overflow-y: clip;
}

    .body-bg {}

    .first-section {

      background-image: url("../../assets/first-sec-back.svg");
      object-fit: cover;
      background-size: 100%;
      padding-left: 10rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      padding-top: 100px;
      padding-bottom: 100px;
      min-height: 100vh;

      @media (max-width:1264px) {
          padding-left: 3rem;
          padding-right: 3rem;
      }
      @media screen and (max-width: 1040px) {
          flex-direction: column;
          background-size: cover;
          background-position: right center;
      }
      @media screen and (width:768px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
          padding-top: 30px;
      }
      @media screen and (max-width:430px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 30px;
      }
      @media screen and (max-width:428px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 30px;
      }
      @media screen and (max-width:414px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 30px;
        //height: 90vh;
      }
      @media screen and (width:390px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 30px;
      }
      @media screen and (width:375px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 30px;
      }
      @media screen and (width:360px) {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        padding-top: 30px;
      }
      @media screen and (width:344px) {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        padding-top: 30px;
      }
         @media (width:1264px) {
            padding-left: 3rem;
            padding-right: 3rem;
        }

        @media screen and (width: 1040px) {
            flex-direction: column;
            background-size: cover;
            background-position: right center;
        }

        @media (width:768px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-top: 30px;
        }
    }

    .left-side {
      display: flex;
      flex-direction: column;
        width: 50% !important;

        @media screen and (max-width: 1024px) {
            width: 100% !important;
        }
    }

    .vue-typer {
        color: #263238 !important;
        font-style: normal;
        font-weight: 900;
        //height: 27vh;
        zoom: normal;

      @media screen and (min-width: 1920px){
        height: 25vh;
        line-height: 90px;
        margin-bottom: 40px;
        font-size: 4.5rem;
      }
      @media screen and (max-width: 1366px){
        height: 25vh;
        line-height: 60px;
        margin-bottom: 40px;
        font-size: 3rem;
      }
      @media screen and (width:1024px) {
          font-size: 2.8rem;
          line-height: 60px;
          height: 30vh;
      }
      @media screen and (width:853px) {
        height: 20vh;
        font-weight: 1000;
        font-size: 3.2rem;
        line-height: 60px;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
      }
      @media screen and (width:576px) {
        font-size: 2.8rem;
        line-height: 60px;
        width: 100%;
        margin-top: 30px;
      }
      @media screen and (width:430px) {
        height: 26vh;
        font-weight: 900;
        font-size: 46px;
        line-height: 56px;
        width: 100%;
        margin-top: 30px;
      }
      @media screen and (width:428px) {
        height: 26vh;
        font-weight: 900;
        font-size: 46px;
        line-height: 56px;
        width: 100%;
        margin-top: 30px;
      }
      @media screen and (width:414px) {
        height: 26vh;
        font-weight: 900;
        font-size: 2.8rem;
        line-height: 55px;
        width: 100%;
        margin-top: 40px;
      }
      @media screen and (width:412px) {
        height: 26vh;
        font-weight: 900;
        font-size: 44px;
        line-height: 56px;
        width: 100%;
        margin-top: 30px;
      }
      @media screen and (width: 390px){
        height: 26vh;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 40px;
        line-height: 55px;
      }
      @media screen and (max-width: 375px){
        height: 26vh;
        margin-top: 20px;
        margin-bottom: 60px;
        font-size: 40px;
        line-height: 55px;
      }
      @media screen and (max-width: 360px){
        height: 26vh;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 38px;
        line-height: 52px;
      }
      @media screen and (max-width: 344px){
        height: 22vh;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 36px;
        line-height: 52px;
      }
    }

    span.vue-typer span.left {
        font-family: Raleway !important;

    }

    .right-side {
        width: 50% !important;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1024px) {
            width: 100% !important;
            margin-top: 60px;
        }
      @media screen and (max-width: 1366px){
        width: 40% !important;
      }
      @media screen and (max-width: 375px){

        width: 100% !important;
        margin-top: -10px;
      }
      @media screen and (max-width: 430px){

        width: 100% !important;
        margin-top: -15px;
      }
    }


    .small-bold-header {
        font-weight: 700 !important;
        font-family: "Raleway", sans-serif !important;
        font-size: 23px;
        font-style: normal;
        font-weight: 300;
        line-height: 38px;
        letter-spacing: 0;
        text-align: left;
        color: #263238;

        @media screen and (min-width: 1920px) {
            font-size: 26px;
            line-height: 38px;
        }

        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 28px;
        }
    }

    .text-subheader {
        font-family: "Raleway", sans-serif !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 38px;
        letter-spacing: 0;
        text-align: left;
        color: #263238;
        width: 90%;

        @media screen and (min-width: 1920px) {
            font-size: 22px;
            line-height: 38px;
        }

        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 28px;
        }
        @media (max-width: 576px) {
          width: 100%;
        }
        @media screen and (width: 430px){
          width: 100%;
          font-size: 18px;
        }
        @media screen and (width: 428px){
          //height: 22vh;
          width: 100%;
          font-size: 18px;

        }
        @media screen and (max-width: 414px){
          font-size: 17px;
          width: 100%;
        }
        @media screen and (width: 390px){
          font-size: 16px;
          width: 100%;
        }
        @media screen and (max-width: 375px){
          width: 100%;
          font-size: 15px;
        }
        @media screen and (max-width: 344px){
          width: 100%;
          font-size: 14px;
        }
        @media (max-width: 576px) {
          width: 100%;
        }
        @media screen and (max-width: 375px){
         width: 100%;
        }
    }

    .fs-title {
        width: 152px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: left;
        height: 50px;
    }

    .signin {
        background: #004aac;
        border-radius: 6px;
        margin-right: 10px;
        color: white;
    }

    .partner-section {
        padding-top: 100px;
        padding-bottom: 100px;

        @media screen and (max-width: 960px) {
            padding-bottom: 70px;
            padding-top: 70px;
        }

        @media (max-width:576px) {
            padding-bottom: 50px;
            padding-top: 50px;
        }

        @media (max-width:400px) {
            padding-bottom: 25px;
            padding-top: 25px;
        }
    }

    .search-sculptor {

        @media (max-width:1500px) {
            margin-left: 0;

        }
    }

    .top-img {
        margin-bottom: -110px;
        z-index: 5;
        margin-left: -100px;

        @media (max-width:1500px) {
            display: none;
        }

        @media (min-width:1920px) {
            margin-left: 0;
        }
    }

    .bottom-img {
        margin-top: -120px;
        margin-left: -100px;

        @media (max-width:1500px) {
            display: none;
        }

        @media (min-width:1920px) {
            margin-left: 0;
        }
    }

    .middle-img {
        margin-left: -100px;
        margin-bottom: 300px;
        z-index: 5;

        @media (max-width:1264px) {
            display: none;
        }
    }

    .middle-nav {
        justify-content: flex-end;
        align-items: flex-end;

        @media (max-width:1264px) {
            align-items: center;
            justify-content: center;
        }
    }
.discount-banner{
  background: #f54c35;
  width: 100%;
  min-height: 60px;
  border-radius: 10px;
}
.discount-banner-text{
  color: white;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 18px;

}
.btn-container{
  @media screen and (max-width: 375px){
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: -10px;
  }
  @media screen and (max-width: 430px){
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: -10px;
  }
}
.google-btn{
  width: 9.281rem;
  height: 2.75rem;
  object-fit: fill;
  @media screen and (max-width: 375px){

  }
}
#apple-btn{
  @media screen and (max-width: 375px){
    padding-left: 10px;
  }
  @media screen and (max-width: 430px){
    padding-left: 10px;
  }
}
.download-text{
  font-family: "Raleway", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 18px;
  color: var(--padiBlue);
  margin-top: 20px;
  @media screen and (width: 375px){
    font-weight: 600;
    font-size: 14px;
    line-height:40px;
    margin-top: -10px;
    padding-left: 2px;
  }
  @media screen and (width: 430px){
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
    margin-top: -10px;
    text-align: left;
  }
  @media screen and (width: 428px){
    font-size: 19px;
    font-weight: 600;
    line-height: 40px;
    margin-top: -10px;
    text-align: left;
  }
}
.mobile-right-side{
  display: none;
  @media screen and (max-width: 375px){
    display: flex;
  }
  @media screen and (max-width: 430px){
    display: flex;
  }
}
.hero-sec-right{
  @media screen and (max-width: 375px){
    display: none;
  }
  @media screen and (max-width: 430px){
    display: none;
  }
}
.all-sec{
  @media screen and (max-width: 375px){
    margin-right: 10%!important;
  }
}
</style>