<template>
  <section
    class="
      eight-section
      tw-hidden tw-items-center
      lg:tw-px-44
      tw-py-40
      lg:tw-py-0
      hundred-vh
    "
  >
    <v-col sm="8" class="box tw-pl-0">
      <p class="text-header">Start Building with Nomadicpod</p>
      <p class="text-subheader tw-mt-5">
        Access high-quality travel services and facilities and start
        facilitating bookings in minutes.
      </p>
      <div class="tw-flex tw-mt-20">
        <router-link
          to="/register-as"
          class="
            fs-title
            signin
            text-center
            tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-4
          "
          >Get Started
        </router-link>
      </div>
    </v-col>
  </section>
</template>

<script>
export default {};
</script>

<style lang=scss scoped>
.eight-section {
  background-image: url("../../assets/homepage/homepage.png");
  object-fit: contain;
  background-size: cover;

  @media screen and(max-width: 786px) {
    padding: 1.5rem;
  }

  @media screen and (max-width: 360px) {
    padding: 1rem;
  }
}

.text-header {
  font-family: Raleway, serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  color: #ffffff;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 768px) {
    height: 114px;
    width: 291px;
    font-family: "Raleway";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.text-subheader {
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 768px) {
    font-family: Inter, serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-align: left;
    height: 68px;
    width: 291px;
  }
}

.fs-title {
  /* width: 152px; */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}

.signin {
  width: 194px;
  height: 52.41px;
  background: #ffffff;
  border-radius: 6px;
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #004aad;
}
</style>