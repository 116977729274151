<template>
  <div
    class="tw-flex tw-w-full tw-h-auto tw-justify-center tw-items-center"
    style="
      background: linear-gradient(
        135.05deg,
        rgba(240, 247, 254, 0.69) 41.6%,
        #fef5f0 73.72%
      );
    "
  >
    <v-col sm="12" lg="7" class="left-side lg:tw-flex">
      <v-col sm="12" class="left-side-body">
        <img
          src="@/assets/logos/white.png"
          alt="logo"
          style="height: 40px; width: 186px; cursor: pointer"
          @click="$router.push({ name: 'HomePage' })"
        />
        <label class="buddy">Hey Nomads!</label>
        <h6 class="welcome tw-mt-5">Welcome Back.</h6>
      </v-col>
    </v-col>
    <v-col
      sm="12"
      lg="5"
      class="right-side tw-flex tw-items-start lg:tw-items-center"
    >
      <v-col sm="12" class="right-side-body">
        <img
          src="@/assets/logos/blue.png"
          alt="logo"
          style="height: 34px; width: 136px"
          class="mb-3 mt-6"
          @click="$router.push({ name: 'HomePage' })"
        />

        <h6 class="signup-header mb-1 ">Sign In</h6>

        <h6 class="welcome tw-mt-0 mb-md-5">Welcome Back!</h6>



        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(LoginTransportCompany)">
            <ValidationProvider
              name="Email Or Phone Number"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 mb-2" :class="classes">
                <v-text-field
                  placeholder="Email Or Phone Number"
                  solo
                  hide-details
                  type="text"
                  v-model="loginData.username"
                  required
                  autofocus
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

            <ValidationProvider
              name="Password"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 mb-1" :class="classes">
                <v-text-field
                  hide-details
                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value = !value)"
                  :type="value ? 'text' : 'password'"
                  solo
                  placeholder="Password"
                  name="password"
                  v-model="loginData.password"
                  id="password"
                >
                </v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

            <div class="tw-mt-5 tw-pb-5 tw-w-full tw-flex tw-flex-row tw-justify-end tw-items-center">
<!--              <v-checkbox hide-details class="terms" v-model="keepMeIn">-->
<!--                <template #label>-->
<!--                  <label> Keep Me Logged in </label>-->
<!--                </template>-->
<!--              </v-checkbox>-->
              <h6 style=" cursor: pointer" class="tw-flex already tw-items-center">
                <router-link style="color: #004aad;"
                             :to="{ name: 'ResetPassword' }"
                >&nbsp;Forget Password?</router-link
                >
              </h6>
            </div>

            <base-button
              class="mt-4"
              width="100%"
              button-text="Log In"
              :loading="loading"
              type="submit"
            ></base-button>
          </form>
        </validation-observer>
        <div class="tw-pt-4">
          <h6 class="tw-flex already tw-items-center tw-justify-center mt-2">
            Don't have an account?
            <router-link
              :to="{ name: 'RegisterAs' }"
              style="color: #004aad; cursor: pointer"
              >&nbsp;Register</router-link
            >
          </h6>
        </div>
      </v-col>
    </v-col>
  </div>
</template>

<script>
import BaseButton from "../components/reuseables/BaseButton";
import check from "../assets/check_one.svg";
import { login } from "../services/api/AuthApiService";
import {
  getCompanyData,
  getCorporateAgentByApplicationUserId,
  getCorporateOrganizationOnboardingByEmail, getInsuranceProviderByEmail,
  getMobileAgentByUserId, getPosProviderByApplicationUserEmail, getWaterTransportOperatorByEmail
} from "../services/api/APIService";

export default {
  name: "LoginView",
  components: { BaseButton },
  data() {
    return {
      title: "Welcome back.",
      description: "Transport Company Admin Board",
      value: false,
      confirmValue: false,
      dialog: false,
      keepMeIn: false,
      loading: false,
      check,
      loginData: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },

    async LoginTransportCompany() {
      this.loading = true;
        if (this.loginData.username.startsWith("0")){
            this.loginData.username = this.loginData.username.replace("0","234")
        }
      login(this.loginData)
        .then(async () => {
          let userData = JSON.parse(sessionStorage.getItem("userData"));
          let userToken = JSON.parse(sessionStorage.getItem("userToken"));
          if (userData.realm_access.roles.includes("TRANSPORT_COMPANY")) {
            await this.handleTransportCompanyAccess(
              userData.email,
              userToken.access_token,
              userToken.refresh_token
            );
          } else if (userData.realm_access.roles.includes("TRANSPORT_COMPANY_MANAGER") ||
              userData.realm_access.roles.includes("TRANSPORT_COMPANY_BOOKING_ADMIN")
          ) {
            window.open(
              process.env.VUE_APP_TRAVEL_FRONTEND_URL +
                `/company-staff/dashboard/?access_token=` +
                userToken.access_token +
                `&refresh_token=` +
                userToken.refresh_token,
              "_self"
            );
          } else if (userData.realm_access.roles.includes("DEVELOPER")) {
            window.open(
              process.env.VUE_APP_DEVELOPER_FRONTEND_URL +
                `/dashboard/?access_token=` +
                userToken.access_token +
                `&refresh_token=` +
                userToken.refresh_token,
              "_self"
            );
          }
          else  if(userData.realm_access.roles.includes("CORPORATE_AGENT")){
            await getCorporateAgentByApplicationUserId(userData.id).then(res =>{
              if(res.data.isCompanyVerified && res.data.isOnBoarded){
                window.open(
                    process.env.VUE_APP_CORPORATE_FRONTEND_URL +
                    `/admin/?access_token=` +
                    userToken.access_token +
                    `&refresh_token=` +
                    userToken.refresh_token,
                    "_self"
                );
              }
              else if(!res.data.isCompanyVerified && res.data.isOnBoarded){
                this.$displaySnackbar({
                  message : "Company is not verified, please contact support"
                })
              }
              else  if(!res.data.isCompanyVerified && !res.data.isOnBoarded){
                this.$router.push({name : "CorporateAgentOnboardingIdentity"})
              }
            })

          }
          else  if(userData.realm_access.roles.includes("INDIVIDUAL_AGENT")){
            await getMobileAgentByUserId(userData.id).then(res =>{
              if(res.data.status === 'ACTIVE' && res.data.onBoarded){
                window.open(
                    process.env.VUE_APP_MOBILE_AGENT_FRONTEND_URL +
                    `/admin/?access_token=` +
                    userToken.access_token +
                    `&refresh_token=` +
                    userToken.refresh_token,
                    "_self"
                );
              }
              else if(res.data.status === 'INACTIVE' && res.data.onBoarded){
                this.$displaySnackbar({ 
                  message : "Your account is not verified, please contact support"
                })
              }
              else  if(res.data.status === 'INACTIVE' && !res.data.onBoarded){
                this.$router.push({name : "IndividualAgentIdentity"})
              }
            })
          }
          else  if(userData.realm_access.roles.includes("CORPORATE_ORGANIZATION")){
            await getCorporateOrganizationOnboardingByEmail(userData.email).then(res =>{
              if(res.data.isCompanyVerified  && res.data.onboarded){
                window.open(
                    process.env.VUE_APP_FLEET_MANAGEMENT_FRONTEND_URL +
                    `/admin/?access_token=` +
                    userToken.access_token +
                    `&refresh_token=` +
                    userToken.refresh_token,
                    "_self"
                );
              }
              else if(!res.data.isCompanyVerified && res.data.onboarded){
                this.$displaySnackbar({
                  message : "Your account is not verified, please contact support"
                })
              }
              else  if(!res.data.isCompanyVerified && !res.data.onboarded){
                sessionStorage.setItem("corporateOrganizationData",JSON.stringify(res.data))
                this.$router.push({name : "FleetManagementOnboardingIdentity"})
              }
            })
          }
          else  if(userData.realm_access.roles.includes("WATER_TRANSPORT_OPERATOR")){
            let data = {}
            data.companyEmail = userData.email
            await getWaterTransportOperatorByEmail(data).then(res =>{
              if(res.data.waterTransportOperatorVerified  && res.data.onboarded){
                window.open(
                    process.env.VUE_APP_WAVES_FRONTEND_URL +
                    `/dashboard/?access_token=` +
                    userToken.access_token +
                    `&refresh_token=` +
                    userToken.refresh_token,
                    "_self"
                );
              }
              else if(!res.data.waterTransportOperatorVerified && res.data.onboarded){
                this.$displaySnackbar({
                  message : "Your account is not verified, please contact support"
                })
              }
              else  if(!res.data.waterTransportOperatorVerified && !res.data.onboarded){
                sessionStorage.setItem("waterTransportOperatorData",JSON.stringify(res.data))
                this.$router.push({name : "WaterTransportOperatorOnboardingIdentity"})
              }
            })
          }
          else  if(userData.realm_access.roles.includes("POS_PROVIDER")){
            await getPosProviderByApplicationUserEmail(userData).then(res =>{
              if(res.data.onboarded && res.data.providerVerified){
                this.$store.dispatch("posProviderData/getProviderData",userData)
                window.open(
                    process.env.VUE_APP_POS_PROVIDER_FRONTEND_URL +
                    `/dashboard/?access_token=` +
                    userToken.access_token +
                    `&refresh_token=` +
                    userToken.refresh_token,
                    "_self"
                );
              }

              else if(res.data.onboarded && !res.data.providerVerified ){
                this.$displaySnackbar({
                  message : "Your account is not verified, please contact support"
                })
              }
              else  if(!res.data.onboarded && !res.data.providerVerified){
                this.$displaySnackbar({
                  message : "You are yet to complete your onboarding, please contact support"
                })
              }
            })

          }
          else  if(userData.realm_access.roles.includes("INSURANCE_PROVIDER")){
            userData.companyEmail = userData.email
            await getInsuranceProviderByEmail(userData).then(res =>{
              if(res.data.onboarded && res.data.companyVerified){
                this.$store.dispatch("insuranceProviderData/getProviderData",userData)
                window.open(
                    process.env.VUE_APP_INSURANCE_PROVIDER_FRONTEND_URL +
                    `/?access_token=` +
                    userToken.access_token +
                    `&refresh_token=` +
                    userToken.refresh_token,
                    "_self"
                );
              }

              else if(res.data.onboarded && !res.data.companyVerified ){
                this.$displaySnackbar({
                  message : "Your account is not verified, please contact support"
                })
              }
              else  if(!res.data.onboarded && !res.data.companyVerified){
                sessionStorage.setItem("insuranceProviderData",JSON.stringify(res.data))
                this.$router.push({name : "InsuranceProviderOnboardingIdentity"})
              }
            })

          }

          this.loading = false;
        })
          .catch(err => {
              this.loading = false
              this.$displaySnackbar({
                  message: err.response.data.error_description,
                  success: false
              })

          }).finally(() => {
          this.loading = false
      })
    },
    async handleTransportCompanyAccess(userEmail, accessToken, refreshToken) {
      // let userData = JSON.parse(sessionStorage.getItem("userData"));
      let data = {}
      data.companyEmail = userEmail
      await getCompanyData(data).then((res) => {
        if (
          Object.keys(res.data).length &&
          res.data.transportCompanyVerified
        ) {
          window.open(
            process.env.VUE_APP_TRAVEL_FRONTEND_URL +
              `/transport-company/dashboard/?access_token=` +
              accessToken +
              `&refresh_token=` +
              refreshToken,
            "_self"
          );
        } else if (
          !res.data.transportCompanyVerified && !res.data.onboarded
        ) {
          // window.open(
          //   process.env.VUE_APP_TRAVEL_FRONTEND_URL +
          //     `/onboarding-identity/?access_token=` +
          //     accessToken +
          //     `&refresh_token=` +
          //     refreshToken,
          //   "_self"
          // );
          sessionStorage.setItem("companyData", JSON.stringify(res.data))
          this.$router.push({name:'CompanyOnboardingIdentity'})
        } else if(!res.data.transportCompanyVerified && res.data.onboarded){
          this.$displaySnackbar({
            message: "Company is not verified, please contact Nomadicpod support: support@nomadicpod.com",
            success: false,
          });
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-family: "Inter", serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 26px;
  color: #004aad;
}
.input {
  width: 48%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.agree {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.7);
}
.have-account {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.forgot-password {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: rgba(117, 115, 115, 0.7);
}

.left-side {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-image: url("../assets/sign-upbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 !important;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}
.left-side-body {
  display: flex;
  width: 100%;
  background: rgba(0, 74, 173, 0.51);
  flex-direction: column;
  padding: 5rem 7rem;
  justify-content: center;
}

.right-side {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 !important;
  background: white;
}

.right-side-body {
  display: flex;
  // background: red;
  width: 100%;
  flex-direction: column;
  padding: 7rem;
  justify-content: center;
  // align-items: center;
  @media screen and (max-width: 1440px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 1366px) and (min-width: 1025px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
  }
}
.right-side-body > img {
  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.right-side-body .welcome {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: var(--padiGrey);
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  margin-left: 0;
  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.right-side-body .admin {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: var(--padiGrey);
  margin-left: 0;
  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.title {
  font-family: "Inter", serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 26px;
  color: #004aad;
}
.input {
  width: 48%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.agree {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.7);
}
.have-account {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.buddy {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-top: 4rem;
}
.welcome {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35.2px;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 1024px) {
    margin-top: 35px;
  }
}
.admin {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-left: 0.8rem;
}

.signup-header {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 64px;
  color: #004aad;
  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
}

.terms {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: var(--padiGrey);
}

.already {
  font-family: "Inter", serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: var(--padiGrey) !important;
}

::v-deep .v-input--selection-controls {
  padding-top: 0!important;
  margin-top: 0!important;
}
</style>