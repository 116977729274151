<template>

    <section class="
          hundred-vh
          seventh-section
                  tw-items-center tw-justify-center tw-flex tw-flex-col
        ">
        <div class="header-div">
            <p class="header-div-text pb-15 ">How Nomadicpod Works</p>
        </div>
        <div class="
            tw-flex tw-w-full
            tw-items-center tw-justify-center
            addpadding
  
          ">
            <div class="
              tw-flex tw-flex-col tw-items-center
              box
              mt-10
              tw-px-4 tw-justify-center
            " style="width: 400px; height: 171px">
                <img src="../assets/first-step.png" alt="circle" class="mb-4 " width="80 " />

                <p class="header">Sign Up & Onboard</p>


            </div>
            <div class="icon-remote">
                <v-icon style="font-size: 30px" class="desktop" color="black">mdi-chevron-right</v-icon>
                <v-icon style="font-size: 30px" class="mobile" color="black">mdi-chevron-down</v-icon>

            </div>
            <div class="
              tw-flex tw-flex-col tw-items-center
              box
              tw-px-4 tw-justify-center
              tw-mt-10
           
            " style="width: 400px; height: 171px">
                <img src="../assets/second-step.png" alt="circle" class="mb-4" width="80" />
                <p class="header">Input Travel Details</p>

            </div>
            <div class="icon-remote">
                <v-icon style="font-size: 30px" class="desktop" color="black">mdi-chevron-right</v-icon>
                <v-icon style="font-size: 30px" class="mobile" color="black">mdi-chevron-down</v-icon>

            </div>
            <div class="
              tw-flex tw-flex-col tw-items-center
              box
              tw-px-4 tw-justify-center    tw-mt-10
         
            " style="width: 400px; height: 171px">
                <img src="../assets/third-step.png" alt="" class="mb-4" width="80" />
                <p class="header">Make Payment</p>
            </div>
            <div class="icon-remote">
                <v-icon style="font-size: 30px" class="desktop" color="black">mdi-chevron-right</v-icon>
                <v-icon style="font-size: 30px" class="mobile" color="black">mdi-chevron-down</v-icon>

            </div>
            <div class="
              tw-flex tw-flex-col tw-items-center
              box
              tw-px-4 tw-justify-center    tw-mt-10
           
            " style="width: 400px; height: 171px">
                <img src="../assets/last-step.png" alt="" class="mb-4" width="80" />
                <p class="header">Enjoy Your Trip!</p>
            </div>
        </div>
    </section>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>
    .seventh-section {
        background-color: #fdfffc;
    }

    .header-div-text {
        font-family: Raleway, serif !important;
        font-weight: 600;
        font-size: 40px;
        line-height: 56px;
        text-align: center;
        color: #263238;

        @media screen and (max-width: 768px) {
            font-family: Raleway, serif !important;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 56px;
            letter-spacing: 0;
            text-align: center;
        }
    }

    .desktop {
        @media (max-width:992px) {
            display: none;
        }
    }

    .mobile {
        display: none;

        @media (max-width:992px) {
            display: block;
        }
    }

    .header {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #4F4F4F;
        width: 120px;

        @media (max-width:768px) {
            font-size: 16px;

        }
    }

    .addpadding {
        padding: 7rem auto;
        width: 60%;
        flex-direction: row;

        @media (max-width: 1440px) {
            width: 70%;
        }

        @media (max-width: 1024px) {
            width: 90%;
        }

        @media (max-width: 992px) {
            width: 90%;
            flex-direction: column;
        }
    }

    .icon-remote {
        padding: 100px 20px 0;

        @media (max-width:992px) {
            padding: 0;
        }
    }
</style>