import { render, staticRenderFns } from "./Ribs.vue?vue&type=template&id=369fd56a&scoped=true&"
import script from "./Ribs.vue?vue&type=script&lang=js&"
export * from "./Ribs.vue?vue&type=script&lang=js&"
import style0 from "./Ribs.vue?vue&type=style&index=0&id=369fd56a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "369fd56a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
