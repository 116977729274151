<template>
  <div class="meet-the-team">
    <!-- <div class="mtt-intro">
      <h1 class="py-7 mtt">Meet the team</h1>
      <p>
        Meet our talented team of fun-lovers, magic makers and exemplary
        visionaries
      </p>
    </div>
    <div class="tw-flex tw-w-full tw-flex-wrap display-center">
      <div class="" v-for="(team, index) in teams" :key="index" >
        <v-card class="team" >
          <v-img :src="team.avatar" class="team-image team-avatar">
            <v-img :src ="team.image" class="team-image main-picture" transition="scroll-y-reverse-transition"/>
          </v-img>

          <div class="team-div">
            <div class="tw-flex tw-w-full tw-px-3 tw-items-center" >
              <vue-feather type="linkedin" class="tw-cursor-pointer" style="color: black; height: 14px; width: 14px"  @click="handleLinkedInClick(team)"/>
              <label class="team-name tw-ml-2">{{ team.name }}</label>
            </div>
            <div class="tw-flex tw-w-full tw-px-3 tw-items-center">
              <v-icon class="icon" color="white"> mdi-twitter</v-icon>
              <label class="team-role tw-ml-2">{{ team.role }}</label>
            </div>
          </div>
        </v-card>
      </div>
    </div> -->
  </div>
</template>

<script>
import tega from '@/assets/team/tega.png'
import lois from '@/assets/team/lois.png'
import efe from "@/assets/team/efe.jpeg"
import leila from "@/assets/team/leila.png"


export default {
  data() {
    return {
      teams: [
          {
          name: "Ovuefe Ighobeduo",
          avatar: efe,
          image:
            "https://res.cloudinary.com/myroadpadi/image/upload/v1649264728/Efe_crop_qdeu55.jpg",
          twitter: "",
          role: "CTO",
        },

        {
          name: "Anielila Inetimi",
          avatar: leila,
          image:
            "https://res.cloudinary.com/myroadpadi/image/upload/v1649264689/Leila_crop_mex8az.jpg",
          twitter: "",
          role: "COO",
        },

        {
          name: "Lois Ekong",
          avatar: lois,
          image:
            "https://res.cloudinary.com/myroadpadi/image/upload/v1651682434/chioma_oorknk.jpg",
          twitter: "",
          role: "Software Engineer",
        },
        {
          name: "Tega Isiboge",
          avatar:tega,
          image: "https://res.cloudinary.com/myroadpadi/image/upload/v1651682506/tega_crop_hkgcci.jpg",
          twitter: "",
          role: "CEO",
        },
      ],
        selectedPerson:null,

    };
  },
  methods:{
    handleLinkedInClick(item){
      this.selectedPerson = item.name
        if (this.selectedPerson === "Tega Isiboge"){
         window.open("https://www.linkedin.com/in/tega-isiboge/")
        }
        else if (this.selectedPerson === "Lois Ekong"){
          window.open("https://www.linkedin.com/in/lois-ekong/")
        }
        else if (this.selectedPerson === "Ovuefe Ighobeduo"){
          window.open("https://www.linkedin.com/in/ovuefe-ighobeduo/")
        }
        else if (this.selectedPerson === "Anielila Inetimi"){
          window.open('https://www.linkedin.com/in/anielila-inetimi-592811200/')
        }
    }
  }
};
</script>

<style lang=scss scoped>
/* .meet-the-team {
  padding: 10% 10% 4% 11rem;
  min-height: 100vh;
  background-color: #fffffb;

  @media screen and (max-width: 1264px) {
    padding: 10% 7%;
    flex-direction: column;
    align-items: center;
    display: flex;
  }
  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
} */

.mtt-intro {
  width: 40%;
  font-family: "DM Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 7%;

  @media screen and (max-width: 1440px) {
    width: 60%;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.mtt-intro p {
  font-weight: 400;
  font-family: "Raleway";
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
}

.mtt {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 48px;
  line-height: 54px;
  color: var(--padiGrey);
}

.team {
  width: 300px;
height: 500px;
  margin: 10px;
}

.team-div {
 margin: 20px;
}

.cards-div {

}

.team-image {
object-fit: cover;
  width: 400px;
  height: 400px;
  background-color: transparent;
  margin-bottom: 20px;
}
.main-picture{
  display: none;
}
.v-card:hover{
  .main-picture{
    display: flex;
    transition: 500ms ease-in-out;
    animation: ease-in-out 500ms;
  }

}
.v-image {
  width: 300px;
  height: 400px;
}

.team-name {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 1rem;
  line-height: 36px;
  color: black;
  display: block;
}

.team-role {
  font-size: 15px;
  font-weight: 300;
  color: black;
}

.icon {
  font-size: 16px !important;
}
</style>
