<template>
    <div id="signup">
    
        <div class="glue tw-flex ">
       <v-col lg="7" md="12" sm="12" class="advert">
        <v-carousel cycle continuous hide-delimiters :show-arrows="false" height="100%"  >
         <v-carousel-item>
         <div class="page-one group-padding">
           <router-link to="/">
             <img src="@/assets/logos/white.png" alt="" width="150">
           </router-link>
     <div class="top-section1">
         <h6 class="padi mb-7">Hey Nomads!</h6>
        <h1 class="welcome1 mb-5">Welcome to Nomadicpod.</h1>
        <p class="text2">Your one-stop travel buddy</p>
     </div>

       </div>

        </v-carousel-item>
         <v-carousel-item>
         <div class="page-two group-padding">
           <router-link to="/">
             <img src="@/assets/logos/white.png" alt="" width="150">
           </router-link>
     <div class="top-section1">
        <h1 class="book-trip mb-5">Book Trips Easily & Quickly</h1>
        <p class="text2">Your one-stop travel buddy</p>
     </div>

       
        </div>

        </v-carousel-item>
         <v-carousel-item>
        <div class="page-three group-padding">
          <router-link to="/">
     <img src="@/assets/logos/white.png" alt="" width="150">
          </router-link>
     <div class="top-section1">

        <h1 class="book-trip mb-5">Get a One-Time Traveller Code</h1>
        <p class="text2">Fill in your details once for all time and it’s would be accessible through all the transport companies</p>
     </div>

       </div>

        </v-carousel-item> 
       </v-carousel>
     
            </v-col>
    
            <v-col lg="5" md="12" sm="12" class="details">

                <h1 class="welcome">Welcome!</h1>
    
                <p class="text1">Book trips easily and get your details across transport companies</p>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(registerCustomer)">
              <ValidationProvider
              name="Email"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 mb-2" :class="classes">
                <v-text-field

                  solo
                  type="email"
                  hide-details
                  placeholder="janedoe@gmail.com"
                  v-model="signupData.email"
                  required
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
    
        <ValidationProvider
              name="Password"
              rules="required"
              v-slot="{ classes, errors }">

         <div class="tw-pt-4 mb-2" :class="classes">
                <v-text-field
                  hide-details
                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value = !value)"
                  :type="value ? 'text' : 'password'"
                  solo
                  placeholder="Password"
                  name="password"
                  v-model="signupData.password"
                  id="password"
                >
                </v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
             <ValidationProvider
              name="Confirm Password"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 mb-2" :class="classes">
                <v-text-field
                  hide-details
                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value = !value)"
                  :type="value ? 'text' : 'password'"
                  solo
                  placeholder="Confirm Password"
                  name="Confirm Password"
                  v-model="signupData.confirmPassword"
                  id="confirmPassword"
                >
                </v-text-field>
                <span>{{ errors[0] }}</span>
                <span class="errmsg" v-if="showError">Password doesn't match</span>
              </div>
            </ValidationProvider>
            <div class="tw-pt-1">
              <v-checkbox hide-details class="terms" v-model="agree">
                <template #label>
                  <label class="mt-1"
                    >I agree to the
                    <span style="color: #f784ad" class="tw-cursor-pointer"  @click="toggleTerms">
                      Terms of Use</span
                    >
                    and
                    <span style="color: #f784ad" class="tw-cursor-pointer" @click="togglePrivacy">
                      Privacy Policy</span
                    ></label
                  >
                
                </template>
              </v-checkbox>
            </div>


            <base-button button-text="Sign Up" class="mt-6" :disabled="!agree" :loading="loading" type="submit"  width="100%"/>

               <Terms  v-if="showTerms"/>
                <Privacy v-if="showPrivacy"/>
           </form>
          </ValidationObserver>
              <div class="tw-pt-4">
                <h6 class="tw-flex already tw-items-center tw-justify-center mt-2">
                  Don't have an account?
                  <router-link
                      :to="{ name: 'RegisterAs' }"
                      style="color: #004aad; cursor: pointer"
                  >&nbsp;Register</router-link
                  >
                </h6>
              </div>

            </v-col>
          <modal
              :dialog="dialog"
              title="Thank you"
              additional-text="We just sent you a confirmation email.
  Please check your inbox to confirm your  email."
              @close="toggleModal"
              :icon="check"
          />
        </div>
    </div>
</template>

<script>
import Modal from "@/components/reuseables/Modal.vue";
import check from "../../assets/check_one.svg";

import Terms from "@/components/reuseables/TermsModal.vue"
import Privacy from "@/components/reuseables/PrivacyModal.vue"
import BaseButton from "@/components/reuseables/BaseButton";
import {customerSignUp} from "@/services/api/AuthApiService"
export default {
    components: {BaseButton, Terms, Privacy, Modal },
name:"CustomerSignUp",
    data() {
        return {
          check,
          loading:false,
            dialog:false,
            showTerms: false,
            showPrivacy: false,
             value: false,
            agree: false,
           showError: false,
             signupData: {
             email: "",
              password: "",
               confirmPassword:""
              },
        }
    },
    methods: {
      toggleModal() {
        if (this.dialog) {
          this.$router.push({ name: "Login" });
        }
        this.dialog = !this.dialog;
      },
        toggleTerms() {
            this.showTerms = !this.showTerms
        },
        togglePrivacy() {
            this.showPrivacy = !this.showPrivacy
        },
        registerCustomer() {
          this.loading=true
          if (this.signupData.password === this.signupData.confirmPassword){
            customerSignUp(this.signupData)
                .then((res) => {
                  console.log(res);

                  this.$displaySnackbar({
                    message: "Your Signup was Successful",
                    success: true,
                  });
                  this.dialog = true;
                  this.loading = false;
                })
                .catch((err) => {
                  console.log(err.response);
                  this.$displaySnackbar({
                    message: err.response.data.details[0],
                    success: false,
                  });
                  this.loading = false;
                })
          }
          else{
              this.$displaySnackbar({
                message: "Passwords don't match",
                success: false,
              });
            }
        this.loading = true;

    },

     validEmail(email) {
      let valid =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.match(valid)) {
        return true;
      } else {
        this.$displaySnackbar({
          message: "Email not valid e.g example@company.com",
          success: false,
        });
        return false;
      }
    },
    }
}
</script>

<style lang="scss" scoped>
#sigunup{
  height: 100vh !important;
}
.glue{
    @media (max-width:1264px) {
        flex-direction: column;
    }
}
.advert{
    padding: 0 !important;
}
.details {
    background-color: #ffff;
    min-height: 100vh;
    padding:15rem 8rem 0;
    @media (max-width:1440px) {
      padding: 15rem 3rem 0;
    }
     @media (max-width:1264px) {
     padding: 7rem  5rem;
    }
      @media (max-width:576px) {
     padding: 7rem 2rem ;
    }
  @media (max-height: 800px){
    padding: 7rem 8rem 0;
  }
}

.welcome {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 29px;
    line-height: 64px;
    color: #004AAD;
}

.text1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
}
.text2{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
color: #FFFFFF;

}
.book-trip{
    font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 34px;
line-height: 40px;
color: #FFFFFF;
text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
}
.welcome1
{
font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 33px;
/* identical to box height */


color: #FFFFFF;

text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
    padding: 20px !important;
}
.terms {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: var(--padiGrey);
}
.padi{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;


}
.v-input{
    background-color:#FEFEFE; 

}
.v-btn{
    background-color: #004AAD !important;
box-shadow: 0px 2px 10px rgba(130, 130, 130, 0.2);
border-radius: 10px !important;
color: #ffff;
height: 50px !important;
}

.page-one{
 background:linear-gradient( rgba(2, 58, 134, 0.51), rgba(2, 58, 134, 0.52)), url(../../assets/signup-pic2.png);


 }
 .page-two{
 background:linear-gradient( rgba(2, 58, 134, 0.51), rgba(2, 58, 134, 0.52)), url(../../assets/signup-pic.png);


 }
  .page-three{
 background:linear-gradient( rgba(2, 58, 134, 0.51), rgba(2, 58, 134, 0.52)), url(../../assets/signup-pic3.png);
 

 }
 .group-padding{
  padding:5rem 10rem 6rem;
   height: 100vh;
 background-size: cover;
 color: white;
   @media (max-height: 781px){
     min-height: 100vh !important;
   }
@media (max-width:1264px) {
     padding: 7rem 5rem ;
  min-height: 100vh;
    }
    @media (max-width:576px) {
        padding: 6rem 3rem;
    }
 }
 .footer{
     padding-top: 12rem;
     @media (max-width:1264px) {
     padding-top: 9rem;
    }
   @media (max-height: 781px){
     padding-top: 7rem;
   }
 }
 .page-one .footer{
   @media (max-height: 781px){
     padding-top: 5rem;
   }
 }
 .top-section1{
 padding-top:15rem;
  @media (max-width:1264px) {
     padding-top: 9rem;
    }

 }
.already {
  font-family: "Inter", serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: var(--padiGrey) !important;
}
</style>