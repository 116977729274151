<template>
  
      <section class="six hundred-vh">
        <v-carousel cycle continuous height="700" hide-delimiter-background :show-arrows="false" light
          delimiter-icon="mdi-minus">
          <div class="tw-h-full back-img">
            <v-carousel-item class="first-box">
              <p class="first-header">For Transporters</p>

              <p class="header tw-mb-0 ">
                Have A Wider Reach on Travelers
              </p>
              <p class="sub-text">
                With our tech-enabled transport and travel solutions, services and facilities are readily accessible,
                enabling faster bookings, and seamless interactions with customers and other service providers.
              </p>
              <button text class="more-button">See More <v-icon color="#004aad">mdi-arrow-right-bold-circle-outline
                </v-icon></button>
                <img src="../assets/homepage/mobile-triangle.png"  class="gone"  alt="">
            </v-carousel-item>
            <v-carousel-item class="tw-w-full second-box
              md:tw-mt-2 ">

              <p class="first-header">For Developers</p>

              <p class="header">Get Decentalized Access to Travel Assets</p>
              <p class="sub-text">
                Use Nomadicpod APIs to access the services and facilities of over
                100 intercity transport services, from routes, fleets, trips,
                bookings and more.
              </p>
              <button text class="more-button">See More <v-icon color="#004aad">mdi-arrow-right-bold-circle-outline
                </v-icon></button>
                <img src="../assets/homepage/mobile-triangle.png"  class="gone" alt="">


            </v-carousel-item>
          </div>
        </v-carousel>
      </section>
</template>

<script>
export default {

}
</script>

<style lang='scss' scoped>

  .six {
    padding: 10rem 11rem 0;
    background-color: #F9FBFD;
    background-image: url('../assets/homepage/triangle.svg');
    background-size: contain;
    background-position: right center;
@media (max-width:1264px) {
    padding: 7rem 3rem 0;
        background-position: right right;

}
@media (max-width:1024px) {
        background-image:none;

}

  }

  .more-button {
    font-family: 'Raleway';
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #004AAD;
    margin-top: 10px;


  }

  .v-carousel__controls__item .v-btn .v-item--active .v-btn--active .v-btn--icon {
    color: #004AAD !important;
  }

    .first-box {
        width: 40%;
   
@media (max-width:1024px) {
    width: 100%;
         background-color: #F5FAFF;
}
      .header {
        font-family: Raleway, sans-serif !important;
        font-weight: 600;
        font-size: 36px;
        line-height: 56px;
        color: #263238;
        text-align: start;
        @media screen and (max-width: 768px) {
          font-size: 22px;
          line-height: 30px;
        }
      }
      .sub-text {
        font-family: Inter, sans-serif !important;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 35px;
        color: #263238;
        text-align: start;

        @media screen and (max-width: 768px) {
          font-family: "Inter", sans-serif !important;
          font-size: 14px;
          line-height: 28px;
        }
      }
    }

    .second-box {
      justify-content: flex-start;
width: 40%;
@media (max-width:1024px) {
    width: 100%;
}

      .header {
        font-family: Raleway, sans-serif !important;
        font-weight: 600;
        font-size: 36px;
        line-height: 56px;
        color: #263238;

        @media screen and (max-width: 768px) {
          height: 60px;
          width: 300px;
          font-size: 22px;
          line-height: 30px;
        }
      }

      .sub-text {
        font-family: Inter, sans-serif !important;
        font-size: 16px;
        line-height: 35px;
        color: #263238;

        @media screen and (max-width: 768px) {
          font-size: 14px;
          line-height: 22px;
          padding-right: 2rem;
        }

     
      }
    }
  

  .first-header {
    font-family: "Raleway", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 0;
    text-align: left;
    color: rgba(0, 74, 173, 1);
  }
  .gone{
    display: none;
    @media (max-width:1024px) {
display: block;
width: 60%;
margin: 4rem auto 6rem;
}
@media (max-width:768px) {
   width: 90%;
   margin: 3rem auto ;

 
}
  }
</style>