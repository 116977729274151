<template>
  <div>
    <v-col sm="12" class="left-side-body">
        <img src="../../assets/logos/white.png" alt="logo" style="height: 30px; width: 150px; cursor: pointer"
             @click="goHome">
        <p class="buddy mb-0">Hey!</p>
        <h6 class="welcome tw-mt-5">Let’s Get You Set</h6>
        <p class="admin tw-mt-5">We’re excited to have you! We just need some more information.</p>

        <ol class="pl-0 onboarding-lists">
          <li class="onboarding-lists-list">
            <div class="circle" :class="currentPage === 'CompanyOnboardingIdentity' ? 'active' : ''">
              <span class="span-tag" :class="currentPage === 'CompanyOnboardingIdentity' ? 'active' : ''">1</span>
            </div>
            <span class="span-tag" :class="currentPage === 'CompanyOnboardingIdentity' ? 'active' : ''">Brand Identity</span>
          </li>
          <li class="onboarding-lists-list">
            <div class="circle" :class="currentPage === 'CompanyOnboardingAddress' ? 'active' : ''">
              <span class="span-tag" :class="currentPage === 'CompanyOnboardingAddress' ? 'active' : ''">2</span>
            </div>
            <span class="span-tag" :class="currentPage === 'CompanyOnboardingAddress' ? 'active' : ''">Address</span>
          </li>
          <li class="onboarding-lists-list">
            <div class="circle" :class="currentPage === 'CompanyOnboardingContact' ? 'active' : ''">
              <span class="span-tag" :class="currentPage === 'CompanyOnboardingContact' ? 'active' : ''">3</span>
            </div>
            <span class="span-tag" :class="currentPage === 'CompanyOnboardingContact' ? 'active' : ''">Contact</span>
          </li>

          <li class="onboarding-lists-list">
            <div class="circle" :class="currentPage === 'CompanyOnboardingVerification' ? 'active' : ''">
              <span class="span-tag" :class="currentPage === 'CompanyOnboardingVerification' ? 'active' : ''">4</span> 
            </div>
            <span class="span-tag" :class="currentPage === 'CompanyOnboardingVerification' ? 'active' : ''">Verification</span>
          </li>
          <li class="onboarding-lists-list">
            <div class="circle" :class="currentPage === 'CompanyOnboardingAccountDetails' ? 'active' : ''">
              <span class="span-tag" :class="currentPage === 'CompanyOnboardingAccountDetails' ? 'active' : ''">5</span>
            </div>
            <span class="span-tag" :class="currentPage === 'CompanyOnboardingAccountDetails' ? 'active' : ''">Account Details</span>
          </li>
        </ol>
    </v-col>
    


  </div>
</template>

<script>
  export default {
    computed: {
      currentPage() {
        // console.log(this.$route)
        return this.$route.name
      }
    },
    methods:{
      goHome(){
        window.open(process.env.VUE_APP_MAIN_FRONTEND_URL,'_self')
      },
    }
  }
</script>

<style lang="scss" scoped>
.left-side-body{
  // width: 100%;
  background: rgba(0, 74, 173, 0.51);
  // background: red;
  padding:3rem 7rem;
  padding-left: 120px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;


}
.buddy{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  margin-top: 99px;
}
.welcome{
  font-family: "Inter", serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.02em;
  text-align: left;
  text-shadow: 0px 4px 40px 0px #00000040;
  margin-top: 8px;

  @media screen and (max-width: 1024px) {
    margin-top: 35px;
  }
}
.admin{
  margin-top: 8px;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: left;
}
.onboarding-lists {
  margin-top: 36px;
}

.onboarding-lists .onboarding-lists-list {
  display: flex;
  align-items: center;
  margin: 16px 0px;
  font-size: 16px;
  font-style: normal; 
  font-weight: 500; 
  line-height: 24px;
  font-family: "Inter";  
  letter-spacing: 0.05em;
}
.onboarding-lists .onboarding-lists-list > .circle {
  margin-right: 12px;
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  width: 33px;
  border-radius: 50%;
}
.onboarding-lists .onboarding-lists-list .circle.active {
  height: 44px;
  width: 44px;
  margin-left: -5px;
  border-width: 3px;
  font-size: 20px;
  font-weight: 600;
}
.onboarding-lists .onboarding-lists-list > .span-tag:first-child {
  margin-right: 12px;
  border: 1px solid #ffffff;
  height: 50px;
  width: 50px;
}
.onboarding-lists .onboarding-lists-list > .span-tag.active:first-child {
  border: 2px solid #ffffff;
  padding: 13px 14px;
  font-weight: 600;
  font-size: 21px;
}
.onboarding-lists .onboarding-lists-list > .span-tag.active:last-child {
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
</style>