import { render, staticRenderFns } from "./ColorPickerModal.vue?vue&type=template&id=69a3cdda&scoped=true&"
import script from "./ColorPickerModal.vue?vue&type=script&lang=js&"
export * from "./ColorPickerModal.vue?vue&type=script&lang=js&"
import style0 from "./ColorPickerModal.vue?vue&type=style&index=0&id=69a3cdda&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69a3cdda",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VColorPicker,VDialog})
