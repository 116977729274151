<template>
  <div class="tw-flex  tw-flex-row tw-w-full  tw-justify-between " style="height: auto" v-if="seatsData.length">
    <div v-if="seatsData.length > 24" class="custom">
      <div v-for="(seat, index) in seatsData" :key="index">
        <seat v-if="index > 1" :seat-number="seat.seatNumber" :seat-status="seat.status"
              :class="[index > 0 ? 'tw-mb-8 tw-mr-9 ': '']" v-on="$listeners" />
      </div>
    </div>
    <div v-else class="tw-flex  tw-flex-row tw-w-full  tw-justify-between ">
      <div v-for="(seat, index) in seatsData" :key="index">
        <seat :seat-number="seat.seatNumber" :seat-status="seat.status" :class="[index > 0 ? 'tw-mb-8': '']" v-on="$listeners" />
      </div>
    </div>

  </div>
</template>

<script>
import Seat from "./Seat";
export default {
  name: "BackSeatColumn",
  components: {Seat},
  props:{
    seatRow:{
      type: [Number],
      default: 3
    },
    seatsData : Array
  }
}
</script>

<style scoped lang="scss">
.custom{
  display: flex;
  width: 300px;
  height: auto;
  flex-wrap: wrap;
}
</style>